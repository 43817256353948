import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpened: false,
}

export const filtersSlice = createSlice({
    name: 'searchInputs',
    initialState,
    reducers: {
        switchIsOpened: (state, action) => {
            state.isOpened = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { switchIsOpened } = filtersSlice.actions

export default filtersSlice.reducer
