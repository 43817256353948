import {format} from "date-fns"
import ruLocale from "date-fns/locale/ru"
import styles from "./TicketTime.module.scss"
import Tooltip from "../../../../components/UI/Tooltip/Tooltip"
import {ReactSVG} from "react-svg"
import clock from "../../../../assets/icons/clock-ticket.svg"


const makeDateFromString = (date) => {
  const dateValues = date?.match(/\d+/g);
  if (dateValues) {
    return new Date(dateValues[2], +dateValues[1] - 1, dateValues[0]);
  }
}

const TicketTime = (props) => {
    const {
        time = "",
        date = "",
        airport = "",
    } = props

    return (
        <div className={styles.ticketTime}>
            <div className={styles.ticketTime__time}>
                {time.substring(0, time.lastIndexOf(':'))}
                <Tooltip
                    classes={styles.ticketTime__time__clock}
                    content={"По местному времени"}
                    children={<ReactSVG src={clock} />}
                />
            </div>
            <p className={styles.ticketTime__date}>{date}</p>
            <p className={styles.ticketTime__airport}>{airport}</p>
        </div>
    )
}

export default TicketTime
