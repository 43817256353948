import styles from "./Drawer.module.scss"

export const Drawer = ({open, children}) => {
    return (
        <nav
            className={styles.drawer}
            style={{
                transform: `${open ? "translateY(0px)" : "translateY(-150%)"}`,
                opacity: `${open ? "1" : "0"}`,
            }}
        >
            {children}
        </nav>
    )
}
