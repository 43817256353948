import { configureStore } from '@reduxjs/toolkit';
import searchInputsReducer from './slices/searchInputs';
import filtersReducer from './slices/filters';
import headerSlice from './slices/headerSlice';
import sortingReducer from './slices/sorting';
import ticketsReducer from './slices/tickets';
import userReducer from './slices/user';
import catalogReducer from './slices/catalog';
import passengersReducer from './slices/passengers';
import { setupListeners } from '@reduxjs/toolkit/query'
import { userApi } from "../services/user";
import { catalogApi } from "../services/catalog";
import { ticketsApi } from "../services/tickets";
import { passengersApi } from "../services/passengers";
import { partnersApi } from '../services/partners';
import { ordersApi } from "../services/orders";
import { linksApi } from "../services/links";
import ordersReducer from './slices/orders';
import linksReducer from './slices/links';

export const store = configureStore({
    reducer: {
        searchInputs: searchInputsReducer,
        headerSlice: headerSlice,
        filters: filtersReducer,
        sorting: sortingReducer,
        tickets: ticketsReducer,
        user: userReducer,
        catalog: catalogReducer,
        passengers: passengersReducer,
        orders: ordersReducer,
        links: linksReducer,
        [userApi.reducerPath]: userApi.reducer,
        [catalogApi.reducerPath]: catalogApi.reducer,
        [ticketsApi.reducerPath]: ticketsApi.reducer,
        [passengersApi.reducerPath]: passengersApi.reducer,
        [partnersApi.reducerPath]: partnersApi.reducer,
        [ordersApi.reducerPath]: ordersApi.reducer,
        [linksApi.reducerPath]: linksApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        userApi.middleware,
        catalogApi.middleware,
        ticketsApi.middleware,
        passengersApi.middleware,
        partnersApi.middleware,
        ordersApi.middleware,
        linksApi.middleware
      ),
})

setupListeners(store.dispatch)
