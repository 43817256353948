import { Rate } from 'antd';
import style from "./Rating.module.scss";

const Rating = ({disabled, defaultValue}) => {
  return (
    <Rate
      className={style.custom_rating}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  )
}

export default Rating