import CustomCheckbox from "../../components/UI/Checkbox/CustomCheckbox";
import style from "./Tickets.module.scss";

const Filters = ({filters, checkedFilters, onChange}) => {
  return(
    <div className={style.filters_block}>
      {filters.map(filter => (
        <CustomCheckbox
          key={filter.id}
          className={style.filter_checkbox}
          onChange={event => onChange(event.target.checked, filter.id)}
          text={filter.name}
          checked={checkedFilters.indexOf(filter.id) !== -1}
        />
      ))}
    </div>
  )
}

export default Filters;