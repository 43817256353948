import DropdownInput from "../../../components/UI/DropdownInput/DropdownInput";
import CustomInput from "../../../components/UI/Input/Input";
import styles from "./Customer.module.scss";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import DropdownCountry from "components/UI/DropdownInput/DropdownCountry";

export function filterSelectWithCountryName(input, option) {
    return (
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
        (option?.name ?? "").toLowerCase().includes(input.toLowerCase()) ||
        (option?.nameEn ?? "").toLowerCase().includes(input.toLowerCase())
    );
}

const Customer = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    showErrors,
    setShowErrors,
}) => {
    const { tel_codes, countries, countries_en } = useSelector(
        (state) => state.catalog.catalog
    );
    const makeArrayFromObject = (obj, prefix = "", needPostfix) => {
        const arr = [];
        for (let key of Object.keys(obj)) {
            let label = prefix ? prefix + obj[key] : obj[key];
            if (needPostfix) label += ` (${key})`;
            const name = countries[key];
            const nameEn = countries_en[key];
            arr.push({ value: key, name, nameEn, label });
        }
        return arr;
    };
    const numberCode = makeArrayFromObject(tel_codes ?? [], "+", true);

    return (
        <div className={styles.customer}>
            <h2 className={styles.customer__title}>Покупатель</h2>
            <p className={styles.customer__subtitle}>
                На указанную почту будет отправлена маршрутная квитанция
            </p>
            <div className={styles.customer__inputs}>
                <CustomInput
                    type="email"
                    name={"email"}
                    placeholder="E-mail"
                    classes={styles.form__field_text}
                    container={styles.form__field_textContainer}
                    value={values.customer.email}
                    onChange={(value) => setFieldValue("customer.email", value)}
                    onBlur={handleBlur}
                    error={
                        showErrors && (errors?.email || errors?.customer?.email)
                    }
                />
                <DropdownCountry
                    value={
                        values.customer.telCode ? values.customer.telCode : "+7"
                    }
                    options={numberCode}
                    showSearch
                    defaultValue={"+7"}
                    onChange={(value) =>
                        setFieldValue(
                            "customer.telCode",
                            "+" + tel_codes[value]
                        )
                    }
                    filterOption={filterSelectWithCountryName}
                />
                <CustomInput
                    type="text"
                    name={"tel"}
                    placeholder="Телефон"
                    classes={styles.form__field_text}
                    container={styles.form__field_textContainer}
                    value={values.customer.tel}
                    onChange={(value) => setFieldValue("customer.tel", value)}
                    error={
                        showErrors && (errors?.phone || errors?.customer?.tel)
                    }
                />
            </div>
        </div>
    );
};

export default Customer;
