import style from "./PlusMinus.module.scss";
import { useState } from "react";
import MinusIcon from "../../../assets/icons/minus-icon.svg"
import PlusIcon from "../../../assets/icons/plus-icon.svg"
import { ReactSVG } from "react-svg";

const PlusMinus = ({ initialNumber, minimumNumber, onChange }) => {
  const [number, setNumber] = useState(initialNumber);

  const numberDecrement = () => {
    if (number > minimumNumber){
      const newNumber = number - 1;
      setNumber(newNumber);
      onChange(newNumber);
    }
  }

  const numberIncrement = () => {
    const newNumber = number + 1;
    setNumber(newNumber);
    onChange(newNumber);
  }

  return (
    <div className={style.plus_minus}>
        <a onClick={numberDecrement} className={style.minus}><ReactSVG src={MinusIcon}/></a>
          <span>{number}</span>
        <a onClick={numberIncrement} className={style.plus}><ReactSVG src={PlusIcon}/></a>
    </div>
  )
}

export default PlusMinus;