import Popup from "../../../../components/UI/Popup/Popup";
import {ReactSVG} from "react-svg";
import ImageDone from "../../../../assets/icons/image-done.png";
import CustomButton from "../../../../components/UI/CustomButton/CustomButton";

const MoneyWithdrawResultPopup = ({visible, setVisibility}) => {
  return (
    <Popup
      open={visible}
      onCancel={() => setVisibility(false)}
      title={(
        <>
          <img alt="doneImg" src={ImageDone}/>
          <p>Средства отправлены</p>
          <span>Они поступят на выбранный способ пополнения в течение 24 часов</span>
        </>
      )}
      footer={(
        <CustomButton
          primary
          fullWidth
          onClick={() => setVisibility(false)}
        >
          Вернуться в кабинет
        </CustomButton>
      )}
    >
    </Popup>
  )
}

export default MoneyWithdrawResultPopup;

