import Popup from "../UI/Popup/Popup";
import ImageProfile from "../../assets/icons/image-profile.png";
import LoginForm from "../UI/Forms/LoginForm";
import RegistrationForm from "../UI/Forms/RegistrationForm";
import Lock from "../../assets/icons/lock.png";
import RecoverPasswordForm from "../UI/Forms/RecoverPasswordForm";
import Mail from "../../assets/icons/mail.png";
import ImageDone from "../../assets/icons/image-done.png";
import CustomButton from "../UI/CustomButton/CustomButton";
import ChangePasswordForm from "../UI/Forms/ChangePasswordForm";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CurrencyForm from "components/UI/Forms/CurrencyForm";
import LangForm from "components/UI/Forms/LangForm";

const Popups = ({
    isLoginModalVisible,
    setLoginModalVisible,
    isCurrencyModalVisible,
    setCurrencyModalVisible,
    isLangModalVisible,
    setLangModalVisible,
}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const pass_reset_token = urlParams.get("pass_reset_token");
    const email_verified = urlParams.get("email_verified");

    const [isRegistrationModalVisible, setRegistrationModalVisible] =
        useState(false);
    const [isRecoverPasswordModalVisible, setRecoverPasswordModalVisible] =
        useState(false);
    const [isEmailModalVisible, setEmailModalVisible] = useState(false);
    const [isChangePasswordModalVisible, setChangePasswordModalVisible] =
        useState(!!pass_reset_token);
    const [email, setEmail] = useState("");
    const [registrationSuccessText, setRegistrationSuccessText] = useState("");
    const [
        isRegistrationSuccessModalVisible,
        setRegistrationSuccessModalVisible,
    ] = useState(false);
    const [
        isChangePasswordSuccessModalVisible,
        setChangePasswordSuccessModalVisible,
    ] = useState(false);
    const [
        isRegistrationConfirmModalVisible,
        setRegistrationConfirmModalVisible,
    ] = useState(!!email_verified && email_verified !== "false");

    const navigate = useNavigate();

    const currencyModalClose = () => {
        setCurrencyModalVisible(false);
    };
    const langModalClose = () => {
        setLangModalVisible(false);
    };
    return (
        <>
            <Popup
                open={isLoginModalVisible}
                onCancel={() => setLoginModalVisible(false)}
                title={
                    <>
                        <img alt="profileIcon" src={ImageProfile} />
                        <p>Войти в AirSurfer</p>
                    </>
                }
                footer={null}
            >
                <LoginForm
                    onRegisterClick={() => {
                        setRegistrationModalVisible(true);
                        setLoginModalVisible(false);
                    }}
                    onRecoverPasswordClick={() => {
                        setRecoverPasswordModalVisible(true);
                        setLoginModalVisible(false);
                    }}
                    closeLoginModal={() => setLoginModalVisible(false)}
                />
            </Popup>
            <Popup
                open={isRegistrationModalVisible}
                onCancel={() => setRegistrationModalVisible(false)}
                title={
                    <>
                        <img alt="profileIcon" src={ImageProfile} />
                        <p>Регистрация в AirSurfer</p>
                    </>
                }
                footer={null}
            >
                <RegistrationForm
                    onLoginClick={() => {
                        setRegistrationModalVisible(false);
                        setLoginModalVisible(true);
                    }}
                    setRegistrationSuccessText={(value) =>
                        setRegistrationSuccessText(value)
                    }
                    onRegister={() => {
                        setRegistrationModalVisible(false);
                        setRegistrationSuccessModalVisible(true);
                    }}
                />
            </Popup>

            <Popup
                open={isRegistrationSuccessModalVisible}
                onCancel={() => setRegistrationSuccessModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Подтверждение регистрации</p>
                        <span>{registrationSuccessText}</span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() =>
                            setRegistrationSuccessModalVisible(false)
                        }
                    >
                        На главную
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isRegistrationConfirmModalVisible}
                onCancel={() => {
                    setRegistrationConfirmModalVisible(false);
                    navigate("/");
                }}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Регистрация подтверждена</p>
                        <span>Ваш профиль успешно подтвержден</span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() => {
                            setRegistrationConfirmModalVisible(false);
                            navigate("/");
                        }}
                    >
                        На главную
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isRecoverPasswordModalVisible}
                onCancel={() => setRecoverPasswordModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={Lock} />
                        <p>Забыли пароль?</p>
                        <span>
                            Введите адрес электронной почты, на которую
                            зарегистрирован кабинет
                        </span>
                    </>
                }
                footer={null}
            >
                <RecoverPasswordForm
                    showEmailPopup={() => {
                        setRecoverPasswordModalVisible(false);
                        setEmailModalVisible(true);
                    }}
                    setEmailValue={(value) => setEmail(value)}
                />
            </Popup>

            <Popup
                open={isEmailModalVisible}
                onCancel={() => setEmailModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={Mail} />
                        <p>Проверьте почту</p>
                        <span>
                            Мы отправили ссылку для создания нового пароля на
                            вашу почту <a>{email}</a>
                        </span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() => setEmailModalVisible(false)}
                    >
                        На главную
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isChangePasswordModalVisible}
                onCancel={() => setChangePasswordModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={Lock} />
                        <p>Восстановление пароля</p>
                        <span>Введите новый пароль</span>
                    </>
                }
                footer={null}
            >
                <ChangePasswordForm
                    closeChangePasswordModal={() => {
                        setChangePasswordModalVisible(false);
                        setChangePasswordSuccessModalVisible(true);
                    }}
                />
            </Popup>

            <Popup
                open={isChangePasswordSuccessModalVisible}
                onCancel={() => setChangePasswordSuccessModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Пароль изменен</p>
                        <span>Ваш пароль был успешно изменен</span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() =>
                            setChangePasswordSuccessModalVisible(false)
                        }
                    >
                        На главную
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isCurrencyModalVisible}
                title={<p>Выберите валюту</p>}
                footer={false}
                onCancel={currencyModalClose}
            >
                <CurrencyForm closeModal={currencyModalClose} />
            </Popup>
            <Popup
                open={isLangModalVisible}
                title={<p>Выберите язык</p>}
                footer={false}
                onCancel={langModalClose}
            >
                <LangForm closeModal={langModalClose} />
            </Popup>
        </>
    );
};

export default Popups;
