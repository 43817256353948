import style from "./PartnerCabinet.module.scss";
import DepositMethod from "./DepositMethod";
import Card from "../../../assets/icons/card.png";
import Requisites from "../../../assets/icons/requisites.png";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import Gear from "../../../assets/icons/gear.png";
import ProfilePageHeader from "../ProfilePageHeader";
import LinkIcon from "../../../assets/icons/link-icon.png";
import React, { useState } from "react";
import { Typography } from "antd";
import {
    useGetPartnersBankQuery,
    useGetPartnersCreditCardQuery,
} from "../../../services/partners";
import Spinner from "../../../components/UI/Spinner/Spinner";

const PartnerCabinet = () => {
    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const {
        data: partnersCards,
        isLoading: isLoaderCards,
        error: errorCards,
    } = useGetPartnersCreditCardQuery({ page });
    const {
        data: partnersBanks,
        isLoading: isLoaderBanks,
        error: errorBanks,
    } = useGetPartnersBankQuery(page);

    return (
        <div className={style.partner_cabinet_page}>
            <ProfilePageHeader title={"Настройки кабинета"} icon={Gear} />
            <div className={style.partner_cabinet}>
                <Typography.Title>Способы пополнения</Typography.Title>
                <div className={style.partner_cabinet__title}>
                    <img src={Card} />
                    <span>Карты</span>
                </div>
                <div className={style.partner_cabinet__cards}>
                    <div className={style.deposit_methods}>
                        {isLoaderCards && <Spinner />}
                        {errorCards && <p>Карты загрузить не удалось</p>}
                        {!isLoaderCards &&
                            partnersCards?.map((item) => (
                                <DepositMethod
                                    type="card"
                                    key={item.id}
                                    item={item}
                                />
                            ))}
                    </div>
                    <CustomButton
                        classes={style.partner_cabinet__button}
                        small
                        primary
                        onClick={() =>
                            navigate("/profile/partnerCabinet/cards/add")
                        }
                    >
                        Добавить карту
                    </CustomButton>
                </div>

                <div className={style.partner_cabinet__title}>
                    <img src={Requisites} />
                    <span>По реквизитам (юридическим лицам)</span>
                </div>
                <div className={style.deposit_methods}>
                    {isLoaderBanks && <Spinner />}
                    {errorBanks && <p>Реквизиты загрузить не удалось</p>}
                    {!isLoaderBanks &&
                        partnersBanks?.map((item) => (
                            <DepositMethod
                                type="requisites"
                                key={item.id}
                                item={item}
                            />
                        ))}
                </div>
                <CustomButton
                    classes={style.partner_cabinet__button}
                    small
                    primary
                    onClick={() =>
                        navigate("/profile/partnerCabinet/requisites/add")
                    }
                >
                    Добавить реквизиты
                </CustomButton>
            </div>
        </div>
    );
};

export default PartnerCabinet;
