import style from "./Tickets.module.scss";
import TicketWayInfo from "../Booking/TicketWayInfo/TicketWayInfo";
import BuyTicket from "./BuyTicket";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setBookingTickets,
    deleteBookingTickets,
    setTickets,
} from "../../store/slices/tickets";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSaveCacheMutation } from "../../services/tickets";

const Ticket = ({ ticket }) => {
    const { to, back } = ticket;
    const [isOpened, setIsOpened] = useState(false);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const [saveCache] = useSaveCacheMutation();

    const handleClick = (to, back) => {
        const bookingTickets = {
            to: to,
            back: back,
            adults: searchParams.get("adults"),
            children: searchParams.get("children"),
            babies: searchParams.get("babies"),
        };
        dispatch(setBookingTickets({ tickets: bookingTickets }));
        // localStorage.setItem('tickets', JSON.stringify({to: to, back: back,
        //     adults: searchParams.get('adults'),
        //     children: searchParams.get('children'),
        //     babies: searchParams.get('babies')}))
        saveCache({ json: JSON.stringify({ ticket: bookingTickets }) })
            .unwrap()
            .then((data) => {
                console.info(data);
                navigate(`/booking?id=${data.data.id}`);
            });
    };
    const hasDetails =
        ticket?.to?.flight?.length > 1 || ticket?.back?.flight?.length > 1;

    if (back) {
        return (
            <div className={style.tickets_page_results_item}>
                <div className={style.tickets_page_results_item_two}>
                    <TicketWayInfo
                        ticket={to}
                        withButton
                        isOpened={isOpened}
                        direction={"to"}
                    />
                    <TicketWayInfo
                        ticket={back}
                        backTicket
                        withButton
                        isOpened={isOpened}
                        direction={"back"}
                    />
                </div>
                <BuyTicket
                    hasBackTicket
                    hasDetails={hasDetails}
                    setIsOpened={() => setIsOpened(!isOpened)}
                    isOpened={isOpened}
                    price={ticket.price}
                    currencyTiket={ticket.to.price.symbol}
                    onClick={() => handleClick(to, back)}
                />
            </div>
        );
    } else {
        return (
            <div className={style.tickets_page_results_item}>
                <TicketWayInfo
                    withButton
                    single
                    isOpened={isOpened}
                    direction={"to"}
                    ticket={to}
                />
                <BuyTicket
                    hasDetails={hasDetails}
                    setIsOpened={() => setIsOpened(!isOpened)}
                    isOpened={isOpened}
                    price={ticket.price}
                    currencyTiket={ticket.to.price.symbol}
                    onClick={() => handleClick(to)}
                />
            </div>
        );
    }
};

export default Ticket;
