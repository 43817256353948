import React from "react";
import ReactDOM from "react-dom/client";
import "./scss/app.scss";
import { routes } from "./root/routes";
import './normalize.css';
import { Provider } from 'react-redux';
import { store } from "./store/store"

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);
