import style from "./SearchPanel.module.scss";
import PlaneIcon from "../../../assets/icons/plane-icon.svg";
import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { ReactSVG } from "react-svg";
import useOutsideClick from "../../../hooks/useOutsideClick";
import ArrowIcon from "../../../assets/icons/arrow-black.svg";
import useIsMobile from "../../../hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { switchIsOpened } from "../../../store/slices/searchInputs";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import { useGetAirportsQuery } from "../../../services/tickets";
import {
    setAirportFromFullName,
    setAirportToFullName,
    setCityFrom,
    setCityTo,
} from "../../../store/slices/tickets";
import { useDebounce } from "../../../hooks/useDebounce";
import { selectLang } from "store/selectors/header";

const places = [
    {
        id: 0,
        name: "Москва",
        country: "Все аэропорты, Россия",
        label: "RTW",
        airports: [
            {
                id: 0,
                name: "Внуково",
                label: "VKO",
            },
            {
                id: 1,
                name: "Домодедово",
                label: "DME",
            },
            {
                id: 2,
                name: "Шереметьево",
                label: "SVO",
            },
            {
                id: 3,
                name: "Жуковский",
                label: "ZIA",
            },
        ],
    },
    {
        id: 1,
        name: "Магадан",
        country: "Россия",
        label: "RTW",
    },
    {
        id: 2,
        name: "Магун",
        country: "Тайвань",
        label: "RTW",
    },
];

const PlaceInput = ({ title, value, onChangeText, onChange, error, type }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const airportFromFullName = urlParams.get("airport_from_full_name");
    const airportToFullName = urlParams.get("airport_to_full_name");
    const currentLang = useSelector(selectLang);

    const [isOpened, setIsOpened] = useState(false);
    useEffect(() => {
        onChangeText(type === "from" ? airportFromFullName : airportToFullName);
    }, []);

    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const debouncedInputValue = useDebounce(value, 300);

    const { data: airports } = useGetAirportsQuery(
        { airport: debouncedInputValue },
        { skip: !debouncedInputValue }
    );

    useOutsideClick(
        wrapperRef,
        () => {
            setIsOpened(false);
            dispatch(switchIsOpened(false));
        },
        inputRef
    );

    const handleOpen = () => {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
        setIsOpened(!isOpened);
        dispatch(switchIsOpened(!isOpened));
    };

    const handleChange = (value) => {
        onChange(value);
        onChangeText(value);
        setIsOpened(true);
    };
    useEffect(() => {
        if (inputRef && isOpened) inputRef.current.focus();
    }, [isOpened]);

    function isEnglish() {
        return currentLang == "EN";
    }
    const handleClick = (event, item) => {
        const { city, name, code, city_en, name_en } = item;
        let cityName = city ? `${city}, ${name}` : name;
        if (isEnglish()) {
            cityName = city_en ? `${city_en}, ${name_en}` : name_en;
        }
        onChangeText(`${cityName}, ${code}`);
        onChange(code);
        if (!isMobile) {
            setIsOpened(false);
        }
        if (type === "from") {
            dispatch(
                setCityFrom({
                    cityFrom: city ? `${city}, ${code}` : `${name}, ${code}`,
                })
            );
            dispatch(
                setAirportFromFullName({
                    airportFromFullName: `${cityName}, ${code}`,
                })
            );
        } else {
            dispatch(
                setCityTo({
                    cityTo: city ? `${city}, ${code}` : `${name}, ${code}`,
                })
            );
            dispatch(
                setAirportToFullName({
                    airportToFullName: `${cityName}, ${code}`,
                })
            );
        }
    };

    return (
        <div className={style.search_panel_input}>
            <input
                placeholder={title}
                onClick={handleOpen}
                value={value}
                onChange={(event) => handleChange(event.target.value)}
                className={error ? style.hasError : null}
            />
            {error && (
                <span className={style.search_panel_input_error}>{error}</span>
            )}
            <div
                ref={isOpened ? wrapperRef : null}
                className={classNames(style.places_list, {
                    [style["places_list_opened"]]:
                        isOpened && (value || isMobile),
                })}
            >
                <div
                    className={style.search_panel_opened_header}
                    onClick={handleOpen}
                >
                    <ReactSVG src={ArrowIcon} />
                    {title}
                </div>
                <input
                    value={value}
                    className={style.search_panel_opened_input}
                    ref={inputRef}
                    placeholder={title}
                    onChange={(event) => handleChange(event.target.value)}
                />
                {airports && airports.data.length ? (
                    airports.data.map((item) => (
                        <>
                            {item.type === "city" ? (
                                <div
                                    key={item.id}
                                    onClick={(event) =>
                                        handleClick(event, item)
                                    }
                                    className={style.places_list_city}
                                >
                                    <p className={style.places_list_city_name}>
                                        {item.name}, {item.code}
                                    </p>
                                    <p
                                        className={
                                            style.places_list_city_country
                                        }
                                    >
                                        {item.country}
                                    </p>
                                    <p className={style.places_list_city_label}>
                                        {item.label}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    key={item.id}
                                    onClick={(event) =>
                                        handleClick(event, item)
                                    }
                                    className={style.places_list_airport}
                                >
                                    <ReactSVG src={PlaneIcon} />
                                    <p className={style.places_list_city_name}>
                                        {item.name}, {item.code}
                                    </p>
                                    <p className={style.places_list_city_label}>
                                        {item.label}
                                    </p>
                                </div>
                            )}
                        </>
                    ))
                ) : (
                    <div className={style.places_list__not_found}>
                        Ничего не найдено
                    </div>
                )}
                <div className={style.apply_button_container}>
                    <CustomButton
                        classes={style.apply_button}
                        onClick={handleOpen}
                        primary
                    >
                        Готово
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default PlaceInput;
