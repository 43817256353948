import style from "./Links.module.scss"
import CustomTable from "../../../components/UI/CustomTable/CustomTable"
import {Link, useNavigate} from "react-router-dom"
import ProfilePageHeader from "../ProfilePageHeader"
import LinkIcon from "../../../assets/icons/link-icon.png"
import LinkItem from "./LinkItem"
import CustomButton from "../../../components/UI/CustomButton/CustomButton"
import React, {useEffect, useState} from "react"
import Sorting from "../Sorting/Sorting"
import classNames from "classnames"
import {useDispatch} from "react-redux";
import {useHidePage} from "../../../hooks/useHidePage";
import {switchIsOpened} from "../../../store/slices/sorting";
import {setLinks} from "../../../store/slices/links";
import {orderBy} from "lodash";
import {useGetPromoCodesQuery} from "../../../services/links";
import Spinner from "../../../components/UI/Spinner/Spinner";


const columns = [
    {
        title: "Название/ссылка",
        dataIndex: "nameToShow",
        key: "title",
        sorter: (a, b) => {
            if (a.title > b.title) {
                return -1
            }
            if (b.title > a.title) {
                return 1
            }
            return 0
        },
        render: (_, record) => {
            return (
                <Link to={`/profile/links/${record.id}`}>
                    <p className={style.links_page_name}>{record.title}</p>
                    <p className={style.links_page_link}>{record.url}</p>
                </Link>
            )
        },
    },
    {
        title: "Промокод",
        dataIndex: "promo_code",
        key: "promo_code",
        sorter: (a, b) => {
            if (a.promo_code > b.promo_code) {
                return -1
            }
            if (b.promo_code > a.promo_code) {
                return 1
            }
            return 0
        },
    },
    {
        title: "Комис.партнера",
        dataIndex: "commission",
        key: "commission",
        sorter: (a, b) => {
            if (a.commission > b.commission) {
                return -1
            }
            if (b.commission > a.commission) {
                return 1
            }
            return 0
        },
        render: (value) => {
            return value + "%"
        },
    },
    {
        title: "Скидка пользователю",
        dataIndex: "discount",
        key: "discount",
        sorter: (a, b) => {
            if (a.discount > b.discount) {
                return -1
            }
            if (b.discount > a.discount) {
                return 1
            }
            return 0
        },
        render: (value) => {
            return value + "%"
        },
    },
    {
        title: "Сумма комиссии",
        dataIndex: "total_commission",
        key: "total_commission",
        sorter: (a, b) => {
            if (a.total_commission > b.total_commission) {
                return -1
            }
            if (b.total_commission > a.total_commission) {
                return 1
            }
            return 0
        },
        render: (value) => {
            return value + " ₽"
        },
    },
]

const sortingNames = {
    title: "Имя",
    promo_code: "Промокод",
    commission: "Комис.партнера",
    discount: "Скидка пользователю",
    total_commission: "Сумма комиссии",
}

const Links = () => {
    const [sorting, setSorting] = useState({
        title: null,
        promo_code: null,
        commission: null,
        discount: null,
        total_commission: null,
    })
    const [isSortingVisible, setIsSortingVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [linksData, setLinksData] = useState([]);

    const dispatch = useDispatch();
    const hideLinksPage = useHidePage();

    const navigate = useNavigate();

    const {data: links, error, refetch, isFetching} = useGetPromoCodesQuery(page);

    useEffect(() => {
      if (links){
        dispatch(setLinks({links: links.data}));
        setLinksData([...linksData, ...links.data])
      }
    }, [links]);

    useEffect( () => {
        refetch();
    }, [page] );

    const onChangeTable = (pagination, filters, sorter) => {
        console.info(sorter)
    }

   if (isFetching){
       return (
           <Spinner className={style.spinner}/>
       )
   }

    if (hideLinksPage && isSortingVisible){
        return (
            <Sorting
                sorting={sorting}
                sortingNames={sortingNames}
                onCancel={() => {
                    setIsSortingVisible(false);
                    dispatch(switchIsOpened(false))
                }}
                setSorting={setSorting}
            />
        )
    }

    return (
        <div className={style.links_page}>
            <ProfilePageHeader
                title={
                  linksData.length
                        ? "Партнерские ссылки/прокомоды"
                        : "У вас пока нет созданных партнерских ссылок/промокодов"
                }
                description={
                    !linksData.length
                        ? "Вы можете создать ссылку по кнопке ниже"
                        : ""
                }
                icon={LinkIcon}
                buttons={[
                    {
                        text: "Создать",
                        onClick: () => navigate("/profile/links/add"),
                        primary: true,
                    },
                ]}
            />
            <CustomButton
                lined
                classes={style.links_page_sort_button}
                onClick={() => {
                    setIsSortingVisible(true);
                    dispatch(switchIsOpened(true));
                }}
                small
            >
                Сортировка
            </CustomButton>
            <div className={style.links_page_table}>
                <CustomTable
                    onChange={onChangeTable}
                    columns={columns}
                    dataSource={linksData}
                />
            </div>
            <div className={style.links_page_list}>
                {linksData && orderBy(
                  linksData, [
                    sorting.title ? "title" : "",
                    sorting.promo_code ? "promo_code" : "",
                    sorting.commission ? "commission" : "",
                    sorting.discount ? "discount" : "",
                    sorting.total_commission ? "total_commission" : "",
                  ].filter(Boolean),
                  [Object.values(sorting).filter(Boolean)]
                ).map((link) => (
                    <LinkItem linkItem={link} />
                ))}
            </div>
            <CustomButton
              linedBlack
              small
              classes={style.links_page_show_more}
              onClick={() => setPage(page => page + 1)}
              disabled={
                isFetching ||
                error ||
                links.meta.current_page >=
                links.meta.last_page
              }
            >
              Показать больше
            </CustomButton>
            <div
                className={classNames(style.links_page_sorting, {
                    [style["links_page_sorting_visible"]]: isSortingVisible,
                })}
            >
                <Sorting
                    sorting={sorting}
                    sortingNames={sortingNames}
                    onCancel={() => setIsSortingVisible(false)}
                    setSorting={setSorting}
                />
            </div>
        </div>
    )
}

export default Links
