import React, { useState, useEffect, useCallback } from "react";
import {useNavigate, useParams} from 'react-router-dom';
import { makeArrayFromObject } from "./Passengers";
import CustomInput from "../../../components/UI/Input/Input";
import DropdownInput from "../../../components/UI/DropdownInput/DropdownInput";
import style from "./Passengers.module.scss";
import RadioGender from "../../../components/UI/RadioGender/RadioGender";
import CustomDatePicker from "../../../components/UI/CustomDatePicker/CustomDatePicker";
import CustomCheckbox from "../../../components/UI/Checkbox/CustomCheckbox";
import DocumentIcon from "../../../assets/icons/document.svg";
import UserIcon from "../../../assets/icons/user.svg";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import {ReactSVG} from "react-svg";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Popup from "../../../components/UI/Popup/Popup";
import SingleBreadCrumb from "../../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {usePostPassengerMutation, useDeletePassengerMutation, usePutPassengerMutation, useGetPassengerByIdQuery} from "../../../services/passengers";
import ImageDone from "../../../assets/icons/image-done.png";
import {makeDate} from "../../../utils/makeDate";

const required = ["firstname", "country", "lastname", "birthday", "gender", "document_number" ];

const genderCompliance = [
    { value : 'female', label : 'Женский' },
    { value : 'male', label : 'Мужской' },
];

const documentTypes = [
    {value: 'DIPLOMATIC', label: 'Дипломатический паспорт'},
    {value: 'RESIDENCEPERMIT', label: 'Вид на жительство'},
    {value: 'PASSPORT', label: 'Иностранный документ'},
    {value: 'SERVICE_PASSPORT', label: 'Служебный паспорт'},
    {value: 'FOREIGN_NATIONAL_PASSPORT', label: 'Заграничный национальный паспорт'}
]

const documentTypesRussia = [
    {value: 'FOREIGN', label: 'Загранпаспорт'},
]

const getFittedPassenger = ( passenger, countries, documentExpiresCallback ) => {

    if ( passenger.document_expires ){
        documentExpiresCallback( true );
    }

    return {
        ...passenger,
        gender : genderCompliance.find( gender => gender.value === passenger.gender ).label,
        country : countries.find( country => country.value === passenger.country ).label,
    }

}

const sortingCitizenship = ( citizenship ) => {

    const russia = citizenship.find( (country) => country.value === 'RU' );
    citizenship = citizenship.filter( (country) => country.value !== 'RU' );
    citizenship.unshift(russia);

    return citizenship;

}

const PassengerForm = () => {

    const navigate = useNavigate();

    const { id } = useParams();
    const isAddForm = window.location.pathname === "/profile/passengers/add";
    const [postPassenger] = usePostPassengerMutation();
    const [putPassenger] = usePutPassengerMutation();
    const [deletePassenger] = useDeletePassengerMutation();

    const { data : passengerQuery, error, isLoading : isLoadingPassenger } = useGetPassengerByIdQuery(id, {skip: !id});
    const [ passenger, setPassenger ] = useState( null );
    const catalog = useSelector((state) => state.catalog.catalog);
    const [citizenship, setCitizenship ] = useState(null);
    const [isLoadingRequest, setIsLoadingRequest] = useState(null);

    const [ hasLoadedAll, setHasLoadedAll ] = useState( false );

    const [errors, setErrors] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [requiredFields, setRequiredFields] = useState(required);
    const [formHasErrors, setFormHasErrors] = useState(false);
    const [isDeletePersonModalVisible, setIsDeletePersonModalVisible] = useState(false);
    const [isSuccessPersonModalVisible, setSuccessPersonModalVisible ] = useState( false );
    const [successModalData, setSuccessModalData] = useState( { title : '', body : '' } );

    const setupCheckBox = ( mode ) => {

        if ( mode ){
            setIsChecked(false);
        }else{
            setRequiredFields(requiredFields.filter(item => item !== "document_expires"));
            setIsChecked(true);
        }

    }

    useEffect( () => {
        if ( catalog ){
            setCitizenship( sortingCitizenship ( makeArrayFromObject( catalog.countries ) )    );
        }
    }, [ catalog ] );

    useEffect( () => {
        if ( citizenship && !isLoadingPassenger ) {
            if ( isAddForm || error ){
                setPassenger( {document_type: null} );
            }else{
                setPassenger( getFittedPassenger( passengerQuery.data, citizenship, setupCheckBox ) );
            }
            setHasLoadedAll(true);
        }
    }, [ citizenship, isLoadingPassenger ] )

    const handleChange = (name, value) => {
        if (name === 'firstname' || name === 'lastname'){
            if (/^[A-Za-z]*$/.test(value)){
                setPassenger({...passenger, [name]: value});
            }
        } else if( name === 'country' ){
            const country = citizenship.find( country => country.value === value)
            if (country.value === 'RU'){
                setPassenger({...passenger, [name]: country.label, document_type: 'FOREIGN'});
            } else {
                setPassenger({...passenger, [name]: country.label, document_type: null});
            }
        } else{
            setPassenger({...passenger, [name]: value});
        }
        setErrors({...errors, [name]: null});
        setFormHasErrors(false);
    }
    const handleCheckboxChange = () => {
        if (isChecked){
            setRequiredFields([...requiredFields, "document_expires"])
        } else {
            setErrors( { ...errors, document_expires : false }  );
            setRequiredFields(requiredFields.filter(item => item !== "document_expires"));
            setPassenger( { ...passenger, document_expires : '' } );
        }
        setIsChecked( !isChecked );
    }

    const handlerResponseErrors = ( errors ) => {
        const newErrors = {...errors}
        Object.keys(errors).forEach( (error) => {
            newErrors[error] = errors[error][0];
            // 'Неккоректные данные';
        } )
        setErrors(newErrors);
    }

    const handleSubmit = () => {
        const today = new Date();
        today.setHours(0,0,0,0)
        if ( isLoadingRequest ) return;

        let isValid = true
        const newErrors = {...errors}
        const passengerData = { ...passenger }

        if ( passengerData.type ){
            delete passengerData.type;
        }

        for (let field of requiredFields){
            if (!passenger[field]){
                newErrors[field] = "Поле обязательно";
                isValid = false;
            }
            else if ((field === "firstname" || field === "lastname") && !/[A-Za-z]/.test(passenger[field])){
                newErrors[field] = "Используйте латинские буквы";
                isValid = false;
            }
            else if (
                (field === "birthday" || field === "document_expires") &&
                passenger[field] &&
                passenger[field].length &&
                !/\d{2}.\d{2}.\d{4}/.test(passenger[field])
            ) {
                newErrors[field] = "Некорректная дата";
                isValid = false;
            }
                // } else if ( field === "document_number" && !/^[0-9]{9}$/.test(passenger[field])){
                //     newErrors[field] = "Номер документа должен состоять из 9 цифр";
                //     isValid = false;
                // }
            else if (field === "document_expires"){
                if (makeDate(passenger[field]) < today){
                    newErrors[field] = "Дата должна быть не ранее сегодняшнего числа";
                    isValid = false;
                }
            } else if (field === "birthday"){
                if (makeDate(passenger[field]) > today){
                    newErrors[field] = "Дата должна быть не позднее сегодняшнего числа";
                    isValid = false;
                }
            }
        }

        //Здесь нужно преобразовать данные для Апи
        if ( passenger.country ){
            passengerData.country = citizenship.find( country => country.label === passenger.country ).value;
        } else {
            newErrors.country = "Укажите страну";
            isValid = false;
        }


        if (passengerData.country === 'RU' && passenger.document_type === 'FOREIGN' && !passenger.document_expires){
            newErrors.document_expires = "Поле обязательно";
            isValid = false;
        }
        if ((passengerData.country === 'RU' || passenger.document_type === 'FOREIGN') && !/^[0-9]{9}$/.test(passenger.document_number)){
            newErrors.document_number = "Номер документа должен состоять из 9 цифр";
            isValid = false;
        }

        if ( passenger.gender ){
            passengerData.gender = genderCompliance.find( gender => gender.label === passenger.gender ).value;
        } else {
            newErrors.gender = "Укажите пол";
            isValid = false;
        }

        setErrors(newErrors);

        // console.log( newErrors );

        if (isValid){

            setIsLoadingRequest( true );

            if ( isAddForm ) {

                postPassenger(passengerData).unwrap()
                    .then(data => {
                        setSuccessPersonModalVisible(true);
                        setSuccessModalData( { title : 'Успешно', body : 'Данные пассажира добавлены' } );
                        navigate("/profile/passengers/"+data.data.id);
                        setIsLoadingRequest( false );
                    })
                    .catch(resp => {
                        if ( resp.data && resp.data.errors ){
                            handlerResponseErrors( resp.data.errors );
                        }
                        setIsLoadingRequest( false );
                    });

            } else{

                putPassenger(passengerData).unwrap()
                    .then(data => {
                        setSuccessPersonModalVisible(true);
                        setSuccessModalData( { title : 'Успешно', body : 'Данные пассажира обновлены' } );
                        // console.info(data)
                        setIsLoadingRequest( false );
                    })
                    .catch(resp => {
                        if ( resp.data && resp.data.errors ){
                            handlerResponseErrors( resp.data.errors );
                        }
                        setIsLoadingRequest( false );
                    });
            }

        } else {
            setFormHasErrors(true);
        }
    }

    const deletePassengerHandler = useCallback( () => {

        setHasLoadedAll(false);

        setIsLoadingRequest( true );

        deletePassenger( id ).then( (resp) => {
            setIsDeletePersonModalVisible(true)
            navigate( "/profile/passengers" );
            setIsLoadingRequest( false );
        } ).catch((err)=>{
            setIsDeletePersonModalVisible(true)
            // console.log(err);
            setIsLoadingRequest( false );
        });

    }, []);

    return (
        <div className={style.passenger_form}>
            <SingleBreadCrumb classes={style.passenger_form_breadcrumb} title={'Назад'} path={'/profile/passengers'}/>
            {
                error && !isAddForm ? (
                    <div>
                        <h1>Пользоваля не существует</h1>
                        <p>К сожалению, такого пользователя не существует. Пожалуйста, вернитесь в каталог и попробуйте снова.</p>
                    </div>

                ) : (
                    <h1>{isAddForm ? "Добавить пассажира" : "Редактировать пассажира"}</h1>
                )
            }

            {
                !hasLoadedAll ? (
                    <Spinner />
                ) : (
                    <>
                        {
                            !error || isAddForm ? (
                                <>

                                    <div className={style.passenger_form_title}>
                                        <ReactSVG src={UserIcon} />
                                        Персональные данные
                                    </div>
                                    <div className={style.passenger_form_row}>
                                        <DropdownInput
                                            showSearch
                                            defaultValue={ passenger?.country }
                                            options={citizenship}
                                            label={"Гражданство"}
                                            containerClasses={style.passenger_form_element}
                                            onChange={(value) => {
                                                handleChange("country", value.toUpperCase())
                                            }}
                                            error={ errors.country }
                                            filterOption={(input, option) =>{
                                                return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }}
                                        />
                                    </div>
                                    <div className={style.passenger_form_row}>
                                        <CustomInput
                                            placeholder={"Имя"}
                                            value={passenger.firstname || ''}
                                            onChange={(value) => handleChange("firstname", value.toUpperCase())}
                                            container={style.passenger_form_element}
                                            error={errors.firstname}
                                        />
                                        <CustomInput
                                            placeholder={"Фамилия"}
                                            value={passenger.lastname || ''}
                                            onChange={(value) => handleChange( "lastname", value.toUpperCase())}
                                            container={style.passenger_form_element}
                                            error={errors.lastname}
                                        />
                                    </div>
                                    {/*<p className={classNames(style.passenger_form_help_text, {*/}
                                    {/*    [style['withErrors']]: formHasErrors*/}
                                    {/*})}>*/}
                                    {/*    Только на латинице, например IVANOV IVAN*/}
                                    {/*</p>*/}
                                    <div className={style.passenger_form_row}>
                                        <CustomDatePicker
                                            value={passenger.birthday}
                                            onChange={(value) => handleChange("birthday", value)}
                                            name={"Дата рождения"}
                                            classes={style.passenger_form_element}
                                            error={errors.birthday}
                                        />
                                        <RadioGender
                                            index={0}
                                            nameMale={"gender"}
                                            valueMale="Мужской"
                                            checkedMale={passenger.gender === "Мужской"}
                                            nameFemale={"gender"}
                                            valueFemale="Женский"
                                            checkedFemale={passenger.gender === "Женский"}
                                            setFieldValue={(name, value) => handleChange(name, value)}
                                            classes={style.passenger_form_element}
                                            errors={[errors.gender]}
                                            onChange={(value) => handleChange("gender", value)}
                                            touched={ errors.gender ? true : false }
                                        />
                                    </div>
                                    <div className={style.passenger_form_title}>
                                        <ReactSVG src={DocumentIcon} />
                                        Документ
                                    </div>
                                    <div className={style.passenger_form_row}>
                                        <DropdownInput
                                            defaultValue={ passenger?.document_type }
                                            value={passenger?.country === 'Россия' ? 'FOREIGN' : passenger?.document_type}
                                            disabled={passenger?.country === 'Россия'}
                                            options={passenger?.country === 'Россия' ? documentTypesRussia : documentTypes}
                                            label={"Тип документа"}
                                            containerClasses={style.passenger_form_element}
                                            onChange={(value) => handleChange("document_type", value.toUpperCase())}
                                            error={ errors.document_type }
                                        />
                                        <CustomInput
                                            placeholder={"Номер документа"}
                                            value={passenger.document_number}
                                            onChange={(value) => handleChange("document_number", value.toUpperCase())}
                                            container={style.passenger_form_element}
                                            error={errors.document_number}
                                        />
                                    </div>
                                    <div className={style.passenger_form_row}>
                                        <div className={style.passenger_form_element}>
                                            <CustomDatePicker
                                                value={ passenger.document_expires }
                                                onChange={(value) => handleChange("document_expires", value)}
                                                name={"Срок действия"}
                                                label={"Срок действия"}
                                                error={errors.document_expires}
                                                // disabled={ isChecked }
                                            />
                                            {/*<CustomCheckbox*/}
                                            {/*    className={style.passenger_form_checkbox}*/}
                                            {/*    text={"Срок не указан"}*/}
                                            {/*    onChange={ () => handleCheckboxChange() }*/}
                                            {/*    checked={isChecked}*/}

                                            {/*/>*/}
                                        </div>
                                    </div>
                                    <div className={classNames(style.passenger_form_buttons, {
                                        [style['withErrors']]: formHasErrors
                                    })}>
                                        {
                                            isAddForm ? (
                                                <>
                                                    <CustomButton
                                                        classes={style.passenger_form_buttons_left}
                                                        primary
                                                        onClick={handleSubmit}
                                                    >
                                                        Добавить
                                                    </CustomButton>
                                                    <CustomButton
                                                        linedBlack
                                                        onClick={() => navigate("/profile/passengers")}
                                                    >
                                                        Отмена
                                                    </CustomButton>
                                                </>
                                            ) : (
                                                <>
                                                    <CustomButton
                                                        classes={style.passenger_form_buttons_left}
                                                        primary
                                                        onClick={handleSubmit}
                                                    >
                                                        Сохранить
                                                    </CustomButton>
                                                    <CustomButton
                                                        linedBlack
                                                        onClick={() => setIsDeletePersonModalVisible(true)}
                                                    >
                                                        Удалить
                                                    </CustomButton>
                                                </>
                                            )
                                        }
                                        {
                                            isLoadingRequest && (
                                                <div className={style.passenger_spinner}>
                                                    <Spinner />
                                                </div>

                                            )
                                        }
                                    </div>
                                    {!!formHasErrors && (
                                        <div className={style.passenger_form_warning}>
                                            <ReactSVG src={WarningIcon}/>
                                            <p>
                                                Проверьте правильность заполнения всех полей
                                            </p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className={classNames(style.passenger_form_buttons)}>
                                    <CustomButton
                                        classes={style.passenger_form_buttons_left}
                                        primary
                                        onClick={ () => navigate('/profile/passengers/') }
                                    >
                                        Вернуться назад
                                    </CustomButton>
                                </div>
                            )
                        }
                    </>

                )
            }

            <Popup
                open={isDeletePersonModalVisible}
                onCancel={() => setIsDeletePersonModalVisible(false)}
                title={
                    <>
                        <p>Удалить пассажира?</p>
                        <span>Все данные безвозвратно удалятся</span>
                    </>
                }
                footer={
                    <div>
                        <CustomButton
                            linedBlack
                            onClick={() => setIsDeletePersonModalVisible(false)}
                        >
                            Отмена
                        </CustomButton>
                        <CustomButton
                            primary
                            onClick={() => deletePassengerHandler() }
                        >
                            Удалить
                        </CustomButton>
                    </div>
                }
            ></Popup>

            <Popup
                open={isSuccessPersonModalVisible}
                onCancel={() => setSuccessPersonModalVisible(false)}
                title={
                    <>
                        {
                            successModalData.title && (
                                <>
                                    <p>{successModalData.title}</p>
                                    <span>{successModalData.body}</span>
                                </>
                            )
                        }
                    </>
                }
                footer={(
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() => {
                            setSuccessPersonModalVisible(false)
                            navigate("/profile/passengers")
                        }}
                    >
                        Вернуться в каталог
                    </CustomButton>
                )}
            >
            </Popup>


        </div>
    )
}

export default PassengerForm;
