import styles from "./Hotel.module.scss"
import planeGrey from "../../../assets/icons/plane-grey.png"

const Hotel = ({town, startDate, endDate}) => {
    return (
        <div className={styles.hotel}>
            <h3 className={styles.hotel__title}>Отель</h3>
            <div className={styles.hotel__info}>
                <h4 className={styles.hotel__info_title}>{town}</h4>
                <p className={styles.hotel__info_text}>
                    {endDate ? `${startDate} - ${endDate}` : startDate}
                </p>
                <img src={planeGrey} alt="planeImg" />
            </div>
        </div>
    )
}

export default Hotel
