import style from "./SearchPanel.module.scss";
import PlusMinus from "../../../components/UI/PlusMinus/PlusMinus";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import CustomTag from "../../../components/UI/CustomTag/CustomTag";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { ReactSVG } from "react-svg";
import ArrowIcon from "../../../assets/icons/arrow-black.svg";
import PassengerIcon from "../../../assets/icons/passenger-input-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { switchIsOpened } from "../../../store/slices/searchInputs";
import useIsMobile from "../../../hooks/useIsMobile";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import { selectLang } from "store/selectors/header";

const tagsRu = {
    ECONOMY: "эконом",
    BUSINESS: "бизнес",
};

const tagsEn = {
    ECONOMY: "economy",
    BUSINESS: "business",
};

const PassengersInput = ({
    activeTag,
    passengers,
    setActiveTag,
    setPassengers,
}) => {
    const currentLang = useSelector(selectLang);
    const tags = currentLang == "RU" ? tagsRu : tagsEn;
    const passangerTxt = currentLang == "RU" ? "пасс" : "pax";
    const [isOpened, setIsOpened] = useState(false);
    const [value, setValue] = useState(
        `${
            passengers.adults + passengers.children + passengers.babies
        } ${passangerTxt}, ${tags[activeTag]}`
    );

    const wrapperRef = useRef(null);
    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    useOutsideClick(
        wrapperRef,
        () => {
            setIsOpened(false);
            dispatch(switchIsOpened(false));
        },
        inputRef
    );

    const handleNumberChange = (name, value) => {
        const newPassengers = { ...passengers, [name]: value };
        setPassengers(newPassengers);
    };

    const handleTagChange = (tag) => {
        setActiveTag(tag);
    };
    useEffect(() => {
        setValue(
            `${
                passengers.adults + passengers.children + passengers.babies
            } ${passangerTxt}, ${tags[activeTag]}`
        );
    }, [
        activeTag,
        passengers.adults,
        passengers.children,
        passengers.babies,
        currentLang,
    ]);

    const handleOpen = () => {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
        setIsOpened(!isOpened);
        dispatch(switchIsOpened(!isOpened));
    };

    return (
        <div className={style.search_panel_input}>
            <div className={style.search_panel_input_container}>
                <input
                    ref={inputRef}
                    placeholder={"Пассажиры, класс"}
                    onClick={handleOpen}
                    value={value}
                />
                <ReactSVG src={PassengerIcon} onClick={handleOpen} />
            </div>

            <div
                ref={isOpened ? wrapperRef : null}
                className={classNames(style.passengers_list, {
                    [style["passengers_list_opened"]]: isOpened,
                })}
            >
                <div
                    className={style.search_panel_opened_header}
                    onClick={handleOpen}
                >
                    <ReactSVG src={ArrowIcon} />
                    Пассажиры
                </div>
                <Passenger
                    initialNumber={passengers.adults}
                    minimumNumber={1}
                    name={"Взрослые"}
                    label={"От 12 лет и старше"}
                    onChange={(value) => handleNumberChange("adults", value)}
                />
                <Passenger
                    initialNumber={passengers.children}
                    minimumNumber={0}
                    name={"Дети"}
                    label={"От 2 до 12 лет"}
                    onChange={(value) => handleNumberChange("children", value)}
                />
                <Passenger
                    initialNumber={passengers.babies}
                    minimumNumber={0}
                    name={"Младенцы"}
                    label={"До 2 лет, без места"}
                    onChange={(value) => handleNumberChange("babies", value)}
                />
                <div className={style.class_tags}>
                    <CustomTag
                        active={activeTag === "BUSINESS"}
                        text={"Эконом"}
                        onClick={() => handleTagChange("ECONOMY")}
                    />
                    <CustomTag
                        active={activeTag === "ECONOMY"}
                        text={"Бизнес"}
                        onClick={() => handleTagChange("BUSINESS")}
                    />
                </div>
                <div className={style.apply_button_container}>
                    <CustomButton
                        classes={style.apply_button}
                        onClick={handleOpen}
                        primary
                    >
                        Готово
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default PassengersInput;

const Passenger = ({ name, label, initialNumber, minimumNumber, onChange }) => {
    return (
        <div className={style.passenger_type}>
            <p className={style.passenger_type_name}>{name}</p>
            <p className={style.passenger_type_label}>{label}</p>
            <div className={style.plus_minus}>
                <PlusMinus
                    initialNumber={initialNumber}
                    minimumNumber={minimumNumber}
                    onChange={(value) => onChange(value)}
                />
            </div>
        </div>
    );
};
