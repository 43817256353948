import CustomButton from "../../../../components/UI/CustomButton/CustomButton";
import styles from "./Payment.module.scss";
import clsx from "clsx";

const Payment = ({ paymentClick, issuing }) => {

    const disabled = true
    return (
        <div
            className={clsx(styles.documents, {
                [styles.documents__issuing]: issuing,
            })}
        >
            <h3 className={styles.documents__title}>Оплата</h3>
                <div className={styles.documents__buttons}>                    
                    <CustomButton
                        classes={styles.documents__btn}
                        onClick={paymentClick}
                        primary
                    >
                        К оплате
                    </CustomButton>                    
                </div>
        </div>
    );
};

export default Payment;
