import App from "../App";
import MainPage from "../pages/MainPage/MainPage";
import Faq from "../pages/Faq/Faq";
import Reviews from "../pages/Reviews/Reviews";
import Products from "../pages/Products/Products";
import Help from "../pages/Help/Help";
import About from "../pages/About/About";
import Contacts from "../pages/Contacts/Contacts";
import UiKit from "../pages/UiKit/UiKit";
import Booking from "../pages/Booking/Booking";
import Tickets from "../pages/Tickets/Tickets";
import {
    TicketsLoading,
    TicketsLoading2,
} from "../pages/Tickets/TicketsLoading";
import BookingSuccess from "../pages/BookingSuccess/BookingSuccess";
import Profile from "../pages/Profile/Profile";
import Passengers from "../pages/Profile/Passengers/Passengers";
import PassengerForm from "../pages/Profile/Passengers/PassengerForm";
import Links from "../pages/Profile/Links/Links";
import Partner from "../pages/Partner/Partner";
import NotFound from "../pages/NotFound/NotFound";
import ReturnPolicy from "../pages/Policy/ReturnPolicy/ReturnPolicy";
import PrivacyPolicy from "../pages/Policy/PrivacyPolicy/PrivacyPolicy";
import ContractOffer from "../pages/Policy/ContractOffer/ContractOffer";
import LinkForm from "../pages/Profile/Links/LinkForm";
import LinkPage from "../pages/Profile/Links/LinkPage";
import Settings from "../pages/Profile/Settings/Settings";
import Bookings from "../pages/Profile/Bookings/Bookings";
import BookingsInfo from "../pages/Profile/BookingsInfo/BookingsInfo";
import Balance from "../pages/Profile/Balance/Balance";
import PartnerCabinet from "../pages/Profile/PartnerCabinet/PartnerCabinet";
import RequisitesForm from "../pages/Profile/PartnerCabinet/PartnerCabinetForms/RequisitesForm";
import CardForm from "../pages/Profile/PartnerCabinet/PartnerCabinetForms/CardForm";
import ProtectedRoute from "../pages/Protected/ProtectedRoute";
import BookingPayment from "../pages/BookingPayment/BookingPayment";
import AuthProtected from "pages/AuthProtected";

export const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/*",
                element: <NotFound />,
            },
            {
                path: "/",
                element: <MainPage />,
            },
            {
                path: "/faq",
                element: <Faq />,
            },
            {
                path: "/reviews",
                element: <Reviews />,
            },
            {
                path: "/products",
                element: <Products />,
            },
            {
                path: "/help",
                element: <Help />,
            },
            {
                path: "/about",
                element: <About />,
            },
            {
                path: "/contacts",
                element: <Contacts />,
            },
            {
                path: "/booking",
                element: <Booking />,
            },
            {
                path: "/booking/:id",
                element: <Booking />,
            },
            {
                path: "/bookingSuccess/:id",
                element: <BookingSuccess />,
            },
            {
                path: "/bookingPayment/:id",
                element: <BookingPayment />,
            },
            {
                path: "/partner",
                element: <Partner />,
            },
            {
                path: "/returnPolicy",
                element: <ReturnPolicy />,
            },
            {
                path: "/privacyPolicy",
                element: <PrivacyPolicy />,
            },
            {
                path: "/contractOffer",
                element: <ContractOffer />,
            },
            {
                path: "/uikit",
                element: <UiKit />,
            },
            {
                path: "/tickets",
                element: <Tickets />,
            },
            {
                path: "/ticketsLoading",
                element: <TicketsLoading />,
            },
            {
                path: "/ticketsLoading2",
                element: <TicketsLoading2 />,
            },
            {
                path: "/profile",
                element: (
                    <AuthProtected>
                        <Profile />
                    </AuthProtected>
                ),
                children: [
                    {
                        path: "/profile/passengers",
                        element: <Passengers />,
                    },
                    {
                        path: "/profile/passengers/:id",
                        element: <PassengerForm />,
                    },
                    {
                        path: "/profile/passengers/add",
                        element: <PassengerForm />,
                    },
                    {
                        path: "/profile/links",
                        element: (
                            <ProtectedRoute>
                                <Links />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/booking",
                        element: <Bookings />,
                    },
                    {
                        path: "/profile/booking/:id",
                        element: <BookingsInfo />,
                    },
                    {
                        path: "/profile/links/:id",
                        element: (
                            <ProtectedRoute>
                                <LinkPage />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/links/add",
                        element: (
                            <ProtectedRoute>
                                <LinkForm />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/links/edit/:id",
                        element: (
                            <ProtectedRoute>
                                <LinkForm />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/settings",
                        element: <Settings />,
                    },
                    {
                        path: "/profile/balance",
                        element: (
                            <ProtectedRoute>
                                <Balance />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/partnerCabinet",
                        element: (
                            <ProtectedRoute>
                                <PartnerCabinet />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/partnerCabinet/requisites/add",
                        element: (
                            <ProtectedRoute>
                                <RequisitesForm />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/partnerCabinet/requisites/edit/:id",
                        element: (
                            <ProtectedRoute>
                                <RequisitesForm />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/partnerCabinet/cards/add",
                        element: (
                            <ProtectedRoute>
                                <CardForm />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: "/profile/partnerCabinet/cards/edit/:id",
                        element: (
                            <ProtectedRoute>
                                <CardForm />
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
        ],
    },
];
