import styles from './TransferText.module.scss'
import walkingMan from '../../../../assets/icons/walkingMan.svg'
import { ReactSVG } from 'react-svg'

const TransferText = ({time = '12ч 31м'}) => {
  let totalSeconds  = time;
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = (totalSeconds / 60) % 60;
  const formattedTime = hours + 'ч ' + minutes + 'м'
    return (
        <div className={styles.transfer}>
            <ReactSVG src={walkingMan} />
            <span>Пересадка - {formattedTime}</span>
        </div>
    )
}

export default TransferText