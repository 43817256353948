import style from "./Links.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import React, {useEffect, useState} from "react";
import CustomInput from "../../../components/UI/Input/Input";
import CustomSlider from "../../../components/UI/CustomSlider/CustomSlider";
import classNames from "classnames";
import PercentIcon from "../../../assets/icons/percent-icon.svg";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import { ReactSVG } from "react-svg";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import Popup from "../../../components/UI/Popup/Popup";
import SingleBreadCrumb from "../../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import {useGetPartnerPromoCodeQuery} from "../../../services/partners";
import {useDeletePromoCodeMutation, usePostPromoCodeMutation, usePutPromoCodeMutation} from "../../../services/links";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {useSelector} from "react-redux";

const requiredFields = ["title", "promo_code"];

const LinkForm = () => {
  const { id } = useParams();
  const {data: linkItem, error, isFetching} = useGetPartnerPromoCodeQuery(id);
  const user = useSelector((state) => state.user.user);
  const partner_discount = user.partner_discount;
  const getLinkInitialValue = (id) => {
    if (window.location.pathname === "/profile/links/add"){
      return {commission: partner_discount / 2, discount: partner_discount / 2}
    } else {
      return linkItem
    }
  }

  const [link, setLink] = useState(() => getLinkInitialValue(id) || {});
  const [errors, setErrors] = useState({});
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [isDeleteLinkModalVisible, setIsDeleteLinkModalVisible] = useState(false);
  const [isLinkSuccessModalVisible, setIsLinkSuccessModalVisible] = useState(false);
  const [isLinkDeleteSuccessModalVisible, setIsLinkDeleteSuccessModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const addForm = window.location.pathname === "/profile/links/add";

  const navigate = useNavigate();

  const [postPromoCode] = usePostPromoCodeMutation();
  const [putPromoCode] = usePutPromoCodeMutation();
  const [deletePromoCode] = useDeletePromoCodeMutation();


  useEffect(() => {
    if (linkItem && !addForm){
      setLink(linkItem.data)
    }
  }, [linkItem]);

  const handleChange = (name, value) => {
    setLink({...link, [name]: value});
    setErrors({...errors, [name]: null});
    setFormHasErrors(false);
  }

  const handleChangeSlider = (value) => {
    setLink({...link, ["commission"]: value[1], ["discount"]: partner_discount - value[1]})
  }

  const handleSubmit = () => {
    let isValid = true;
    const newErrors = {...errors}
    for (let field of requiredFields) {
      if (!link[field]) {
        newErrors[field] = "Поле обязательно";
        isValid = false;
      }
    }
    setErrors(newErrors);
    if (!isValid){
      setFormHasErrors(true);
      return;
    }
    setIsLoading(true);
    const {title, promo_code, commission, discount} = link;
    if (addForm){
      postPromoCode({title, promo_code, commission, discount}).unwrap()
        .then(data => {
          setIsLinkSuccessModalVisible(true)
          setIsLoading(false);
        })
        .catch(resp => {
            setIsLoading(false);
            const errors = resp.data.errors;
            if (errors){
                const errorsKeys = Object.keys(resp.data.errors);
                for (let error of errorsKeys){
                    newErrors[error] = errors[error][0]
                }
                setErrors(newErrors)
            }
        });
    } else {
      putPromoCode({id, title, promo_code, commission, discount}).unwrap()
        .then(data => {
            setIsLoading(false);
            setIsLinkSuccessModalVisible(true)
        })
        .catch(resp => {
            setIsLoading(false);
            const errors = resp.data.errors;
            if (errors){
                const errorsKeys = Object.keys(resp.data.errors);
                for (let error of errorsKeys){
                  newErrors[error] = errors[error][0]
                }
                setErrors(newErrors)
            }
        });
    }
  }

  const deleteLink = () => {
    setIsDeleteLoading(true)
    deletePromoCode(id).unwrap().then(
      data => {
        setIsLinkDeleteSuccessModalVisible(true)
        setIsDeleteLoading(false);
      }
    )
    .catch(resp => {
      setIsDeleteLoading(false);
    })
  }

  if (isFetching){
    return <Spinner className={style.spinner}/>
  }

  return (
    <div className={style.link_form}>
        <SingleBreadCrumb classes={style.link_form_breadcrumb} title={'Назад'} path={'/profile/links'}/>
        <h1>{addForm ? "Добавить партнерскую ссылку/промокод" : "Редактировать партнерскую ссылку/промокод"}</h1>
      <div className={style.link_form_row}>
        <CustomInput
          value={link.title}
          onChange={(value) => handleChange("title", value)}
          error={errors.title}
          placeholder={"Название ссылки"}
          container={style.link_form_row_full_width_element}
        />
      </div>
      <div className={style.link_form_row}>
        <CustomInput
          value={link.url}
          onChange={(value) => handleChange("url", value)}
          error={errors.url}
          blocked
          placeholder={"Сгенерированная ссылка"}
          container={style.link_form_row_element}
        />
        <CustomInput
          value={link.promo_code}
          onChange={(value) => handleChange("promo_code", value)}
          error={errors.promo_code}
          placeholder={"Промокод"}
          container={style.link_form_row_element}
        />
      </div>
      <div className={style.link_form_row}>
        <div className={style.link_form_slider_container}>
          <div className={style.link_form_slider_container_title}>
            <ReactSVG src={PercentIcon}/>
            <p>Настройка комиссии</p>
          </div>

          <div className={style.link_form_slider_container_description}>
            <div className={style.link_form_slider_container_description_col}>
              <p className={style.link_form_slider_container_description_col_title}>
                Партнерская комиссия
              </p>
              <p className={style.link_form_slider_container_description_col_text}>
                Ваша комиссия, которую вы получите с заказа пользоватетеля
              </p>
            </div>
            <div className={classNames(
              style.link_form_slider_container_description_col,
              style.link_form_slider_container_description_col_right
            )}>
              <p className={style.link_form_slider_container_description_col_title}>
                Скидка пользователю
              </p>
              <p className={style.link_form_slider_container_description_col_text}>
                Скидка, которую получит пользователь при заказе
              </p>
            </div>
          </div>

          <div className={style.link_form_slider_container_values}>
            <p className={style.link_form_slider_container_values_value}>
              {link.commission} %
            </p>
            <p className={style.link_form_slider_container_values_value}>
              {link.discount} %
            </p>
          </div>
          <CustomSlider
            value={[0, link.commission]}
            onChange={(value) => handleChangeSlider(value)}
            min={0}
            max={partner_discount}
            range
            className={style.link_form_slider_container_slider}
          />
        </div>
      </div>

      <div className={style.link_form_buttons}>
        {
          addForm ? (
            <>
              <CustomButton
                classes={[style.link_form_buttons_left, style.link_form_buttons_button]}
                primary
                onClick={handleSubmit}>
                  {isLoading && (<Spinner/>)}
                  Добавить
              </CustomButton>
              <CustomButton
                classes={style.link_form_buttons_button}
                linedBlack
                onClick={() => navigate("/profile/links")}
              >
                Отмена
              </CustomButton>
            </>
          ) : (
            <>
              <CustomButton
                classes={[style.link_form_buttons_left, style.link_form_buttons_button]}
                primary
                onClick={handleSubmit}
              >
                  {isLoading && (<Spinner/>)}
                  Сохранить
              </CustomButton>
              <CustomButton
                classes={style.link_form_buttons_button}
                linedBlack
                onClick={() => setIsDeleteLinkModalVisible(true)}
              >
                Удалить
              </CustomButton>
            </>
          )
        }
      </div>
      {!!formHasErrors && (
        <div className={style.link_form_warning}>
          <ReactSVG src={WarningIcon}/>
          <p>
            Проверьте правильность заполнения всех полей
          </p>
        </div>
      )}

      <Popup
        open={isDeleteLinkModalVisible}
        onCancel={() => setIsDeleteLinkModalVisible(false)}
        title={
          <>
            <p>Удалить ссылку?</p>
            <span>Все данные безвозвратно удалятся</span>
          </>
        }
        footer={
          <div>
            <CustomButton
              linedBlack
              onClick={() => setIsDeleteLinkModalVisible(false)}
            >
              Отмена
            </CustomButton>
            <CustomButton
              primary
              onClick={() => {
                setIsDeleteLinkModalVisible(false);
                deleteLink();
              }}
            >
              {isDeleteLoading && (<Spinner/>)}
              Удалить
            </CustomButton>
          </div>
        }
      ></Popup>

        <Popup
            open={isLinkSuccessModalVisible}
            onCancel={() => setIsLinkSuccessModalVisible(false)}
            title={
                <>
                {addForm ? (<p>Вы успешно создали новую ссылку!</p>) : (<p>Вы успешно обновили ссылку!</p>)}
                </>
            }
            footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() => setIsLinkSuccessModalVisible(false)}
                    >
                        Принять
                    </CustomButton>
            }
        ></Popup>

      <Popup
        open={isLinkDeleteSuccessModalVisible}
        onCancel={() => setIsLinkDeleteSuccessModalVisible(false)}
        title={
          <>
            Вы успешно удалили ссылку
          </>
        }
        footer={
          <CustomButton
            primary
            fullWidth
            onClick={() => {
              setIsLinkDeleteSuccessModalVisible(false);
              navigate('/profile/links')
            }}
          >
            Принять
          </CustomButton>
        }
      ></Popup>
    </div>
  )
}

export default LinkForm;
