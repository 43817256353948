import { Outlet } from "react-router-dom";
import Header from "./components/Header/Header";
import React from "react";
import Footer from "./components/Footer/Footer";
import { useHidePage } from "./hooks/useHidePage";
import ScrollToTop from "./utils/crollToTop";

function App() {
    const hideMainPage = useHidePage();
    return (
        <div className="app">
            <Header />
            <ScrollToTop />
            <main className='main'>
                <Outlet />
            </main>
            {!hideMainPage && (
                <div className="footer">
                    <Footer />
                </div>
            )}
        </div>
    );
}

export default App;
