import styles from "./Hotel.module.scss";
import clsx from "clsx";
import pro from "../../../assets/icons/pro-hotel.png";
import home from "../../../assets/icons/home.svg";
import { ReactSVG } from "react-svg";
import checkmarkBooking from "../../../assets/icons/checkmarkBooking.svg";
import CustomInput from "../../../components/UI/Input/Input";
import CustomDatePicker from "../../../components/UI/CustomDatePicker/CustomDatePicker";
import { useSelector } from "react-redux";
import { BASE_URL_FRONT } from "../../../api/constants";
import { getTariffPrice, getTariffsHotel } from "utils/tariffService";
import { selectTariffs } from "store/selectors/catalog";
import { selectCurrency } from "store/selectors/header";
import { BookingVariant } from "../BookingBlock/BookingVariant/BookingVariant";

const Hotel = ({
    values,
    errors,
    setFieldValue,
    showErrors,
    tariffId,
    setTariffId,
}) => {
    const tariffs = useSelector(selectTariffs);
    const currentCurrency = useSelector(selectCurrency);
    const bookingHotelsTariffs = getTariffsHotel(tariffs);

    /**
     * Выбор тарифа
     * @param {string} code Символьный код тарифа
     */
    const onSelect = (id) => {
        let hasHotel = !!tariffId;
        if (id == tariffId) hasHotel = false;
        else hasHotel = true;

        setFieldValue("hotel.hasHotel", hasHotel);
        if (!hasHotel) {
            setFieldValue("hotel.departureDate", undefined);
            setFieldValue("hotel.arrivalDate", undefined);
            setFieldValue("hotel.town", undefined);
            setTariffId(undefined);
        } else {
            console.log(values.hotel);
            setFieldValue("hotel.departureDate", values.hotel.departureDate);
            setFieldValue("hotel.arrivalDate", values.hotel.arrivalDate);
            setFieldValue("hotel.town", values.hotel.town);
            setTariffId(id);
        }
    };

    return (
        <div className={styles.hotel}>
            <h2 className={styles.hotel__title}>Бронь отеля</h2>
            <div className={styles.hotel__list}>
                {bookingHotelsTariffs.map((item, index) => {
                    return (
                        <BookingVariant
                            key={index}
                            onSelect={onSelect}
                            id={item.id}
                            active={item.id == tariffId}
                            image={item.icon}
                            price={getTariffPrice(currentCurrency?.code, item)}
                            priceUnit={currentCurrency?.symbol}
                            description={item.description}
                            examplePdf={item.example_pdf}
                            textFor={"за отель"}
                            name={item.name}
                        />
                    );
                })}
            </div>
            {values.hotel.hasHotel && (
                <div className={styles.details}>
                    <div className={styles.details__header}>
                        <ReactSVG src={home} />
                        <h2 className={styles.details__title}>
                            Детали бронирования отеля
                        </h2>
                    </div>
                    <div className={styles.details__inputs}>
                        <div className={styles.details__input}>
                            <CustomInput
                                value={values.hotel.town}
                                placeholder={"Город"}
                                onChange={(value) => {
                                    setFieldValue("hotel.town", value);
                                }}
                                name={"town"}
                                error={
                                    showErrors &&
                                    errors &&
                                    errors.hotel &&
                                    errors?.hotel.town
                                }
                            />
                        </div>
                        <div className={styles.details__input}>
                            <CustomDatePicker
                                value={values.hotel.arrivalDate}
                                onChange={(value) => {
                                    setFieldValue("hotel.arrivalDate", value);
                                }}
                                label={"Дата заезда"}
                                name={"Дата заезда"}
                                classes={styles.route__date_datepicker}
                                error={
                                    showErrors &&
                                    errors &&
                                    errors.hotel &&
                                    errors?.hotel.arrivalDate
                                }
                            />
                        </div>
                        <div className={styles.details__input}>
                            <CustomDatePicker
                                value={values.hotel.departureDate}
                                onChange={(value) => {
                                    setFieldValue("hotel.departureDate", value);
                                }}
                                label={"Дата выезда"}
                                name={"Дата выезда"}
                                classes={styles.route__date_datepicker}
                                error={
                                    showErrors &&
                                    errors &&
                                    errors.hotel &&
                                    errors?.hotel.departureDate
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Hotel;
