import style from "./Dropdown.module.scss";
import { useState } from "react";
import ArrowIcon from "../../../assets/icons/arrow-black.svg";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

const Dropdown = ({
    children,
    title,
    titleChildren,
    isColumn,
    opened,
    classes,
    containerClass,
    isMobile,
}) => {
    const [isOpened, setIsOpened] = useState(opened);
    return (
        <div
            className={classNames(style.dropdown, classes, {
                [style["dropdown_opened"]]: isOpened,
                [style.dropdown_mobile]: isMobile
            })}
        >
            <div
                onClick={() => setIsOpened(!isOpened)}
                className={classNames(style.dropdown_header, {
                    [style.dropdown_headerMobile]: isMobile,
                })}
            >
                {(title || titleChildren) && (
                    <div className={style.dropdown_title_wrapper}>
                        {title && (
                            <p className={style.dropdown_title}>{title}</p>
                        )}
                        {titleChildren}
                    </div>
                )}
                <ReactSVG className={style.dropdown_arrow} src={ArrowIcon} />
            </div>
            <div
                className={
                    isColumn
                        ? classNames(
                              style.dropdown_content_column,
                              containerClass
                          )
                        : ""
                }
            >
                {children}
            </div>
        </div>
    );
};

export default Dropdown;
