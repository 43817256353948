import { COOKIE_DOMAIN } from "api/constants";
import Cookies from "universal-cookie";

export const localStorageKeys = {
    langKey: "yt-widget",
};

export const cookieKeys = {
    currency: "AIR_CURRENCY",
    langKey: "SITE_LANG",
};
export const cookieExpires = {
    never: new Date(2147483647 * 1000),
};

const cookies = new Cookies();

export const setCookie = (cookieName, value, options) => {
    cookies.set(cookieName, value, {
        path: "/",
        ...options,
        domain: COOKIE_DOMAIN,
        sameSite: true,
    });
};

export const getCookie = (cookieName) => {
    return cookies.get(cookieName);
};

export function deleteCookie(sKey, sPath, sDomain) {
    cookies.remove(sKey, {
        path: sPath.Cookies,
        domain: sDomain,
    });
}
