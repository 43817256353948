import React from "react";
import styles from "./BookingVariant.module.scss";
import { Radio } from "components/UI/Radio/Radio";
import clsx from "clsx";
export const BookingVariant = function ({
    onSelect,
    id,
    active,
    price,
    priceUnit,
    image,
    error,
    description,
    examplePdf,
    name,
    textFor
}) {
    return (
        <div
            onClick={() => onSelect(id)}
            className={clsx(styles.card, {
                [styles.card__active]: active,
            })}
        >
            <div>
                <div className={styles.card__header}>
                    <img
                        className={styles.card__header_img}
                        src={image}
                        alt="plane"
                    />
                    <div className={styles.card__header_checkbox}>
                        <div className={styles.form__field_group}>
                            <Radio
                                checked={active}
                                classes={styles.form__field_text}
                                container={styles.form__field_textContainer}
                                value={id}
                                error={error}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.card__main}>
                    <h3 className={styles.card__main_logo}>{name}</h3>
                    <p className={styles.card__main_text}>
                        {price} {priceUnit} <span>{textFor}</span>
                    </p>
                    <div
                        className={styles.card__list}
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                    <a
                        className={styles.card__list_btn}
                        href={examplePdf}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        rel="noopener noreferrer"
                    >
                        Смотреть образец
                    </a>
                </div>
            </div>
        </div>
    );
};
