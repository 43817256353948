import { Link } from "react-router-dom";
import CustomBreadCrumb from "../../components/UI/CustomBreadCrumb/CustomBreadCrumb";
import FAQ from "../BookingSuccess/FAQ/FAQ";
import Maximum from "../MainPage/Maximum/Maximum";
import styles from "./Products.module.scss";

const Products = () => {
    const items = [
        { title: <Link to={"/tickets"}>Авиабилеты и отели для визы</Link> },
        { title: <a>Тарифы</a> },
    ];

    return (
        <div className={styles.products}>
            <div className={styles.products__top}>
                <div className="container">
                    <CustomBreadCrumb white items={items} />
                </div>
                <Maximum isProducts />
            </div>
            <FAQ />
        </div>
    );
};

export default Products;
