import { Form, Divider } from 'antd';
import Input from "../Input/Input";
import CustomButton from "../CustomButton/CustomButton";
import style from "./Form.module.scss";
import { useState } from "react";
import { useChangePasswordMutation } from "../../../services/user";
import Spinner from "../Spinner/Spinner";
import {useNavigate} from "react-router";

const urlParams = new URLSearchParams(window.location.search);
const pass_reset_token = urlParams.get('pass_reset_token');
const email = urlParams.get('email')

const ChangePasswordForm = ({closeChangePasswordModal}) => {
  const [ password, setPassword ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  const [changePassword] = useChangePasswordMutation();

  const navigate = useNavigate();

  const onFinish = () => {
    if (!password){
      setPasswordError('Введите пароль!')
      return;
    }
    if (isLoading){
      return
    }
    setIsLoading(true);
    changePassword({email: email, token: pass_reset_token, password: password}).unwrap()
      .then(data => {
        closeChangePasswordModal();
        navigate('/');
        setIsLoading(false);
      })
      .catch(resp => {
        const errors = resp.data.errors;
        setPasswordError(errors.password);
        setIsLoading(false);
      });
  };

  const onChange = (value, callback) => {
    callback(value);
    setPasswordError("");
  }

  return (
    <Form
      name="change_password_form"
      className={style.custom_form}
    >
      <Form.Item
        name="password"
      >
        <Input
          password
          placeholder="Пароль"
          value={password}
          error={passwordError}
          onChange={(value) => onChange(value, setPassword)}
        />
        <p className={style.help_text}>Не менее 8 символов</p>
      </Form.Item>
      <Form.Item>
        <CustomButton
          primary
          onClick={() => onFinish()}
          className="login-form-button"
          fullWidth
        >
          {isLoading && (<Spinner/>)}
          Сменить пароль
        </CustomButton>
      </Form.Item>
    </Form>
  );
};
export default ChangePasswordForm;
