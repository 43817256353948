import styles from "./Trail.module.scss"
import plane from "../../../assets/icons/plane-trail.png"
import phone from "../../../assets/icons/phone-trail.png"
import stat from "../../../assets/icons/stat-trail.png"
import { BASE_URL_FRONT } from "../../../api/constants";

const Trail = () => {
    return (
        <section className={styles.trail}>
            <div className="container">
                <div className={styles.trail__wrapper}>
                    <h2 className={styles.trail__title}>
                        Как работает <span>Airsurfer</span>
                    </h2>
                    <ul className={styles.trail__items}>
                        <li className={styles.trail__item}>
                            <div className={styles.trail__item_top}>
                                <p className={styles.trail__item_count}>1</p>
                                <h3 className={styles.trail__item_title}>
                                    Выберите нужный маршрут и введите данные
                                    пассажиров
                                </h3>
                            </div>
                            <img
                                className={styles.trail__item_img}
                                src={plane}
                                alt="plane"
                            />
                        </li>
                        <li className={styles.trail__item}>
                            <div className={styles.trail__item_top}>
                                <p className={styles.trail__item_count}>2</p>
                                <h3 className={styles.trail__item_title}>
                                    Оплатите сервисный сбор
                                </h3>
                                <p className={styles.trail__item_text}>
                                    Полную стоимость билета оплачивать не нужно
                                </p>
                            </div>
                            <img
                                className={styles.trail__item_img}
                                src={phone}
                                alt="phone"
                            />
                        </li>
                        <li className={styles.trail__item}>
                            <div className={styles.trail__item_top}>
                                <p className={styles.trail__item_count}>3</p>
                                <h3 className={styles.trail__item_title}>
                                    Получите бронь билета
                                </h3>
                                <p className={styles.trail__item_text}>
                                    C подтвержденным PNR-кодом от авиакомпании сразу после оплаты. Маршрутная квитанция действительна 3 дня, 5-7 дней или 10-14 дней на выбор.
                                    <a
                                        href={BASE_URL_FRONT + "AirSurfer_Flight_Example.pdf"}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            <span> Пример брони в PDF</span>
                                     </a>
                                </p>
                            </div>
                            <img
                                className={styles.trail__item_img}
                                src={stat}
                                alt="stat"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Trail
