import { baseApi } from "api";

export const passengersApi = baseApi({
    reducerPath: "passengersApi",
    endpoints: (builder) => ({
        getPassengers: builder.query({
            query: (page) => ({
                url: `passengers?page=${page}`,
                method: "GET",
            }),
            providesTags: ["Passenger"],
        }),
        getPassengerById: builder.query({
            query: (id) => ({ url: `passengers/?id=${id}`, method: "GET" }),
            providesTags: ["Passenger"],
        }),
        postPassenger: builder.mutation({
            query: (passenger) => ({
                url: `passengers`,
                method: "POST",
                body: passenger,
            }),
            invalidatesTags: (_, error) => (error ? [] : ["Passenger"]),
        }),
        putPassenger: builder.mutation({
            query: (passenger) => ({
                url: `passengers`,
                method: "PUT",
                body: passenger,
            }),
            invalidatesTags: (_, error) => (error ? [] : ["Passenger"]),
        }),
        deletePassenger: builder.mutation({
            query: (id) => ({ url: `passengers/?id=${id}`, method: "DELETE" }),
            invalidatesTags: ["Passenger"],
        }),
    }),
});

export const {
    useGetPassengersQuery,
    useGetPassengerByIdQuery,
    usePostPassengerMutation,
    usePutPassengerMutation,
    useDeletePassengerMutation,
} = passengersApi;
