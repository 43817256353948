import React from "react";
import style from "./ProfilePassangers.module.scss";

const List = ({ passengers, passengersClickHandler, index, selectedPassengers, setFieldValue }) => {
  return (
    <div className={style.passenger2}>
      {passengers.map((obj, id) => {
       return (
          <div className={(selectedPassengers[index] == id ? style.passenger__active : style.passenger) } onClick={() => passengersClickHandler(index, id, setFieldValue)}>
            <div>
                <div className={style.passenger__name}>{obj?.firstname} {obj?.lastname}</div>
                <div className={style.passenger__birthday}>{obj?.birthday}</div>
            </div>
            <div className={(selectedPassengers[index] == id ? style.passenger__ring__active : style.passenger__ring) } onClick={() => passengersClickHandler(index, id, setFieldValue)}></div>
          </div>
        );
      })}
    </div>
  );
};

export default List;