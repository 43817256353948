import clsx from "clsx"
import styles from "./StatusInfo.module.scss"

const StatusInfo = ({statusType}) => {
    return (
        <div
            className={clsx(styles.status, {
                [styles.status__confirmed]:
                    statusType === "Оплачено" || statusType === "Оформляется",
                [styles.status__cancelled]:
                    statusType === "Отменено" || statusType === "Истекло",
                [styles.status__notConfirmed]: statusType === "Не оплачено",
                [styles.status__indeed]: statusType === "Оформлено",
            })}
        >
            {statusType}
        </div>
    )
}

export default StatusInfo
