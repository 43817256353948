import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orders: [],
    order: {},
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrder: (state, {payload: order}) => {
            state.order = order;
        },
    },
})

export const { setOrders, setOrder } = ordersSlice.actions

export default ordersSlice.reducer