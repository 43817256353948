import { baseApi } from "api";

export const userApi = baseApi({
    reducerPath: "userApi",
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: `login`,
                method: "POST",
                body: credentials,
            }),
        }),
        logout: builder.mutation({
            query: () => ({ url: `logout`, method: "DELETE" }),
        }),
        register: builder.mutation({
            query: (credentials) => ({
                url: `registration/email`,
                method: "POST",
                body: credentials,
            }),
        }),
        recoverPassword: builder.mutation({
            query: (credentials) => ({
                url: `password/send`,
                method: "POST",
                body: credentials,
            }),
        }),
        changePassword: builder.mutation({
            query: (credentials) => ({
                url: `password/reset`,
                method: "POST",
                body: credentials,
            }),
        }),
        getUser: builder.query({
            query: () => ({ url: `user_profile`, method: "GET" }),
        }),
        updateUser: builder.mutation({
            query: (data) => ({
                url: `user_profile`,
                method: "POST",
                body: data.user,
            }),
        }),
        updateUserEmail: builder.mutation({
            query: ({ email }) => ({
                url: `/verification/email`,
                method: "POST",
                body: { email },
            }),
        }),
        updateUserPassword: builder.mutation({
            query: (data) => ({
                url: `/password`,
                method: "PATCH",
                body: data.credentials,
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useRegisterMutation,
    useRecoverPasswordMutation,
    useChangePasswordMutation,
    useUpdateUserMutation,
    useUpdateUserEmailMutation,
    useUpdateUserPasswordMutation,
    useGetUserQuery,
} = userApi;
