import style from "../components/Header/Header.module.scss";

export const menu1 = [
    {
        container: style.link1,
        type: "Link",
        url: "/",
        text: "Авиабилеты и отели для визы",
    },
    { container: style.link1, type: "Link", url: "/faq", text: "Вопросы и ответы" },
    { container: style.link1, type: "A", url: "https://www.trustpilot.com/review/airsurfer.co", text: "Отзывы" },
    { container: style.link1, type: "Link", url: "/products", text: "Тарифы" },
    { container: style.link1, type: "Link", url: "/partner", text: "Партнерская программа" },
];

export const menu2 = [
    {
        container: style.link2,
        type: "Link",
        url: "/help",
        text: "support@airsurfer.co",
    },
];
