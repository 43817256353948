import styles from "./BookingBlock.module.scss";
import { useSelector } from "react-redux";
import { BookingVariant } from "./BookingVariant/BookingVariant";
import { selectTariffs } from "store/selectors/catalog";
import { getTariffPrice, getTariffsFlight } from "utils/tariffService";
import { selectCurrency } from "store/selectors/header";

const BookingBlock = ({
    errors,
    touched,
    setFieldValue,
    tariffId,
    setTariffId,
}) => {
    const tariffs = useSelector(selectTariffs);
    const bookingTiketTariffs = getTariffsFlight(tariffs);
    const currentCurrency = useSelector(selectCurrency);
    /**
     * Выбор тарифа
     * @param {string} code Символьный код тарифа
     */
    const onSelect = (id) => {
        setTariffId(id);
    };

    return (
        <div className={styles.route}>
            <h2 className={styles.route__title}>Бронь авиабилета</h2>
            <div className={styles.route__cards}>
                {bookingTiketTariffs.map((item, index) => {
                    return (
                        <BookingVariant
                            key={index}
                            onSelect={onSelect}
                            id={item.id}
                            active={item.id == tariffId}
                            image={item.icon}
                            price={getTariffPrice(currentCurrency?.code, item)}
                            priceUnit={currentCurrency?.symbol}
                            description={item.description}
                            examplePdf={item.example_pdf}
                            textFor={"за пассажира"}
                            error={
                                touched &&
                                touched.bookingRoute &&
                                touched?.bookingRoute.card &&
                                errors &&
                                errors.bookingRoute &&
                                errors?.bookingRoute.card
                            }
                            name={item.name}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default BookingBlock;
