import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  links: [],
}

export const linksSlice = createSlice({
  name: 'links',
  initialState,
  reducers: {
    setLinks: (state, {payload: links}) => {
      state.links = links;
    },
  },
})

export const { setLinks } = linksSlice.actions

export default linksSlice.reducer