import style from "./Tickets.module.scss";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import ArrowIcon from "../../assets/icons/arrow-black.svg";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectCheepestTariff } from "store/selectors/catalog";
import { getTariffPrice } from "utils/tariffService";
import { selectCurrency } from "store/selectors/header";

const BuyTicket = ({
    setIsOpened,
    isOpened,
    price,
    currencyTiket,
    hasDetails,
    onClick,
}) => {
    //Самый дешевый тариф
    const cheeperTariff = useSelector(selectCheepestTariff);
    const currentCurrency = useSelector(selectCurrency);
    const priceT = getTariffPrice(currentCurrency?.code, cheeperTariff);
    return (
        <div className={style.buy_ticket}>
            <p className={style.buy_ticket_price}>
                {price.toLocaleString()} {currencyTiket}
            </p>
            <CustomButton
                small
                className={style.buy_ticket_button}
                primary
                onClick={onClick}
            >
                Купить за {priceT} {currentCurrency?.symbol} <sup>*</sup>
            </CustomButton>
            <p className={style.buy_ticket_info}>*Бронь на одного пассажира</p>
            {hasDetails && (
                <div
                    className={classNames(
                        style.buy_ticket_details,
                        isOpened ? style.buy_ticket_details__opened : ""
                    )}
                    onClick={setIsOpened}
                >
                    {isOpened ? "Свернуть детали" : "Детали перелета"}
                    <ReactSVG src={ArrowIcon} />
                </div>
            )}
        </div>
    );
};

export default BuyTicket;
