import styles from "./Bookings.module.scss"
import checkmarkIcon from "../../../assets/icons/checkmarkIcon.png"
import Status from "./Status/Status"
import CustomButton from "../../../components/UI/CustomButton/CustomButton"
import Empty from "./Empty/Empty"
import {useGetOrdersQuery} from "../../../services/orders.js";
import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";

const Bookings = () => {
    const user = useSelector((state) => state.user.user);
    const [ page, setPage ] = useState(1);
    const  { data: getOrdersQuery, isLoading, refetch, isFetching, error } = useGetOrdersQuery(page);
    const navigate = useNavigate();
    const [ orders, setOrders ] = useState( [] );

    useEffect( () => {
        if ( !isLoading ) {
            if ( getOrdersQuery ){
                setOrders(  [ ...orders, ...getOrdersQuery.data ] );
            }
        }
    }, [ isLoading ] );

    const orderStatus = {
        "created" : "Не оплачено",
        "payed" : "Оплачено",
        "processing" : "Оформляется",
        "finished" : "Оформлено",
        "canceled" : "Отменено",
        "expired" : "Истекло"
    };

    const month = {
        "01" : "янв",
        "02" : "фев",
        "03" : "мар",
        "04" : "апр",
        "05" : "май",
        "06" : "июн",
        "07" : "июл",
        "08" : "авг",
        "09" : "сен",
        "10" : "окт",
        "11" : "ноя",
        "12" : "дек"
    };

    const data = [];

    if (orders.length > 0) {
        orders.forEach(function(elem) {
            let trip_to = JSON.parse(elem.trip_to);
            let trip_back = JSON.parse(elem.trip_back);
            let departure_city = trip_to.flight ? trip_to?.flight[0].departure_city : '';
            let departure_city_str = departure_city[Object.keys(departure_city)[0]];
            let arrival_city = trip_to.flight ? trip_to.flight.slice(-1)[0].arrival_city : '';
            let arrival_city_str = arrival_city[Object.keys(arrival_city)[0]];

            let [departure_date_day, departure_date_month, departure_date_year] = trip_to.departure_date ? trip_to.departure_date.split(/\./) : [];
            let departure_date_str = departure_date_day + ' ' + month[departure_date_month];
            let [arrival_date_day, arrival_date_month, arrival_date_year] = trip_to.arrival_date ? trip_to.arrival_date.split(/\./) : [];
            let arrival_date_str = arrival_date_day + ' ' + month[arrival_date_month];


            const extendOrder = {...elem};
            extendOrder.fromTown = departure_city_str;
            extendOrder.toTown = arrival_city_str;
            extendOrder.startDate = departure_date_str;
            extendOrder.endDate = arrival_date_str;
            data.push(extendOrder);
        });
    }

    const clickHandler = (id) => {
        navigate('/profile/booking/' + id);
    }

    useEffect( () => {
        refetch();
    }, [page] );

    const loadingMoreOrders = () => {
        setPage( page+1 );
    }

    return (
        <div className={styles.bookings}>
            {data.length === 0 ? (<Empty />) : (
                <div className={styles.bookings__header}>
                    <h2 className={styles.bookings__header_title}>Бронирования</h2>
                    <img
                        className={styles.bookings__header_img}
                        src={checkmarkIcon}
                        alt="checkmarkIcon"
                    />
                </div>
            )}
            <div className={styles.bookings__block}>
            {data.map(
                (order, index) => (
                <Status
                    orderNo={order.order_number}
                    status={orderStatus[order.order_status]}
                    date={order.order_start_booking}
                    passengersQuantity={order.passengers.length}
                    sum={order.price + ' ' + order.currency}
                    fromTown={order.fromTown}
                    toTown={order.toTown}
                    startDate={order.startDate}
                    endDate={order.endDate}
                    clickHandler={() => clickHandler(order.id)}
                    orderId={order.id}
                    hotelBookingFile={order.hotel_booking_file}
                    flightBookingFile={order.flight_booking_file}
                    flightToPnr={order.flight_to_pnr}
                    flightFromPnr={order.flight_from_pnr}
                    flightToBookingExpiresAt={order.flight_to_booking_expires_at}
                    flightFromBookingExpiresAt={order.flight_from_booking_expires_at}
                    flightToAirUrl={order.flight_to_air_url}
                    flightFromAirUrl={order.flight_from_air_url}
                />
                )
            )}
            </div>
            {
                isLoading || isFetching ? (
                    <Spinner />
                ) : ''
            }
            {
                !error && !isFetching && getOrdersQuery.meta && getOrdersQuery.meta.to < getOrdersQuery.meta.total  ? (
                <CustomButton
                    classes={styles.bookings__moreBtn}
                    fullWidth
                    linedBlack
                    onClick={ () => loadingMoreOrders() }
                >
                    Показать больше
                </CustomButton>
                ) : ''
            }
        </div>
    )
}

export default Bookings
