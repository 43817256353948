import styles from "./Booking.module.scss"
import police from "../../../assets/icons/police.svg"
import plane from "../../../assets/icons/plane.svg"
import redFlag from "../../../assets/icons/redFlag.svg"
import whiteCheckMark from "../../../assets/icons/whiteCheckMark.svg"
import file from "../../../assets/icons/file.svg"
import shield from "../../../assets/icons/shield.png"
import {ReactSVG} from "react-svg"
import Button from "../../../components/UI/Button/Button"
import { BASE_URL_FRONT } from "../../../api/constants";

const Booking = () => {
    const handleClick = () => {
        window.open(`${BASE_URL_FRONT}AirSurfer_Flight_Example.pdf`,'_blank');
    }

    return (
        <section className={styles.booking}>
            <div className="container">
                <div className={styles.booking__wrapper}>
                    <h2 className={styles.booking__title}>
                        Получите бронь авиабилета, которая подходит
                    </h2>
                    <div className={styles.booking__content}>
                        <div className={styles.booking__content_item}>
                            <div className={styles.booking__item}>
                                <div className={styles.booking__item_img}>
                                    <ReactSVG src={plane} />
                                </div>
                                <p className={styles.booking__item_text}>
                                    Для оформления визы по всему миру
                                </p>
                            </div>
                            <div className={styles.booking__item}>
                                <div className={styles.booking__item_img}>
                                    <ReactSVG src={police} />
                                </div>
                                <p className={styles.booking__item_text}>
                                    Для прохождения пограничного контроля
                                </p>
                            </div>
                            <div className={styles.booking__item}>
                                <div className={styles.booking__item_img}>
                                    <ReactSVG src={redFlag} />
                                </div>
                                <p className={styles.booking__item_text}>
                                    Для собственных целей, где нужна бронь
                                    авиабилета
                                </p>
                            </div>
                        </div>
                        <div className={styles.booking__content_item}>
                            <h3 className={styles.booking__item_title}>
                                Новый уровень надежности по всему миру
                            </h3>
                            <p className={styles.booking__item_listTitle}>
                                В соответствии:
                            </p>
                            <ul className={styles.booking__list}>
                                <li className={styles.booking__list_item}>
                                    <div className={styles.booking__list_img}>
                                        <ReactSVG src={whiteCheckMark} />
                                    </div>
                                    <p className={styles.booking__list_text}>
                                        C актуальными требованиями посольств,
                                        консульств для получения визы
                                    </p>
                                </li>
                                <li className={styles.booking__list_item}>
                                    <div className={styles.booking__list_img}>
                                        <ReactSVG src={whiteCheckMark} />
                                    </div>
                                    <p className={styles.booking__list_text}>
                                        C действующими правилами прохождения
                                        пограничного контроля
                                    </p>
                                </li>
                            </ul>
                            <div className={styles.booking__example}>
                                <div className={styles.booking__example_img}>
                                    <ReactSVG src={file} />
                                </div>
                                <div className={styles.booking__example_text}>
                                    Образец маршрутной квитанции
                                </div>
                            </div>
                            <Button
                                text={"Открыть в PDF"}
                                type="Button"
                                onClick={handleClick}
                                container={styles.booking__btn}
                            />
                            <div className={styles.booking__bg}>
                                <img src={shield} alt="shield" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Booking
