import styles from "./Hotel.module.scss"
import planeGrey from "../../../assets/icons/plane-grey.png"
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import {useNavigate, useParams} from 'react-router-dom';

const Hotel = ({town, startDate, endDate}) => {

    const params = useParams();
    const navigate = useNavigate();
    return (
        <div className={styles.hotel}>
            <h3 className={styles.hotel__title}>Отель</h3>
            <div className={styles.hotel__info}>
                { town ? (
                <>
                <h4 className={styles.hotel__info_title}>{town}</h4>
                <p className={styles.hotel__info_text}>
                    {endDate ? `${startDate} - ${endDate}` : startDate}
                </p>
                </>
                ) : (
                 <>
                 <h4 className={styles.hotel__info_title}>Добавьте бронь отеля</h4><br/>              
                 <CustomButton
                    classes={styles.payment__sum_btn}
                    primary={true}
                    fullWidth={true}
                    onClick={(e) => navigate("/booking/" + params.id)}
                    type={"button"}
                >
                    Забронировать отель
                </CustomButton>
                 </>
                )}
                <img src={planeGrey} alt="planeImg" />
            </div>
        </div>
    )
}

export default Hotel
