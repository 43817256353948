import plane from "../assets/icons/plane-route.png";
import plane2 from "../assets/icons/plane2-route.png";

export const tarrifs = [
    {
        //Изображение на карточке
        image: plane,
        //Символьный идентификатор
        code: "card1",
        //Длительность брони
        term: "до 3 дней",
        //Название тарифа
        name: "AirSurf",
        //Функция получение цены из каталога
        priceFromCatalog: (prices) => {
            return prices?.price;
        },
    },
    {
        image: plane2,
        code: "card2",
        term: "от 3 до 14 дней",
        name: "AirSurf+",
        priceFromCatalog: (prices) => {
            return prices?.price_plus;
        },
    },
];

/**
 * Список статусов
 */
export const orderStatuses = {
    EXPIRED: "expired",
};
