import { baseApi } from "api";
import { unionBy } from "lodash";

export const partnersApi = baseApi({
    reducerPath: "partnersApi",
    tagTypes: ["Cards", "Bank", "Takeouts", "Balance"],
    endpoints: (builder) => ({
        getPartnersCreditCard: builder.query({
            query: ({ page }) => ({
                url: `partners/credit_card`,
                method: "GET",
                params: { page },
            }),
            transformResponse(res) {
                return res.data;
            },
            providesTags: ["Cards"],
        }),
        getPartnerCreditCard: builder.query({
            query: ({ id }) => ({
                url: `partners/credit_card`,
                method: "GET",
                params: { id },
            }),
            transformResponse(res) {
                return res.data;
            },
            providesTags: (result, error, arg) => [
                { type: "Card", id: arg.id },
            ],
        }),
        postPartnerCreditCard: builder.mutation({
            query: (card) => ({
                url: `partners/credit_card`,
                method: "POST",
                params: {
                    card_number: card["card_number"],
                    card_bank_name: card["card_bank_name"],
                },
            }),
            invalidatesTags: ["Cards"],
        }),
        putPartnerCreditCard: builder.mutation({
            query: (card) => ({
                url: `partners/credit_card`,
                method: "PUT",
                params: card,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Card", id: arg.id },
                "Cards",
            ],
        }),
        deletePartnerCreditCard: builder.mutation({
            query: (id) => ({
                url: `partners/credit_card`,
                method: "DELETE",
                params: { id },
            }),
            invalidatesTags: ["Cards"],
        }),
        getPartnersBank: builder.query({
            query: ({ page }) => ({
                url: `partners/bank`,
                params: { page },
                method: "GET",
            }),
            providesTags: ["Bank"],
            transformResponse(res) {
                return res.data;
            },
        }),
        getPartnerBank: builder.query({
            query: ({ id }) => ({
                url: `partners/bank`,
                params: { id },
                method: "GET",
            }),
            transformResponse(res) {
                return res.data;
            },
            providesTags: (result, error, arg) => [
                { type: "Bank", id: arg.id },
            ],
        }),
        postPartnerBank: builder.mutation({
            query: (bank) => ({
                url: `partners/bank`,
                method: "POST",
                params: bank,
            }),
            invalidatesTags: ["Bank"],
        }),
        putPartnerBank: builder.mutation({
            query: (bank) => ({
                url: `partners/bank`,
                method: "PUT",
                params: bank,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Bank", id: arg.id },
            ],
        }),
        deletePartnerBank: builder.mutation({
            query: (id) => ({
                url: `partners/bank`,
                method: "DELETE",
                params: { id },
            }),
            invalidatesTags: ["Bank"],
        }),
        getPartnersTakeOuts: builder.query({
            query: (page) => ({
                url: `partners/takeouts`,
                method: "GET",
                params: { page },
            }),
            providesTags: ["Takeouts"],
            transformResponse(res) {
                return {
                    data: res.data?.map((item) => ({ key: item.id, ...item })),
                    currentPage: res.meta.current_page,
                    lastPage: res.meta.last_page,
                };
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                currentCache.data = unionBy(
                    currentCache.data,
                    newItems.data,
                    "id"
                );
                currentCache.currentPage = newItems.currentPage;
                currentCache.lastPage = newItems.lastPage;
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        postPartnerTakeouts: builder.mutation({
            query: (takeout) => ({
                url: `/partners/takeouts`,
                method: "POST",
                params: takeout,
            }),
            invalidatesTags: ["Takeouts", "Balance"],
        }),
        getPartnersBalance: builder.query({
            query: () => ({
                url: `/partners/balance`,
                method: "GET",
            }),
            transformResponse(res) {
                return res?.data?.balance ?? 0;
            },
            providesTags: ["Balance"],
        }),
        postPartnerMessage: builder.mutation({
            query: (passenger) => ({
                url: `partners/message`,
                method: "POST",
                body: passenger,
            }),
        }),
        getPartnerPromoCodes: builder.query({
            query: () => ({
                url: `partners/promocodes`,
                method: "GET",
            }),
        }),
        getPartnerPromoCode: builder.query({
          query: (id) => ({
            url: `partners/promocodes`,
            method: "GET",
            params: {id}
          }),
        }),
    }),
});

export const {
    useGetPartnersCreditCardQuery,
    useGetPartnerCreditCardQuery,
    usePostPartnerCreditCardMutation,
    usePutPartnerCreditCardMutation,
    useDeletePartnerCreditCardMutation,

    useGetPartnersBankQuery,
    useGetPartnerBankQuery,
    usePostPartnerBankMutation,
    usePutPartnerBankMutation,
    useDeletePartnerBankMutation,

    useGetPartnersTakeOutsQuery,
    usePostPartnerTakeoutsMutation,
    useGetPartnersBalanceQuery,

    usePostPartnerMessageMutation,

    useGetPartnerPromoCodesQuery,
    useGetPartnerPromoCodeQuery
} = partnersApi;
