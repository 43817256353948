import {Link} from "react-router-dom"
import styles from "./Cookies.module.scss"
import CustomButton from "../CustomButton/CustomButton"
import {useState} from "react"

const Cookies = () => {
    const [showAgreement, setShowAgreement] = useState(true)

    const handleAgree = () => {
        setShowAgreement(false)
        localStorage.setItem("cookieAgreement", "true")
    }

    return (
        <>
            {showAgreement && (
                <div className={styles.cookies}>
                    <div className="container">
                        <div className={styles.cookies__wrapper}>
                            <p className={styles.cookies__text}>
                                Мы используем cookie-файлы для оптимизации своей
                                работы и улучшения работы пользователей с ним.
                                Нажимая кнопку «Я согласен», Вы соглашаетесь на
                                использование нами указанных файлов cookie в
                                соответствии с нашей{" "}
                                <Link to={"/"}>
                                    <span>
                                        Политикой в отношении файлов cookie.
                                    </span>
                                </Link>
                            </p>
                            <CustomButton
                                onClick={handleAgree}
                                primary
                                classes={styles.cookies__btn}
                            >
                                Я согласен
                            </CustomButton>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Cookies
