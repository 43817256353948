import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    profile: {},
    token: localStorage.getItem('token'),
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, {payload: {user}}) => {
      state.user = user;
    },
    setToken: (state, {payload: {token}}) => {
      state.token = token;
    },
    setProfile: (state, {payload: {profile}}) => {
      state.profile = profile;
    },
    logoutUser: (state) => {
      state.user = null;
      state.token = null;
      state.profile = {};
    }
  },
});

export const { setUser, logoutUser, setToken, setProfile } = userSlice.actions;
export default userSlice.reducer;
