import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const AuthProtected = ({ children }) => {
    const location = useLocation();
    const user = useSelector((state) => state.user);

    if (!user.token && location.pathname !== "/") {
        return <Navigate to="/" replace />;
    }

    return children;
};
