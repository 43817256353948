// import useOutsideClick from "../../../hooks/useOutsideClick"
import { Burger } from "./Burger/Burger";
import { Drawer } from "./Drawer/Drawer";
import React, { useRef, useEffect } from "react";
import styles from "./Menu.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../../store/slices/headerSlice";

const Menu = ({ items = [], children }) => {
    const isOpened = useSelector((state) => state.headerSlice.isOpened);
    const dispatch = useDispatch()
    const node = useRef(null);

    const handleClick = (isOpened) => {
        dispatch(setOpen(!isOpened));
    }

    useEffect(() => {
        const mouseWheelHandler = function (e) {
            if (isOpened) {
                e.preventDefault();
            }
        };

        const touchMoveHandler = function (e) {
            if (isOpened) {
                e.preventDefault();
            }
        };

        document.addEventListener("mousewheel", mouseWheelHandler, {
            passive: false,
        });
        document.addEventListener("touchmove", touchMoveHandler, {
            passive: false,
        });

        return () => {
            document.removeEventListener("mousewheel", mouseWheelHandler);
            document.removeEventListener("touchmove", touchMoveHandler);
        };
    }, [isOpened]);

    return (
        <div className={styles.menu} ref={node}>
            <Burger open={isOpened} setOpen={handleClick} />
            <Drawer open={isOpened} items={items}>
                {children}
            </Drawer>
        </div>
    );
};

export default Menu;
