import classNames from "classnames";
import style from "./Sorting.module.scss";
import SortIcon from "../../../assets/icons/sort-icon.svg";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import { ReactSVG } from "react-svg";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";

const Sorting = ({ sorting, sortingNames, onCancel, setSorting }) => {
    const handleSortingChange = (value) => {
        if (!sorting[value]) {
            setSorting({ ...sorting, [value]: "asc" });
        } else if (sorting[value] === "asc") {
            setSorting({ ...sorting, [value]: "desc" });
        } else if (sorting[value] === "desc") {
            setSorting({ ...sorting, [value]: null });
        }
    };
    return (
        <div className={style.sorting}>
            <div className={style.sorting_header}>
                <ReactSVG src={ArrowLeftIcon} onClick={onCancel} />
                <p>Сортировка</p>
            </div>
            {Object.keys(sorting).map((item) => (
                <div
                    key={item}
                    onClick={() => handleSortingChange(item)}
                    className={classNames(style.sorting_item, {
                        [style["sorting_item_active"]]:
                            sorting[item] === "desc" || sorting[item] === "asc",
                    })}
                >
                    <div className={style.sorting_item_container}>
                        <p>{sortingNames[item]}</p>
                        <p
                            className={classNames(style.sorting_item_arrow, {
                                [style["sorting_item_arrow_asc"]]:
                                    sorting[item] === "asc",
                                [style["sorting_item_arrow_desc"]]:
                                    sorting[item] === "desc",
                            })}
                        ></p>
                        <ReactSVG src={SortIcon} />
                    </div>

                    <p>
                        {sorting[item] === "asc"
                            ? "По возрастанию"
                            : sorting[item] === "desc"
                            ? "По убыванию"
                            : ""}
                    </p>
                </div>
            ))}
            <CustomButton
                primary
                classes={style.sorting_button}
                onClick={onCancel}
            >
                Применить
            </CustomButton>
        </div>
    );
};

export default Sorting;
