import { localStorageKeys } from "utils/cookie";
import * as Flags from "country-flag-icons/react/1x1";

export function initYTrasnlate() {
    let script = document.createElement("script");
    script.src = `https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=ru&widgetTheme=light&autoMode=true`;
    document.getElementsByTagName("head")[0].appendChild(script);
}

export function getBrowserlang() {
    const language = navigator?.language ?? null;
    return language?.slice(0, 2)?.toUpperCase();
}

export function yaTranslateGetCode() {
    // Возвращаем язык на который переводим
    // Returning the language to which we are translating
    return localStorage[localStorageKeys.langKey] != undefined &&
        JSON.parse(localStorage[localStorageKeys.langKey]).lang != undefined
        ? JSON.parse(localStorage[localStorageKeys.langKey]).lang?.toUpperCase()
        : null;
}

/**
 * Список языков
 */
export const langList = {
    en: "en",
    ru: "ru",
};

export const availableLangs = {
    RU: Flags.RU,
    EN: Flags.US,
};

export const availableName = {
    RU: "Русский",
    EN: "English",
};
