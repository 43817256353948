import style from "./Calendar.module.scss";
import React, { useState } from "react";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { selectLang } from "store/selectors/header";

export const monthsRu = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
];

export const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const Calendar = ({
    currentMonth,
    currentYear,
    activeDays,
    changeCurrentMonth,
    changeCurrentYear,
    setActiveDays,
    showLeftIcon,
    showRightIcon,
}) => {
    const currentLang = useSelector(selectLang);
    const setActiveDaysFunction = (day) => {
        setActiveDays(day);
    };

    const renderCurrentDate = () => {
        const months = currentLang == "RU" ? monthsRu : monthsEn;
        return (
            <p className={style.current_date}>
                {months[currentMonth]} {currentYear}
            </p>
        );
    };

    const renderDays = () => {
        const firstMonthDay = new Date(currentYear, currentMonth, 0).getDay();
        const lastMonthDate = new Date(
            currentYear,
            currentMonth + 1,
            0
        ).getDate();
        const lastDateOfLastMonth = new Date(
            currentYear,
            currentMonth,
            0
        ).getDate();
        const lastMonthDay = new Date(
            currentYear,
            currentMonth,
            lastMonthDate
        ).getDay();
        const days = [];
        let number = 0;
        for (let i = firstMonthDay; i > 0; i--) {
            // days.push(<li onClick={() => setSelectedKeys([...selectedKeys, key])} key={key} className={style.inactive_day}>{lastDateOfLastMonth - i + 1}</li>);
            days.push(
                <Day
                    date={new Date(
                        currentYear,
                        currentMonth - 1,
                        lastDateOfLastMonth - i + 1
                    ).getTime()}
                    className={style.inactive_day}
                    activeDays={activeDays}
                >
                    {/*{lastDateOfLastMonth - i + 1}*/}
                </Day>
            );
            number++;
        }
        for (let i = 1; i <= lastMonthDate; i++) {
            const isPastDate =
                new Date().getTime() >
                new Date(currentYear, currentMonth, i).getTime();
            days.push(
                <Day
                    date={new Date(currentYear, currentMonth, i).getTime()}
                    onClick={
                        !isPastDate
                            ? (number) => setActiveDaysFunction(number)
                            : null
                    }
                    activeDays={activeDays}
                    className={isPastDate ? style.inactive_day : null}
                >
                    {i}
                </Day>
            );
            number++;
        }

        if (lastMonthDay !== 0) {
            for (let i = 1; i <= 7 - lastMonthDay; i++) {
                days.push(
                    <Day
                        date={new Date(
                            currentYear,
                            currentMonth + 1,
                            i
                        ).getTime()}
                        className={style.inactive_day}
                        activeDays={activeDays}
                    >
                        {/*{i}*/}
                    </Day>
                );
                number++;
            }
        }

        return <ul className={style.days}>{days}</ul>;
    };
    return (
        <div className={style.calendar_wrapper}>
            <div className={style.header}>
                {showLeftIcon && (
                    <ReactSVG
                        className={style.left_icon}
                        src={ArrowLeft}
                        onClick={() => {
                            if (currentMonth > 0) {
                                changeCurrentMonth(currentMonth - 1);
                            } else {
                                changeCurrentMonth(11);
                                changeCurrentYear(currentYear - 1);
                            }
                        }}
                    />
                )}
                {renderCurrentDate()}
                {showRightIcon && (
                    <ReactSVG
                        className={style.right_icon}
                        src={ArrowRight}
                        onClick={() => {
                            if (currentMonth < 11) {
                                changeCurrentMonth(currentMonth + 1);
                                changeCurrentYear(currentYear);
                            } else {
                                changeCurrentMonth(0);
                                changeCurrentYear(currentYear + 1);
                            }
                        }}
                    />
                )}
            </div>
            <div className={style.calendar}>
                <ul className={style.weeks}>
                    <li>Пн</li>
                    <li>Вт</li>
                    <li>Ср</li>
                    <li>Чт</li>
                    <li>Пт</li>
                    <li>Сб</li>
                    <li>Вс</li>
                </ul>
                {renderDays()}
            </div>
        </div>
    );
};

export default Calendar;

const Day = ({ children, date, className, activeDays, onClick }) => {
    return (
        <li
            key={date}
            className={classNames(className, {
                [style["selected_day"]]: activeDays.indexOf(date) !== -1,
                [style["selected_days_range"]]:
                    date > activeDays[0] && date < activeDays[1],
            })}
            onClick={onClick ? () => onClick(date) : null}
        >
            {children}
        </li>
    );
};
