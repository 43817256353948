import { baseApi } from "api";

export const catalogApi = baseApi({
    reducerPath: "catalogApi",
    endpoints: (builder) => ({
        getCatalog: builder.query({
            query: () => ({ url: `assets/ru`, method: "GET" }),
        }),
    }),
});

export const { useGetCatalogQuery } = catalogApi;
