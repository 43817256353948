import { ReactSVG } from "react-svg";
import styles from "./PassengersForm.module.scss";
import profile from "../../../../assets/icons/profile.svg";
import doc from "../../../../assets/icons/document.svg";
import CustomInput from "../../../../components/UI/Input/Input";
import DropdownInput from "../../../../components/UI/DropdownInput/DropdownInput";
import clsx from "clsx";
import CustomCheckbox from "../../../../components/UI/Checkbox/CustomCheckbox";
import CustomDatePicker from "../../../../components/UI/CustomDatePicker/CustomDatePicker";
import ProfilePassangers from "../../../../components/UI/ProfilePassangers/ProfilePassangers";
import { useField } from "formik";
import { useSelector } from "react-redux";
import DropdownCountry from "components/UI/DropdownInput/DropdownCountry";
import { filterSelectWithCountryName } from "pages/Booking/Customer/Customer";

const RenderPassengerFields = ({
    passengerType,
    profilePassangers,
    index,
    values,
    setFieldValue,
    errors,
    backErrors,
    touched,
    setFieldError,
    showErrors,
    setShowErrors,
    passengersClickHandler,
    selectedPassengers,
    passengersChangeHandler,
}) => {
    const { countries, countries_en } = useSelector(
        (state) => state.catalog.catalog
    );
    const typesCompliance = {
        adults: "Взрослый",
        children: "Ребёнок",
        babies: "Младенец",
    };

    const documentTypes = [
        { value: "RESIDENCEPERMIT", label: "Вид на жительство" },
        {
            value: "PASSPORT",
            label: "Заграничный национальный паспорт",
        },
        // {value: 'DIPLOMATIC', label: 'Дипломатический паспорт'},
        // {value: 'FOREIGN_NATIONAL_PASSPORT', label: 'Иностранный документ'},
        // {value: 'SERVICE_PASSPORT', label: 'Служебный паспорт'},
    ];

    const documentTypesRussia = [{ value: "FOREIGN", label: "Загранпаспорт" }];

    const getTranslateType = (type) => {
        return typesCompliance[type];
    };

    const makeArrayFromObject = (obj, prefix) => {
        const arr = [];
        for (let key of Object.keys(obj)) {
            const label = obj[key];
            const name = countries[key];
            const nameEn = countries_en[key];
            arr.push({ value: key, name, nameEn, label });
        }
        const sort = arr.sort((a, b) => {
            const nameA = a.label;
            const nameB = b.label;
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        return sort;
    };

    const handleChange = (name, value) => {
        if (name === "country") {
            setFieldValue(countryFieldName, value);
        }
    };

    const countryFieldName = `passengers.${index}.country`;
    const firstNameFieldName = `passengers.${index}.firstName`;
    const lastNameFieldName = `passengers.${index}.lastName`;
    const birthDateName = `passengers.${index}.birthDate`;
    const genderName = `passengers.${index}.gender`;
    const documentNumberName = `passengers.${index}.documentNumber`;
    const documentExpirationDateName = `passengers.${index}.documentExpirationDate`;
    const documentTypeName = `passengers.${index}.documentType`;

    const citizenship = makeArrayFromObject(countries ?? []);

    return (
        <div key={index} className={styles.form__field}>
            <div className={styles.form__field_header}>
                <ReactSVG src={profile} />
                <h3 className={styles.form__field_title}>
                    {index + 1} пассажир,{" "}
                    <span>{getTranslateType(passengerType)}</span>
                </h3>
            </div>
            <div className={styles.profilePassangersWrapper}>
                <ProfilePassangers
                    passengers={profilePassangers}
                    index={index}
                    passengersClickHandler={passengersClickHandler}
                    selectedPassengers={selectedPassengers}
                    setFieldValue={setFieldValue}
                />
            </div>
            <div>
                <DropdownCountry
                    placeholder={"Гражданство"}
                    defaultValue={"RU"}
                    name={countryFieldName}
                    classes={styles.form__field_select}
                    options={citizenship}
                    value={values[`passengers`][index]?.country}
                    showSearch
                    filterOption={filterSelectWithCountryName}
                    onChange={(value) => {
                        if (value === "RU") {
                            passengersChangeHandler(
                                index,
                                "FOREIGN",
                                documentTypeName,
                                setFieldValue
                            );
                        } else {
                            passengersChangeHandler(
                                index,
                                null,
                                documentTypeName,
                                setFieldValue
                            );
                        }
                        passengersChangeHandler(
                            index,
                            value,
                            countryFieldName,
                            setFieldValue
                        );
                    }}
                />
                {errors[`passengers.${index}.${"countryFieldName"}`] &&
                    touched?.passengers[index].countryFieldName && (
                        <span className={styles.error_text}>
                            {touched &&
                                touched.passengers &&
                                touched?.passengers[index] &&
                                touched?.passengers[index]?.countryFieldName &&
                                errors &&
                                errors.passengers &&
                                errors?.passengers[index] &&
                                errors?.passengers[index]?.countryFieldName}
                        </span>
                    )}
            </div>
            <div className={styles.form__field_group}>
                <CustomInput
                    type="text"
                    name={firstNameFieldName}
                    placeholder="Имя"
                    classes={styles.form__field_text}
                    container={styles.form__field_textContainer}
                    value={values[`passengers`][index]?.firstName}
                    onChange={(value) => {
                        passengersChangeHandler(
                            index,
                            value,
                            firstNameFieldName,
                            setFieldValue
                        );
                    }}
                    error={
                        showErrors &&
                        errors &&
                        errors.passengers &&
                        errors?.passengers[index] &&
                        errors?.passengers[index]?.firstName
                    }
                />
                <CustomInput
                    type="text"
                    name={lastNameFieldName}
                    placeholder="Фамилия"
                    classes={styles.form__field_text}
                    container={styles.form__field_textContainer}
                    value={values[`passengers`][index]?.lastName}
                    onChange={(value) => {
                        passengersChangeHandler(
                            index,
                            value,
                            lastNameFieldName,
                            setFieldValue
                        );
                    }}
                    error={
                        showErrors &&
                        errors &&
                        errors.passengers &&
                        errors?.passengers[index] &&
                        errors?.passengers[index]?.lastName
                    }
                />
            </div>
            <p className={styles.form__field_info}>
                Только на латинице, например IVANOV IVAN
            </p>
            <div className={styles.form__field_group}>
                <div style={{ width: "100%" }}>
                    <CustomDatePicker
                        name={birthDateName}
                        label="Дата рождения"
                        value={values[`passengers`][index]?.birthDate}
                        onChange={(newValue) => {
                            passengersChangeHandler(
                                index,
                                newValue,
                                birthDateName,
                                setFieldValue
                            );
                        }}
                        error={
                            showErrors &&
                            ((errors &&
                                errors.passengers &&
                                errors?.passengers[index] &&
                                errors?.passengers[index]?.birthDate) ||
                                (backErrors &&
                                    backErrors.passengers &&
                                    backErrors?.passengers[index]?.birthDate))
                        }
                    />
                </div>
                <div className={styles.form__radio}>
                    <p className={styles.form__radio_title}>Пол</p>
                    <div
                        className={clsx(styles.form__radio_labels, {
                            [styles.form__field_errorRadio]:
                                showErrors &&
                                errors &&
                                errors.passengers &&
                                errors?.passengers[index] &&
                                errors?.passengers[index]?.gender,
                        })}
                    >
                        <label
                            className={clsx(styles.form__radio_item, {
                                [styles.form__radio_active]:
                                    values[`passengers`][index]?.gender ===
                                    "male",
                            })}
                        >
                            <input
                                type="radio"
                                name={genderName}
                                value="male"
                                checked={
                                    values[`passengers`][index]?.gender ===
                                    "male"
                                }
                                onChange={(event) => {
                                    passengersChangeHandler(
                                        index,
                                        event.target.value,
                                        genderName,
                                        setFieldValue
                                    );
                                }}
                            />
                            Мужской
                        </label>
                        <label
                            className={clsx(styles.form__radio_item, {
                                [styles.form__radio_active]:
                                    values[`passengers`][index]?.gender ===
                                    "female",
                            })}
                        >
                            <input
                                type="radio"
                                name={genderName}
                                value="female"
                                checked={
                                    values[`passengers`][index]?.gender ===
                                    "female"
                                }
                                onChange={(event) => {
                                    passengersChangeHandler(
                                        index,
                                        event.target.value,
                                        genderName,
                                        setFieldValue
                                    );
                                }}
                            />
                            Женский
                        </label>
                    </div>
                    {touched.passengers &&
                        touched.passengers[index] &&
                        touched.passengers[index].gender &&
                        errors &&
                        errors.passengers &&
                        errors?.passengers[index] &&
                        errors?.passengers[index]?.gender && (
                            <span className={styles.error_text}>
                                {errors.passengers[index].gender}
                            </span>
                        )}
                </div>
            </div>
            <div className={styles.form__field_doc}>
                <ReactSVG src={doc} />
                <p className={styles.form__field_docTitle}>Документ</p>
            </div>
            <div className={styles.form__field_group}>
                <div className={styles.form__field_textContainer}>
                    <DropdownInput
                        placeholder={"тип документа"}
                        disabled={values[`passengers`][index]?.country === "RU"}
                        name={documentTypeName}
                        options={
                            values[`passengers`][index]?.country === "RU"
                                ? documentTypesRussia
                                : documentTypes
                        }
                        value={
                            values[`passengers`][index]?.country === "RU"
                                ? "FOREIGN"
                                : values[`passengers`][index]?.documentType
                        }
                        onChange={(value) => {
                            passengersChangeHandler(
                                index,
                                value,
                                documentTypeName,
                                setFieldValue
                            );
                        }}
                        defaultValue={"FOREIGN"}
                    />
                    {errors[`passengers.${index}.${"documentTypeName"}`] &&
                        touched?.passengers[index].documentTypeName && (
                            <span className={styles.error_text}>
                                {touched &&
                                    touched.passengers &&
                                    touched?.passengers[index] &&
                                    touched?.passengers[index]
                                        ?.documentTypeName &&
                                    errors &&
                                    errors.passengers &&
                                    errors?.passengers[index] &&
                                    errors?.passengers[index]?.documentTypeName}
                            </span>
                        )}
                </div>
                <CustomInput
                    type="text"
                    name={documentNumberName}
                    placeholder="Номер документа"
                    classes={styles.form__field_text}
                    container={styles.form__field_textContainer}
                    value={values[`passengers`][index]?.documentNumber}
                    onChange={(value) => {
                        passengersChangeHandler(
                            index,
                            value,
                            documentNumberName,
                            setFieldValue
                        );
                    }}
                    error={
                        showErrors &&
                        errors &&
                        errors.passengers &&
                        errors?.passengers[index] &&
                        errors?.passengers[index]?.documentNumber
                    }
                />
            </div>
            <div className={styles.form__field_group}>
                <div className={styles.form__field_half}>
                    <div>
                        <CustomDatePicker
                            name={documentExpirationDateName}
                            value={
                                values[`passengers`][index]
                                    ?.documentExpirationDate
                            }
                            label="Срок действия"
                            onChange={(value) => {
                                passengersChangeHandler(
                                    index,
                                    value,
                                    documentExpirationDateName,
                                    setFieldValue
                                );
                            }}
                            error={
                                showErrors &&
                                errors &&
                                errors.passengers &&
                                errors?.passengers[index] &&
                                errors?.passengers[index]
                                    ?.documentExpirationDate
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RenderPassengerFields;
