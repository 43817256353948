import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    passengers: [],
    passenger: {},
}

export const passengersSlice = createSlice({
    name: 'passengers',
    initialState,
    reducers: {
        setPassengers: (state, {payload: passengers}) => {
            state.passengers = passengers;
        },
        setPassenger: (state, {payload: passenger}) => {
            state.passenger = passenger;
        },
    },
})

export const { setPassengers, setPassenger } = passengersSlice.actions

export default passengersSlice.reducer
