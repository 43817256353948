import styles from "./Radio.module.scss";

const Radio = ({ checked, name, onChange, value, id }) => {
    return (
        <label className={styles.radio}>
            <input
                type="radio"
                className={styles.radio__input}
                name={name}
                checked={checked}
                onChange={onChange}
                value={value}
            />
        </label>
    );
};

export { Radio };
