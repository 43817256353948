import { Form, Radio } from "antd";
import Input from "../Input/Input";
import CustomButton from "../CustomButton/CustomButton";
import DropdownInput from "../DropdownInput/DropdownInput";
import RadioGroup from "../RadioGroup/RadioGroup";
import style from "./Form.module.scss";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import {
    useGetPartnersBankQuery,
    useGetPartnersCreditCardQuery,
    usePostPartnerTakeoutsMutation,
} from "services/partners";

function transformToDropdownCardItem(item) {
    return {
        value: item.id,
        label: (
            <>
                <p>{item.card_number}</p>
                <p className={style.dropdown_label}>{item.card_bank_name}</p>
            </>
        ),
    };
}

function transformToDropdownRequisitesItem(item) {
    return {
        value: item.id,
        label: (
            <>
                <p>{item.bank_name}</p>
                <p className={style.dropdown_label}>{item.bank_user_account}</p>
            </>
        ),
    };
}

const MoneyWithdrawForm = ({ onCancel, onSubmit }) => {
    const [payment, setPayment] = useState({});
    const [errors, setErrors] = useState({});
    const [formHasErrors, setFormHasErrors] = useState(false);

    const { data: partnersCards } = useGetPartnersCreditCardQuery({});
    const { data: partnersBanks } = useGetPartnersBankQuery({});
    const [postPartnerTakeouts] = usePostPartnerTakeoutsMutation();

    const [dropdownData, setDropdownData] = useState([]);

    useEffect(() => {
        if (!Object.values(errors).filter(Boolean).length) {
            setFormHasErrors(false);
        }
    }, [errors]);

    useEffect(() => {
        if (payment.takeoutable_type === "credit-card") {
            setDropdownData(partnersCards?.map(transformToDropdownCardItem));
        } else {
            setDropdownData(
                partnersBanks?.map(transformToDropdownRequisitesItem)
            );
        }
    }, [payment.takeoutable_type, partnersCards, partnersBanks]);

    const onFinish = () => {
        postPartnerTakeouts(payment).then((data) => {
            if (data?.error) {
                if (data.error.status === 422) {
                    setFormHasErrors(true);
                }
                setErrors(data.error.data.errors);
            } else {
                onSubmit();
            }
        });
    };

    const onChange = (value, field) => {
        if (field === "takeoutable_type"){
            setPayment({ ...payment, [field]: value, takeoutable_id: null });
        } else {
            setPayment({ ...payment, [field]: value });
        }
        setErrors({ ...errors, [field]: null });
    };

    return (
        <Form name="login_form" className={style.custom_form}>
            <Form.Item name="amount">
                <Input
                    placeholder="Сумма,₽"
                    value={payment.amount}
                    error={errors.amount}
                    onChange={(value) => onChange(value, "amount")}
                />
            </Form.Item>
            <p className={style.help_text}>Куда выводим:</p>
            <Form.Item name="takeoutable_type">
                <RadioGroup
                    value={payment.takeoutable_type}
                    onChange={(event) => {
                        onChange(event.target.value, "takeoutable_type")
                    }
                    }
                >
                    <Radio value={"credit-card"}>На карту</Radio>
                    <Radio value={"bank"}>По реквизитам</Radio>
                </RadioGroup>
            </Form.Item>
            {!!payment.takeoutable_type && (
                <DropdownInput
                    containerClasses={style.req_dropdown}
                    value={payment?.takeoutable_id}
                    defaultValue={payment?.takeoutable_id}
                    options={dropdownData}
                    onChange={(value) => onChange(value, "takeoutable_id")}
                    label={payment.takeoutable_id ? undefined :
                        payment.takeoutable_type === "credit-card"
                            ? "Карта"
                            : "Реквизиты"
                    }
                />
            )}
            <Form.Item className={style.buttons}>
                <CustomButton
                    linedBlack
                    onClick={onCancel}
                    className="login-form-button"
                >
                    Отмена
                </CustomButton>
                <CustomButton
                    primary
                    onClick={() => onFinish()}
                    className="login-form-button"
                >
                    Вывести
                </CustomButton>
            </Form.Item>
            {formHasErrors && (
                <div className={style.warning}>
                    <ReactSVG src={WarningIcon} />
                    <p>Проверьте правильность заполнения всех полей</p>
                </div>
            )}
        </Form>
    );
};
export default MoneyWithdrawForm;
