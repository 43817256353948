import style from "./Balance.module.scss";
import { format, parseISO } from "date-fns";
import { statuses } from "./Balance.constants";
import { Pin } from "components/UI/Pin";

const MobileBalanceItem = ({ balanceItem }) => {
    return (
        <div className={style.balance}>
            <div className={style.balance_info}>
                <div className={style.balance_info_item}>
                    <div className={style.balance_info_item_title}>Куда</div>
                    <div className={style.balance_info_item_value}>
                        {balanceItem.takeoutable_type === "credit-card"
                            ? "Карта"
                            : "Расчетный счет"}
                        <p className={style.balance_info_card_number}>
                            {balanceItem.takeoutable_type === "credit-card"
                                ? balanceItem.takeoutable?.card_number
                                : balanceItem.takeoutable?.bank_user_account}
                        </p>
                    </div>
                </div>
                <div className={style.balance_info_item}>
                    <div className={style.balance_info_item_title}>
                        Дата и время
                    </div>
                    <div className={style.balance_info_item_value}>
                        {balanceItem.updated_at
                            ? format(
                                  parseISO(balanceItem.updated_at),
                                  "dd.MM.yyyy в HH:mm"
                              )
                            : ""}
                    </div>
                </div>
                <div className={style.balance_info_item}>
                    <div className={style.balance_info_item_title}>
                        Сумма, ₽
                    </div>
                    <div className={style.balance_info_item_value}>
                        <span className={style.sum}>
                            {balanceItem.amount} ₽
                        </span>
                    </div>
                </div>
                <div className={style.balance_info_item}>
                    <div className={style.balance_info_item_title}>Статус</div>
                    <div className={style.balance_info_item_value}>
                        <Pin status={balanceItem.status}>
                            {statuses[balanceItem.status]}
                        </Pin>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileBalanceItem;
