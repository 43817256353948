import style from "./Passengers.module.scss";
import PassengerIcon from "../../../assets/icons/passenger-icon.png";
import PencilIcon from "../../../assets/icons/pencil.svg"
import {Link} from "react-router-dom";

const typesCompliance = {
    'adults' : 'Взрослый',
    'children' : 'Ребёнок',
    'babies' : 'Младенец'
}

const getTranslateType = ( type ) => {

    return typesCompliance[type];

}

const getTranslateGender = ( gender ) => {

    if ( gender === 'female' ){
        return 'Женский';
    }
    if ( gender === 'male' ){
        return 'Мужской';
    }
    return;

}

const getTranslateDocumentNumber = ( document ) => {

    if ( document ){
        return `${document.substr(0, 4)} ${document.substr(4, document.length)}`;
    }
    return;

}

const getTranslateCountry = ( country, countries ) => {

    return countries.find( item => item.value === country )?.label;

}

const Passenger = ({passenger, citizenship}) => {
  const {id, firstname, lastname, type, birthday, country, gender, document_number} = passenger;

  return (
    <div className={style.passenger}>
      <div className={style.passenger_header}>
        <div className={style.passenger_header_info}>
          <p className={style.passenger_name}>{firstname} {lastname}</p>
          <p className={style.passenger_type}>{getTranslateType(type)}</p>
        </div>
          <img alt="passengerIcon" src={PassengerIcon}/>
      </div>
      <div className={style.passenger_info}>

          <div className={style.passenger_info_item}>
            <div className={style.passenger_info_item_title}>
              Дата рождения
            </div>
            <div className={style.passenger_info_item_value}>
              {birthday}
            </div>
          </div>
          <div className={style.passenger_info_item}>
            <div className={style.passenger_info_item_title}>
              Гражданство
            </div>
            <div className={style.passenger_info_item_value}>
              {getTranslateCountry(country, citizenship)}
            </div>
          </div>
          <div className={style.passenger_info_item}>
            <div className={style.passenger_info_item_title}>
              Пол
            </div>
            <div className={style.passenger_info_item_value}>
              {getTranslateGender(gender)}
            </div>
          </div>
          <div className={style.passenger_info_item}>
            <div className={style.passenger_info_item_title}>
              Документ
            </div>
            <div className={style.passenger_info_item_value}>
              {getTranslateDocumentNumber(document_number)}
            </div>
          </div>
        </div>
      <div className={style.passenger_edit}>
        <img alt="passengerIcon" src={PencilIcon}/>
        <Link to={`/profile/passengers/${id}`}>Редактировать</Link>
      </div>
    </div>
  )
}

export default Passenger
