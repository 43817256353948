import { Form, Divider } from 'antd';
import Input from "../Input/Input";
import CustomButton from "../CustomButton/CustomButton";
import style from "./Form.module.scss";
import { useState } from "react";
import {useRecoverPasswordMutation} from "../../../services/user";
import Spinner from "../Spinner/Spinner";

const RecoverPasswordForm = ({showEmailPopup, setEmailValue}) => {
  const [ email, setEmail ] = useState('');
  const [ emailError, setEmailError ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  const [recoverPassword] = useRecoverPasswordMutation();

  const onFinish = () => {
    if (!email){
      setEmailError('Введите email!')
      return;
    }
    if (isLoading){
      return
    }
    setIsLoading(true);
    recoverPassword({email: email}).unwrap()
      .then(data => {
        setEmailValue(email);
        showEmailPopup();
        setIsLoading(false);
      })
      .catch(resp => {
        const errors = resp.data.errors;
        setEmailError(errors.email);
        setIsLoading(false);
      });
  };

  const onChange = (value, callback) => {
    callback(value);
    setEmailError("");
  }

  return (
    <Form
      name="login_form"
      className={style.custom_form}
    >
      <Form.Item
        name="email"
      >
        <Input
          placeholder="E-mail"
          value={email}
          error={emailError}
          onChange={(value) => onChange(value, setEmail)}
        />
      </Form.Item>
      <Form.Item>
        <CustomButton
          primary
          onClick={() => onFinish()}
          className="login-form-button"
          fullWidth
        >
          {isLoading && (<Spinner/>)}
          Восстановить
        </CustomButton>
      </Form.Item>
    </Form>
  );
};
export default RecoverPasswordForm;