import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpened: false,
}

export const sortingSlice = createSlice({
    name: 'sorting',
    initialState,
    reducers: {
        switchIsOpened: (state, action) => {
            state.isOpened = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { switchIsOpened } = sortingSlice.actions

export default sortingSlice.reducer
