import { baseApi } from "api";

export const linksApi = baseApi({
  reducerPath: "linksApi",
  endpoints: (builder) => ({
    getPromoCodes: builder.query({
      query: (page) => ({
        url: `partners/promocodes`,
        method: "GET",
        params: {page}
      }),
      providesTags: ["Links"],
    }),
    getPromoCode: builder.query({
      query: (id, page) => ({
        url: `partners/promocodes`,
        method: "GET",
        params: {id, page}
      }),
      providesTags: ["Links"],
    }),
    postPromoCode: builder.mutation({
      query: (promoCode) => ({
        url: `partners/promocodes`,
        method: "POST",
        body: promoCode,
      }),
      invalidatesTags: ["Links"],
    }),
    putPromoCode: builder.mutation({
      query: (promoCode) => ({
        url: `partners/promocodes`,
        method: "PUT",
        body: promoCode,
      }),
      invalidatesTags: ["Links"],
    }),
    deletePromoCode: builder.mutation({
      query: (id) => ({
        url: `partners/promocodes`,
        method: "DELETE",
        body: {id},
      }),
      invalidatesTags: ["Links"],
    }),
  }),
});

export const {
  useGetPromoCodesQuery,
  useGetPromoCodeQuery,
  usePostPromoCodeMutation,
  usePutPromoCodeMutation,
  useDeletePromoCodeMutation
} = linksApi;
