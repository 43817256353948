import {useState, cloneElement} from "react"
import styles from "./Tooltip.module.scss"
import classNames from "classnames";

const Tooltip = ({content, children, classes}) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)

    const showTooltip = () => {
        setIsTooltipVisible(true)
    }

    const hideTooltip = () => {
        setIsTooltipVisible(false)
    }

    return (
        <div
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
            className={classNames(styles.wrapper, classes, {
                [styles.wrapper__active]: isTooltipVisible
            })}
        >
            {typeof children === "string" ? (
                <span className={styles.target}>{children}</span>
            ) : (
                cloneElement(children, {
                    onMouseEnter: () => {
                        showTooltip()
                        children.props.onMouseEnter &&
                            children.props.onMouseEnter()
                    },
                    onMouseLeave: () => {
                        hideTooltip()
                        children.props.onMouseLeave &&
                            children.props.onMouseLeave()
                    },
                })
            )}
            {isTooltipVisible && (
                <div className={styles.tooltip}>{content}</div>
            )}
        </div>
    )
}

export default Tooltip
