import styles from "./Faq.module.scss";
import CustomBreadCrumb from "../../components/UI/CustomBreadCrumb/CustomBreadCrumb";
import { Link } from "react-router-dom";
import question from "../../assets/icons/question.png";
import { useState } from "react";
import clsx from "clsx";
import { Accordion } from "../../ui-kit/Accordion/Accordion";
import { BASE_URL_FRONT } from "../../api/constants";

const Faq = () => {
    const [asideActive, setAsideActive] = useState("");

    const handleAsideActive = (e, anchor) => {
        e.preventDefault();
        const targetElement = document.querySelector(anchor);
        const yOffset = -150;
        const y =
            targetElement?.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
        setAsideActive(e.target.innerText);
    };

    const items = [
        { title: <Link to={"/tickets"}>Авиабилеты и отели для визы</Link> },
        { title: <a>Вопросы и ответы</a> },
    ];

    const asideContent = [
        {
            title: "Общая информация",
            anchor: "#general",
        },
        {
            title: "Поиск",
            anchor: "#search",
        },
        {
            title: "Заполнение данных",
            anchor: "#passengersInfo",
        },
        {
            title: "Бронирование",
            anchor: "#booking",
        },
        {
            title: "Оплата",
            anchor: "#payment",
        },
        {
            title: "Возврат",
            anchor: "#tiketback",
        },
    ];

    const accordionContent1 = [
        {
            title: "Можете ли вы вкратце описать, в чём заключается ваша услуга?",
            content:
                "Сервис предоставляет бронирование авиабилетов и отелей для подачи заявлений на визу, для прохождения пограничного контроля, для личных целей. Данная услуга предназначена для путешественников, обращающихся за визами, которые требуют подтверждение бронирования авиабилетов и/или отелей. ",
        },
        {
            title: "Мне нужно бронирование авиабилетов для подачи заявления на визу или в аэропорту. Это то, что мне нужно?",
            content:
                "Если Вам необходимо предоставить бронирование авиабилетов для заявления на выдачу визы, прохождения пограничного контроля, то эта услуга – то, что Вам нужно.",
        },
        {
            title: "Что такое бронирование авиабилетов? ",
            content:
                "Бронирование авиабилета (или маршрутная квитанция) – это документ с подробной информацией о Вашем рейсе. Данный документ включает в себя название рейса, время вылета и прилета, даты, код рейса, уникальный код PNR (номер брони). Бронирование авиабилета не является оплаченным билетом.",
        },
        {
            title: "Предоставляете ли вы электронный авиабилет?",
            content: (
                <>
                    • Для тарифов AirSurf 3, AirSurf 7, AirSurf+ бронирование
                    авиабилета не предусматривает номер электронного билета.
                    Сервис предоставляет маршрутную квитанцию для подтверждения
                    бронирования авиабилета.
                    <br />• Для тарифа AirSurf Max сервис предоставляет
                    выкупленный билет, который проверяется на сайте авиакомпании
                    с использованием PNR-кода бронирования и отслеживанием
                    номера билета
                </>
            ),
        },
        {
            title: "Как я могу оформить заказ?",
            content:
                "1. Выберите желаемые маршрут, даты и пассажиров.\n" +
                "    2. Заполните персональные данные.\n" +
                "    3. Выберите нужный тариф. \n" +
                "    4. Оплатите заказ. После оплаты ваш заказ будет обработан автоматически.\n" +
                "    5. Получите оформление бронирование сразу же после успешной оплаты заказа. Скачайте маршрутную квитанцию и бронь отеля на сайте или во вложении к Email-письму.\n" +
                "    6. Используйте маршрутную квитанцию и бронь отеля до даты истечения бронирования.",
        },
        {
            title: "Что такое смарт-маршрут?",
            content:
                'Смарт-маршрут – это так называемая "умная стыковка", так как это уникальный алгоритм поиска, который стыкует рейсы лоукостеров и авиакомпаний из разных альянсов. Механизм сервиса AirSurfer даёт ещё больше возможностей по выбору перелёта.\n\n' +
                "      Если вам предстоит длительный полёт, то Вам вероятнее всего будет необходима пересадка. Не говоря уже о перелете из одной точки мира в другую. Чтобы не искать билеты отдельно на каждый отрезок пути, воспользуйтесь смарт-маршрутами. AirSurfer найдёт единый билет с разным количеством пересадок, а главное — это будет дешевле, чем самостоятельно составленный маршрут или обычный билет с пересадкой. В итоге у вас получится несколько билетов в заказе, который Вы собрали за один поиск!\n\n" +
                "      Поиск авиабилетов работает туда и обратно.",
        },
    ];

    const accordionContent2 = [
        {
            title: "Какое максимальное количество пассажиров можно включить в один заказ?",
            content:
                "Вы можете добавить до 9 пассажиров в один и тот же заказ во время процесса бронирования.",
        },
        {
            title: "Ваш сервис работает с любыми гражданствами?",
            content:
                "Да. AirSurfer работает для всех во всём мире, независимо от того, откуда Вы.",
        },
    ];

    const accordionContent3 = [
        // {
        //     title: "Как проверить бронирование авиабилета для определенной авиакомпании?",
        //     content: (
        //         <>
        //             Существует два способа проверки, всё ли в порядке с бронью
        //             билета:
        //             <br />
        //             1. Проверка билета на сайте авиакомпании <br />
        //             Вы можете проверить билет прямо на сайте авиакомпании по
        //             номеру бронирования (PNR-код), который указан на маршрутной
        //             квитанции.
        //             <br />
        //             2. Проверка авиабилета через Интернет
        //             <br />
        //             Все авиабилеты бронируются с помощью одной из 4-х глобальных
        //             систем бронирования (GDS):
        //             <br />
        //             • Amadeus
        //             <br />
        //             • Galileo
        //             <br />
        //             • Sabre
        //             <br />
        //             • Сирена – Трэвел
        //             <br />
        //             AirSurfer бронирует авиабилеты через GDS Amadeus. Проверить
        //             детали билета вы можете на официальном сайте
        //             <br />
        //             для{" "}
        //             <a
        //                 className={styles.faq_link}
        //                 href={"https://checkmytrip.com/"}
        //                 target="_blank"
        //             >
        //                 GDS Amadeus
        //             </a>
        //             . Для этого в специальную форму введите фамилию
        //             <br />
        //             (в том виде, как она написана в маршрутной квитанции) и
        //             номер бронирования. Если информация введена <br />
        //             правильно и авиабилет для вас действительно куплен, то перед
        //             вами откроется маршрутная квитанция.
        //             <br />О том, что билет подтвержден, будет свидетельствовать
        //             надпись \"Подтверждено\".{" "}
        //         </>
        //     ),
        // },
        {
            title: "Как ввести номер документа пассажира",
            content:
                "Необходимо ввести серию и номер документа полностью, без пробелов и других символов. Загранпаспорт РФ — девять цифр серии и номера подряд, например: 711234567. \n",
        },
        {
            title: "Как ввести фамилию, имя и отчество пассажира",
            content:
                "Необходимо написать данные латиницей в полном соответствии с данными в загранпаспорте РФ.",
        },
        {
            title: "Как правильно заполнить данные, если двойная фамилия?",
            content:
                "Обратите внимание, что в связи с особенностью ввода данных в систему бронирования все пробелы и" +
                " дефисы в имени, фамилии или номере документа должны быть убраны. Поэтому если у вас двойное " +
                "имя или фамилия, то необходимо указывать данные слитно.",
        },
    ];

    const accordionContent4 = [
        {
            title: "Что такое код бронирования (PNR)?",
            content:
                "Код бронирования, или PNR — это уникальный код, шести- или пятизначная последовательность букв и цифр. По данному коду сотрудник аэропорта, авиакомпании или агента может найти информацию по вашим билетам.",
        },
        {
            title: "Что такое код-шеринговый рейс?",
            content:
                "Код-шеринговый рейс — это когда несколько авиакомпаний работают вместе, чтобы предложить пассажирам больше маршрутов и удобств. Вы бронируете билет у одной или нескольких авиакомпаний, но сам рейс выполняется другой авиакомпанией. Это значит, что вы можете лететь дальше с удобной пересадкой, не беспокоясь о разных бронированиях. Имейте в виду, что правила и услуги на борту могут немного различаться в зависимости от авиакомпании, выполняющей рейс.",
        },
        {
            title: "Как выглядит пример маршрутной квитанции и брони отеля?",
            content: (
                <>
                    Маршрутные квитанции могут выглядеть по-разному. При этом
                    они всегда содержат следующие сведения: <br />
                    • имя пассажира, <br />
                    • название авиакомпании, <br />
                    • название агентства, выписавшего билет, <br />
                    • код брони, на основании которой был выписан билет, <br />
                    • номер паспорта пассажира, <br />
                    • дату рождения пассажира, <br />
                    • дату выписки билета, <br />
                    • город вылета (FROM) и город прибытия (TO), <br />
                    • номер рейса, <br />
                    • класс бронирования (обычно первый класс обозначается
                    буквами F, P, A; бизнес – J, C, D, I, Z; эконом – W, S, Y,
                    B, H, K, L, M, N, Q, T, V, X) <br />
                    • дату и время вылета, <br />
                    • норму провоза багажа, <br />
                    • стоимость авиабилета. <br />
                    <a
                        className={styles.faq_link}
                        href={BASE_URL_FRONT + "AirSurfer_Flight_Example.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Пример брони в PDF
                    </a>
                </>
            ),
        },
        {
            title: "Как мне проверить, что мое бронирование авиабилета действительно?",
            content: (
                <>
                    Вы можете проверить билет прямо на сайте авиакомпании по
                    номеру бронирования (PNR-коду) и фамилии пассажира, которые
                    указаны в маршрутной квитанции. Ссылка на сайт авиакомпании
                    доступна на странице успешного заказа в столбце “Проверка“.
                </>
            ),
        },
        // {
        //     title: "Я не могу найти свое бронирование авиабилета на сайте авиакомпании. Что мне делать?",
        //     content:
        //         "Обратите внимание на то, что на сайтах некоторых авиакомпаний можно просматривать только заказы," +
        //         " сделанные непосредственно на данных сайтах. Для проверки бронирования обратитесь в колл-центр " +
        //         "авиакомпании с номером брони или напишите в поддержку AirSurfer.",
        // },
        {
            title: "Как долго ждать получения маршрутной квитанции после бронирования?",
            content:
                "Обработка заказа происходит мгновенно и включает в себя получение маршрутной квитанции и" +
                " отправка письма с маршрутной квитанцией на почту, указанную при оформлении. " +
                "В редких случаях время оформления брони авиабилета занимает до 10 минут в зависимости от " +
                "длительности подтверждения бронирования авиакомпанией. Время оформления брони отеля занимает до 1 часа. ",
        },
        {
            title: "Как долго действует бронирование авиабилета?",
            content:
                "Срок действия - до 28 дней. Бронь авиабилета действительна 3 дня, 5-7 дней, 10-14 дней или 20-28 дней на выбор.",
        },
        {
            title: "Могу ли я продлить текущее бронирование авиабилета?",
            content:
                "К сожалению, нет. Данная опция не предусмотрена ни в одной авиакомпании.  ",
        },
        {
            title: "Когда мне следует оформить бронирование авиабилета?",
            content:
                "Советуем оформлять бронь билетов за один день до подачи заявления на визу или до предъявления при прохождении пограничного контроля. ",
        },
        {
            title: "Нужно ли мне самостоятельно запрашивать отмену бронирования авиабилета?",
            content:
                "Бронирование авиабилета отменяется автоматически по истечению времени. С Вашей стороны никаких дополнительных действий не требуется.",
        },
        {
            title: "Могу ли я добавить пассажиров к текущему бронированию?",
            content:
                "Да. Вы можете добавить до 9 пассажиров в один и тот же заказ во время процесса бронирования.",
        },
        {
            title: "Можно ли забронировать билет и оплатить позже?",
            content:
                "Нет, приступить к оплате необходимо сразу после оформления заказа. ",
        },

        {
            title: "Нужно ли оформлять билет на младенца, и является ли он бесплатным?",
            content:
                "Билеты для младенцев оформляются только в одном заказе с сопровождающими их взрослыми. Оформление авиабилета на младенца в отдельном заказе невозможно.\n",
        },
    ];
    const accordionContent5 = [
        {
            title: "Как оплатить зарубежной картой (не РФ)?",
            content: (
                <>
                    • Выберите валюту “USD”
                    <br />
                    • Для оплаты нажмите “К оплате, USD”
                    <br />
                    • В открытой всплывающей форме нажмите “Иностранной картой”
                    <br />
                    • Введите данные карты
                    <br />
                    <br />
                    *Ограничения по странам: не принимаются оплаты из
                    Азербайджана, Индии, Армении, оплата из Турции доступна с
                    включенным VPN
                </>
            ),
        },
        {
            title: "Какие предусмотрены правила оплаты?",
            content: `К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, МИР.

                Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ оплаты: банковской картой.

                При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:

                тип карты;

                номер карты;

                срок действия карты (указан на лицевой стороне карты);

                Имя держателя карты (латинскими буквами, точно также как указано на карте);

                CVC2/CVV2 код.

                Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.

                Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии шифрования SSL. Эта информация недоступна посторонним лицам.`,
        },
    ];
    const accordionContent6 = [
        {
            title: "Могу ли я изменить оформленное бронирование?",
            content:
                "Сервис AirSurfer делает моментальную бронь, поэтому оформленное бронирование изменить нельзя. Если Вам потребуются какие-либо изменения, Вам нужно будет сделать новое бронирование.",
        },
        {
            title: "Могу ли я отменить бронирование и вернуть деньги?",
            content:
                "После оформления бронирования, отменить его и получить возмещение невозможно.",
        },
        {
            title: "Как отменить неоплаченную бронь?",
            content:
                "Если оплата не была совершена, то бронирование автоматически аннулируется по истечении срока оплаты, " +
                "который указан в электронном письме с данными заказа. Если Вы хотите сделать новую бронь на рейс той же" +
                " авиакомпании или просто освободить забронированные места для других пассажиров, то вы можете " +
                "обратиться в поддержку AirSurfer и сообщить персональному менеджеру номер заказа, который необходимо отменить.",
        },
    ];

    const faqContent = [
        {
            id: "general",
            title: "Общая информация",
            items: accordionContent1,
        },
        {
            id: "search",
            title: "Поиск",
            items: accordionContent2,
        },
        {
            id: "passengersInfo",
            title: "Заполнение данных",
            items: accordionContent3,
        },
        {
            id: "booking",
            title: "Бронирование",
            items: accordionContent4,
        },
        {
            id: "payment",
            title: "Оплата",
            items: accordionContent5,
        },
        {
            id: "tiketback",
            title: "Возврат",
            items: accordionContent6,
        },
    ];

    return (
        <section className={styles.faq}>
            <div className="container">
                <CustomBreadCrumb items={items} />
                <div className={styles.faq__header}>
                    <h2 className={styles.faq__header_title}>
                        Вопросы и ответы
                    </h2>
                    <img src={question} alt="question" />
                </div>
                <div className={styles.faq__main}>
                    <aside className={clsx(styles.faq__aside)}>
                        <ul className={styles.faq__aside_list}>
                            {asideContent.map((item, i) => {
                                return (
                                    <li
                                        key={i}
                                        className={clsx(
                                            styles.faq__aside_item,
                                            {
                                                [styles.faq__aside_active]:
                                                    asideActive === item.title,
                                            }
                                        )}
                                        onClick={(e) => {
                                            handleAsideActive(e, item.anchor);
                                        }}
                                    >
                                        {item.title}
                                    </li>
                                );
                            })}
                        </ul>
                    </aside>
                    <div className={styles.faq__divide}></div>
                    <div className={styles.faq__block}>
                        {faqContent.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    id={item.id}
                                    className={styles.faq__block_item}
                                >
                                    <h3 className={styles.faq__block_title}>
                                        {item.title}
                                    </h3>
                                    <Accordion accordionItems={item.items} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
