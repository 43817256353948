import { Input } from "antd";
import { useState } from "react";

function mask(value, limit, separator) {
    var output = [];
    for (let i = 0; i < value.length; i++) {
        if (i !== 0 && i % limit === 0) {
            output.push(separator);
        }

        output.push(value[i]);
    }

    return output.join("");
}

const unmask = (value) => value.replace(/[^\d]/g, "");

const checkSeparator = (position, interval) =>
    Math.floor(position / (interval + 1));

export const CardInput = ({
    placeholder,
    onChange,
    value,
    className,
    disabled,
}) => {
    const [oldCursor, setOldCursor] = useState();
    const [oldValue, setOldValud] = useState();

    function onKeyDownHandler(e) {
        let el = e.target;
        setOldValud(el.value);
        setOldCursor(el.selectionEnd);
    }

    function onInputHandler(e) {
        let el = e.target,
            newValue = unmask(el.value),
            newCursorPosition;

        if (newValue.match(/^\d{0,16}$/g)) {
            newValue = mask(newValue, 4, " ");

            newCursorPosition =
                oldCursor -
                checkSeparator(oldCursor, 4) +
                checkSeparator(
                    oldCursor + (newValue.length - oldValue.length),
                    4
                ) +
                (unmask(newValue).length - unmask(oldValue).length);

            el.value = newValue !== "" ? newValue : "";
            onChange(el.value);
        } else {
            el.value = oldValue;
            onChange(el.value);
            newCursorPosition = oldCursor;
        }

        el.setSelectionRange(newCursorPosition, newCursorPosition);
    }

    return (
        <Input
            className={className}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onKeyDown={onKeyDownHandler}
            onInput={onInputHandler}
        />
    );
};
