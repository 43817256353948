import { Button, Form } from "antd";
import style from "./Form.module.scss";
import React from "react";
import RadioLabels from "../RadioLabels/RadioLabels";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencies } from "store/selectors/catalog";
import { selectCurrency } from "store/selectors/header";
import { setCurrency } from "store/slices/headerSlice";

const CurrencyForm = ({ closeModal }) => {
    const dispatch = useDispatch();
    const carrencies = useSelector(selectCurrencies);
    const activeCurrency = useSelector(selectCurrency);

    const onChangeCurrency = (value) => {
        dispatch(setCurrency(value));
        closeModal();
        window.location.reload();
    };

    return (
        <Form name="currency_form" className={style.custom_form}>
            <p className={style.form__text}>
                Там, где это необходимо, цены будут конвертированы и отображены
                в выбранной вами валюте. Валюта, в которой вы заплатите, зависит
                от вашего бронирования. Кроме того, может взиматься сервисный
                сбор.
            </p>
            <p className={style.carrency__subtitle}>Все валюты</p>

            <div className={style.carrency__list}>
                {carrencies?.map((item) => (
                    <RadioLabels
                        title={item.name}
                        active={activeCurrency.code == item.code}
                        subtitle={item.code}
                        value={item}
                        onClick={onChangeCurrency}
                    />
                ))}
            </div>
        </Form>
    );
};
export default CurrencyForm;
