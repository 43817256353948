import Player from "../../../components/UI/Player/Player";
import styles from "./PartnerVideo.module.scss";


const PartnerVideo = ({ url, playIcon, preview }) => {
    return (
        <div className={styles.video}>
            <h2 className={styles.video__title}>
                Подробный <span>видео-обзор</span>
                <br />
                реферальной программы и способах работы с ней
            </h2>
            <p className={styles.video__subtitle}>
                Вы найдете все необходимые инструкции в видео ниже.
            </p>
            <div className={styles.video__player}>
                <Player url={url} playIcon={playIcon} preview={preview} />
            </div>
        </div>
    );
};

export default PartnerVideo;
