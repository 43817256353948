import TabsItems from "../../components/UI/Tabs/Tabs";
import style from "../../components/UI/Tabs/Tabs.module.scss";
import styles from "./MainPage.module.scss";
import PlaneIcon from "../../assets/icons/plane.svg";
import DocumentsIcon from "../../assets/icons/documents.svg";
import InsuranceIcon from "../../assets/icons/insurance.svg";
import OrangeStar from "../../assets/icons/orangeStar.svg";
import GreenStar from "../../assets/icons/greenStar.svg";
import Reliably from "./Reliably/Reliably";
import { ReactSVG } from "react-svg";
import Сooperation from "./Сooperation/Сooperation";
import Booking from "./Booking/Booking";
import Trail from "./Trail/Trail";
import ReviewsMain from "./ReviewsMain/ReviewsMain";
import Maximum from "./Maximum/Maximum";
import QA from "./QA/QA";
import Partner from "./Partner/Partner";
import SearchPanel from "./SearchPanel/SearchPanel";
import classNames from "classnames";
import { useHidePage } from "../../hooks/useHidePage";
import ResetPassword from "../Mails/ResetPassword/ResetPassword";
import Registred from "../Mails/Registred/Registred";
import BookingComplete from "../Mails/BookingCompleted/BookingComplete";
import BookingFramed from "../Mails/BookingFramed/BookingFramed";
import BookingCanceled from "../Mails/BookingCanceled/BookingCanceled";
import BookingPaid from "../Mails/BookingPaid/BookingPaid";

const items = [
    {
        key: "1",
        label: (
            <div className={style.tabs_item}>
                <ReactSVG src={PlaneIcon} />
                <p className={style.tabs_item_text}>
                    Авиабилеты и отели для визы
                </p>
            </div>
        ),
        children: <SearchPanel />,
    },
    {
        key: "2",
        label: (
            <div className={style.tabs_item}>
                <ReactSVG src={DocumentsIcon} />
                <span className={style.status_text}>Скоро</span>
                <p className={style.tabs_item_text}>Документы для визы</p>
            </div>
        ),
        children: `Content of Tab Pane 2`,
        disabled: true,
    },
    {
        key: "3",
        label: (
            <div className={style.tabs_item}>
                <ReactSVG src={InsuranceIcon} />
                <span className={style.status_text}>Скоро</span>
                <p className={style.tabs_item_text}>Страхование за границей</p>
            </div>
        ),
        children: `Content of Tab Pane 3`,
        disabled: true,
    },
];

const MainPage = () => {
    const hideMainPage = useHidePage();

    return (
        <div
            className={classNames(styles.main, {
                [styles["main__hidden"]]: hideMainPage,
            })}
        >
            <div className={styles.main__top}>
                <div className="container">
                    <div className={styles.main__wrapper}>
                        <h1 className={styles.main__title}>
                            Бронирование авиабилетов для визы без оплаты
                        </h1>
                        <p className={styles.main__subtitle}>
                            Уже 10 000+ путешественников прошли паспортный контроль, оформили и продлили визу с помощью сервиса!
                        </p>
                        <div className={styles.main__rating}>
                            <div className={styles.main__rating_leftSide}>
                                <div className={styles.main__rating_whiteStar}>
                                    <ReactSVG src={OrangeStar} />
                                </div>
                                <p className={styles.main__rating_rate}>4.8</p>
                            </div>
                            <div className={styles.main__rating_rightSide}>
                                <div className={styles.main__rating_greenStar}>
                                    <ReactSVG src={GreenStar} />
                                </div>
                                <a href="https://www.trustpilot.com/review/airsurfer.co" target="_blank" rel="noopener noreferrer">
                                    <p className={styles.main__rating_text}>
                                    Trustpilot
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div className={styles.main__tabs}>
                            <TabsItems items={items} />
                        </div>
                    </div>
                </div>
            </div>
            {!hideMainPage && (
                <>
                    <Reliably />
                    <Сooperation />
                    <Booking />
                    <Trail />
                    <ReviewsMain />
                    <Maximum />
                    <QA />
                    <Partner />
                </>
            )}
        </div>
    );
};

export default MainPage;
