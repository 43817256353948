import { Divider } from "antd";
import style from "./Divider.module.scss";

const CustomDivider = ({children}) => {
  return (
    <Divider className={style.custom_divider}>
      {children}
    </Divider>
  )
}

export default CustomDivider