import { Form, Divider } from 'antd';
import Input from "../Input/Input";
import CustomButton from "../CustomButton/CustomButton";
import style from "./Form.module.scss";
import React, { useState } from "react";
import CustomCheckbox from "../Checkbox/CustomCheckbox";
import CustomDivider from "../CustomDivider/CustomDivider";
import Google from "../../../assets/icons/google.svg";
import { ReactSVG } from "react-svg"
import { useRegisterMutation } from "../../../services/user";
import Spinner from "../Spinner/Spinner";
import { setToken, setUser } from "../../../store/slices/user";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../api/constants";

const RegistrationForm = ({onLoginClick, setRegistrationSuccessText, onRegister}) => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordRepeat, setPasswordRepeat ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const [ passwordRepeatError, setPasswordRepeatError ] = useState('');
  const [ emailError, setEmailError ] = useState('');
  const [ checked, setChecked ] = useState(false);
  const [ checkedError, setCheckedError] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  const [register] = useRegisterMutation();

  const dispatch = useDispatch();

  const onFinish = () => {
    if (!email){
      setEmailError('Введите email!')
    }
    if (!password){
      setPasswordError('Введите пароль!')
    }
    if (!passwordRepeat){
      setPasswordRepeatError('Введите пароль еще раз!')
    }
    if (!checked){
      setCheckedError('Вы должны согласиться с условиями');
    }
    if (password !== passwordRepeat){
        setPasswordRepeatError('Пароли не совпадают')
    }
    if (isLoading || !email || !password || !passwordRepeat || !checked || password !== passwordRepeat){
      return
    }
    setIsLoading(true);
    if (password && email && passwordRepeat && checked){
      register({email: email, password: password, password_confirmation: passwordRepeat}).unwrap()
        .then(data => {
          const user = data.data.user;
          localStorage.setItem('user', JSON.stringify(user));
          const {token} = data.data;
          localStorage.setItem('token', token);
          dispatch(setUser({user}));
          dispatch(setToken({token}));
          setRegistrationSuccessText(data.message);
          onRegister();
          setIsLoading(false);
        })
        .catch(resp => {
          const errors = resp.data.errors;
          setEmailError(errors.email);
          setPasswordError(errors.password);
          setIsLoading(false);
        });
    }
  };

  const onChange = (value, callback) => {
    callback(value);
    setPasswordError('');
    setEmailError('');
    setPasswordRepeatError('');
    setCheckedError('');
  }

  return (
    <Form
      name="login_form"
      className={style.custom_form}
    >
      <Form.Item
        name="username"
      >
        <Input
          placeholder="E-mail"
          value={email}
          error={emailError}
          onChange={(value) => onChange(value, setEmail)}
        />
      </Form.Item>
      <Form.Item
        name="password"
      >
        <Input
          password
          type="password"
          placeholder="Пароль"
          value={password}
          error={passwordError}
          onChange={(value) => onChange(value, setPassword)}
        />
      </Form.Item>
      <Form.Item
        name="passwordRepeat"
      >
        <Input
          password
          type="passwordRepeat"
          placeholder="Повторите пароль"
          value={passwordRepeat}
          error={passwordRepeatError}
          onChange={(value) => onChange(value, setPasswordRepeat)}
        />
      </Form.Item>
      <Form.Item>
        <CustomCheckbox
          onChange={() => {
            setChecked(!checked);
            setCheckedError('');
          }}
          checked={checked}
          text={"Соглашаюсь с условиями использования и политикой конфиденциальности"}
          error={checkedError}
        />
      </Form.Item>

      <Form.Item>
        <CustomButton
          primary
          onClick={() => onFinish()}
          className="login-form-button"
          fullWidth
        >
          {isLoading && (<Spinner/>)}
          Зарегистрироваться
        </CustomButton>
      </Form.Item>
      <Form.Item>
        <CustomDivider>Или</CustomDivider>
      </Form.Item>
      <CustomButton
        linedBlack
        fullWidth
        onClick={() => {
          localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
          window.location.href = `${BASE_URL}auth/google/redirect`
        }}
      >
        <ReactSVG src={Google} className={style.google_icon}/>
        С помощью Google
      </CustomButton>
      <Form.Item className={style.register_link}>
        Уже есть аккаунт? <a onClick={onLoginClick}>Войти</a>
      </Form.Item>
    </Form>
  );
};
export default RegistrationForm;
