import styles from "./Policy.module.scss";

const Policy = ({ title, items }) => {
    return (
        <div className={styles.policy}>
            <div className={styles.policy__wrapper}>
              {title && (<h1 className={styles.policy__title}>{title}</h1>)}
                <ol start="1" type="1" className={styles.policy__list}>
                    {items.map((item, index) => (
                        <li className={styles.policy__item} key={index}>
                          {item.title && (
                            <h2 className={styles.policy__item_title}>
                                {`${
                                    title !== "Политика безопасности и возврата" && title !== "Контакты"
                                        ? (index + 1 + '.')
                                        : ''
                                } ${item.title}`}
                            </h2>
                            )}
                            <p className={styles.policy__item_text}>
                                {item.description}
                            </p>
                            <hr className={styles.policy__divider} />
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default Policy;
