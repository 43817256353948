import { isEqual, forEach } from "lodash";

export function getDirtyFields(originalObject, modifiedObject) {
    const dirtyFields = {};

    forEach(modifiedObject, (value, key) => {
        if (!isEqual(value, originalObject[key])) {
            dirtyFields[key] = value;
        }
    });

    return dirtyFields;
}
