import { Checkbox } from 'antd';
import style from "./CustomCheckbox.module.scss";
import classNames from "classnames";

const CustomCheckbox = ({text, className, checked, onChange, error, disabled}) => {
  return(
    <>
      <Checkbox
        className={classNames(style.custom_checkbox, className)}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      >
        {text}
      </Checkbox>
      {error && <span className={style.error_text}>{error}</span>}
    </>
  )
}

export default CustomCheckbox;