import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import styles from "./Paid.module.scss";
import done from "../../../assets/icons/done.png";
import Spinner from "../../../components/UI/Spinner/Spinner";

const Paid = ({
    email,
    routeLinkFlight,
    routeLinkHotel,
    flightBookingFile,
    hotelBookingFile,
    isOrderWithHotel,
}) => {
    return (
        <div className={styles.bookingSuccess__top}>
            <img
                className={styles.bookingSuccess__top_img}
                src={done}
                alt="done"
            />
            <h1 className={styles.bookingSuccess__top_title}>
                Бронирование подтверждено!
            </h1>
            <p className={styles.bookingSuccess__top_text}>
                Вы можете скачать маршрутную квитанцию по кнопке ниже.
                <br />
                Также маршрутная квитанция была выслана вам на почту{" "}
                <span>{email}</span>
            </p>
            {!flightBookingFile && (
                <p className={styles.bookingSuccess__top_download}>
                    Маршрутная квитанция будет доступна для скачивания в течение
                    15 минут.
                    {isOrderWithHotel && (
                        <>
                            Бронь отеля будет доступна для скачивания в течение
                            60 минут.
                        </>
                    )}
                </p>
            )}
            <div className={styles.bookingSuccess__top_buttons}>
                {flightBookingFile ? (
                    <CustomButton primary={true} onClick={routeLinkFlight}>
                        Скачать маршрутную квитанцию
                    </CustomButton>
                ) : (
                    <Spinner />
                )}
                {hotelBookingFile ? (
                    <CustomButton lined={true} onClick={routeLinkHotel}>
                        Скачать бронь отеля
                    </CustomButton>
                ) : null}
            </div>
        </div>
    );
};

export default Paid;
