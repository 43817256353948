import styles from "./Reliably.module.scss";
import CheckMark from "../../../assets/icons/checkmark.svg";
import Clock from "../../../assets/icons/clock.svg";
import Lightning from "../../../assets/icons/lightning.svg";
import House from "../../../assets/icons/house.svg";
import { ReactSVG } from "react-svg";
import { BASE_URL_FRONT } from "../../../api/constants";

const Reliably = () => {
    const reliablyContent = [
        {
            img: <ReactSVG src={CheckMark} />,
            title: "Официальная бронь авиабилета",
            text: (
                <>
                    Бронь авиабилета можно проверить на сайте авиакомпании после
                    оплаты заказа.
                    <p>
                        <a
                            className={styles.reliably__item_link}
                            href={"/faq"}
                            target={"_blank"}
                        >
                            Подробнее, как проверить.
                        </a>
                    </p>
                </>
            ),
            key: 0,
        },
        {
            img: <ReactSVG src={Clock} />,
            title: "Срок действия - до 28 дней",
            text: "Бронь авиабилета действительна 3 дня, 5-7 дней, 10-14 дней или 20-28 дней на выбор",
            key: 1,
        },
        {
            img: <ReactSVG src={Lightning} />,
            title: "Мгновенное подтверждение",
            text: (
                <>
                    Получите маршрутную квитанцию с подтвержденным PNR-кодом от
                    авиакомпании сразу после оплаты.
                    <a
                        className={styles.reliably__item_link}
                        href={BASE_URL_FRONT + "AirSurfer_Flight_Example.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {" "}
                        Пример маршрутной квитанции
                    </a>
                </>
            ),
            key: 2,
        },
        {
            img: <ReactSVG src={House} />,
            title: "Бронирование отелей",
            text: (
                <>
                    Подтверждение проживания в гостинице,
                    <a
                        className={styles.reliably__item_link}
                        href={BASE_URL_FRONT + "AirSurfer_Hotel_Example.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span> отеле</span>
                    </a>
                </>
            ),
            key: 3,
        },
    ];

    const updatedContent = reliablyContent.map((item) => {
        // if (item.text.includes("Подробнее, как проверить.")) {
        //     const [beforeText, afterText] = item.text.split(
        //         "Подробнее, как проверить."
        //     )
        //
        //     return {
        //         ...item,
        //         text: beforeText,
        //         linkText: afterText,
        //         link: "/faq",
        //     }
        // }
        return item;
    });

    return (
        <section className={styles.reliably}>
            <div className="container">
                <div className={styles.reliably__wrapper}>
                    <h2 className={styles.reliably__title}>
                        Бронируйте авиабилеты без оплаты
                    </h2>
                    <div className={styles.reliably__items}>
                        {updatedContent.map((item) => (
                            <div
                                className={styles.reliably__item}
                                key={item.key}
                            >
                                <div className={styles.reliably__item_img}>
                                    {item.img}
                                </div>
                                <h2 className={styles.reliably__item_title}>
                                    {item.title}
                                </h2>
                                {item.link ? (
                                    <>
                                        <p
                                            className={
                                                styles.reliably__item_text
                                            }
                                        >
                                            {item.text}
                                            <br />
                                            <a
                                                className={
                                                    styles.reliably__item_link
                                                }
                                                href={item.link}
                                            >
                                                Подробнее, как проверить.
                                            </a>
                                        </p>
                                    </>
                                ) : (
                                    <p className={styles.reliably__item_text}>
                                        {item.text}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Reliably;
