import { Link } from "react-router-dom";
import CustomBreadCrumb from "../../../components/UI/CustomBreadCrumb/CustomBreadCrumb";
import Policy from "../Policy";
import styles from "./ReturnPolicy.module.scss";

const ReturnPolicy = () => {
    const policyItems = {
        title: "Политика безопасности и возврата",
        items: [
            {
                title: "Политика безопасности платежей",
                description: (
                    <>
                        <p>Оплатить заказ можно:</p>
                        <ol
                            className={styles.return__list}
                            style={{ paddingLeft: "18px" }}
                            start="1"
                            type="1"
                        >
                            <li>С помощью банковских карт РФ.</li>
                            <li>Tinkoff Pay.</li>
                            <li>MIR Pay.</li>
                            <li>Yandex Pay.</li>
                            <li>Через Систему Быстрых Платежей.</li>

                        </ol>
                        <br />
                        <p>В сервисе AirSurfer вы можете совершить оплату с помощью банковских карт РФ. Ввод данных для оплаты, включая номер банковской карты, происходит в нашем сервисе. После этого данные карты обрабатываются платежным центром.</p>

                        <p>Вся предоставленная вами информация передается в зашифрованном виде по защищенному каналу связи SSL. Все операции по вашей карте производятся при полном соблюдении требований платежных систем. Сервис AirSurfer соответствует требованиям стандарта безопасности платежей PCI DSS.</p>

                        <p>Для снижения рисков при проведении оплаты может быть задействована технология 3D Secure. Благодаря ей ваша карта защищена одноразовым паролем, который доступен только вам и действителен для одной покупки. Он высылается по смс, когда вы совершаете оплату.</p>
                    </>
                ),
            },
            {
                title: "Правила возврата",
                description:
                    "При отказе от услуги, оплаченная сумма за услугу, подлежит возврату в течение 10 дней (ст 22 Закона РФ \"О защите прав потребителей\" N 2300-1 от 07.02- далее Закон № 2300-1).",
            },
            {
                title: "Обратите внимание!",
                description:
                    "Отказ от услуги возможен только до оформленной брони авиабилета, доступной для скачивания и отправленной на электронную почту. Возврат осуществляется на ту же банковскую карту, с которой была оплачена услуга.\n",
            },
        ],
    };

    const items = [
        { title: <Link to={"/"}>Авиабилеты и отели для визы</Link> },
        { title: <Link to={"/"}>Политика безопасности и возврата</Link> },
    ];

    return (
        <div className={styles.return}>
            <div className="container">
                <CustomBreadCrumb items={items} />

                <div className={styles.return__wrapper}>
                    <Policy
                        title={policyItems.title}
                        items={policyItems.items}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReturnPolicy;
