import useIsMobile from "./useIsMobile";
import { useSelector } from "react-redux";

export const useHidePage = () => {
    const isMobile = useIsMobile();
    const isInputOpened = useSelector((state) => state.searchInputs.isOpened);
    const isFilterMenuOpened = useSelector((state) => state.filters.isOpened);
    const isSortingMenuOpened = useSelector((state) => state.sorting.isOpened);
    return (isInputOpened || isFilterMenuOpened || isSortingMenuOpened) && isMobile
}
