import { Link } from "react-router-dom";
import CustomBreadCrumb from "../../../components/UI/CustomBreadCrumb/CustomBreadCrumb";
import Policy from "../Policy";
import styles from "./ContractOffer.module.scss";

const ContractOffer = () => {
    const policyItems = {
        title: "Договор оферты",
        items: [
            {
                title: "Термины и определения",
                description: (
                    <>
                        <p>
                            1.1 Оферта – адресованное любому физическому лицу
                            предложение Агентства на заключение Договора на
                            условиях, содержащихся в настоящей Оферте.
                        </p>
                        <br />
                        <p>
                            1.2 Опубликование – размещение текста настоящей
                            Оферты в свободном доступе в сети Интернет по
                            адресу:{" "}
                            <a href="https://airsurfer.co/contractOffer">
                                https://airsurfer.co/contractOffer
                            </a>{" "}
                            .
                        </p>
                        <br />

                        <p>
                            1.3 ИП Гнатюк Анатолий Дмитриевич (ОГРНИП:
                            323265100162401)
                        </p>
                        <br />

                        <p>
                            1.4 Агентство не является авиаперевозчиком, услуги
                            Агентства носят посреднический характер. Агентство
                            обязуется оказать Клиенту услуги по бронированию
                            авиабилетов на регулярные рейсы перевозчиков и
                            прочих услуг поставщиков, а Заказчик обязуется
                            оплатить услуги Агентства по установленной стоимости
                            в разделе Тарифы.
                        </p>
                        <br />
                        <p>
                            {" "}
                            1.5 Клиент (пользователь) – физическое, совершающее
                            онлайн-бронирование на интернет-портале Агентства:{" "}
                            <a href="https://airsurfer.co/">
                                https://airsurfer.co/
                            </a>{" "}
                            .
                        </p>
                        <br />
                        <p>
                            {" "}
                            1.6 Перевозчик – авиакомпания эксплуатант,
                            осуществляющий воздушные перевозки пассажиров,
                            багажа, имеющий лицензию на осуществление
                            подлежащего лицензированию в соответствии с
                            законодательством Российской Федерации вида
                            деятельности в области авиации, обеспечивающий
                            услуги по перевозке пассажиров и багажа в пункт
                            временного/постоянного пребывания.
                        </p>
                        <br />
                        <p>
                            1.7 Пассажир – физическое лицо, заключившее договор
                            воздушной перевозки пассажира, либо физическое лицо,
                            в целях перевозки которого заключен договор
                            фрахтования воздушного судна (воздушный чартер).
                        </p>
                        <br />
                        <p>
                            1.8 Договор - заключаемый посредством акцепта оферты
                            договор между Клиентом и Агентством на указанных
                            ниже условиях, является официальным предложением
                            (публичной офертой) и содержит все существенные
                            условия по оказанию услуг на Веб-сайте Агентства{" "}
                            <a href="https://airsurfer.co/">
                                https://airsurfer.co/
                            </a>{" "}
                            .
                        </p>
                        <br />
                        <p>
                            1.9 Заказ – должным образом, оформленный на
                            веб-сайте запрос Клиента на бронирование авиабилетов
                            или прочих услуг, включающий сведения о маршруте
                            перевозки, датах вылета/прилета, классе
                            обслуживания, фамилии, имени, отчества клиента/ов,
                            данные документов, удостоверяющих личность
                            пассажиров, контакты. В случае оформления
                            международной перевозки, имя и фамилия пассажира в
                            латинской транскрипции должны точно соответствовать
                            их написанию в загранпаспорте. Маршрут-квитанция
                            электронного билета - часть электронного билета,
                            содержащая установленные законодательством
                            Российской Федерации сведения о перевозке пассажира
                            и является документом, удостоверяющим заключение
                            договора воздушной перевозки пассажира между
                            авиакомпанией-перевозчиком и пассажиром.
                        </p>
                        <br />
                        <p>
                            1.10 Тайм-лимит - срок выкупа авиабилета или отеля,
                            устанавливается согласно правилам и распоряжениям
                            Перевозчиков или Отеля. Перевозчики или отель
                            оставляют за собой право на изменение тайм-лимита,
                            аннуляции бронирования в любое время.
                        </p>
                        <br />
                        <p>
                            1.11 Совершение Клиентом действий по заказу
                            электронных документов, посредством интернет-ресурса{" "}
                            <a href="https://airsurfer.co/">
                                https://airsurfer.co/
                            </a>{" "}
                            , электронной почты, свидетельствует о принятии Вами
                            всех условий настоящего Договора-оферты. В
                            соответствии с условиями использования нашего Сайта
                            Вы подтверждаете, что Вам исполнилось 18 лет, Вы
                            дееспособны, правоспособны, имеете законное право
                            пользоваться банковской платежной картой и вступать
                            в договорные отношения с Агентством. Вы берете на
                            себя ответственность по обязательствам, что
                            возникают в результате исполнения настоящего
                            договора. Если Клиент не согласен с этими правилами
                            и условиями, он не имеет права использовать
                            настоящий интернет-портал в целях использования
                            размещенной на нем системы бронирования.
                        </p>
                        <br />
                    </>
                ),
            },
            {
                title: "Предмет договора",
                description: (
                    <>
                        <p>
                            2.1 Агентство обязуется оказать Клиенту
                            посреднические услуги по бронированию авиабилетов и
                            прочих услуг.
                        </p>
                        <br />
                        <p>
                            2.2 Обязательство Агентства по обеспечению услуги
                            бронирования, предусмотренное настоящим Договором,
                            во всех случаях зависит от фактического наличия мест
                            у Поставщиков.
                        </p>
                        <br />
                        <p>
                            2.3 С момента получения Клиентом оформленных
                            документов по электронной почте, обязательства
                            Агентства перед Клиентом считаются исполненными, а
                            услуга - оказанной. В случае отказа Клиентом от
                            оказанной услуги, оплаченные денежные средства не
                            подлежат возврату.
                        </p>
                        <br />
                        <p>
                            2.4 Агентство не несет ответственность за решение
                            консульством/посольством о выдачи визы или
                            гарантированного прохождения границы в стране
                            вылета/прилета.
                        </p>
                    </>
                ),
            },
            {
                title: "Порядок бронирования и оплаты",
                description: (
                    <>
                        <p>
                            3.1 Обязательства Агентства по бронированию
                            распространяются на подтвержденные и не отмененные
                            Клиентом Заказы, содержащие информацию, доводимую
                            Клиентом до Агентства на интернет-портале{" "}
                            <a href="https://airsurfer.co/">
                                https://airsurfer.co/
                            </a>{" "}
                            путем самостоятельного заполнения формы Заказа.
                            Заказ Клиента должен содержать следующие сведения:
                            маршрут перевозки, даты вылета/прилета, класс
                            обслуживания, фамилию, имя, отчество, пол
                            клиентов/пассажиров (в случае оформления
                            международной перевозки, имя и фамилия пассажира в
                            латинской транскрипции должны точно соответствовать
                            их написанию в загранпаспорте), данные документов,
                            удостоверяющих личность пассажиров, контакты для
                            обратной связи с Клиентом: телефон, электронная
                            почта или иное средство оперативной связи.
                        </p>
                        <br />
                        <p>
                            3.2 Заполнение формы Заказа онлайн является акцептом
                            настоящего Договора со стороны Клиента. Заказ
                            является указанием Клиента для выполнения поручения
                            и содержит в себе информацию, указанную в п. 3.1.
                            настоящего Договора.
                        </p>
                        <br />
                        <p>
                            3.3 После получения оплаты, Агентство высылает на
                            адрес электронной почты Клиента оформленные
                            документы. Бронирование билета оформляется на
                            основании данных документа, удостоверяющего личность
                            пассажира в соответствии с законодательством
                            Российской Федерации или международным договором
                            Российской Федерации для перевозки пассажира по
                            маршруту, предусмотренному договором воздушной
                            перевозки пассажира. Агентство считается исполнившим
                            свои обязательства в момент отправки на электронную
                            почту Клиента маршрутной квитанции.
                        </p>
                    </>
                ),
            },
            {
                title: "Обязанности Агентства",
                description: (
                    <>
                        <p>
                            4.1 Агентство обязуется забронировать авиабилет или
                            прочую услугу согласно подтвержденному Заказу
                            Клиента (за исключением случаев отмены Заказа).
                        </p>
                    </>
                ),
            },
            {
                title: "Обязанности Клиента",
                description: (
                    <>
                        <p>
                            5.1 Произвести своевременную оплату и предоставить
                            достоверные и точные сведения, необходимые для
                            бронирования авиабилетов или прочих услуг, указанные
                            в пункте 3.1.
                        </p>
                        <br />
                        <p>
                            5.2 Предоставить Агентству точную информацию о своем
                            электронном адресе и телефоне, необходимую Агентству
                            для оперативной связи с Клиентом.
                        </p>
                        <br />
                        <p>
                            5.3 Ознакомить всех пассажиров (в случае оформления
                            заказа в интересах третьих лиц) с содержанием
                            настоящего Договора и со всей информацией,
                            предоставленной Агентством Клиенту, обеспечить
                            выполнение ими всех обязанностей Клиента по
                            Договору. Клиент гарантирует наличие у себя
                            полномочий на осуществление сделки в интересах иных
                            лиц.
                        </p>
                    </>
                ),
            },
            {
                title: "Обязанности Клиента",
                description: (
                    <>
                        <p>
                            6.1 Оферта вступает в силу с момента опубликования
                            на сайте Агентства в сети интернет, по адресу{" "}
                            <a href="https://airsurfer.co/">
                                https://airsurfer.co/
                            </a>{" "}
                            и действует до момента отзыва Оферты Агентством.
                        </p>
                        <br />
                        <p>
                            6.2 Агентство оставляет за собой право внести
                            изменения в условия Оферты и/или отозвать Оферту в
                            любой момент по своему усмотрению. В случае внесения
                            Агентством изменений в Оферту, такие изменения
                            вступают в силу с момента опубликования на Сайте,
                            если иной срок вступления изменений в силу не
                            определен дополнительно при их публикации.{" "}
                        </p>
                        <br />
                    </>
                ),
            },
            {
                title: "Срок действия, изменение и расторжение договора",
                description: (
                    <>
                        <p>
                            7.1 Договор вступает в силу с момента Акцепта Оферты
                            и действует до момента исполнения Агентством
                            обязательств по бронированию услуги и отправления
                            документов на электронную почту Клиента.
                        </p>
                        <br />
                        <p>
                            7.2 Настоящий Договор может быть расторгнут по
                            взаимному согласию Сторон или по иным основаниям,
                            предусмотренным действующим законодательством РФ
                        </p>
                        <br />
                        <p>
                            7.3 Отказ от услуги возможен только до бронирования
                            авиабилетов, отправленных на электронную почту.
                        </p>
                    </>
                ),
            },
            {
                title: "Прочие условия",
                description: (
                    <>
                        <p>
                            8.1 Все вопросы, не урегулированные Офертой или
                            Договором, регламентируются действующим
                            законодательством Российской Федерации. Споры и
                            разногласия, которые могут возникнуть при исполнении
                            настоящего Договора, будут по возможности
                            разрешаться путем переговоров между сторонами
                            Договора и в претензионном порядке. Претензионный
                            порядок рассмотрения спора является обязательным.
                            Срок ответа на претензию устанавливается в
                            соответствии с действующим законодательством. В
                            случае не урегулирования разногласий спор подлежит
                            рассмотрению в суде с применением права РФ.
                        </p>
                        <br />
                        <p>
                            8.2 Целостность Договора. Агентство не принимает на
                            себя никаких условий и обязательств в отношении
                            предмета Оферты (Заказа), не указанных в Оферте.
                            Исключение могут составлять случаи, когда такие
                            условия или обязательства зафиксированы в письменном
                            виде и подписаны Агентством и Заказчиком.
                        </p>
                        <br />
                        <p>
                            8.3 Последствия признания условий оферты
                            недействительными. Если какое-либо из условий Оферты
                            признано судом недействительным или является
                            ничтожным, данный факт не влияет на
                            недействительность Договора.
                        </p>
                        <br />
                        <p>
                            Клиенту разъяснены и понятны права субъекта
                            персональных данных. Внимательно ознакомьтесь с
                            текстом публичной оферты, и если Вы не согласны с
                            каким-либо пунктом оферты, Вы вправе отказаться от
                            услуг Агентства и не совершать действий, указанных в
                            настоящей Оферте.
                        </p>
                    </>
                ),
            },
        ],
    };

    const items = [
        { title: <Link to={"/"}>Авиабилеты и отели для визы</Link> },
        { title: <Link to={"/"}>Договор оферты</Link> },
    ];

    return (
        <div className={styles.offer}>
            <div className="container">
                <CustomBreadCrumb items={items} />

                <div className={styles.offer__wrapper}>
                    <h2 className={styles.policy__item_title}>
                        Договор оферты
                    </h2>
                    <>
                        <p>
                            Настоящая Оферта, является официальным публичным
                            предложением заключить Договор между Клиентом и
                            Агентством на оказание услуг бронирования
                            авиабилетов и прочих услуг далее Договор) на
                            Веб-сайте Агентства{" "}
                            <a href="https://airsurfer.co/">
                                https://airsurfer.co/
                            </a>{" "}
                            . Договор считается заключенным и приобретает силу с
                            момента совершения физическим лицом действий,
                            предусмотренных офертой и означающих безоговорочное
                            принятие всех условий оферты без каких-либо изъятий
                            или ограничений, на условиях присоединения.
                        </p>
                    </>
                    <Policy items={policyItems.items} />
                </div>
            </div>
        </div>
    );
};

export default ContractOffer;
