import clsx from "clsx"
import StatusInfo from "../../../components/UI/StatusInfo/StatusInfo"
import styles from "./AboutPurchase.module.scss"
import CustomButton from "../../../components/UI/CustomButton/CustomButton"
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

const AboutPurchase = ({
    status,
    date,
    orderNumber,
    passengersQuantity,
    sum,
    children,
    paymentClick,
    flightToPnr,
    flightFromPnr,
    flightToBookingExpiresAt,
    flightFromBookingExpiresAt,
    flightToAirUrl,
    flightFromAirUrl,
}) => {
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    let flightToAirUrlArr = flightToAirUrl ? JSON.parse(flightToAirUrl) : [];
    let flightFromAirUrlArr = flightFromAirUrl ? JSON.parse(flightFromAirUrl): [];

    return (
        <div className={styles.about}>
            <h3 className={styles.about__title}>О заказе</h3>
            <div
                className={clsx(styles.about__content, {
                    [styles.about__content_min]: passengersQuantity,
                })}
            >
                <div className={styles.about__content_item}>
                    <h4 className={styles.about__content_title}>
                        Статус бронирования:
                    </h4>
                    <StatusInfo statusType={status} />
                </div>
                <div className={styles.about__content_item}>
                    <h4 className={styles.about__content_title}>
                        Дата бронирования:
                    </h4>
                    <p className={styles.about__content_text}>{dayjs(date).utc(true).local().format('DD.MM.YYYY HH:mm')}</p>
                </div>
                { passengersQuantity ? (
                    <div className={styles.about__content_item}>
                        <h4 className={styles.about__content_title}>
                            Пассажиры:
                        </h4>
                        <p className={styles.about__content_text}>
                            {passengersQuantity}
                        </p>
                    </div>
                ) : (
                    <></>
                )}
                <div className={styles.about__content_item}>
                    <h4 className={styles.about__content_title}>Сумма:</h4>
                    <p className={styles.about__content_text}>{sum}</p>
                    <div className={styles.about__content_info}>{children}</div>
                </div>
            </div>
            {orderNumber ? (
                    <div className={styles.about__content}>
                    <div className={styles.about__content_order_no}>
                        <h4 className={styles.about__content_title}>
                            Номер заказа:
                        </h4>
                        <p>{orderNumber}</p>
                    </div>
                    <div className={styles.about__content_order_no}>
                        <h4 className={styles.about__content_title}>
                        Код:
                        </h4>
                        <p>{flightToPnr}</p>
                        <p>{flightFromPnr}</p>
                    </div>
                    {/*<div className={styles.about__content_order_no}>*/}
                    {/*   <h4 className={styles.about__content_title}>*/}
                    {/*   Окончание:*/}
                    {/*    </h4>*/}
                    {/*    <p>{flightToBookingExpiresAt ? dayjs(flightToBookingExpiresAt).utc(true).local().format('DD.MM.YYYY HH:mm') : ''}</p>*/}
                    {/*    <p>{flightFromBookingExpiresAt ? dayjs(flightFromBookingExpiresAt).utc(true).local().format('DD.MM.YYYY HH:mm') : ''}</p>*/}
                    {/*</div>*/}
                    <div className={styles.about__content_order_no}>
                    {flightToAirUrlArr?.code ? ( <>
                        <h4 className={styles.about__content_title}>Проверка:</h4>
                        <p><a href={flightToAirUrlArr?.site}target="_blank">{flightToAirUrlArr?.name ?? flightToAirUrlArr?.code}</a></p>
                        <p><a href={flightFromAirUrlArr?.site} target="_blank">{flightFromAirUrlArr?.name ?? flightFromAirUrlArr?.code}</a></p>
                    </>) : null }
                    </div>
                </div>
            ) : (<></>)}
        </div>
    )
}

export default AboutPurchase
