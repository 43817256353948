import {ReactSVG} from "react-svg"
import CustomButton from "../../components/UI/CustomButton/CustomButton"
import styles from "./NotFound.module.scss"
import notFound from "../../assets/icons/404.svg"
import {Link} from "react-router-dom"
import Cookies from "../../components/UI/Cookies/Cookies"

const NotFound = () => {
    return (
        <div className={styles.notFound}>
            <div className="container">
                <div className={styles.notFound__wrapper}>
                    <ReactSVG className={styles.notFound__img} src={notFound} />
                    <h1 className={styles.notFound__title}>
                        Страница не найдена
                    </h1>
                    <h2 className={styles.notFound__subtitle}>
                        Страница, которую вы пытаетесь открыть, не существует
                        или больше недоступна
                    </h2>
                    <Link to={"/"}>
                        <CustomButton primary classes={styles.notFound__btn}>
                            На главную
                        </CustomButton>
                    </Link>
                </div>
            </div>
            <Cookies/>
        </div>
    )
}

export default NotFound
