import styles from "./AccordionItem.module.scss";
import plusWhite from "../../../assets/icons/plusWhite.svg";
import plus from "../../../assets/icons/plus.svg";
import clsx from "clsx";
import { useRef, useEffect, useState } from "react";

const AccordionItem = ({ content, title, isOpen, openHandler, index }) => {
    const [hasShortText, setHasShortText] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        const lineHeight = 25;

        if (contentRef.current.clientHeight < lineHeight) {
            setHasShortText(true);
        } else {
            setHasShortText(false);
        }
    }, [content]);

    const handleOnClick = () => {
        openHandler(index);
    };

    return (
        <div
            className={clsx(styles.accordion, {
                [styles.accordion__longText]: hasShortText,
            })}
            onClick={handleOnClick}
        >
            <div ref={contentRef} className={styles.accordion__text}>
                <h2 className={styles.accordion__title}>{title}</h2>
                <div
                    className={clsx(styles.accordion__icon, {
                        [styles.accordion__icon_outer]: isOpen,
                    })}
                >
                    <img
                        className={clsx(styles.accordion__icon, {
                            [styles.accordion__icon_active]: isOpen,
                        })}
                        src={isOpen ? plusWhite : plus}
                        alt="plus"
                    />
                </div>
            </div>
            <div
                className={clsx(styles.accordion__content, {
                    [styles.accordion__content_active]: isOpen,
                })}
            >
                {content}
            </div>
        </div>
    );
};

export { AccordionItem };
