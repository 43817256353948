import style from "./Tickets.module.scss";
import TicketSpinner from "./TicketSpinner/TicketSpinner";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import classNames from "classnames";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import React, {memo} from "react";
import SearchPanel from "../MainPage/SearchPanel/SearchPanel";
import {useSelector} from "react-redux";

const MemoizedSearchPanel = memo(SearchPanel);

const urlParams = new URLSearchParams(window.location.search);


export const TicketsLoading = () => {
  const cityFrom = useSelector((state) => state.tickets.cityFrom) || urlParams.get('city_from');
  const cityTo = useSelector((state) => state.tickets.cityTo) || urlParams.get('city_to');
  const itemsCount = Array.from({ length: 6 }).fill("");
  return (
    <div className={style.skeleton}>
      <div className="container">
        <MemoizedSearchPanel className={style.tickets_search_panel} />
        <div className={style.loader}>
          <TicketSpinner cityFrom={cityFrom} cityTo={cityTo} />
        </div>
        <CustomButton
          classes={classNames(style.open_filters, style.isMobile)}
          lined
          fullWidth
          onClick={() => console.log("фильтры сброшены")}
        >
          Фильтры
        </CustomButton>
        <SkeletonTheme baseColor="#f3f3f3" highlightColor="#F7F8FA">
          <div className={style.skeleton__wrapper}>
            <aside className={style.skeleton__aside}>
              <Skeleton className={style.skeleton__aside_item} />
              <Skeleton className={style.skeleton__aside_item} />
              <Skeleton
                className={style.skeleton__aside_itemBig}
              />
              <Skeleton
                className={style.skeleton__aside_itemSmall}
              />
            </aside>
            <div className={style.skeleton__main}>
              {itemsCount.map((_, i) => {
                return (
                  <div
                    key={i}
                    className={style.skeleton__main_item}
                  >
                    <div className={style.skeleton__left}>
                      <div
                        className={
                          style.skeleton__item_header
                        }
                      >
                        <Skeleton
                          height={20}
                          width={72}
                        />
                        <Skeleton
                          height={32}
                          width={120}
                        />
                      </div>

                      <div
                        className={
                          style.skeleton__item_body
                        }
                      >
                        <Skeleton
                          className={
                            style.skeleton__item_body1
                          }
                        />
                        <div
                          className={
                            style.skeleton__middle
                          }
                        >
                          <Skeleton
                            className={
                              style.skeleton__hr
                            }
                          />
                        </div>
                        <Skeleton
                          className={
                            style.skeleton__item_body2
                          }
                        />
                        <Skeleton
                          className={
                            style.skeleton__item_body3
                          }
                        />
                      </div>
                    </div>
                    <div className={style.skeleton__right}>
                      <Skeleton
                        className={
                          style.skeleton__right_item1
                        }
                      />
                      <Skeleton
                        className={
                          style.skeleton__right_item2
                        }
                      />
                      <Skeleton
                        className={
                          style.skeleton__right_item3
                        }
                      />
                      <Skeleton
                        className={
                          style.skeleton__right_item4
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </div>
  );
};
export const TicketsLoading2 = () => {
  const cityFrom = useSelector((state) => state.tickets.cityFrom) || urlParams.get('city_from');
  const cityTo = useSelector((state) => state.tickets.cityTo) || urlParams.get('city_to');
  const itemsCount = Array.from({ length: 6 }).fill("");
  return (
    <div className={style.skeleton}>
      <div className="container">
        <MemoizedSearchPanel className={style.tickets_search_panel} />
        <div className={style.loader}>
          <TicketSpinner cityFrom={cityFrom} cityTo={cityTo} />
        </div>
        <CustomButton
          classes={classNames(style.open_filters, style.isMobile)}
          lined
          fullWidth
          onClick={() => console.log("фильтры сброшены")}
        >
          Фильтры
        </CustomButton>
        <SkeletonTheme baseColor="#f3f3f3" highlightColor="#F7F8FA">
          <div className={style.skeleton__wrapper}>
            <aside className={style.skeleton__aside}>
              <Skeleton className={style.skeleton__aside_item} />
              <Skeleton className={style.skeleton__aside_item} />
              <Skeleton
                className={style.skeleton__aside_itemBig}
              />
              <Skeleton
                className={style.skeleton__aside_itemSmall}
              />
            </aside>
            <div className={style.skeleton__main}>
              {itemsCount.map((_, i) => {
                return (
                  <div
                    key={i}
                    className={style.skeleton__main_item}
                  >
                    <div className={style.skeleton__double}>
                      <div
                        className={style.skeleton__left}
                      >
                        <div
                          className={
                            style.skeleton__item_header
                          }
                        >
                          <Skeleton
                            height={20}
                            width={72}
                          />
                          <Skeleton
                            height={32}
                            width={120}
                          />
                        </div>

                        <div
                          className={
                            style.skeleton__item_body
                          }
                        >
                          <Skeleton
                            className={
                              style.skeleton__item_body1
                            }
                          />
                          <div
                            className={
                              style.skeleton__middle
                            }
                          >
                            <Skeleton
                              className={
                                style.skeleton__hr
                              }
                            />
                          </div>
                          <Skeleton
                            className={
                              style.skeleton__item_body2
                            }
                          />
                          <Skeleton
                            className={
                              style.skeleton__item_body3
                            }
                          />
                        </div>
                      </div>
                      <div className={style.skeleton__double_hr}></div>
                      <div
                        className={style.skeleton__left}
                      >
                        <div
                          className={
                            style.skeleton__item_header
                          }
                        >
                          <Skeleton
                            height={20}
                            width={72}
                          />
                          <Skeleton
                            height={32}
                            width={120}
                          />
                        </div>
                        <div
                          className={
                            style.skeleton__item_body
                          }
                        >
                          <Skeleton
                            className={
                              style.skeleton__item_body1
                            }
                          />
                          <div
                            className={
                              style.skeleton__middle
                            }
                          >
                            <Skeleton
                              className={
                                style.skeleton__hr
                              }
                            />
                          </div>
                          <Skeleton
                            className={
                              style.skeleton__item_body2
                            }
                          />
                          <Skeleton
                            className={
                              style.skeleton__item_body3
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={style.skeleton__rightDouble}>
                      <Skeleton
                        className={
                          style.skeleton__right_item1
                        }
                      />
                      <Skeleton
                        className={
                          style.skeleton__right_item2
                        }
                      />
                      <Skeleton
                        className={
                          style.skeleton__right_item3
                        }
                      />
                      <Skeleton
                        className={
                          style.skeleton__right_item4
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </div>
  );
};