import { Tag } from 'antd';
import style from "./CustomTag.module.scss";
import classNames from "classnames";

const CustomTag = ({text, active, inactive, onClick}) => {
  return(
    <Tag
      className={classNames(style.custom_tag, {
        [style["custom_tag_active"]]: active || !inactive,
        [style["custom_tag_inactive"]]: inactive || !active,
      })}
      onClick={onClick}
    >
      {text}
    </Tag>
  )
}

export default CustomTag;