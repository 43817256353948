import {Select} from "antd"
import style from "./DropdownInput.module.scss"
import {ReactComponent as ArrowIcon} from "../../../assets/icons/dropdown_arrow.svg"
import classNames from "classnames"

const DropdownInput = ({
    placeholder,
    label,
    options,
    defaultValue,
    onChange,
    name,
    classes,
    containerClasses,
    value,
    error,
    showSearch,
    disabled,
    filterOption
}) => {
    return (
        <div className={classNames(style.dropdown_item_container, containerClasses)}>
            <Select
              showSearch={showSearch}
                placeholder={placeholder}
                className={classNames(style.dropdown_input, classes, {
                    [style["dropdown_input_with_label"]]: label,
                    [style["dropdown_input_error"]]: error,

                })}
                options={options}
                defaultValue={defaultValue}
                suffixIcon={<ArrowIcon />}
                onChange={onChange}
                name={name}
                value={value}
                disabled={disabled}
                filterOption={filterOption}
            />
            {label && (
                <span className={style.dropdown_input_label}>{label}</span>
            )}
            {error && <span className={style.error_text}>{error}</span>}
        </div>
    )
}

export default DropdownInput
