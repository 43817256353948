import { createSelector } from "@reduxjs/toolkit";

const selectHeader = (state) => state.headerSlice;

export const selectCurrency = createSelector(
    [selectHeader],
    (header) => header.currency
);

export const selectLang = createSelector(
    [selectHeader],
    (header) => header.lang
);
