import style from "./Profile.module.scss";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import {ReactSVG} from "react-svg";


const ProfilePageHeader = ({title, description, icon, buttons}) => {
  return (
    <div className={style.profile_page_header}>
      <div className={style.profile_page_header_title}>
        <p>{title}</p>
        <p className={style.profile_page_header_title_description}>
          {description}
        </p>
        <div className={style.profile_page_header_buttons}>
          {
            buttons && buttons.map((button, index) => (
              <CustomButton
                key={`profile-page-header-custom-bottom-${index}`}
                small
                primary={button.primary}
                lined={button.lined}
                onClick={button.onClick}
                classes={style.profile_page_header_buttons_button}
                disabled={button.disabled}
              >
                {button.text}
              </CustomButton>
            ))
          }
        </div>

      </div>

      <img alt="icon" src={icon} className={style.profile_page_header_icon}/>
    </div>
  )
}

export default ProfilePageHeader;
