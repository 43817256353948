import {Breadcrumb} from "antd"
import style from "./BreadCrumb.module.scss"
import ArrowIcon from "../../../assets/icons/leftArrow.svg"
import whiteArrowIcon from "../../../assets/icons/whiteArrowIcon.svg"
import {ReactSVG} from "react-svg"
import clsx from "clsx"

const CustomBreadCrumb = ({items, white}) => {
    return (
        <Breadcrumb
            separator={<ReactSVG src={white ? whiteArrowIcon : ArrowIcon} />}
            className={clsx(style.custom_breadcrumb, {
                [style.custom_breadcrumb_white]: white,
            })}
            items={items}
        />
    )
}

export default CustomBreadCrumb
