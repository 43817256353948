import style from "./PartnerCabinet.module.scss";
import Plane  from "../../../assets/icons/plane.png"
import Pencil  from "../../../assets/icons/pencil.svg"
import {Link} from "react-router-dom";

const DepositMethod = ({type, item}) => {

    return (
        <div className={style.deposit_method}>
            <img alt="plane" src={Plane}/>
            {
                type  === "card" ? (
                    <>
                        <p className={style.deposit_method__value}>{item.card_bank_name}</p>
                        <p className={style.deposit_method__label}>{item.card_number}</p>
                    </>
                ) : (
                    <>
                        <p className={style.deposit_method__value}>{item.bank_name}</p>
                        <p className={style.deposit_method__label}>ИНН: {item.org_inn}</p>
                    </>
                )

            }
            <Link to={type  === "requisites" ?  `/profile/partnerCabinet/requisites/edit/${item.id}` : `/profile/partnerCabinet/cards/edit/${item.id}`} className={style.deposit_method__edit}>Редактировать <img alt="edit" src={Pencil}/></Link>
        </div>
    )
}

export default DepositMethod;
