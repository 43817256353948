import React from "react"
import clsx from "clsx"
import styles from "./RadioGender.module.scss"

const RadioGender = ({
    index = "0",
    nameMale = "gender",
    nameFemale = "gender",
    valueMale = "male",
    valueFemale = "female",
    checkedMale,
    checkedFemale,
    setFieldValue,
    errors,
    touched,
    classes,
    disabled
}) => {
    return (
        <div className={clsx(styles.form__radio, classes)}>
            <p className={styles.form__radio_title}>Пол</p>
            <div
                className={clsx(styles.form__radio_labels, {
                    [styles.form__radio_errorRadio]: touched && errors,
                })}
            >
                <label
                    className={clsx(styles.form__radio_item, {
                        [styles.form__radio_active]: checkedMale,
                    })}
                >
                    <input
                        type="radio"
                        name={nameMale}
                        value={valueMale}
                        checked={checkedMale}
                        onChange={(event) =>
                            !disabled && setFieldValue(nameMale, event.target.value)
                        }
                    />
                    Мужской
                </label>
                <label
                    className={clsx(styles.form__radio_item, {
                        [styles.form__radio_active]: checkedFemale,
                    })}
                >
                    <input
                        type="radio"
                        name={nameFemale}
                        value={valueFemale}
                        checked={checkedFemale}
                        onChange={(event) =>
                          !disabled && setFieldValue(nameFemale, event.target.value)
                        }
                    />
                    Женский
                </label>
            </div>
            {touched && errors && (
                <span className={styles.error_text}>
                    {errors}
                </span>
            )}
        </div>
    )
}

export default RadioGender
