import { Slider } from "antd";
import style from "./CustomSlider.module.scss";
import classNames from "classnames";

const CustomSlider = ({range, min, max, value, className, onChange}) => {

  return (
    <>
      <Slider
        range={range}
        className={classNames(style.custom_slider, className)}
        value={[value[0], value[1]]}
        onChange={(value) => onChange(value)}
        min={min}
        max={max}
      />
    </>
  )
}

export default CustomSlider;