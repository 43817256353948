import { useState } from "react";
import TicketWayInfo from "../TicketWayInfo/TicketWayInfo";
import styles from "./TicketWayInfoWrapper.module.scss";

const TicketWayInfoWrapper = ({ trip_to, trip_back }) => {
    const [isOpened, setIsOpened] = useState(false);

    const openHandler = () => [setIsOpened((prev) => !prev)];

    return (
        <div className={styles.ticket}>
            {trip_to && trip_back && (
                <>
                    <TicketWayInfo
                        isOpened={isOpened}
                        direction="to"
                        openHandler={openHandler}
                        ticket={trip_to}
                        isBooking
                    />
                    <TicketWayInfo
                        isOpened={isOpened}
                        openHandler={openHandler}
                        direction="back"
                        backTicket
                        ticket={trip_back}
                        isBooking
                    />
                </>
            )}

            {trip_to && !trip_back && (
                <>
                    <TicketWayInfo
                        isOpened={isOpened}
                        openHandler={openHandler}
                        direction={"to"}
                        single
                        ticket={trip_to}
                        isBooking
                    />
                </>
            )}
        </div>
    );
};

export default TicketWayInfoWrapper;
