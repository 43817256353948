import { parse, isDate  } from "date-fns";
import * as Yup from "yup";

const nextYearDate = new Date(new Date().getFullYear() + 1, 11, 31);

export const passengerSchema = Yup.object().shape({
    country: Yup.string().required("Поле обязательно"),
    firstName: Yup.string()
        .required("Поле обязательно")
        .matches(/^[a-zA-Z]+$/, "Имя должно содержать латинские буквы"),
    lastName: Yup.string()
        .required("Поле обязательно")
        .matches(/^[a-zA-Z]+$/, "Фамилия должна содержать латинские буквы"),
    birthDate: Yup.date().typeError("Некорректный формат даты")
        .required("Поле обязательно")
        .transform((value, originalValue, context) => {
            return isDate(originalValue) ? originalValue : parse(originalValue, "dd.MM.yyyy", new Date());
        })
        .max(new Date(), "Дата рождения не может быть в будущем"),
    gender: Yup.string().required("Поле обязательно"),
    documentNumber: Yup.string()
        .test("documentNumber", "Номер документа должен состоять из 9 цифр", function(value) {
            //console.info(this.parent)
            if (this.parent.country === 'RU' || this.parent.documentType === 'FOREIGN'){
                return /^[0-9]{9}$/.test(value)
            } else {
                return true
            };
        }).required("Поле обязательно"),
        // .matches(/[0-9]{9}$/, "Номер документа должен содержать 9 цифр")
        // .min(9, "Номер документа должен содержать 9 цифр")
        // .max(9, "Номер документа должен содержать 9 цифр"),
    documentExpirationDate: Yup.date().typeError("Некорректный формат даты")
        // .required("Поле обязательно")
        .transform((value, originalValue, context) => {
            return isDate(originalValue) ? originalValue : parse(originalValue, "dd.MM.yyyy", new Date());
        })
        .min(new Date(), "Срок действия документа просрочен")
        .test("documentExpirationDate", "Поле обязательно", function(value) {
            if (this.parent.country === 'RU' && this.parent.documentType === 'FOREIGN'){
                return !!value
            } else {
                return true
            };
        }),
});

export const bookingRouteSchema = Yup.object().shape({
    // card: Yup.string().required("Поле обязательно"),
    // bookingDate: Yup.date()
    //     .typeError("Некорректный формат даты")
    //     .required("Поле обязательно")
    //     .transform((value, originalValue, context) => {
    //         return isDate(originalValue) ? originalValue : parse(originalValue, "dd.MM.yyyy", new Date());
    //     })
    //     .min(new Date(), "Дата не может быть в прошлом")
    //     .max(
    //         nextYearDate,
    //         "Дата не может быть более чем на 1 год вперед"
    //     ),
});

export const customerSchema = Yup.object().shape({
    email: Yup.string()
        .email("Неверный формат почты")
        .required("Обязательное поле"),
    //telCode: Yup.string().required("Обязательное поле"),
    tel: Yup.string().required("Обязательное поле")
    .matches(/[0-9]$/, "Номер телефона должен содержать цифры"),
});

export const hotelSchema = Yup.object().shape({
    hasHotel: Yup.boolean(),
    town: Yup.string()
        .when("hasHotel", {
            is: true,
            then: () => Yup.string().required("Поле обязательно"),
        })
        .min(2, "Город должен содержать не менее 2 символов"),
    arrivalDate: Yup.date().when("hasHotel", {
        is: true,
        then: () =>
            Yup.date().typeError("Некорректный формат даты")
                .required("Поле обязательно")
                .nullable()
                .transform((value, originalValue, context) => {
                    return parse(originalValue, "dd.MM.yyyy", new Date());
                })
                .test(
                    "min",
                    "Дата не может быть в прошлом",
                    (value) => !value || value >= new Date(new Date().setHours(0,0,0,0))
                )
                .test(
                    "max",
                    "Дата не может быть более чем на 1 год вперед",
                    (value) =>
                        !value ||
                        value <= nextYearDate
                ),
    }),
    departureDate: Yup.date().when("hasHotel", {
        is: true,
        then: () =>
            Yup.date().typeError("Некорректный формат даты")
                .required("Поле обязательно")
                .nullable()
                .transform((value, originalValue, context) => {
                    return parse(originalValue, "dd.MM.yyyy", new Date());
                })
                .test(
                    "min",
                    "Дата не может быть в прошлом",
                    (value) => !value || value >= new Date()
                )
                .test(
                    "max",
                    "Дата не может быть более чем на 1 год вперед",
                    (value) =>
                        !value ||
                        value <= nextYearDate
                ),
    }),
});
