import styles from "./Payment.module.scss";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import CustomCheckbox from "../../../components/UI/Checkbox/CustomCheckbox";
import Input from "../../../components/UI/Input/Input";
import { ReactSVG } from "react-svg";
import file from "../../../assets/icons/fileComplete.svg";
import clock from "../../../assets/icons/smallClock.svg";
import home from "../../../assets/icons/home.svg";
import { useEffect, useState } from "react";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import { useSelector } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import percent from "../../../assets/icons/percent-icon.svg";
import { selectTariffs } from "store/selectors/catalog";
import { selectCurrency } from "store/selectors/header";
import {
    getPassengerAmountString,
    getTariffPrice,
    getTariffsFlight,
    getTariffsHotel,
    pluralizeDay,
} from "utils/tariffService";
import { floor } from "lodash";
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const Payment = ({
    passangerCount,
    isReturnTikets,
    cityFrom,
    cityTo,
    hotel,
    submitClick,
    isErrors,
    values,
    isLoading,
    isLoadingPromo,
    tariffId,
    tariffHotelId,
    isPaid,
    promoCodeClick,
    discount,
}) => {
    const tariffs = useSelector(selectTariffs);
    const currentCurrency = useSelector(selectCurrency);

    const [isChecked, setIsChecked] = useState(true);
    const [discountSum, setDiscountSum] = useState(0);
    const [priceT, setPriceT] = useState(0);
    const [priceH, setPriceH] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [termT, setTermT] = useState(0);
    const [isValid, setIsValid] = useState("");
    const [validValue, setValidValue] = useState(values.customer.promocode);
    const passengersAmount = getPassengerAmountString(passangerCount);

    const promoCodeInput = (e) => {
        setValidValue(e);
        values.customer.promocode = e;
    };

    const bookingDate = dayjs(
        values.bookingRoute?.bookingDate,
        "DD.MM.YYYY HH:mm",
        "ru"
    ).isValid()
        ? dayjs(
              values.bookingRoute?.bookingDate,
              "DD.MM.YYYY HH:mm",
              "ru"
          ).format("DD.MM.YYYY HH:mm")
        : dayjs(values.bookingRoute?.bookingDate)
              .utc(true)
              .local()
              .format("DD.MM.YYYY HH:mm");

    useEffect(() => {
        const bookingTiketTariffs = getTariffsFlight(tariffs);
        const bookingHotelTariffs = getTariffsHotel(tariffs);

        /**
         * Текущий тариф
         */
        const currentTarrif = bookingTiketTariffs.find(
            (item) => item.id == tariffId
        );

        if (currentTarrif) {
            setTermT(currentTarrif?.term);
            const singlePrice = getTariffPrice(
                currentCurrency?.code,
                currentTarrif
            );
            setPriceT(singlePrice);
        }

        /**
         * Если выбран тариф отеля
         */
        if (tariffHotelId) {
            /**
             * Текущий тариф отеля
             */
            const currentTarrifHotel = bookingHotelTariffs.find(
                (item) => item.id == tariffHotelId
            );
            if (currentTarrifHotel) {
                const singlePriceHotel = getTariffPrice(
                    currentCurrency?.code,
                    currentTarrifHotel
                );
                setPriceH(singlePriceHotel);
            }
        } else {
            setPriceH(0);
        }
    }, [tariffId, tariffs, tariffHotelId, currentCurrency?.code]);
    /**
     * Подсчет суммы
     */
    useEffect(() => {
        let totalSum = 0;
        /**Кол-во пассажиров */
        totalSum += priceT * passangerCount;
        /**Если возвратный*/
        if (isReturnTikets) totalSum *= 2;
        totalSum += priceH;
        if (discount) {
            const discountSum = Math.ceil((Number(discount) * totalSum) / 100);
            totalSum -= discountSum;
            setDiscountSum(discountSum);
        }
        setTotalPrice(totalSum);
    }, [priceT, priceH, passangerCount, discount, isReturnTikets]);

    return (
        <aside className={styles.payment}>
            <h2 className={styles.payment__title}>Ваше бронирование</h2>
            <div className={styles.payment__items}>
                <div className={styles.payment__item}>
                    <div className={styles.payment__item_icon}>
                        <ReactSVG src={file} />
                    </div>
                    <div className={styles.payment__item_text}>
                        <p className={styles.payment__item_title}>
                            Маршрутная квитанция
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            {passangerCount} {passengersAmount}
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            {isReturnTikets && (
                                <>
                                    2 направления ({cityFrom} - {cityTo},{" "}
                                    {cityTo} -{cityFrom})
                                </>
                            )}
                            {!isReturnTikets && (
                                <>
                                    1 направление ({cityFrom} - {cityTo})
                                </>
                            )}
                        </p>
                    </div>
                    <div className={styles.payment__item_price}>
                        <p>
                            {priceT} {currentCurrency?.symbol} х{" "}
                            {passangerCount}
                            {isReturnTikets && <> x 2</>}
                        </p>
                    </div>
                </div>
                <div className={styles.payment__item}>
                    <div className={styles.payment__item_icon}>
                        <ReactSVG src={clock} />
                    </div>
                    <div className={styles.payment__item_text}>
                        <p className={styles.payment__item_title}>
                            Время действия
                            <br />
                            брони
                        </p>
                        <p className={styles.payment__item_subtitle}>
                            c {bookingDate}
                        </p>
                    </div>
                    <div className={styles.payment__item_price}>
                        <p>{Math.ceil(termT / 24)} {pluralizeDay(Math.ceil(termT / 24))}</p>
                    </div>
                </div>
                {tariffHotelId && (
                    <div className={styles.payment__item}>
                        <div className={styles.payment__item_icon}>
                            <ReactSVG src={home} />
                        </div>
                        <div className={styles.payment__item_text}>
                            <p className={styles.payment__item_title}>Отель</p>
                            <p className={styles.payment__item_subtitle}>
                                {values.hotel.arrivalDate} -{" "}
                                {values.hotel.departureDate}
                            </p>
                        </div>
                        <div className={styles.payment__item_price}>
                            <p>
                                {priceH}
                                {currentCurrency?.symbol}
                            </p>
                        </div>
                    </div>
                )}
                {(discount || discount === 0) && (
                    <div className={styles.payment__item}>
                        <div className={styles.payment__item_icon}>
                            <ReactSVG src={percent} />
                        </div>
                        <div className={styles.payment__item_text}>
                            <p className={styles.payment__item_title}>
                                Промокод
                            </p>
                        </div>
                        <div className={styles.payment__item_discount}>
                            <p>
                                -{discountSum} {currentCurrency?.symbol}
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.payment__divider}>&nbsp;</div>
            <div className={styles.payment__sum}>
                <p className={styles.payment__sum_title}>Итого</p>
                <p className={styles.payment__sum_cost}>
                    {totalPrice} {currentCurrency?.symbol}
                </p>
            </div>
            <CustomButton
                classes={styles.payment__sum_btn}
                primary={true}
                fullWidth={true}
                onClick={submitClick}
                type={"submit"}
                disabled={!isChecked || isPaid}
            >
                {isLoading && <Spinner />}
                Перейти к подтверждению
            </CustomButton>
            {isErrors && !isPaid && (
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon} />
                    <p>Проверьте правильность заполнения всех полей</p>
                </div>
            )}
            {isPaid && (
                <div className={styles.warning}>
                    <ReactSVG src={WarningIcon} />
                    <p>Ваш заказ оплачен</p>
                </div>
            )}

            <div className={styles.payment__personal}>
                <CustomCheckbox
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                />
                <p className={styles.payment__personal_text}>
                    Даю согласие на обработку{" "}
                    <a href={"/privacyPolicy"} target={"_blank"}>
                        <span>персональных данных</span>
                    </a>
                    , согласен(а) с{" "}
                    <a href={"/products"} target={"_blank"}>
                        <span>тарифом</span>
                    </a>
                    , условиями{" "}
                    <a href={"/contractOffer"} target={"_blank"}>
                        <span>договора-оферты</span>
                    </a>
                    .
                </p>
            </div>
            <div className={styles.payment__divider2}>&nbsp;</div>
            <div className={styles.payment__promo}>
                <Input
                    placeholder={"Промокод"}
                    error={
                        isValid === false
                            ? "Данный промокод отсутствует или недействителен"
                            : undefined
                    }
                    value={validValue}
                    success={isValid === true ? "Промокод применен" : undefined}
                    onChange={(e) => promoCodeInput(e)}
                />
                <CustomButton
                    classes={styles.payment__sum_btn}
                    primary={true}
                    fullWidth={true}
                    onClick={promoCodeClick}
                    type={"submit"}
                    disabled={!isChecked || isPaid}
                >
                    {isLoadingPromo && <Spinner />}
                    Применить
                </CustomButton>
            </div>
        </aside>
    );
};

export default Payment;
