import styles from "./Direction.module.scss"
import plane2 from '../../../assets/icons/plane2.png'

const Direction = ({fromTown, toTown, startDate, endDate}) => {
    return (
        <div className={styles.direction}>
            <h3 className={styles.direction__title}>
                {fromTown} - {toTown}
            </h3>
            <p className={styles.direction__subtitle}>
                {startDate} - {endDate}
            </p>
            <img className={styles.direction__img} src={plane2} alt="plane" />
        </div>
    )
}

export default Direction
