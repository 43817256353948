import { Radio, Space } from 'antd';
import style from './RadioGroup.module.scss';

const RadioGroup = ({children, onChange}) => {
  return (
    <Radio.Group onChange={onChange} className={style.custom_radio_group}>
      <Space direction="vertical">
        {children}
      </Space>
    </Radio.Group>
  )
}

export default RadioGroup;