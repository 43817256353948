import ReactPlayer from "react-player";
import styles from './Player.module.scss'
import { useRef } from "react";

const Player = ({ url, playIcon, preview }) => {
    const playerRef = useRef(null);

    const handlePlayPause = () => {
        const player = playerRef.current.getInternalPlayer();

        if (!player) return;

        if (player.paused) {
            player.play();
        } else {
            player.pause();
        }
    };

    return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <ReactPlayer
                ref={playerRef}
                url={url}
                controls
                onClickPreview={handlePlayPause}
                playing
                width="100%"
                height="100%"
                playIcon={
                    <img
                        src={playIcon}
                        alt="Play"
                        className={styles.player__icon}
                    />
                }
                light={
                    <img
                        src={preview}
                        alt="preview"
                        style={{ maxWidth: "100%", borderRadius: "12px" }}
                    />
                }
                wrapper={(props) => (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                            paddingTop: "56.25%",
                        }}
                        {...props}
                    />
                )}
            />
        </div>
    );
};

export default Player;
