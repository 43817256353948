import styles from "./TicketTransfer.module.scss"
import {format} from "date-fns"

const TicketTransfer = (props) => {
    const {time = new Date(), transfer, isOpened} = props
    let totalSeconds  = time;
    let hours = Math.floor(totalSeconds / 60);
    let minutes = Math.floor(totalSeconds %= 60);
    const formattedTime = hours + 'ч ' + minutes + 'м'

    const transferText =
        transfer === 0
            ? "прямой"
            : transfer === 1
            ? "1 пересадка"
            : transfer < 4
            ? `${transfer} пересадки`
            : "3+ пересадок"

    return (
        <div className={styles.ticketTransfer}>
            <p className={styles.ticketTransfer__time}>{formattedTime}</p>
            {!isOpened && (<p className={styles.ticketTransfer__transfer}>{transferText}</p>)}
        </div>
    )
}

export default TicketTransfer
