import styles from "../Booking/TicketWayInfo/TicketWayInfo.module.scss";
import TicketTime from "../Booking/TicketWayInfo/TicketTime/TicketTime";
import { Tooltip } from "antd";
import TicketTransfer from "../Booking/TicketWayInfo/TicketTransfer/TicketTransfer";
import classNames from "classnames";

const TicketDetails = ({isOpened, flights, departureTime, arrivalTime, departureDate, arrivalDate, duration, transfer, departureAirport, arrivalAirport, flight}) => {
  let departureAirportStr = departureAirport;
  let arrivalAirportStr = arrivalAirport;
  if (isOpened){
    const departureCity = flight?.departure_city[Object.keys(flight?.departure_city)[0]];
    const departure_airport = flight?.departure[Object.keys(flight?.departure)[0]];
    const departureAirportCode = Object.keys(flight?.departure)[0];
    departureAirportStr = departureCity + ', ' + departure_airport + '(' + departureAirportCode + ')';
    const arrivalCity = flight?.arrival_city[Object.keys(flight?.arrival_city)[0]];
    const arrival_airport = flight?.arrival[Object.keys(flight?.arrival)[0]];
    const arrivalAirportCode = Object.keys(flight?.arrival)[0];
    arrivalAirportStr = arrivalCity + ', ' + arrival_airport + '(' + arrivalAirportCode + ')';
  }

  let totalSeconds  = duration;
  let hours = Math.floor(totalSeconds / 60);
  let minutes = totalSeconds %= 60;
  const formattedTime = hours + 'ч ' + minutes + 'м'

  return (
    <div className={styles.ticket__main}>
      <div className={styles.ticket__main__info}>
        <div className={styles.ticket__main_time}>
          <TicketTime time={departureTime} date={departureDate} airport={departureAirportStr}/>
        </div>
        <div className={styles.ticket__main_tooltip}>
          {isOpened || flights?.length === 1 ? (
            <Tooltip title={`${formattedTime} в пути`}>
              <div
                className={
                  classNames(styles.ticket__tooltip_item_container, styles.ticket__tooltip_item_container__full)
                }
              >
                <div
                  className={styles.ticket__tooltip_item}
                ></div>
              </div>
            </Tooltip>
          ) : (
            <>
            {flights?.map((item, index) => {
              let totalSeconds  = item.duration;
              let hours = Math.floor(totalSeconds / 60);
              let minutes = totalSeconds %= 60;
              const formattedTime = hours + 'ч ' + minutes + 'м'
              return (
                <>
                  <Tooltip title={`${formattedTime} в пути`}>
                    <div
                      className={styles.ticket__tooltip_item_container}
                    >
                      <div
                        className={styles.ticket__tooltip_item}
                      ></div>
                    </div>
                  </Tooltip>
                  {index < flights.length - 1 && (
                    <Tooltip title={item.arrival_city[Object.keys(item.arrival_city)[0]]}>
                      <div
                        className={
                          styles.ticket__tooltip_item_container
                        }
                      >
                        <div
                          className={
                            styles.ticket__tooltip_item_transfer
                          }
                        ></div>
                      </div>
                    </Tooltip>
                  )}
                </>
              )
            })}
            </>
          )}

        </div>
        <div className={styles.ticket__main_time}>
          <TicketTime time={arrivalTime} date={arrivalDate} airport={arrivalAirportStr} />
        </div>
      </div>
      <div className={styles.ticket__main_tooltip__mobile}>
        {isOpened || flights?.length === 1 ? (
          <Tooltip title={`${formattedTime} в пути`}>
            <div
              className={
                classNames(styles.ticket__tooltip_item_container, styles.ticket__tooltip_item_container__full)
              }
            >
              <div
                className={styles.ticket__tooltip_item}
              ></div>
            </div>
          </Tooltip>
        ) : (
          <>
            {flights?.map((item, index) => {
              let totalSeconds  = item.duration;
              let hours = Math.floor(totalSeconds / 60);
              let minutes = totalSeconds %= 60;
              const formattedTime = hours + 'ч ' + minutes + 'м'
              return (
                <>
                  <Tooltip title={`${formattedTime} в пути`}>
                    <div
                      className={styles.ticket__tooltip_item_container}
                    >
                      <div
                        className={styles.ticket__tooltip_item}
                      ></div>
                    </div>
                  </Tooltip>
                  {index < flights.length - 1 && (
                    <Tooltip title={item.arrival_city[Object.keys(item.arrival_city)[0]]}>
                      <div
                        className={
                          styles.ticket__tooltip_item_container
                        }
                      >
                        <div
                          className={
                            styles.ticket__tooltip_item_transfer
                          }
                        ></div>
                      </div>
                    </Tooltip>
                  )}
                </>
              )
            })}
          </>
        )}
      </div>
      <div className={styles.ticket__main_total}>
        <TicketTransfer time={duration} transfer={transfer} isOpened={isOpened}/>
      </div>

    </div>
  )
}

export default TicketDetails;