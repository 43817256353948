import styles from "./How.module.scss"
import CustomButton from "../../../components/UI/CustomButton/CustomButton"
import {setModalOpen} from "../../../store/slices/headerSlice";
import {useDispatch} from "react-redux";

const How = ({title, items, setPartnerModalVisible, userToken}) => {
  const dispatch = useDispatch();
    return (
        <div className={styles.how}>
            <h2 className={styles.how__title}>{title}</h2>
            <div className={styles.how__content}>
                {items.map((item, i) => {
                    return (
                        <div className={styles.how__content_item} key={i}>
                            <div className={styles.how__content_counter}>
                                {i + 1}
                            </div>
                            <h3 className={styles.how__content_title}>
                                {item.title}
                            </h3>
                            <p className={styles.how__content_text}>
                                {item.text}
                            </p>
                            {i === 5 &&
                              <>
                                {userToken ? (
                                    <CustomButton
                                      secondary
                                      onClick={() => setPartnerModalVisible(true)}
                                    >
                                      Стать партнером
                                    </CustomButton>
                                  ) : (
                                    <CustomButton
                                      secondary
                                      onClick={() => dispatch( setModalOpen(true) )}
                                    >
                                      Стать партнером
                                    </CustomButton>
                                  )}
                                </>
                            }
                            <img src={item.img} alt={item.title} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default How
