import React, {useState} from "react";
import TabsItems from "../../components/UI/Tabs/Tabs";
import CustomInput from "../../components/UI/Input/Input";
import DropdownInput from "../../components/UI/DropdownInput/DropdownInput";
import CustomCheckbox from "../../components/UI/Checkbox/CustomCheckbox";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import CustomTag from "../../components/UI/CustomTag/CustomTag";
import Rating from "../../components/UI/Rating/Rating";
import style from "../../components/UI/Tabs/Tabs.module.scss";
import css from "./UiKit.module.scss"
import PlaneIcon from "../../assets/icons/plane.svg";
import DocumentsIcon from "../../assets/icons/documents.svg";
import InsuranceIcon from "../../assets/icons/insurance.svg";
import CalendarIcon from "../../assets/icons/calendar.svg";
import Lock from "../../assets/icons/lock.png";
import Mail from "../../assets/icons/mail.png";
import ImageProfile from "../../assets/icons/image-profile.png";
import ImageDone from "../../assets/icons/image-done.png";
import {ReactSVG} from "react-svg"
import Popup from "../../components/UI/Popup/Popup";
import done from '../../assets/icons/done.png'
import LoginForm from "../../components/UI/Forms/LoginForm"
import RegistrationForm from "../../components/UI/Forms/RegistrationForm"
import RecoverPasswordForm from "../../components/UI/Forms/RecoverPasswordForm"
import ChangePasswordForm from "../../components/UI/Forms/ChangePasswordForm"
import MoneyWithdrawForm from "../../components/UI/Forms/MoneyWithdrawForm"
import CustomDatePicker from "../../components/UI/CustomDatePicker/CustomDatePicker"
import CustomBreadCrumb from "../../components/UI/CustomBreadCrumb/CustomBreadCrumb"
import {Form, Formik} from "formik"
import RadioGender from "../../components/UI/RadioGender/RadioGender"
import CustomTable from "../../components/UI/CustomTable/CustomTable";
import BecomeAPartnerForm from "../../components/UI/Forms/BecomeAPartnerForm";
import { useNavigate } from "react-router";

function UIForm() {
  return (
    <Formik
      initialValues={{
        passengers: [
          {
            gender: "",
          },
          // ...
        ],
        // ...
      }}
      onSubmit={(values) => {
        // ...
      }}
    >
      {({values, errors, touched, setFieldValue}) => (
        <Form>
          <RadioGender
            index={0}
            nameMale={`passengers.gender`}
            valueMale="male"
            checkedMale={values.passengers.gender === "male"}
            nameFemale={`passengers.gender`}
            valueFemale="female"
            checkedFemale={
              values.passengers.gender === "female"
            }
            setFieldValue={setFieldValue}
            errors={errors.passengers}
            touched={touched.passengers}
          />
          {/* ... */}
        </Form>
      )}
    </Formik>
  )
}

const items = [
  {
    key: '1',
    label: (
      <div className={style.tabs_item}>
        <ReactSVG src={PlaneIcon}/>
        <p className={style.tabs_item_text}>Tab 1</p>
      </div>
    ),
    children: `Content of Tab Pane 1`,
  },
  {
    key: '2',
    label: (
      <div className={style.tabs_item}>
        <ReactSVG src={DocumentsIcon}/>
        <span className={style.status_text}>Скоро</span>
        <p className={style.tabs_item_text}>Tab 2</p>
      </div>
    ),
    children: `Content of Tab Pane 2`,
  },
  {
    key: '3',
    label: (
      <div className={style.tabs_item}>
        <ReactSVG src={InsuranceIcon}/>
        <span className={style.status_text}>Скоро</span>
        <p className={style.tabs_item_text}>Tab 3</p>
      </div>
    ),
    children: `Content of Tab Pane 3`,
  },
];

const dropdownOptions = [
    {value: "Россия", label: "Россия"},
    {value: "Беларусь", label: "Беларусь"},
]

const breadCrumbItems = [
    {
        title: "Home",
    },
    {
        title: <a href="">Application Center</a>,
    },
    {
        title: <a href="">Application List</a>,
    },
    {
        title: "An Application",
    },
]

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    age: 32,
    address: '10 Downing Street',
  },
  {
    key: '2',
    name: 'John',
    age: 42,
    address: '10 Downing Street',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    sorter: (a, b) => a.age - b.age
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

const UiKit = () => {
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isRegistrationModalVisible, setRegistrationModalVisible] = useState(false);
  const [isRecoverPasswordModalVisible, setRecoverPasswordModalVisible] = useState(false);
  const [isEmailModalVisible, setEmailModalVisible] = useState(false);
  const [isChangePasswordModalVisible, setChangePasswordModalVisible] = useState(false);
  const [isMoneyWithdrawModalVisible, setMoneyWithdrawModalVisible] = useState(false);
  const [isMoneyWithdrawResultModalVisible, setMoneyWithdrawResultModalVisible] = useState(false);
  const [isDeletePersonModalVisible, setDeletePersonModalVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isPartnerModalVisible ,setPartnerModalVisible] = useState(false)
  const [isPartnerSuccessModal ,setPartnerSuccessModal] = useState(false)
  const navigate = useNavigate()

  return (
    <div style={{paddingTop: 100}}>
      <div className={css.ui_kit_item}>
        <TabsItems items={items}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomInput placeholder={"ФИО"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomInput icon={<ReactSVG src={CalendarIcon}/>} placeholder={"ФИО"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomInput error={"Поле обязательно"} placeholder={"ФИО"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomInput searchError={"Поле обязательно"} placeholder={"ФИО"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomInput blocked placeholder={"ФИО"}/>
      </div>
      <div className={css.ui_kit_item}>
        <DropdownInput defaultValue="Россия" options={dropdownOptions} label={"Гражданство"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomDatePicker name={"Дата рождения"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomDatePicker name={"Дата рождения"} error={"Введите дату рождения"}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomBreadCrumb items={breadCrumbItems} />
      </div>
      <div className={css.ui_kit_item}>
        <CustomCheckbox text={"Без пересадок"} checked={isCheckboxChecked} onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomButton primary>primary big</CustomButton>
      </div>
      <div className={css.ui_kit_item}>
        <CustomButton primary small>primary small</CustomButton>
      </div>
      <div className={css.ui_kit_item}>
        <CustomButton lined>lined</CustomButton>
      </div>
      <div className={css.ui_kit_item}>
        <CustomButton linedBlack>linedBlack</CustomButton>
      </div>
      <div className={css.ui_kit_item}>
        <CustomButton secondary>secondary</CustomButton>
      </div>
      <div className={css.ui_kit_item}>
        <CustomButton icon={<ReactSVG src={InsuranceIcon} />} lined>lined with icon</CustomButton>
      </div>
      <div className={css.ui_kit_item}>
        <CustomTag text={"Active tag"} active/>
      </div>
      <div className={css.ui_kit_item}>
        <CustomTag text={"Inactive tag"} inactive/>
      </div>
      <div className={css.ui_kit_item}>
        <p>Меняющийся рейтинг</p>
        <Rating/>
      </div>
      <div className={css.ui_kit_item}>
        <p>Неизменяемый рейтинг</p>
        <Rating disabled defaultValue={4}/>
      </div>

      <Popup
        open={isLoginModalVisible}
        onCancel={ () => setLoginModalVisible(false)}
        title={(
          <>
            <img alt="profileIcon" src={ImageProfile}/>
            <p>Войти в AirSurfer</p>
          </>
        )}
        footer={null}
      >
        <LoginForm/>
      </Popup>

      <Popup
        open={isRegistrationModalVisible}
        onCancel={() => setRegistrationModalVisible(false)}
        title={(
          <>
            <img alt="profileIcon" src={ImageProfile}/>
            <p>Регистрация в AirSurfer</p>
          </>
        )}
        footer={null}
      >
        <RegistrationForm/>
      </Popup>

      <Popup
        open={isRecoverPasswordModalVisible}
        onCancel={() => setRecoverPasswordModalVisible(false)}
        title={(
          <>
            <img alt="icon" src={Lock}/>
            <p>Забыли пароль</p>
            <span>Введите адрес электронной почты, на которую зарегистрирован кабинет</span>
          </>
        )}
        footer={null}
      >
        <RecoverPasswordForm/>
      </Popup>

      <Popup
        open={isEmailModalVisible}
        onCancel={() => setEmailModalVisible(false)}
        title={(
          <>
            <img alt="icon" src={Mail}/>
            <p>Проверьте почту</p>
            <span>Мы отправили ссылку для создания нового пароля на вашу почту <a>example@mail.ru</a></span>
          </>
        )}
        footer={(
          <CustomButton
            primary
            fullWidth
            onClick={() => setEmailModalVisible(false)}
          >На главную</CustomButton>
        )}
      >
      </Popup>

      <Popup
        open={isChangePasswordModalVisible}
        onCancel={() => setChangePasswordModalVisible(false)}
        title={(
          <>
            <img alt='icon' src={Lock}/>
            <p>Восстановление пароля</p>
            <span>Введите новый пароль</span>
          </>
        )}
        footer={null}
      >
        <ChangePasswordForm/>
      </Popup>

      <Popup
        open={isMoneyWithdrawModalVisible}
        onCancel={() => setMoneyWithdrawModalVisible(false)}
        title={(<p style={{textAlign: 'left'}}>Вывод средств</p>)}
        footer={null}
      >
        <MoneyWithdrawForm onCancel={() => setMoneyWithdrawModalVisible(false)}/>
      </Popup>

      <Popup
        open={isMoneyWithdrawResultModalVisible}
        onCancel={() => setMoneyWithdrawResultModalVisible(false)}
        title={(
          <>
            <img alt="doneIcon" src={ImageDone}/>
            <p>Средства отправлены</p>
            <span>Они поступят на выбранный способ пополнения в течении 24 часов</span>
          </>
        )}
        footer={(
          <CustomButton
            primary
            fullWidth
            onClick={() => setMoneyWithdrawResultModalVisible(false)}
          >
            Вернуться в кабинет
          </CustomButton>
        )}
      >
      </Popup>

      <Popup
        open={isDeletePersonModalVisible}
        onCancel={() => setDeletePersonModalVisible(false)}
        title={(
          <>
            <p>Удалить пассажира?</p>
            <span>Все данные безвозвратно удалятся</span>
          </>
        )}
        footer={(
          <div>
            <CustomButton
              linedBlack
              onClick={() => setDeletePersonModalVisible(false)}
            >
              Отмена
            </CustomButton>
            <CustomButton
              primary
              onClick={() => setDeletePersonModalVisible(false)}
            >
              Удалить
            </CustomButton>
          </div>
        )}
      >
          </Popup>
          
        <Popup
        open={isPartnerModalVisible}
        onCancel={() => setPartnerModalVisible(false)}
        title={(<p style={{textAlign: 'left'}}>Стать партнером</p>)}
        footer={null}
        afterClose={() => setPartnerSuccessModal(true)}
      >
        <BecomeAPartnerForm  onSubmit={(values) => console.log(values)}/>
      </Popup>
      
        <Popup
        open={isPartnerSuccessModal}
        onCancel={() => setPartnerSuccessModal(false)}
              title={(<>
                <img className={css.partner__img} src={done} alt="icon" />
                  <p className={css.partner__title}>Заявка отправлена!</p>
                  <span className={css.partner__text}>После обработки вашей заявки вы будет подключены к партнерской программы, если у вас появились проблемы с подключением напишите нам на <a href="mailto:support@airsurfer.co"><span>support@airsurfer.co</span></a></span>
              </>)}
            footer = { <CustomButton CustomButton
                primary
                fullWidth
            onClick={() => {
                setPartnerSuccessModal(false)
                navigate('/')
            }}
          >
            На главную
          </CustomButton>}
      >
      </Popup>

      <CustomButton onClick={() => setPartnerModalVisible(true)}>Стать партнером</CustomButton>
      <CustomButton onClick={() => setLoginModalVisible(true)}>окно логина</CustomButton>
      <CustomButton onClick={() => setRegistrationModalVisible(true)}>окно регистрации</CustomButton>
      <CustomButton onClick={() => setRecoverPasswordModalVisible(true)}>окно восстановления пароля</CustomButton>
      <CustomButton onClick={() => setEmailModalVisible(true)}>окно отправки сообщения на почту</CustomButton>
      <CustomButton onClick={() => setChangePasswordModalVisible(true)}>окно смены пароля</CustomButton>
      <CustomButton onClick={() => setMoneyWithdrawModalVisible(true)}>окно вывода средств</CustomButton>
      <CustomButton onClick={() => setMoneyWithdrawResultModalVisible(true)}>окно подтверждения вывода средств</CustomButton>
      <CustomButton onClick={() => setDeletePersonModalVisible(true)}>окно подтверждения удаления пассажира</CustomButton>
      <UIForm />
      <CustomTable columns={columns} dataSource={dataSource}/>
    </div>
  );
}

export default UiKit;
