import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./constants";
import { resetCache } from "./resetCache";

export function baseQuery({ baseUrl = BASE_URL } = {}) {
    return (...args) => {
        return fetchBaseQuery({
            baseUrl,
            credentials:"include",
            prepareHeaders: (headers) => {
                const token = localStorage.getItem("token");

                if (token) {
                    headers.set("Authorization", `Bearer ${token}`);
                }

                headers.set("Accept", "application/json");
                headers.set("Content-type", "application/json");

                return headers;
            },
        })(...args).then((res) => {
            if (res.error?.status === 401) {
                resetCache(args[1].dispatch);
                localStorage.removeItem("token");
                window.location.reload(false);
            }
            return res;
        });
    };
}

export const baseApi = (args) =>
    createApi({
        refetchOnFocus: true,
        baseQuery: baseQuery(),
        credentials: 'include',
        ...args,
    });
