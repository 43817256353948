import { Formik } from "formik";
import styles from "./Bookings.module.scss";
import { Form } from "react-router-dom";
import RenderPassengerFields from "./Passengers/PassengersForm/PassengerField";
import * as Yup from "yup";
import {
    bookingRouteSchema,
    customerSchema,
    hotelSchema,
    passengerSchema,
} from "./Passengers/PassengersForm/Validation";
import Payment from "./Payment/Payment";
import SingleBreadCrumb from "../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import Customer from "./Customer/Customer";
import Comment from "./Comment/Comment";
import AlreadyRegistered from "./AlreadyRegistered/AlreadyRegistered";
import Hotel from "./Hotel/Hotel";
import TicketWayInfoWrapper from "./TicketWayInfoWrapper/TicketWayInfoWrapper";
import plane from "../../assets/icons/plane-booking.png";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import {
    useGetOrderByIdQuery,
    usePostOrderMutation,
    usePutOrderMutation,
    usePostOrderPriceMutation,
} from "../../services/orders.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "../../components/Header/Popups";
import Popup2 from "../../components/UI/Popup/Popup";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import { useGetPassengersQuery } from "../../services/passengers.js";
import { useGetCacheQuery } from "../../services/tickets";
import BookingBlock from "./BookingBlock/BookingBlock";
import { selectCheepestTariff } from "store/selectors/catalog";
import { getBrowserlang } from "services/lang";
import { selectLang } from "store/selectors/header";

const BookingPage = () => {
    //Самый дешевый тариф
    const cheeperTariff = useSelector(selectCheepestTariff);
    const user = useSelector((state) => state.user.user);
    const userToken = useSelector((state) => state.user.token);

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const { data } = useGetCacheQuery({ id });
    const params = useParams();
    const prices = {};
    const [bookingTickets, setBookingTickets] = useState(
        useSelector((state) => state.tickets.bookingTickets)
    );

    const { data: passengersQuery, passengersIsLoading } =
        useGetPassengersQuery(1, { skip: !userToken });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPromo, setIsLoadingPromo] = useState(false);
    const currentLang = useSelector(selectLang);
    const orderId = params.id;
    const { data: getOrdersQuery, error } = useGetOrderByIdQuery(
        { id: orderId },
        { skip: !orderId }
    );
    const [isLoginModalVisible, setLoginModalVisible] = useState(false);
    const [postOrder] = usePostOrderMutation();
    const [putOrder] = usePutOrderMutation();
    const [postOrderPrice] = usePostOrderPriceMutation();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [backErrors, setBackErrors] = useState({});
    const [showErrors, setShowErrors] = useState();
    const [selectedPassengers, setSelectedPassengers] = useState([]);
    const [passangersReady, setPassangersReady] = useState(false);
    //Тарифы выбранного Id
    const [tariffId, setTariffId] = useState();

    //Тарифы отеля выбранного Id
    const [tariffHotelId, setTariffHotelId] = useState();
    const [discount, setDiscount] = useState();
    const [discountPrice, setDiscountPrice] = useState(0);

    const passengerTypes = [];
    let itineraryReceiptAmount = 0;
    dayjs.extend(customParseFormat);
    dayjs.extend(utc);
    const todayDateTime = dayjs().format("DD.MM.YYYY HH:mm");
    dayjs.extend(customParseFormat);

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [initialValues, setInitialValues] = useState();
    const [profilePassangers, setProfilePassangers] = useState(
        user?.profile ?? []
    );

    if (params.id === undefined) {
        for (let i = 0; i < bookingTickets?.adults; i++) {
            passengerTypes.push("adults");
            itineraryReceiptAmount++;
        }

        for (let i = 0; i < bookingTickets?.children; i++) {
            passengerTypes.push("children");
            itineraryReceiptAmount++;
        }

        for (let i = 0; i < bookingTickets?.babies; i++) {
            passengerTypes.push("babies");
            itineraryReceiptAmount++;
        }
    }

    /**
     * Инициализация первого тарифа
     */
    useEffect(() => {
        if (cheeperTariff) setTariffId(cheeperTariff.id);
    }, [cheeperTariff]);

    useEffect(() => {
        if (data) {
            setBookingTickets(JSON.parse(data.data[0].json).ticket);
            setInitialValues(null);
        }
    }, [data]);

    useEffect(() => {
        if (!user) {
            setPassangersReady(true);
        } else {
            initialValues.customer.email = user.email ?? "";
            initialValues.customer.telCode =
                user.profile?.phone_prefix ??
                getOrdersQuery?.data.phone_prefix ??
                "+7";
            initialValues.customer.tel =
                user.profile?.phone ?? getOrdersQuery?.data.phone ?? "";
            initialValues.hotel.hasHotel = getOrdersQuery?.data.hotel_city
                ? true
                : false;
            initialValues.hotel.town = getOrdersQuery?.data.hotel_city ?? "";
            initialValues.hotel.arrivalDate =
                getOrdersQuery?.data.hotel_check_in ?? "";
            initialValues.hotel.departureDate =
                getOrdersQuery?.data.hotel_check_out ?? "";
            initialValues.bookingRoute.card = getOrdersQuery?.data.tariff_id;
            initialValues.passengers = passengerTypes.map((el, index) => ({
                country: getOrdersQuery
                    ? getOrdersQuery?.data.passengers[index].country
                    : currentLang == "RU"
                    ? "RU"
                    : "US",
                //DEFAULT COUNTRY
                firstName: getOrdersQuery?.data.passengers[index].firstname,
                lastName: getOrdersQuery?.data.passengers[index].lastname,
                birthDate: getOrdersQuery?.data.passengers[index].birthday,
                gender: getOrdersQuery?.data.passengers[index].gender,
                documentNumber:
                    getOrdersQuery?.data.passengers[index].document_number,
                documentExpirationDate:
                    getOrdersQuery?.data.passengers[index].document_expires,
                documentType: getOrdersQuery
                    ? getOrdersQuery?.data.passengers[index].document_type
                    : currentLang == "RU"
                    ? "FOREIGN"
                    : "PASSPORT",
                passengerTypes: el,
            }));

            setInitialValues({ ...initialValues });
            //refetch();
            if (user.profile) {
                setProfilePassangers([user.profile]);
            }
            if (getOrdersQuery?.data.tariff_id)
                setTariffId(getOrdersQuery?.data.tariff_id);
            if (getOrdersQuery?.data.hotel_tariff_id)
                setTariffHotelId(getOrdersQuery?.data.hotel_tariff_id);

            setPassangersReady(true);
        }
        if (passengersQuery?.data && profilePassangers.length === 1) {
            passengersQuery.data.forEach(function (elem) {
                profilePassangers.push(elem);
            });
            setProfilePassangers(profilePassangers);
            setPassangersReady(true);
        }
    }, [
        passengersQuery,
        profilePassangers,
        passengersIsLoading,
        user,
        getOrdersQuery,
        data,
        bookingTickets,
    ]);

    let INITIAL_VALUES = {
        passengers: passengerTypes.map((el, index) => ({
            country: currentLang == "RU" ? "RU" : "US",
            firstName: "",
            lastName: "",
            birthDate: "",
            gender: "",
            documentNumber: "",
            documentExpirationDate: "",
            documentType: "FOREIGN",
            passengerTypes: el,
        })),
        hotel: {
            hasHotel: false,
            town: "",
            arrivalDate: "",
            departureDate: "",
        },
        bookingRoute: {
            bookingDate: todayDateTime,
        },
        customer: {
            email: user ? user?.email : "",
            telCode: (user && user.profile?.phone_prefix) ?? "",
            tel: (user && user?.profile?.phone) ?? "",
            comment: "",
            promocode: "",
        },
    };

    if (getOrdersQuery) {
        getOrdersQuery?.data.passengers.forEach(function (elem) {
            passengerTypes.push(elem.type);
            itineraryReceiptAmount++;
            INITIAL_VALUES.passengers.push({
                country: "RU",
                documentType: "FOREIGN",
            });
        });
    }

    // if (bookingTickets == undefined && params.id === undefined && !data) {
    //     navigate('/')
    //     return
    // }

    if (getOrdersQuery) {
        INITIAL_VALUES = {
            passengers: passengerTypes.map((_, index) => ({
                country: getOrdersQuery
                    ? getOrdersQuery?.data.passengers[index].country
                    : "RU",
                firstName: getOrdersQuery?.data.passengers[index].firstname,
                lastName: getOrdersQuery?.data.passengers[index].lastname,
                birthDate: getOrdersQuery?.data.passengers[index].birthday,
                gender: getOrdersQuery?.data.passengers[index].gender,
                documentNumber:
                    getOrdersQuery?.data.passengers[index].document_number,
                documentExpirationDate:
                    getOrdersQuery?.data.passengers[index].document_expires,
                documentType: getOrdersQuery?.data.passengers[index]
                    .document_type
                    ? getOrdersQuery?.data.passengers[index].document_type
                    : !getOrdersQuery?.data.passengers[index].country ||
                      getOrdersQuery?.data.passengers[index].country === "RU"
                    ? "FOREIGN"
                    : null,
            })),
            hotel: {
                hasHotel: getOrdersQuery?.data.hotel_city ? true : false,
                town: getOrdersQuery?.data.hotel_city ?? "",
                arrivalDate: getOrdersQuery?.data.hotel_check_in ?? "",
                departureDate: getOrdersQuery?.data.hotel_check_out ?? "",
            },
            bookingRoute: {
                bookingDate:
                    getOrdersQuery?.data.order_start_booking || todayDateTime,
                card: getOrdersQuery?.data.tariff_id,
            },
            customer: {
                email: getOrdersQuery?.data.email,
                telCode: getOrdersQuery?.data.phone_prefix,
                tel: getOrdersQuery?.data.phone,
                comment: getOrdersQuery?.data.comment,
                promocode: getOrdersQuery?.data.promo_code,
            },
        };

        if (discountPrice === 0 && getOrdersQuery?.data.price > 0) {
            setDiscount(getOrdersQuery?.data.discountPrc);
            setDiscountPrice(getOrdersQuery?.data.price);
        }

        if (
            initialValues?.passengers?.length === 0 &&
            getOrdersQuery?.data?.passengers?.length > 0
        ) {
            setInitialValues({ ...INITIAL_VALUES });
        }
    }

    if (!initialValues) {
        setInitialValues({ ...INITIAL_VALUES });
    }

    const month = {
        "01": "янв",
        "02": "фев",
        "03": "мар",
        "04": "апр",
        "05": "май",
        "06": "июн",
        "07": "июл",
        "08": "авг",
        "09": "сен",
        10: "окт",
        11: "ноя",
        12: "дек",
    };

    const month2 = {
        "01": "января",
        "02": "февраля",
        "03": "марта",
        "04": "апреля",
        "05": "мая",
        "06": "июня",
        "07": "июля",
        "08": "августа",
        "09": "сентября",
        10: "октября",
        11: "ноября",
        12: "декабря",
    };

    const trip_to = getOrdersQuery
        ? JSON.parse(getOrdersQuery?.data.trip_to)
        : bookingTickets?.to
        ? bookingTickets?.to
        : "";
    const trip_back = getOrdersQuery
        ? JSON.parse(getOrdersQuery?.data.trip_back)
        : bookingTickets?.back
        ? bookingTickets?.back
        : "";

    const departure_city = trip_to ? trip_to?.flight[0].departure_city : "";
    let departure_city_str = departure_city[Object.keys(departure_city)[0]];
    const arrival_city = trip_to
        ? trip_to.flight.slice(-1)[0].arrival_city
        : "";
    let arrival_city_str = arrival_city[Object.keys(arrival_city)[0]];

    let [departure_date_day, departure_date_month, departure_date_year] =
        trip_to ? trip_to.departure_date.split(/\./) : [];
    const departure_date_str =
        departure_date_day + " " + month[departure_date_month];
    let [arrival_date_day, arrival_date_month, arrival_date_year] = trip_to
        ? trip_to.arrival_date.split(/\./)
        : [];
    const arrival_date_str = arrival_date_day + " " + month[arrival_date_month];

    const [checkInDay, checkInMonth, checkInYear] = getOrdersQuery?.data
        .hotel_check_in
        ? getOrdersQuery.data.hotel_check_in.split(".")
        : [];
    const hotelCheckIn = checkInDay + " " + month2[checkInMonth];
    const [checkOutDay, checkOutMonth, checkOutYear] = getOrdersQuery?.data
        .hotel_check_out
        ? getOrdersQuery.data.hotel_check_out.split(".")
        : [];
    const hotelCheckOut =
        checkOutDay + " " + month2[checkOutMonth] + " " + checkOutYear;

    const hotel = {
        period: hotelCheckIn + " - " + hotelCheckOut,
        price: prices?.price_hotel,
    };

    const passengersClickHandler = (index, id, setFieldValue) => {
        const initialDocumentType =
            profilePassangers[id].country === "RU" ? "FOREIGN" : null;
        setFieldValue(
            "passengers[" + index + "].firstName",
            profilePassangers[id].firstname
        );
        setFieldValue(
            "passengers[" + index + "].lastName",
            profilePassangers[id].lastname
        );
        setFieldValue(
            "passengers[" + index + "].country",
            profilePassangers[id].country
        );
        setFieldValue(
            "passengers[" + index + "].gender",
            profilePassangers[id].gender
        );
        setFieldValue(
            "passengers[" + index + "].birthDate",
            profilePassangers[id].birthday
        );
        setFieldValue(
            "passengers[" + index + "].documentNumber",
            profilePassangers[id].document_number
        );
        setFieldValue(
            "passengers[" + index + "].documentExpirationDate",
            profilePassangers[id].document_expires || ""
        );
        setFieldValue(
            "passengers[" + index + "].documentType",
            profilePassangers[id].document_type || initialDocumentType
        );

        let copy = Object.assign([], selectedPassengers);
        copy[index] = id;
        setSelectedPassengers(copy);
        setErrors({});
        setShowErrors(false);
    };

    const passengersChangeHandler = (
        index,
        value,
        fieldName,
        setFieldValue
    ) => {
        delete selectedPassengers[index];
        setSelectedPassengers(selectedPassengers);

        if (fieldName) {
            fieldName = fieldName.replace("passengers." + index + ".", "");
        }

        if (fieldName === "firstName" || fieldName === "lastName") {
            value = value.toUpperCase();
        }

        setShowErrors(false);
        setFieldValue("passengers[" + index + "]." + fieldName, value);
    };

    const submitClick = (values, errors, isOrder) => {
        setErrors({});
        setBackErrors({});
        if (isLoading) {
            return;
        }

        const isAddForm = params.id ? false : true;
        let passengers = new Object();
        let birthDateErrorsTemp = [];
        let birthDateErrors = [];
        let newbirthDateErrors = {};
        //Дата вылета
        const departure_date = dayjs.unix(trip_to.departure_timestamp);
        values.passengers.forEach((el, index) => {
            //младенец -> от 0 до 2 лет
            if (
                el.birthDate &&
                el.passengerTypes === "babies" &&
                dayjs(el.birthDate, ["DD.MM.YYYY"], "ru", true)
                    .add(2, "year")
                    .isBefore(departure_date)
            ) {
                birthDateErrorsTemp.push({
                    index: index,
                    birthDate: "Возраст младенца должен быть от 0 до 2 лет",
                });
            }
            //ребенок -> больше 2 лет но меньше 12 лет
            if (
                el.birthDate &&
                el.passengerTypes === "children" &&
                (dayjs(el.birthDate, ["DD.MM.YYYY"], "ru", true)
                    .add(12, "year")
                    .isBefore(departure_date) ||
                    !dayjs(el.birthDate, ["DD.MM.YYYY"], "ru", true)
                        .add(2, "year")
                        .isBefore(departure_date))
            ) {
                birthDateErrorsTemp.push({
                    index: index,
                    birthDate: "Возраст ребенка должен быть от 2 до 12 лет",
                });
            }
            //взрослый -> больше 12 лет
            if (
                el.birthDate &&
                el.passengerTypes === "adults" &&
                dayjs(el.birthDate, ["DD.MM.YYYY"], "ru", true)
                    .add(12, "year")
                    .isAfter(departure_date)
            ) {
                birthDateErrorsTemp.push({
                    index: index,
                    birthDate: "Возраст взрослого должен быть от 12 лет",
                });
            }

            birthDateErrorsTemp.forEach(function (item, i, arr) {
                birthDateErrors[item.index] = { birthDate: item.birthDate };
            });
            newbirthDateErrors = { passengers: birthDateErrors };

            passengers[index] = {
                firstname: el.firstName,
                lastname: el.lastName,
                birthday: el.birthDate,
                document_number: el.documentNumber,
                document_expires: el.documentExpirationDate,
                document_type: el.documentType,
                country: el.country,
                gender: el.gender,
            };
        });
        setBackErrors(newbirthDateErrors);

        var trip_to_str = JSON.stringify(trip_to)
            .replace(/\\/g, "")
            .replace(/^["]+/, "")
            .replace(/"+$/, "");
        var trip_back_str =
            trip_back && trip_back !== "null"
                ? JSON.stringify(trip_back)
                      ?.replace(/\\/g, "")
                      .replace(/^["]+/, "")
                      .replace(/"+$/, "")
                : null;
        //Получение реферального кода из ссылки
        const referralСode = localStorage.getItem("promo");

        const orderData = {
            email: values.customer.email,
            phone_prefix: values.customer.telCode
                ? `${values.customer.telCode}`
                : "+7",
            phone: values.customer.tel,
            comments: values.customer.comment,
            order_start_booking: values.bookingRoute.bookingDate,
            hotel_city: values.hotel.hasHotel ? values.hotel.town : "",
            tariff_id: tariffId,
            hotel_tariff_id: tariffHotelId,
            hotel_check_in: tariffHotelId ? values.hotel.arrivalDate : "",
            hotel_check_out: tariffHotelId ? values.hotel.departureDate : "",
            promo_code: values.customer.promocode,
            referral_code: referralСode,
            trip_to: trip_to_str,
            trip_back: trip_back_str,
            passengers: passengers,
        };
        setShowErrors(true);

        if (
            Object.keys(errors).length === 0 &&
            Object.keys(newbirthDateErrors.passengers).length === 0 &&
            isOrder
        ) {
            setIsLoading(true);
            if (isAddForm) {
                postOrder(orderData)
                    .unwrap()
                    .then((data) => {
                        navigate("/bookingPayment/" + data.data.id);
                        setIsLoading(false);
                    })
                    .catch((resp) => {
                        setErrors(resp.data.errors);
                        if (resp.data.errors?.email) {
                            errors.email = resp.data.message;
                        }

                        if (resp.status === 503) {
                            setIsPopupVisible(true);
                        }
                        setIsLoading(false);
                    });
            } else {
                orderData.id = getOrdersQuery.data.id;
                orderData.order_number = getOrdersQuery.data.order_number;
                putOrder(orderData)
                    .unwrap()
                    .then((data) => {
                        navigate("/bookingPayment/" + data.data.id);
                        setIsLoading(false);
                    })
                    .catch((resp) => {
                        setErrors(resp.data.errors);
                        if (resp.data.errors?.email) {
                            errors.email = resp.data.message;
                        }
                        if (resp.status === 503) {
                            setIsPopupVisible(true);
                        }
                        setIsLoading(false);
                    });
            }
        }

        //расчет цены с промокодом
        if (
            Object.keys(errors).length === 0 &&
            Object.keys(newbirthDateErrors.passengers).length === 0 &&
            !isOrder
        ) {
            setIsLoadingPromo(true);

            postOrderPrice(orderData)
                .unwrap()
                .then((data) => {
                    setIsLoadingPromo(false);
                    setDiscount(data.data.discount);
                    setDiscountPrice(data.data.price);
                })
                .catch((resp) => {
                    if (resp?.data?.errors) {
                        setErrors(resp.data.errors);
                        if (resp.data.errors?.email) {
                            errors.email = resp.data.message;
                        }
                    }

                    if (resp.status === 503) {
                        setIsPopupVisible(true);
                    }
                    setIsLoadingPromo(false);
                });
        }
    };

    const directionInfo = bookingTickets?.back ? "both" : "to";
    const handleClickBack = (e) => {
        navigate(-1);
    };
    const isPaid =
        getOrdersQuery && getOrdersQuery?.data.order_status !== "created"
            ? true
            : false;
    const orderNotFound = !getOrdersQuery && orderId ? true : false;

    return (
        <section className={styles.booking}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    passengers: Yup.array().of(passengerSchema),
                    bookingRoute: bookingRouteSchema,
                    hotel: hotelSchema,
                    customer: customerSchema,
                })}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldError,
                    submitForm,
                }) => (
                    <div className="container">
                        <div className={styles.booking__wrapper}>
                            <Form className={styles.booking__form}>
                                <SingleBreadCrumb
                                    title={"Назад"}
                                    onClick={handleClickBack}
                                />
                                <div className={styles.booking__top}>
                                    {orderNotFound && error !== undefined ? (
                                        <>
                                            <h2
                                                className={
                                                    styles.booking__top_title
                                                }
                                            >
                                                Заказ не найден
                                            </h2>
                                        </>
                                    ) : (
                                        <h2
                                            className={
                                                styles.booking__top_title
                                            }
                                        >
                                            {departure_city_str} -{" "}
                                            {arrival_city_str}
                                        </h2>
                                    )}

                                    {error === undefined && (
                                        <p
                                            className={
                                                styles.booking__top_subtitle
                                            }
                                        >
                                            {departure_date_str} -{" "}
                                            {arrival_date_str}
                                        </p>
                                    )}

                                    <img
                                        className={styles.booking__top_img}
                                        src={plane}
                                        alt="plane"
                                    />
                                </div>
                                {!orderNotFound && (
                                    <TicketWayInfoWrapper
                                        trip_to={trip_to}
                                        trip_back={trip_back}
                                    />
                                )}
                                {!orderNotFound && (
                                    <BookingBlock
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        tariffId={tariffId}
                                        setTariffId={setTariffId}
                                    />
                                )}
                                {!orderNotFound && (
                                    <Hotel
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        tariffId={tariffHotelId}
                                        setTariffId={setTariffHotelId}
                                        showErrors={showErrors}
                                        setShowErrors={setShowErrors}
                                    />
                                )}
                                {!user && (
                                    <AlreadyRegistered
                                        isLoginModalVisible={
                                            isLoginModalVisible
                                        }
                                        setLoginModalVisible={
                                            setLoginModalVisible
                                        }
                                    />
                                )}
                                <Popup
                                    isLoginModalVisible={isLoginModalVisible}
                                    setLoginModalVisible={setLoginModalVisible}
                                />
                                {!orderNotFound && passangersReady && (
                                    <Customer
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        showErrors={showErrors}
                                        setShowErrors={setShowErrors}
                                    />
                                )}
                                {!orderNotFound && (
                                    <div className={styles.passengers}>
                                        <div
                                            className={styles.passengers__text}
                                        >
                                            <h2
                                                className={
                                                    styles.passengers__title
                                                }
                                            >
                                                Пассажиры
                                            </h2>
                                            <p
                                                className={
                                                    styles.passengers__subtitle
                                                }
                                            >
                                                Введите данные пассажиров
                                            </p>
                                        </div>
                                        {passangersReady &&
                                            passengerTypes.map(
                                                (passengerType, index) => (
                                                    <RenderPassengerFields
                                                        key={index}
                                                        passengerType={
                                                            passengerType
                                                        }
                                                        profilePassangers={
                                                            profilePassangers
                                                        }
                                                        passengersClickHandler={
                                                            passengersClickHandler
                                                        }
                                                        passengersChangeHandler={
                                                            passengersChangeHandler
                                                        }
                                                        selectedPassengers={
                                                            selectedPassengers
                                                        }
                                                        setSelectedPassengers={
                                                            setSelectedPassengers
                                                        }
                                                        index={index}
                                                        values={values}
                                                        errors={errors}
                                                        setErrors={setErrors}
                                                        backErrors={backErrors}
                                                        touched={touched}
                                                        setFieldValue={
                                                            setFieldValue
                                                        }
                                                        setFieldError={
                                                            setFieldError
                                                        }
                                                        showErrors={showErrors}
                                                        setShowErrors={
                                                            setShowErrors
                                                        }
                                                    />
                                                )
                                            )}
                                    </div>
                                )}
                                {!orderNotFound && (
                                    <Comment
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Form>
                            <div className={styles.booking__rightBlock}>
                                {!orderNotFound && (
                                    <Payment
                                        hotel={hotel}
                                        passangerCount={itineraryReceiptAmount}
                                        tariffHotelId={tariffHotelId}
                                        isReturnTikets={
                                            !!trip_to && !!trip_back
                                        }
                                        cityFrom={departure_city_str}
                                        cityTo={arrival_city_str}
                                        isErrors={
                                            errors.passengers?.length ||
                                            errors?.customer ||
                                            errors?.hotel ||
                                            errors?.email
                                        }
                                        values={values}
                                        isLoading={isLoading}
                                        isLoadingPromo={isLoadingPromo}
                                        submitClick={() =>
                                            submitClick(values, errors, true)
                                        }
                                        promoCodeClick={() =>
                                            submitClick(values, errors, false)
                                        }
                                        tariffId={tariffId}
                                        isPaid={isPaid}
                                        discount={discount}
                                    />
                                )}
                                <Popup2
                                    open={isPopupVisible}
                                    onCancel={() => setIsPopupVisible(false)}
                                    title={
                                        <>
                                            <p>Результаты могли устареть</p>
                                            <span>
                                                Обновите поиск, чтобы увидеть
                                                актуальные билеты
                                            </span>
                                        </>
                                    }
                                    footer={
                                        <div className="footer_center">
                                            <CustomButton
                                                primary
                                                onClick={() => navigate(-1)}
                                            >
                                                Обновить
                                            </CustomButton>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </section>
    );
};

export default BookingPage;
