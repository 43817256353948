import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    catalog: {},
    currencies: [],
    tariffs: [],
};

export const catalogSlice = createSlice({
    name: "catalog",
    initialState,
    reducers: {
        setCatalog: (state, { payload: { catalog } }) => {
            state.catalog = catalog;
        },
        setCurrencies: (state, { payload: { currencies } }) => {
            state.currencies = currencies;
        },
        setTariffs: (state, { payload: { tariffs } }) => {
            state.tariffs = tariffs;
        },
    },
});

export const { setCatalog, setCurrencies, setTariffs } = catalogSlice.actions;

export default catalogSlice.reducer;
