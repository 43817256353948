import { Accordion } from "../../../ui-kit/Accordion/Accordion";
import styles from "./QA.module.scss";
import questionMark from "../../../assets/icons/questionMark.png";
import Button from "../../../components/UI/Button/Button";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import { Link } from "react-router-dom";

const QA = () => {
    const qaContent = [
        {
            title: "Можете ли вы вкратце описать, в чём заключается ваша услуга?",
            content:
                "Сервис предоставляет бронирование авиабилетов и отелей для подачи заявлений на визу, для прохождения пограничного контроля, для личных целей. Данная услуга предназначена для путешественников, обращающихся за визами, которые требуют подтверждение бронирования авиабилетов и/или отелей. ",
        },
        {
            title: "Мне нужно бронирование авиабилетов для подачи заявления на визу или в аэропорту. Это то, что мне нужно?",
            content:
                "Если Вам необходимо предоставить бронирование авиабилетов для заявления на выдачу визы, прохождения пограничного контроля, то эта услуга – то, что Вам нужно.",
        },
        {
            title: "Что такое бронирование авиабилетов? ",
            content:
                "Бронирование авиабилета (или маршрутная квитанция) – это документ с подробной информацией о Вашем рейсе. Данный документ включает в себя название рейса, время вылета и прилета, даты, код рейса, уникальный код PNR (номер брони). Бронирование авиабилета не является оплаченным билетом.",
        },
        {
            title: "Предоставляете ли вы электронный авиабилет?",
            content: (
                <>
                    • Для тарифов AirSurf 3, AirSurf 7, AirSurf+ бронирование
                    авиабилета не предусматривает номер электронного билета.
                    Сервис предоставляет маршрутную квитанцию для подтверждения
                    бронирования авиабилета.
                    <br />• Для тарифа AirSurf Max сервис предоставляет
                    выкупленный билет, который проверяется на сайте авиакомпании
                    с использованием PNR-кода бронирования и отслеживанием
                    номера билета
                </>
            ),
        },
        {
            title: "Как я могу оформить заказ?",
            content:
                "1. Выберите желаемые маршрут, даты и пассажиров.\n" +
                "    2. Заполните персональные данные.\n" +
                "    3. Выберите нужный тариф.\n" +
                "    4. Оплатите заказ. После оплаты ваш заказ будет обработан автоматически.\n" +
                "    5. Получите оформление бронирование сразу же после успешной оплаты заказа. Скачайте маршрутную квитанцию и бронь отеля на сайте или во вложении к Email-письму.\n" +
                "    6. Используйте маршрутную квитанцию и бронь отеля до даты истечения бронирования.",
        },
        {
            title: "Что такое смарт-маршрут?",
            content:
                'Смарт-маршрут – это так называемая "умная стыковка", так как это уникальный алгоритм поиска, который стыкует рейсы лоукостеров и авиакомпаний из разных альянсов. Механизм сервиса AirSurfer даёт ещё больше возможностей по выбору перелёта.\n\n' +
                "      Если вам предстоит длительный полёт, то Вам вероятнее всего будет необходима пересадка. Не говоря уже о перелете из одной точки мира в другую. Чтобы не искать билеты отдельно на каждый отрезок пути, воспользуйтесь смарт-маршрутами. AirSurfer найдёт единый билет с разным количеством пересадок, а главное — это будет дешевле, чем самостоятельно составленный маршрут или обычный билет с пересадкой. В итоге у вас получится несколько билетов в заказе, который Вы собрали за один поиск!\n\n" +
                "      Поиск авиабилетов работает туда и обратно.",
        },
    ];

    return (
        <section className={styles.qa}>
            <div className="container">
                <div className={styles.qa__wrapper}>
                    <h2 className={styles.qa__title}>Вопросы и ответы</h2>
                    <div className={styles.qa__block}>
                        <Accordion accordionItems={qaContent} />
                        <Link to="/faq">
                            <Button
                                container={`${styles.qa__btn} ${styles.qa__btnMobile}`}
                                text={"Смотреть все"}
                            />
                        </Link>
                        <div className={styles.qa__card}>
                            <img src={questionMark} alt="questionMark" />
                            <h3 className={styles.qa__card_title}>
                                Остались вопросы?
                            </h3>
                            <p className={styles.qa__card_text}>
                                В нашем чате вы можете получить короткие
                                консультации по перелёту и задать общие вопросы
                            </p>
                            <CustomButton
                                id={"qa_btn"}
                                primary={true}
                                classes={styles.qa__card_btn}
                            >
                                Задать вопрос
                            </CustomButton>
                        </div>
                    </div>
                    <Link to="/faq">
                        <CustomButton
                            lined={true}
                            classes={styles.qa__btn}
                            href="/faq"
                        >
                            Смотреть все
                        </CustomButton>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default QA;
