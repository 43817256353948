import { Form, Divider } from 'antd';
import Input from "../Input/Input";
import CustomButton from "../CustomButton/CustomButton";
import CustomDivider from "../CustomDivider/CustomDivider";
import style from "./Form.module.scss";
import React, {useEffect, useState} from "react";
import Google from "../../../assets/icons/google.svg";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import {useGetUserQuery, useLoginMutation} from "../../../services/user";
import {setProfile, setToken, setUser} from "../../../store/slices/user";
import Spinner from "../Spinner/Spinner";
import { BASE_URL } from "../../../api/constants";

const LoginForm = ({onRegisterClick, onRecoverPasswordClick, closeLoginModal}) => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ passwordError, setPasswordError ] = useState('');
  const [ emailError, setEmailError ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isUserLogged, setIsUserLogged] = useState(false);

  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  const  { data: profile  } = useGetUserQuery({}, {skip: !isUserLogged});

  useEffect(() => {
    if (profile){
      dispatch(setProfile({profile: profile.data.profile}));
    }
  }, [profile])

  const onFinish = () => {
    if (!password){
      setPasswordError('Введите пароль!')
    }
    if (!email){
      setEmailError('Введите email!')
    }
    if (isLoading || !email || !password){
      return
    }
    setIsLoading(true);
    if (password && email){
      login({email: email, password: password}).unwrap()
        .then(data => {
          const user = data.data.user;
          const token = data.data.token
          localStorage.setItem('token', token);
          dispatch(setToken({token}));
          dispatch(setUser({user}));
          setIsUserLogged(true);
          closeLoginModal();
          setIsLoading(false);
        })
        .catch(resp => {
          if (resp && resp.data){
            const errors = resp.data.errors;
            setEmailError(errors.email);
            setPasswordError(errors.password);
            setIsLoading(false);
          }
        });
    }
  };

  const onChange = (value, callback) => {
    callback(value);
    setPasswordError("");
    setEmailError("");
  }

  return (
    <Form
      name="login_form"
      className={style.custom_form}
    >
      <Form.Item
        name="username"
      >
        <Input
          placeholder="E-mail"
          value={email}
          error={emailError}
          onChange={(value) => onChange(value, setEmail)}
        />
      </Form.Item>
      <Form.Item
        name="password"
      >
        <Input
          password
          type="password"
          placeholder="Пароль"
          value={password}
          error={passwordError}
          onChange={(value) => onChange(value, setPassword)}
        />
      </Form.Item>
      <Form.Item>
        <a className={style.login_form_forgot} onClick={onRecoverPasswordClick}>
          Забыли пароль?
        </a>
      </Form.Item>

      <Form.Item>
        <CustomButton
          primary
          onClick={() => onFinish()}
          className="login-form-button"
          fullWidth
        >
          {isLoading && (<Spinner/>)}
          Войти
        </CustomButton>
      </Form.Item>
      <Form.Item>
        <CustomDivider>Или</CustomDivider>
      </Form.Item>
      <CustomButton
        linedBlack
        fullWidth
        onClick={() => {
          localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
          window.location.href = `${BASE_URL}auth/google/redirect`
        }}
      >
        <ReactSVG src={Google} className={style.google_icon}/>
        С помощью Google
      </CustomButton>
      <Form.Item className={style.register_link}>
        Ещё нет аккаунта? <a onClick={onRegisterClick}>Зарегистрироваться</a>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
