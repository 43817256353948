import {AccordionItem} from "./AccoridonItem/AccordionItem"
import {useState} from "react"
import styles from './Accordion.module.scss'

const Accordion = ({accordionItems, initialValue = -1}) => {
    const [isOpenFirst, setOpen] = useState(initialValue)

    const openHandler = (id) => {
        if (isOpenFirst === id) {
            return setOpen(-1)
        }

        setOpen(id)
    }

    return (
        <div className={styles.accordion}>
            {accordionItems.map((item, i) => {
                return (
                    <AccordionItem
                        key={i}
                        title={item.title}
                        content={item.content}
                        openHandler={openHandler}
                        index={i}
                        isOpen={isOpenFirst === i}
                    />
                )
            })}
        </div>
    )
}

export {Accordion}
