import React from "react";
import Button from "../Button/Button";

const List = ({ array, container }) => {
  return (
    <div className={container}>
      {array.map((obj, id) => {
        return (
          <Button
            key={id}
            container={obj.container}
            type={obj.type}
            url={obj.url}
            text={obj.text}
          ></Button>
        );
      })}
    </div>
  );
};

export default List;
