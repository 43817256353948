import { Link, useNavigate } from "react-router-dom";
import CustomBreadCrumb from "../../components/UI/CustomBreadCrumb/CustomBreadCrumb";
import styles from "./Partner.module.scss";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import PartnerAbout from "./PartnerAbout/PartnerAbout";
import like from "../../assets/icons/like.svg";
import sandClock from "../../assets/icons/sandClock.svg";
import rocket from "../../assets/icons/rocket.svg";
import glassesEmod from "../../assets/icons/glassesEmod.svg";
import laptopEmod from "../../assets/icons/laptopEmod.svg";
import plane from "../../assets/icons/plane.svg";
import How from "./How/How";
import FAQ from "../BookingSuccess/FAQ/FAQ";
import playIcon from "../../assets/icons/playIcon.svg";
import preview from "../../assets/img/partner_preview.jpg";
import PartnerVideo from "./PartnerVideo/PartnerVideo";
import img1 from "../../assets/icons/partner_1.png";
import img2 from "../../assets/icons/partner_2.png";
import img3 from "../../assets/icons/partner_3.png";
import img4 from "../../assets/icons/partner_4.png";
import img5 from "../../assets/icons/partner_5.png";
import img6 from "../../assets/icons/partner_6.png";
import Popup from "../../components/UI/Popup/Popup";
import BecomeAPartnerForm from "../../components/UI/Forms/BecomeAPartnerForm";
import {useEffect, useState} from "react";
import done from '../../assets/icons/done.png'
import {useDispatch, useSelector} from "react-redux";
import { setModalOpen } from "../../store/slices/headerSlice";
import {BASE_ADMIN_URL} from "../../api/constants";

const Partner = () => {

    const dispatch = useDispatch();

    const userToken = useSelector( state => state.user.token );
    const user = useSelector((state) => state.user.user);
    const [ isPartner, setPartner ] = useState(false);

    const [isPartnerModalVisible ,setPartnerModalVisible] = useState(false);
    const [isPartnerSuccessModal, setPartnerSuccessModal] = useState(false);

    useEffect( () => {
        if( user ){
            setPartner( user.is_partner );
        }
    }, [ user ]);

    const items = [
        { title: <Link to={"/"}>Авиабилеты и отели для визы</Link> },
        { title: <Link to={"/"}>Партнерская программа</Link> },
    ];

    const aboutItems1 = {
        title: "Подойдет",
        items: [
            {
                img: glassesEmod,
                title: "Блогерам, владельцам групп, каналов в соц. сетях",
                text: "В тревел-тематике",
            },
            {
                img: laptopEmod,
                title: "Владельцам сайтов, порталов, форумов ",
            },
            {
                img: plane,
                title: "Путешественникам",
                text: "Которые активно общаются с другими",
            },
        ],
    };
    const aboutItems2 = {
        title: "Зарабатывайте вместе с нами",
        items: [
            {
                img: like,
                title: "Легко применить",
                text: "Просто скопируйте партнерскую ссылку и промокод и поделитесь с аудиторией",
            },
            {
                img: sandClock,
                title: "Срок действия - 30 дней",
                text: "Время жизни cookie-файлов",
            },
            {
                img: rocket,
                title: "Высокая конверсия",
                text: "Инструмент, который приносит прибыль",
            },
        ],
    };

    const howContent = {
        title: "Как это работает",
        items: [
            {
                title: "Создавайте ссылку в два клика",
                text: "Создавайте несколько ссылок и промокодов под разные цели",
                img: img1,
            },
            {
                title: "Настраивайте комиссию под вашу аудиторию",
                text: "Комиссия с каждого заказа составляет от 10%. Вы можете распределить ее между вами и вашей аудитории в удобном формате",
                img: img2,
            },
            {
                title: "Размещайте партнерскую ссылку и делитесь партнерским промокодом",
                text: "С друзьями или в любых медиа-ресурсах - у себя на сайте, в канале, в блоге",
                img: img3,
            },
            {
                title: "Получайте партнерскую комиссию",
                text: "С выводом из личного кабинета в любое время",
                img: img4,
            },
            {
                title: "Отслеживайте статистику",
                text: "Вы можете отслеживать статистику использования и эффективность выбранного инструмента. Эта информация поможет вам увеличить прибыль.",
                img: img5,
            },
            {
                title: "Станьте партнером прямо сейчас!",
                text: "Получайте прибыль за завершенные заказы, сделанные по вашей партнерской ссылке / промокоду",
                img: img6,
            },
        ],
    };

    const navigate = useNavigate();

    return (
        <section className={styles.partner}>
            <Popup
                isPartner
                open={isPartnerModalVisible}
                onCancel={() => setPartnerModalVisible(false) }
                title={<p style={{ textAlign: "left"}}>Стать партнером</p>}
                footer={null}
                afterClose={() => setPartnerSuccessModal(true)}
            >
                <BecomeAPartnerForm
                    onSubmit={(values) => console.log(values)}
                />
            </Popup>

            <Popup
                isFinished
                open={isPartnerSuccessModal}
                onCancel={() => setPartnerSuccessModal(false) }
                title={
                    <>
                        <img
                            className={styles.partner__img}
                            src={done}
                            alt="icon"
                        />
                        <p className={styles.partner__modalTitle}>
                            Заявка отправлена!
                        </p>
                        <span className={styles.partner__text}>
                            После обработки вашей заявки вы будет подключены к
                            партнерской программы, если у вас появились проблемы
                            с подключением напишите нам на{" "}
                            <a href="mailto:support@airsurfer.co">
                                <span>support@airsurfer.co</span>
                            </a>
                        </span>
                    </>
                }
                footer={
                    <CustomButton
                        CustomButton
                        primary
                        fullWidth
                        onClick={() => {
                            setPartnerSuccessModal(false);
                            navigate("/");
                        }}
                    >
                        На главную
                    </CustomButton>
                }
            ></Popup>
            <div className={styles.partner__top}>
                <div className="container">
                    <CustomBreadCrumb white items={items} />
                </div>
                <div className={styles.partner__container}>
                    <div className={styles.partner__header}>
                        <h1 className={styles.partner__title}>
                            Партнерская программа{" "}
                            <br className={styles.partner__mobile} />
                            AirSurfer
                        </h1>
                        <p className={styles.partner__subtitle}>
                            Получайте прибыль за завершенные заказы, сделанные
                            по вашей партнерской ссылке / промокоду
                        </p>
                        <div className={styles.partner__header_btn}>
                            {
                                userToken ? (
                                    <CustomButton
                                        primary
                                        onClick={() => setPartnerModalVisible(true)}
                                    >
                                        Стать партнером
                                    </CustomButton>
                                ) : (
                                    <CustomButton
                                        primary
                                        onClick={() => dispatch( setModalOpen(true) )}
                                    >
                                      Стать партнером
                                    </CustomButton>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.partner__container}>
                <PartnerAbout
                    items={aboutItems1.items}
                    title={aboutItems1.title}
                />
                <PartnerAbout
                    items={aboutItems2.items}
                    title={aboutItems2.title}
                />
                <How userToken={userToken} setPartnerModalVisible={setPartnerModalVisible} items={howContent.items} title={howContent.title} />
            </div>
            <div className={styles.partner__video}>
                <div className={styles.partner__container}>
                    <PartnerVideo
                        playIcon={playIcon}
                        preview={preview}
                        url={`${BASE_ADMIN_URL}storage/7679412e9d821266.mp4`}
                    />
                </div>
            </div>
            <FAQ title="Вопросы и ответы" />
            <div className={styles.partner__container}>
                <div className={styles.partner__member}>
                    <h3 className={styles.partner__member_title}>
                        Зарабатывайте с помощью партнерской программы AirSurfer
                    </h3>
                    <div className={styles.partner__member_group}>
                      {
                        userToken ? (
                          <CustomButton
                            secondary
                            onClick={() => setPartnerModalVisible(true)}
                          >
                            Стать партнером
                          </CustomButton>
                        ) : (
                          <CustomButton
                            secondary
                            onClick={() => dispatch( setModalOpen(true) )}
                          >
                            Стать партнером
                          </CustomButton>
                        )
                      }
                        <img src={img6} alt={"icon"} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partner;
