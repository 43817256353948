import { baseApi } from "api";

export const ticketsApi = baseApi({
    reducerPath: "ticketsApi",
    endpoints: (builder) => ({
        getTickets: builder.query({
            query: (params) => ({
                url: `flights`,
                method: "GET",
                params,
            }),
        }),
        getAirports: builder.query({
            query: (airport) => ({
                url: `airports`,
                method: "GET",
                params: airport,
            }),
        }),
        saveCache: builder.mutation({
            query: (data) => ({
                url: `cache`,
                method: "POST",
                body: data,
            }),
        }),
        getCache: builder.query({
            query: (id) => ({
                url: `cache`,
                method: "GET",
                params: id,
            }),
        }),
    }),
});

export const { useGetTicketsQuery, useGetAirportsQuery, useSaveCacheMutation, useGetCacheQuery } = ticketsApi;
