import styles from "./PassengersItem.module.scss"
import icon from "../../../../assets/icons/alet.png"
import clsx from "clsx"

const PassengersItem = ({
    name,
    ticketType,
    birthDate,
    citizenship,
    sex,
    documentNumber,
    profile,
}) => {
    return (
        <div
            className={clsx(styles.passengers, {
                [styles.passengers__profile]: profile,
            })}
        >
            <div className={styles.passengers__top}>
                <h4 className={styles.passengers__top_title}>{name}</h4>
                <p className={styles.passengers__top_subtitle}>{ticketType}</p>
                <img src={icon} alt="icon" />
            </div>
            <div className={styles.passengers__content}>
                <div className={styles.passengers__content_item}>
                    <h4 className={styles.passengers__content_title}>
                        Дата рождения:
                    </h4>
                    <p className={styles.passengers__content_text}>
                        {birthDate}
                    </p>
                </div>
                <div className={styles.passengers__content_item}>
                    <h4 className={styles.passengers__content_title}>
                        Гражданство:
                    </h4>
                    <p className={styles.passengers__content_text}>
                        {citizenship}
                    </p>
                </div>
                <div className={styles.passengers__content_item}>
                    <h4 className={styles.passengers__content_title}>Пол:</h4>
                    <p className={styles.passengers__content_text}>{sex}</p>
                </div>
                <div className={styles.passengers__content_item}>
                    <h4 className={styles.passengers__content_title}>
                        Документ:
                    </h4>
                    <p className={styles.passengers__content_text}>
                        {documentNumber}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PassengersItem
