import { Form } from "antd";
import style from "./Form.module.scss";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "store/slices/headerSlice";
import { availableLangs, availableName } from "services/lang";
import { selectLang } from "store/selectors/header";

const LangForm = ({ closeModal }) => {
    const dispatch = useDispatch();
    const currentLang = useSelector(selectLang);

    function yaTranslateSetLang(lang) {
        // Записываем выбранный язык в localStorage объект yt-widget
        // Writing the selected language to localStorage
        dispatch(setLang({ lang }));
    }

    const onChangeLang = (value) => {
        return () => {
            yaTranslateSetLang(value);
            // Перезагружаем страницу
            // Reloading the page
            closeModal();
            window.location.reload();
        };
    };

    return (
        <Form name="currency_form" className={style.custom_form}>
            <div className="lang__list" data-lang-list="">
                {Object.entries(availableLangs).map((item) => {
                    const Flag = item[1];
                    const name = availableName[item[0]];
                    const active = item[0] == currentLang;
                    return (
                        <div
                            className={`lang__link lang__link_sub ${
                                active ? "active" : ""
                            }`}
                            onClick={onChangeLang(item[0])}
                            translate="no"
                        >
                            <Flag />
                            <span>{name}</span>
                        </div>
                    );
                })}
            </div>
        </Form>
    );
};
export default LangForm;
