import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import styles from "./Documents.module.scss";
import plane from "../../../assets/icons/plane-doc.svg";
import house from "../../../assets/icons/home-doc.svg";
import fileComplete from "../../../assets/icons/fileComplete.svg";
import { ReactSVG } from "react-svg";
import clsx from "clsx";

const Documents = ({ hotelLink, flightLink, issuing, flightBookingFile, hotelBookingFile }) => {

    return (
        <div
            className={clsx(styles.documents, {
                [styles.documents__issuing]: issuing,
            })}
        >
            <h3 className={styles.documents__title}>Документы</h3>
            {issuing ? (
                <div className={styles.status__issued}>
                    <div className={styles.status__issued_doc}>
                        <ReactSVG src={fileComplete} />
                        <p className={styles.status__issued_text}>
                            Документы будут доступны в ближайшее время
                        </p>
                    </div>
                </div>
            ) : (
                <>
                    <p className={styles.documents__text}>
                        Скачать документы:
                    </p>
                    <div className={styles.documents__buttons}>
                        {flightBookingFile ? (
                            <CustomButton
                                classes={styles.documents__btn}
                                linedBlack={true}
                                onClick={flightLink}
                            >
                                <ReactSVG src={plane} />
                                Маршрутная квитанция
                            </CustomButton>
                        ) : null }
                        {hotelBookingFile ? (
                            <CustomButton
                                classes={styles.documents__btn}
                                linedBlack={true}
                                onClick={hotelLink}
                            >
                                <ReactSVG src={house} /> Бронь отеля
                            </CustomButton>
                        ) : null }
                    </div>
                </>
            )}
        </div>
    );
};

export default Documents;
