import {Link} from "react-router-dom";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import styles from "./NotPaid.module.scss";

const NotPaid = ({linkTo, message, booking}) => {

    return (
        <div className={styles.bookingSuccess__top}>
            <h1 className={styles.bookingSuccess__top_title}>
                Заказ не оформлен
            </h1>
            <p className={styles.bookingSuccess__top_text}>
                {message ?? 'Поменять на сайте:\n' +
                    'К сожалению, при оплате заказа произошла ошибка. Попробуйте оплатить снова по кнопке ниже или напишите в поддержку.'}
            </p>
            <div className={styles.bookingSuccess__top_buttons}>
                {message
                    ? (
                        <div className={styles.main__wrapper}>
                            <div>Напишите в поддержку</div>
                        </div>
                    )
                    : (
                        <Link to={linkTo}>
                            <CustomButton primary={true}>Перейти к оплате</CustomButton>
                        </Link>
                    )
                }

            </div>
        </div>
    );
};

export default NotPaid;
