import {useState,useEffect} from "react"
import styles from "./Comment.module.scss"

const Comment = ({ values,setFieldValue }) => {
    
    useEffect(() => {
        setFieldValue("customer.comment", values.customer.comment);
    }, [values]);

    const handleTextareaChange = (event) => {
        setFieldValue("customer.comment",event.target.value)
    }
    
    return (
        <div className={styles.comment}>
            <h2 className={styles.comment__title}>Комментарий к заказу</h2>
            <Textarea
                name={"comment"}
                value={values.customer.comment}
                onChange={handleTextareaChange}
                classes={styles.comment__textarea}
                placeholder="Комментарий"
                setFieldValue={setFieldValue}
            />
        </div>
    )
}

function Textarea(props) {
    return (
        <textarea
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            className={props.classes}
            placeholder={props.placeholder}
        />
    )
}

export default Comment
