import style from "./Links.module.scss";
import {Link} from "react-router-dom";

const LinkItem = ({linkItem}) => {
  const {id, title, url, promo_code, commission, discount, total_commission} = linkItem;
  return (
    <div className={style.link} key={id}>
      <div className={style.link_header}>
        <div className={style.link_header_info}>
          <Link to={`/profile/links/${id}`}>
            <p className={style.link_name}>{title}</p>
            <p className={style.link_link}>{url}</p>
          </Link>
        </div>
      </div>
      <div className={style.link_info}>
        <div className={style.link_info_item}>
          <div className={style.link_info_item_title}>
            Промокод
          </div>
          <div className={style.link_info_item_value}>
            {promo_code}
          </div>
        </div>
        <div className={style.link_info_item}>
          <div className={style.link_info_item_title}>
            Комис.себе
          </div>
          <div className={style.link_info_item_value}>
            {commission}%
          </div>
        </div>
        <div className={style.link_info_item}>
          <div className={style.link_info_item_title}>
            Комис.клиента
          </div>
          <div className={style.link_info_item_value}>
            {discount}%
          </div>
        </div>
        <div className={style.link_info_item}>
          <div className={style.link_info_item_title}>
            Сумма комиссии
          </div>
          <div className={style.link_info_item_value}>
            {total_commission} ₽
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkItem