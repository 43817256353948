import style from "./Tickets.module.scss";
import CustomSlider from "../../components/UI/CustomSlider/CustomSlider";

const TimeFilters = ({sliders, timeFilters, onChange}) => {
  const renderTime = (time) => {
    if (time === 24){
      return "23:59";
    }
    let newTime = time;
    if (time < 10){
      newTime = "0" + time;
    }
    return newTime + ":00";
  }

  return(
    <div className={style.time_sliders}>
      {
        sliders.map((slider, index) => (
          <div className={style.time_sliders_item}>
            {slider.title}
            <p className={style.time_sliders_item_name}>{slider.name}</p>
            <div className={style.time_sliders_item_time}>
              <span>{renderTime(timeFilters[index][0])}</span>
              <span>{renderTime(timeFilters[index][1])}</span>
            </div>
            <CustomSlider
              key={slider.id}
              value={timeFilters[index]}
              min={0}
              max={24}
              range
              onChange={(value) => onChange(value, index)}
              className={style.time_sliders_item_slider}
            />
          </div>
        ))
      }
    </div>
  )
}

export default TimeFilters

