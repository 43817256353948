import style from "./Balance.module.scss";
import { format, parseISO } from "date-fns";
import { Pin } from "components/UI/Pin";
import { orderBy } from "lodash";
import ProfilePageHeader from "../ProfilePageHeader";
import BalanceIcon from "../../../assets/icons/balance.png";
import CustomTable from "../../../components/UI/CustomTable/CustomTable";
import React, { useState } from "react";
import classNames from "classnames";
import Sorting from "../Sorting/Sorting";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import MoneyWithdrawResultPopup from "./Popups/MoneyWithdrawResultPopup";
import MoneyWithdrawPopup from "./Popups/MoneyWithdrawPopup";
import { switchIsOpened } from "../../../store/slices/sorting";
import { useDispatch } from "react-redux";
import MobileBalanceItem from "./MobileBalanceItem";
import { useHidePage } from "../../../hooks/useHidePage";
import {
    useGetPartnersTakeOutsQuery,
    useGetPartnersBalanceQuery,
} from "services/partners";
import { statuses } from "./Balance.constants";

const columns = [
    {
        title: "Куда",
        dataIndex: "cardNumber",
        render: (_, record) => {
            return (
                <>
                    <p className={style.balance_page_name}>
                        {record.takeoutable_type === "credit-card"
                            ? "Карта"
                            : "Расчетный счет"}
                    </p>
                    <p className={style.balance_page_link}>
                        {record.takeoutable_type === "credit-card"
                            ? record.takeoutable?.card_number
                            : record.takeoutable?.bank_user_account}
                    </p>
                </>
            );
        },
    },
    {
        title: "Статус операции",
        dataIndex: "status",
        sorter: (a, b) => statuses[a.status].localeCompare(statuses[b.status]),
        render: (value) => {
            return <Pin status={value}> {statuses[value]}</Pin>;
        },
    },
    {
        title: "Дата и время",
        dataIndex: "date",
        sorter: {
            compare: (a, b) => {
                return new Date(a.updated_at) - new Date(b.updated_at);
            },
        },
        render: (_, record) => {
            return record.updated_at
                ? format(parseISO(record.updated_at), "dd.MM.yyyy в HH:mm")
                : "";
        },
    },
    {
        title: "Сумма",
        dataIndex: "amount",
        sorter: (a, b) => a.amount - b.amount,
        render: (value) => {
            return <span className={style.sum}>{value} ₽</span>;
        },
    },
];

const sortingNames = {
    amount: "Сумма",
    updated_at: "Дата и время",
    status: "Статус",
};

const Balance = () => {
    const [sorting, setSorting] = useState({
        updated_at: null,
        amount: null,
        status: null,
    });
    const [isSortingVisible, setIsSortingVisible] = useState(false);
    const [isMoneyWithdrawModalVisible, setIsMoneyWithdrawModalVisible] =
        useState(false);
    const [
        isMoneyWithdrawResultModalVisible,
        setMoneyWithdrawResultModalVisible,
    ] = useState(false);
    const [page, setPage] = useState(1);

    const {
        data: partnersTakeOuts,
        isLoading,
        isError,
    } = useGetPartnersTakeOutsQuery(page);
    const { data: partnersBalance } = useGetPartnersBalanceQuery();

    const dispatch = useDispatch();

    const hideBalancePage = useHidePage();

    const onChangeTable = (pagination, filters, sorter) => {
        console.info(sorter);
    };

    if (hideBalancePage && isSortingVisible) {
        return (
            <Sorting
                sorting={sorting}
                sortingNames={sortingNames}
                onCancel={() => {
                    setIsSortingVisible(false);
                    dispatch(switchIsOpened(false));
                }}
                setSorting={setSorting}
            />
        );
    }

    return (
        <div className={style.balance_page}>
            <ProfilePageHeader
                title={
                    <span>
                        Текущий баланс -{" "}
                        <span className={style.current_balance}>
                            {partnersBalance} ₽
                        </span>{" "}
                    </span>
                }
                icon={BalanceIcon}
                buttons={[
                    {
                        text: "Вывести средства",
                        primary: true,
                        onClick: () => setIsMoneyWithdrawModalVisible(true),
                    },
                ]}
            />

            {!isLoading && !isError && !partnersTakeOuts.data?.length && (
                <>
                    <p className={style.balance_page_empty}>
                        По балансу пока нет истории вывода
                    </p>
                    <p className={style.balance_page_empty_text}>
                        Сделайте первый вывод средства для отображения истории
                        выводов
                    </p>
                </>
            )}
            {!isLoading && !isError && !!partnersTakeOuts.data?.length && (
                <>
                    <p className={style.balance_page_title}>История вывода:</p>
                    <CustomButton
                        lined
                        classes={style.balance_page_sort_button}
                        onClick={() => {
                            setIsSortingVisible(true);
                            dispatch(switchIsOpened(true));
                        }}
                        small
                    >
                        Сортировка
                    </CustomButton>

                    <div className={style.balance_page_table}>
                        <CustomTable
                            onChange={onChangeTable}
                            columns={columns}
                            dataSource={partnersTakeOuts.data}
                        />
                    </div>
                    <div className={style.balance_page_list}>
                        {!!partnersTakeOuts?.data?.length &&
                            orderBy(
                                partnersTakeOuts?.data,
                                [
                                    sorting.amount ? "amount" : "",
                                    sorting.updated_at
                                        ? (item) => new Date(item.updated_at)
                                        : "",
                                    sorting.status
                                        ? (item) => statuses[item.status]
                                        : "",
                                ].filter(Boolean),
                                [Object.values(sorting).filter(Boolean)]
                            )?.map((balanceItem) => (
                                <MobileBalanceItem
                                    key={balanceItem.key}
                                    balanceItem={balanceItem}
                                />
                            ))}
                    </div>
                    <CustomButton
                        linedBlack
                        small
                        classes={style.balance_page_show_more}
                        disabled={
                            isLoading ||
                            isError ||
                            partnersTakeOuts.currentPage >=
                                partnersTakeOuts.lastPage
                        }
                        onClick={() => setPage((page) => page + 1)}
                    >
                        Показать больше
                    </CustomButton>
                    <div
                        className={classNames(style.balance_page_sorting, {
                            [style["balance_page_sorting_visible"]]:
                                isSortingVisible,
                        })}
                    >
                        <Sorting
                            sorting={sorting}
                            sortingNames={sortingNames}
                            onCancel={() => {
                                setIsSortingVisible(false);
                                dispatch(switchIsOpened(false));
                            }}
                            setSorting={setSorting}
                        />
                    </div>
                </>
            )}

            <MoneyWithdrawPopup
                visible={isMoneyWithdrawModalVisible}
                setVisibility={setIsMoneyWithdrawModalVisible}
                setResultVisibility={setMoneyWithdrawResultModalVisible}
            />

            <MoneyWithdrawResultPopup
                visible={isMoneyWithdrawResultModalVisible}
                setVisibility={setMoneyWithdrawResultModalVisible}
            />
        </div>
    );
};

export default Balance;
