import styles from "./TransferInfoHeader.module.scss";
import {useSelector} from "react-redux";

const TransferInfoHeader = ({
    time = "",
    isBack,
    departureCity = "",
    arrivalCity = ""
}) => {
  let totalSeconds  = time;
  let hours = Math.floor(totalSeconds / 60);
  let minutes = totalSeconds %= 60;
  const formattedTime = hours + 'ч ' + minutes + 'м'
  const urlParams = new URLSearchParams(window.location.search);
  const cityFrom = useSelector((state) => state.tickets.cityFrom) || (urlParams.get('city_from') ? urlParams.get('city_from') : departureCity);
  const cityTo = useSelector((state) => state.tickets.cityTo) || (urlParams.get('city_to') ? urlParams.get('city_to') : arrivalCity);
  
    return (
        <div className={styles.transfer}>
            <p className={styles.transfer__direction}>
                {!isBack
                    ? `${cityFrom} → ${cityTo}`
                    : `${cityTo} → ${cityFrom}`}
            </p>
            <p className={styles.transfer__time}>{formattedTime} в пути</p>
        </div>
    );
};

export default TransferInfoHeader;
