import styles from "./SingleBreadCrumb.module.scss";
import ArrowIcon from "../../../assets/icons/leftArrow.svg";
import { Link } from "react-router-dom";
import classNames from "classnames";

const SingleBreadCrumb = ({ title, path, classes, onClick }) => {
    return (
        <Link className={classNames(styles.crumb, classes)} to={path} onClick={onClick}>
            <img
                className={styles.crumb__separator}
                alt="separator"
                src={ArrowIcon}
            />
            {title}
            <p className={styles.crumb__title}></p>
        </Link>
    );
};

export default SingleBreadCrumb;
