import styles from "./Сooperation.module.scss"
import emirates from "../../../assets/icons/emirates.png"
import singapore from "../../../assets/icons/singapore.png"
import airfrance from "../../../assets/icons/airfrance.png"
import lufthansa from "../../../assets/icons/lufthansa.png"
import britishAirways from "../../../assets/icons/britishAirways.png"
import klm from "../../../assets/icons/klm.png"

const Сooperation = () => {
    const cooperationContent = [
        {
            title: "emirates",
            img: emirates,
            key: 0,
        },
        {
            title: "singapore",
            img: singapore,
            key: 1,
        },
        {
            title: "airfrance",
            img: airfrance,
            key: 2,
        },
        {
            title: "emirates",
            img: lufthansa,
            key: 3,
        },
        {
            title: "emirates",
            img: britishAirways,
            key: 4,
        },
        {
            title: "emirates",
            img: klm,
            key: 5,
        },
    ]

    return (
        <section className={styles.coop}>
            <div className="container">
                <div className={styles.coop__wrapper}>
                    <h2 className={styles.coop__title}>
                        Мы сотрудничаем с 450+ <span>проверенными </span>
                        {""}
                        авиакомпаниями
                    </h2>
                    <div className={styles.coop__items}>
                        {cooperationContent.map((item) => {
                            return (
                                <div
                                    key={item.key}
                                    className={styles.coop__item}
                                >
                                    <img
                                        className={styles.coop__item_img}
                                        alt={item.title}
                                        src={item.img}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Сooperation
