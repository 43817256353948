import Button from "../../../components/UI/Button/Button"
import styles from "./Partner.module.scss"
import firstImg from "../../../assets/icons/partner_6.png"
import secondImg from "../../../assets/icons/second-partner.png"
import appStore from "../../../assets/icons/appStore-partner.png"
import googlePlay from "../../../assets/icons/googlePlay-partner.png"
import {useNavigate} from "react-router";

const Partner = () => {
  const navigate = useNavigate();
    return (
        <section className={styles.partner}>
            <div className="container">
                <div className={styles.partner__wrapper}>
                    <div className={styles.partner__card}>
                        <h3 className={styles.partner__card_title}>
                            Партнерская программа
                        </h3>
                        <p className={styles.partner__card_subtitle}>
                            Зарабатывайте вместе с трэвел-сервисом по бронированию авиабилетов и отелей для визы
                            Партнерская программа AirSurfer подойдёт для блогеров, владельцов сайтов, веб-мастеров,
                            турагентов, отелей, сервисов и других проектов, которые хотят зарабатывать в сфере путешествий.
                        </p>
                        <Button
                            onClick={() => navigate('/partner')}
                            container={styles.partner__card_btn}
                            text={"Подробнее"}
                        />
                        <img
                            className={styles.partner__card_firstImg}
                            src={firstImg}
                            alt="icons"
                        />
                    </div>
                    <div className={styles.partner__card}>
                        <h3 className={styles.partner__card_title}>
                            Мобильное приложение
                        </h3>
                        <p className={styles.partner__card_subtitle}>
                            Устанавливайте приложение AirSurfer и оформляйте бронь прямо в аэропорту
                        </p>
                        <div className={styles.partner__card_icons}>
                            <div className={styles.partner__icon}>
                                <img
                                    className={styles.partner__icon_img}
                                    src={appStore}
                                    alt="appStore"
                                />
                            </div>
                            <div className={styles.partner__icon}>
                                <img
                                    className={styles.partner__icon_img}
                                    src={googlePlay}
                                    alt="googleStore"
                                />
                            </div>
                        </div>
                        <img
                            className={styles.partner__card_secondImg}
                            src={secondImg}
                            alt="icons"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partner
