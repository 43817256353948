import styles from "./PartnerAbout.module.scss"

const PartnerAbout = ({title, items}) => {
    return (
        <div className={styles.about}>
            <h2 className={styles.about__title}>{title}</h2>
            <div className={styles.about__content}>
                {items?.map((item, i) => {
                    return (
                        <div key={i} className={styles.about__content_item}>
                            <img src={item.img} alt="item.title" />
                            <h3 className={styles.about__content_title}>
                                {item.title}
                            </h3>
                            <p className={styles.about__content_text}>
                                {item.text}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PartnerAbout
