import plane from "../../assets/icons/plane.png"
import CustomButton from "../../components/UI/CustomButton/CustomButton"
import styles from "./Tickets.module.scss"

const NotFound = ({resetFilters, filtersChanged}) => {
    return (
        <div className={styles.notFound}>
            <img className={styles.notFound__img} src={plane} alt="planeIcon" />
            <h2 className={styles.notFound__title}>
                По вашему запросу ничего не нашлось
            </h2>
          {filtersChanged ? (
            <p className={styles.notFound__text}>
                Попробуйте скорректировать условия поиска или сбросить фильтры
            </p>
          ) : (
              <p className={styles.notFound__text}>
                Попробуйте скорректировать условия поиска, изменив даты вылета и прилета, количество пассажиров
              </p>
            )
          }
          {filtersChanged && (
            <CustomButton lined onClick={resetFilters} classes={styles.notFound__btn}>
                Сбросить фильтры
            </CustomButton>
          )}
        </div>
    )
}

export default NotFound
