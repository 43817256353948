import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tickets: [],
  loading: true,
  cityFrom: '',
  cityTo: '',
  airportFromFullName: '',
  airportToFullName: '',
  timeoutError: false,
  airlines: [],
  timeLimits: []
}

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTickets: (state, {payload: {tickets}}) => {
      state.tickets = tickets;
    },
    setLoading: (state, {payload: {loading}}) => {
      state.loading = loading;
    },
    setBookingTickets: (state, {payload: {tickets}}) => {
      state.bookingTickets = tickets;
    },
    deleteBookingTickets: (state) => {
      state.bookingTickets = null;
    },
    setCityFrom: (state, {payload: {cityFrom}}) => {
      state.cityFrom = cityFrom;
    },
    setCityTo: (state, {payload: {cityTo}}) => {
      state.cityTo = cityTo;
    },
    setAirportFromFullName: (state, {payload: {airportFromFullName}}) => {
      state.airportFromFullName = airportFromFullName;
    },
    setAirportToFullName: (state, {payload: {airportToFullName}}) => {
      state.airportToFullName = airportToFullName;
    },
    setTimeoutError: (state, {payload: {timeoutError}}) => {
      state.timeoutError = timeoutError;
    },
    setAirlines: (state, {payload: {airlines}}) => {
      state.airlines = airlines;
    },
    setTimeLimits: (state, {payload: {timeLimits}}) => {
      state.timeLimits = timeLimits;
    },
  },
})

export const {
  setTickets,
  setLoading,
  setBookingTickets,
  deleteBookingTickets,
  setCityFrom,
  setCityTo,
  setAirportFromFullName,
  setAirportToFullName,
  setTimeoutError,
  setAirlines,
  setTimeLimits
} = ticketsSlice.actions

export default ticketsSlice.reducer
