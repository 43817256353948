import CustomBreadCrumb from "components/UI/CustomBreadCrumb/CustomBreadCrumb";
import Policy from "pages/Policy/Policy";
import { Link } from "react-router-dom";
import styles from "./Contact.module.scss";

const Contacts = () => {
    const policyItems = {
        title: "Контакты",
        items: [
            {
                title: "Реквизиты",
                description: (
                    <>
                        <p> ИП Гнатюк Анатолий Дмитриевич</p>

                        <p>
                            Почтовый адрес: 357430, Россия, Ставропольский край,
                            г. Железноводск, п. Иноземцево
                        </p>

                        <p>ИНН: 262702531585</p>

                        <p>ОГРНИП: 323265100162401</p>
                    </>
                ),
            },
            {
                title: "Контакты",
                description: (
                    <>
                        {/* <p>Телефон: +7 (8652) 97-77-85</p> */}
                        <p>
                            Почта:{" "}
                            <a href="mailto:support@airsurfer.co">
                                support@airsurfer.co
                            </a>
                        </p>
                        <p>
                            <a href="https://t.me/airsurfer_bot">
                                Telegram-бот
                            </a>
                        </p>
                    </>
                ),
            },
        ],
    };

    const items = [
        { title: <Link to={"/"}>Авиабилеты и отели для визы</Link> },
        { title: "Контакты" },
    ];
    return (
        <div className={styles.return}>
            <div className="container">
                <CustomBreadCrumb items={items} />

                <div className={styles.return__wrapper}>
                    <Policy
                        title={policyItems.title}
                        items={policyItems.items}
                    />
                </div>
            </div>
        </div>
    );
};

export default Contacts;
