import { useEffect } from "react";

function useOutsideClick(ref, callback, inputRef1, inputRef2) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                ref?.current &&
                !ref?.current.contains(event.target) &&
                !inputRef1?.current.contains(event.target) &&
                (!inputRef2 || inputRef2 && !inputRef2?.current.contains(event?.target))
            ) {
                callback();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default useOutsideClick;