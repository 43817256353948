import React from "react";
import style from "./RadioLabels.module.scss";

const RadioLabels = ({ title, subtitle, active, onClick, value }) => {
    return (
        <div className={`${style.radio_label_wrapper} `}>
            <div
                className={`${style.radio_label__inner} ${
                    active && style.active
                }`}
                onClick={() => {
                    onClick(value);
                }}
            >
                <span className={style.radio_label__title}>{title}</span>
                <br />
                <span className={style.radio_label__subtitle}>{subtitle}</span>
            </div>
        </div>
    );
};

export default RadioLabels;
