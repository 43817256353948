import * as Yup from "yup";
import {makeDate} from "../../../utils/makeDate";

const phoneRegex = /\(\d{3}\) \d{3}-\d{2}-\d{2}$/;
// const documentRegex = /^[0-9]{4} [0-9]{6}$/;
const documentRegex = /^[0-9]{9}$/;

const today = new Date();
today.setHours(0, 0, 0, 0);

function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
}

export const validation1 = Yup.object().shape({
    email: Yup.string()
        .required("Поле обязательно"),
    phone_prefix: Yup.string().required("Поле обязательно"),
    phone: Yup.string()
        .required("Поле обязательно"),
    country: Yup.string().required("Поле обязательно"),
    firstname: Yup.string()
        .matches(
            /^[A-Za-z]+$/,
            "Имя должно содержать только буквы латинского алфавита"
        )
        .required("Поле обязательно"),
    lastname: Yup.string()
        .matches(
            /^[A-Za-z]+$/,
            "Фамилия должна содержать только буквы латинского алфавита"
        )
        .required("Поле обязательно"),
    birthday: Yup.string().required("Поле обязательно")
      .test("maxDate", "Возраст должен быть не менее 12 лет", function(value){
        const date = makeDate(value);
        const now = new Date();
        const maxDate = subtractYears(now, 12);
        return date <= maxDate;
    }),
    gender: Yup.string().required("Поле обязательно"),
    // document_expires: Yup.string().test("required", "Поле обязательно", function(value) {
    //     return !!value || this.parent.termNotSpecified;
    // }).test("minDate", "Дата должна быть не ранее сегодняшнего числа", function(value) {
    //     if (value && !this.parent.termNotSpecified){
    //         const date = makeDate(value)
    //         return value && date >= today;
    //     } else {
    //         return true
    //     }
    // }),
    document_number: Yup.string()
        .required("Поле обязательно"
        ).test("document_number", "Номер документа должен состоять из 9 цифр", function(value) {
            if (this.parent.country === 'RU' || this.parent.document_type === 'FOREIGN'){
                return documentRegex.test(value)
            } else {
                return true
            };
        }),
        // .matches(
        //     documentRegex,
        //     "Номер документа должен состоять из 9 цифр"
        // ),
    document_expires: Yup.string().test("required", "Поле обязательно", function(value) {
        if (this.parent.country === 'RU' && this.parent.document_type === 'FOREIGN'){
            return !!value
        } else {
            return true
        };
    })
});

export const validation2 = Yup.object().shape({
    current_password: Yup.string().required("Введите старый пароль"),
    password: Yup.string()
        .required("Введите новый пароль")
        .min(6, "Пароль должен содержать не менее 6 символов")
        .max(20, "Пароль должен содержать не более 20 символов")
        .test("match", "Новый пароль не должен совпадать со старым паролем", function(value) {
            return value !== this.parent.oldPassword;
        }),
    password_confirmation: Yup.string()
      .test("match", "Пароли должны совпадать", function(value) {
          return value === this.parent.password;
      }),
});
