import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import style from "./Passengers.module.scss";
import React, {useEffect, useState} from "react";
import ImageProfile from "../../../assets/icons/image-profile.png";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import Passenger from "./Passenger";
import ProfilePageHeader from "../ProfilePageHeader";
import {useGetPassengersQuery} from "../../../services/passengers.js";
import {setPassengers} from "../../../store/slices/passengers.js";
import Spinner from "../../../components/UI/Spinner/Spinner";

export const makeArrayFromObject = (obj, prefix) => {
    const arr = [];
    for (let key of Object.keys(obj)){
        const label = prefix ? prefix + obj[key] : obj[key]
        arr.push({value: key, label})
    }
    return arr
}

const Passengers = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ page, setPage ] = useState( 1 );
    // const passengers = useSelector((state) => state.passengers.passengers);

    const [ passengers, setPassengers ] = useState( [] );

    const catalog = useSelector((state) => state.catalog.catalog);
    const user = useSelector((state) => state.user.user);
    const [ citizenship, setCitizenship ] = useState(null);
    const  { data: passengersQuery, error, isLoading, refetch, isFetching } = useGetPassengersQuery( page );

    const [ hasLoadedAll, setHasLoadedAll ] = useState(false);
    useEffect( () => {
        if ( catalog ){
            setCitizenship( makeArrayFromObject( catalog.countries ) );
        }
    }, [ catalog ] );

    useEffect( () => {
        if ( citizenship && !isLoading ) {
            if ( passengersQuery ){
                // dispatch( setPassengers( passengersQuery.data ) );
                setPassengers( passengersQuery.data );
            }
            setHasLoadedAll(true);
        }
    }, [ citizenship, isLoading ] );

    useEffect( () => {
        if ( passengersQuery ){
            // dispatch( setPassengers( [ ...passengers, ...passengersQuery.data ] ) );
            setPassengers( [ ...passengers, ...passengersQuery.data ] );
        }

    }, [passengersQuery] );

    useEffect( () => {
        refetch();
    }, [page] );

    const loadingMorePassenger = () => {
        setPage( page+1 );
    }

    return (
        <div className={style.passengers_page}>
            {
                error && user?.email_verified && (
                    <>
                        <h1>Не удалось загрузить пассажиров</h1>
                    </>
                )
            }
            {
              (!error || (error && !user?.email_verified)) && hasLoadedAll && (
                    <>
                        <ProfilePageHeader
                            title={passengers.length ? "Пассажиры" : "Пассажиров пока нет"}
                            description={!passengers.length ? "Создайте пассажиров, чтобы быстро добавлять их в новые бронирования" : ""}
                            icon={ImageProfile}
                            buttons={[
                                  {
                                      text: passengers.length ? "Добавить" : "Создать",
                                      onClick: () => navigate("/profile/passengers/add"),
                                      primary: true,
                                      disabled: !user?.email_verified
                                  }]
                            }
                        />
                        <div className={style.passengers_page_container}>
                            {passengers.map(passenger => (
                                <Passenger passenger={passenger} citizenship={citizenship} key={`passenger-${passenger.id}`} />
                            ))}
                        </div>
                    </>
                )
            }
            {user && !user?.email_verified && (
              <div className={style.passengers_page__not_verified}>
                Подтвердите свой email
              </div>
            )}
            {
                isLoading || isFetching || !hasLoadedAll ? (
                    <Spinner />
                ) : ''
            }
            {
                !error && !isFetching && hasLoadedAll && passengersQuery.meta && passengersQuery.meta.to < passengersQuery.meta.total  ? (
                    <CustomButton
                        linedBlack small classes={style.passengers_page_show_more}
                        onClick={ () => loadingMorePassenger() }
                    >
                        Показать больше
                    </CustomButton>
                ) : ''
            }
        </div>
    )
}

export default Passengers;
