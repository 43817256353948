import styles from "./Passengers.module.scss"
import PassengersItem from "./PassengersItem/PassengersItem"

const Passengers = ({passengers}) => {
    return (
        <div className={styles.passengers}>
            <h3 className={styles.passengers__title}>Пассажиры</h3>
            {passengers.map((item, i) => {
                return (
                    <PassengersItem
                        key={i}
                        birthDate={item.birthDate}
                        citizenship={item.citizenship}
                        documentNumber={item.documentNumber}
                        name={item.name}
                        sex={item.sex}
                        ticketType={item.ticketType}
                    />
                )
            })}
        </div>
    )
}

export default Passengers
