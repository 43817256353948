import styles from "./BookingSuccess.module.scss";
import Direction from "./Direction/Direction";
import AboutPurchase from "./AboutPurchase/AboutPurchase";
import Documents from "./Documents/Documents";
import Hotel from "./Hotel/Hotel";
import Passengers from "./Passengers/Passengers";
import FAQ from "./FAQ/FAQ";
import SingleBreadCrumb from "../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import Paid from "./Paid/Paid";
import NotPaid from "./NotPaid/NotPaid";
import TicketWayInfoWrapper from "../Booking/TicketWayInfoWrapper/TicketWayInfoWrapper";
import {useSearchParams, useParams} from 'react-router-dom';
import {useGetOrderByIdQuery} from "../../services/orders.js";
import {useSelector} from "react-redux";
import {BASE_URL} from "../../api/constants";

const BookingSuccess = () => {

    const params = useParams();
    const orderId = params.id;
    const {
        data: ordersQuery,
        error,
        isLoading,
        refetch,
        isFetching
    } = useGetOrderByIdQuery({id: orderId}, {skip: !orderId});
    const [searchParams, setSearchParams] = useSearchParams();

    const sumItems = [];
    const catalog = useSelector((state) => state.catalog.catalog);
    const citizenship = catalog?.countries;
    const passengerTypes = {"adults": "взрослый", "children": "ребёнок", "babies": "младенец"};

    const month = {
        "01": "янв",
        "02": "фев",
        "03": "мар",
        "04": "апр",
        "05": "май",
        "06": "июн",
        "07": "июл",
        "08": "авг",
        "09": "сен",
        "10": "окт",
        "11": "ноя",
        "12": "дек"
    };

    const orderStatus = {
        "created": "Не оплачено",
        "payed": "Оплачено",
        "processing": "Оформляется",
        "finished": "Оформлено",
        "canceled": "Отменено",
        "expired": "Истекло"
    };

    let itineraryReceiptAmount = 0;
    ordersQuery?.data.passengers.forEach(function (elem) {

        itineraryReceiptAmount++;

    });

    const passengersContent = [];
    ordersQuery?.data.passengers.forEach(function (elem) {
        let passenger = {
            birthDate: elem.birthday,
            citizenship: citizenship ? citizenship[elem.country] : '',
            documentNumber: elem.document_number,
            name: elem.lastname + " " + elem.firstname,
            sex: elem.gender === "female" ? "Женский" : "Мужской",
            ticketType: passengerTypes[elem.type],
        }

        passengersContent.push(passenger);
    });


    const trip_to = ordersQuery ? JSON.parse(ordersQuery?.data.trip_to) : '';
    const trip_back = ordersQuery ? JSON.parse(ordersQuery?.data.trip_back) : '';

    const departure_city = trip_to ? trip_to?.flight[0].departure_city : '';
    let departure_city_str = departure_city[Object.keys(departure_city)[0]];
    const arrival_city = trip_to ? trip_to.flight.slice(-1)[0].arrival_city : '';
    let arrival_city_str = arrival_city[Object.keys(arrival_city)[0]];

    let [departure_date_day, departure_date_month, departure_date_year] = trip_to ? trip_to.departure_date.split(/\./) : [];
    const departure_date_str = departure_date_day + ' ' + month[departure_date_month];
    let [arrival_date_day, arrival_date_month, arrival_date_year] = trip_to ? trip_to.arrival_date.split(/\./) : [];
    const arrival_date_str = arrival_date_day + ' ' + month[arrival_date_month];

    const hotelLink = () => {
        window.location.replace(`${BASE_URL}orders/download?id=` + orderId + '&booking_type=hotel');
    }
    const flightLink = () => {
        window.location.replace(`${BASE_URL}orders/download?id=` + orderId + '&booking_type=flight');
    }

    const linkTo = '/bookingPayment/' + orderId;
    const directionInfo = trip_back !== null ? "both" : "to";

    const flightBookingFile = ordersQuery?.data?.flight_booking_file;
    const hotelBookingFile = ordersQuery?.data?.hotel_booking_file;
    const isOrderWithHotel = !!ordersQuery?.data?.hotel_city;

    if (ordersQuery && !flightBookingFile) {
        setTimeout(() => refetch(), 30000)
    }

    return (
        <section className={styles.bookingSuccess}>
            <div className="container">
                <div className={styles.bookingSuccess__wrapper}>
                    <SingleBreadCrumb title={"На главную"} path={"/"}/>

                    {/* {ordersQuery?.data?.message && !flightBookingFile && (
                        <>
                            <NotPaid linkTo={linkTo} message={ordersQuery?.data?.message}/>
                        </>
                    )} */}

                    {searchParams.get('message') && searchParams.get('payment') == 'failed' &&  (
                        <>
                            <NotPaid linkTo={linkTo} message={searchParams.get('message')}/>
                        </>

                    )}



                    {ordersQuery
                        && ordersQuery?.data.order_status !== 'canceled'
                        // && (!ordersQuery?.data?.message || flightBookingFile)
                        && !searchParams.get('message')
                        && (
                            <>
                                <Paid
                                    email={ordersQuery?.data.email}
                                    routeLinkFlight={flightLink}
                                    routeLinkHotel={hotelLink}
                                    flightBookingFile={flightBookingFile}
                                    hotelBookingFile={hotelBookingFile}
                                    isOrderWithHotel={isOrderWithHotel}/>
                            </>
                    )}
                    <Direction
                        fromTown={departure_city_str}
                        toTown={arrival_city_str}
                        startDate={departure_date_str}
                        endDate={arrival_date_str}
                        error={error}
                    />
                    {ordersQuery && (
                        <AboutPurchase
                            status={orderStatus[ordersQuery?.data.order_status]}
                            date={ordersQuery?.data.order_start_booking}
                            orderNumber={"№" + ordersQuery?.data.order_number}
                            sum={ordersQuery?.data.price + " " + ordersQuery?.data.currency}
                            flightToPnr={ordersQuery?.data.flight_to_pnr}
                            flightFromPnr={ordersQuery?.data.flight_from_pnr}
                            flightToBookingExpiresAt={ordersQuery?.data.flight_to_booking_expires_at}
                            flightFromBookingExpiresAt={ordersQuery?.data.flight_from_booking_expires_at}
                            flightToAirUrl={ordersQuery?.data.flight_to_air_url}
                            flightFromAirUrl={ordersQuery?.data.flight_from_air_url}
                            passengersQuantity={passengersContent.length}
                        >
                            {sumItems?.map((item, i) => {
                                return (
                                    <p className={styles.sumItems} key={i}>
                                        {item}
                                    </p>
                                );
                            })}
                        </AboutPurchase>
                    )}
                    {flightBookingFile || hotelBookingFile ? (
                        <Documents
                            hotelLink={hotelLink}
                            flightLink={flightLink}
                            flightBookingFile={flightBookingFile}
                            hotelBookingFile={hotelBookingFile}
                        />
                    ) : null}
                    {ordersQuery?.data.hotel_city && (
                        <Hotel
                            town={ordersQuery?.data.hotel_city}
                            startDate={ordersQuery?.data.hotel_check_in}
                            endDate={ordersQuery?.data.hotel_check_out}
                        />
                    )}
                    {ordersQuery && (
                        <>
                        <TicketWayInfoWrapper
                            directionInfo={directionInfo}
                            trip_to={trip_to}
                            trip_back={trip_back}
                        />
                        <Passengers passengers={passengersContent}/>
                        </>
                    )}
                </div>
            </div>
            <FAQ title={"Вопросы и ответы"}
                 type={searchParams.get('message') && (searchParams.get('payment') == 'failed' || searchParams.get('booking') == 'failed') ? 'fail' : 'success'}/>
        </section>
    );
};

export default BookingSuccess;
