import styles from "./Pin.module.scss";

export const Pin = (props) => {
    return (
        <div class={styles.wrapper}>
            <div className={`${styles.pin} ${styles[props.status]}`} />
            {props.children}
        </div>
    );
};
