import styles from "./Settings.module.scss";
import gear from "../../../assets/icons/gear.png";
import document from "../../../assets/icons/document.svg";
import lock from "../../../assets/icons/lockIcon.svg";
import warning from "../../../assets/icons/warning.svg";
import userIcon from "../../../assets/icons/user.svg";
import { ReactSVG } from "react-svg";
import CustomInput from "../../../components/UI/Input/Input";
import DropdownInput from "../../../components/UI/DropdownInput/DropdownInput";
import RadioGender from "../../../components/UI/RadioGender/RadioGender";
import CustomDatePicker from "../../../components/UI/CustomDatePicker/CustomDatePicker";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import { Formik, Form } from "formik";
import { validation1, validation2 } from "./Validation";
import { useDispatch, useSelector } from "react-redux";
import {
    useUpdateUserEmailMutation,
    useUpdateUserMutation,
    useUpdateUserPasswordMutation,
} from "../../../services/user";
import React, { useRef, useState } from "react";
import Popup from "../../../components/UI/Popup/Popup";
import ImageDone from "../../../assets/icons/image-done.png";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { setProfile } from "../../../store/slices/user";
import DropdownCountry from "components/UI/DropdownInput/DropdownCountry";
import { filterSelectWithCountryName } from "pages/Booking/Customer/Customer";

const Settings = () => {
    const [
        isChangePasswordSuccessModalVisible,
        setIsChangePasswordSuccessModalVisible,
    ] = useState(false);
    const [
        isChangeSettingsSuccessModalVisible,
        setIsChangeSettingsSuccessModalVisible,
    ] = useState(false);
    const [
        isChangeEmailSuccessModalVisible,
        setIsChangeEmailSuccessModalVisible,
    ] = useState(false);
    const [isResendSuccessModalVisible, setIsResendSuccessModalVisible] =
        useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isChangePasswordLoading, setIsChangePasswordLoading] =
        useState(false);
    const [isResendEmailLoading, setIsResendEmailLoading] = useState(false);

    const profile = useSelector((state) => state.user.profile);
    const user = useSelector((state) => state.user.user);
    const catalog = useSelector((state) => state.catalog.catalog);
    const token = useSelector((state) => state.user.token);

    const formRef = useRef();

    const [updateUser] = useUpdateUserMutation();
    const [updateUserEmail] = useUpdateUserEmailMutation();
    const [updateUserPassword] = useUpdateUserPasswordMutation();

    const dispatch = useDispatch();

    if (!profile || !catalog || !user) {
        return null;
    }

    const verified = user.email_verified;

    const { tel_codes, countries, countries_en } = catalog;

    const makeArrayFromObject = (obj, prefix = "", needPostfix) => {
        const arr = [];
        for (let key of Object.keys(obj)) {
            let label = prefix ? prefix + obj[key] : obj[key];
            if (needPostfix) label += ` (${key})`;
            const name = countries[key];
            const nameEn = countries_en[key];
            arr.push({ value: key, name, nameEn, label });
        }
        return arr;
    };

    const numberCode = makeArrayFromObject(tel_codes, "+", true);
    const citizenship = makeArrayFromObject(countries);

    const documentTypes = [
        { value: "DIPLOMATIC", label: "Дипломатический паспорт" },
        { value: "RESIDENCEPERMIT", label: "Вид на жительство" },
        { value: "PASSPORT", label: "Иностранный документ" },
        { value: "SERVICE_PASSPORT", label: "Служебный паспорт" },
        {
            value: "FOREIGN_NATIONAL_PASSPORT",
            label: "Заграничный национальный паспорт",
        },
    ];

    const documentTypesRussia = [{ value: "FOREIGN", label: "Загранпаспорт" }];

    const {
        birthday,
        country,
        document_expires,
        document_number,
        firstname,
        gender,
        lastname,
        phone,
        phone_prefix,
        document_type,
    } = profile;

    const handleVerifyEmail = () => {
        const email = formRef.current.values.email;
        setIsResendEmailLoading(true);
        updateUserEmail({ email })
            .unwrap()
            .then((data) => {
                //console.info(data)
                setIsResendSuccessModalVisible(true);
                setIsResendEmailLoading(false);
            })
            .catch((resp) => {
                //console.info(resp)
                setIsResendEmailLoading(false);
            });
    };

    return (
        <div className={styles.settings}>
            <div className={styles.settings__header}>
                <h2 className={styles.settings__header_title}>
                    Настройки профиля
                </h2>
                <img
                    className={styles.settings__header_img}
                    src={gear}
                    alt="gearIcon"
                />
            </div>
            <Formik
                innerRef={formRef}
                enableReinitialize={true}
                onSubmit={(values, { setErrors }) => {
                    setIsLoading(true);
                    if (!verified) {
                        updateUserEmail({ email: values.email })
                            .unwrap()
                            .then((data) => {
                                //console.info(data)
                                setIsLoading(false);
                                setIsChangeEmailSuccessModalVisible(true);
                            })
                            .catch((resp) => {
                                //console.info(resp)
                                const errors = resp.data.errors;
                                if (errors) {
                                    const errorsKeys = Object.keys(
                                        resp.data.errors
                                    );
                                    for (let error of errorsKeys) {
                                        setErrors({
                                            [error]: errors[error][0],
                                        });
                                    }
                                    setIsLoading(false);
                                }
                            });
                    } else {
                        const { email, termNotSpecified, ...rest } = values;
                        const profileData = {
                            ...rest,
                            phone: rest.phone.replace(/[^0-9]/g, ""),
                            document_number: rest.document_number.replace(
                                " ",
                                ""
                            ),
                            // country: Object.keys(countries).find(key => countries[key] === rest.country)
                            phone_prefix:
                                rest.phone_prefix[0] === "+"
                                    ? rest.phone_prefix
                                    : `+${
                                          catalog.tel_codes[rest.phone_prefix]
                                      }`,
                            // document_expires: termNotSpecified ? null : rest.document_expires
                        };
                        updateUser({ user: profileData, token })
                            .unwrap()
                            .then((data) => {
                                setIsLoading(false);
                                setIsChangeSettingsSuccessModalVisible(true);
                                dispatch(setProfile({ profile: data.data }));
                            })
                            .catch((resp) => {
                                const errors = resp.data.errors;
                                if (errors) {
                                    const errorsKeys = Object.keys(
                                        resp.data.errors
                                    );
                                    for (let error of errorsKeys) {
                                        setErrors({
                                            [error]: errors[error][0],
                                        });
                                    }
                                    setIsLoading(false);
                                }
                            });
                    }
                }}
                validateOnMount={true}
                initialValues={{
                    email: user.email,
                    phone_prefix: phone_prefix || "+7",
                    phone,
                    country,
                    firstname: firstname || "",
                    lastname: lastname || "",
                    gender,
                    birthday,
                    document_number,
                    document_expires: document_expires || "",
                    document_type: country === "RU" ? "FOREIGN" : document_type,
                    // termNotSpecified: false,
                }}
                validationSchema={verified ? validation1 : null}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <div className={styles.settings__block}>
                            <div className={styles.settings__item}>
                                <div className={styles.settings__item_header}>
                                    <img src={userIcon} />
                                    <h3 className={styles.settings__item_title}>
                                        Данные профиля
                                    </h3>
                                </div>
                                <div className={styles.settings__item_inputs3}>
                                    <div>
                                        <CustomInput
                                            blocked={verified}
                                            placeholder={"E-mail"}
                                            value={values.email}
                                            onChange={(value) =>
                                                setFieldValue(`email`, value)
                                            }
                                            error={
                                                touched.email && errors.email
                                            }
                                            container={styles.settings__input}
                                        />
                                        {!verified && (
                                            <>
                                                <p
                                                    className={
                                                        styles.settings__email_not_verified
                                                    }
                                                >
                                                    <img src={warning} />
                                                    Почта не подтверждена :(
                                                    перейдите по ссылке в письме
                                                    для подтверждения. Если
                                                    письмо не пришло, мы
                                                    отправим повторно.
                                                </p>
                                                <a
                                                    onClick={handleVerifyEmail}
                                                    className={
                                                        styles.settings__verify_email
                                                    }
                                                >
                                                    Отправить письмо повторно
                                                    {isResendEmailLoading && (
                                                        <Spinner
                                                            className={
                                                                styles.settings__spinner_email
                                                            }
                                                        />
                                                    )}
                                                </a>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <DropdownCountry
                                            disabled={!verified}
                                            showSearch
                                            options={numberCode}
                                            value={values.phone_prefix}
                                            onChange={(value) =>
                                                setFieldValue(
                                                    `phone_prefix`,
                                                    value
                                                )
                                            }
                                            error={
                                                touched.phone_prefix &&
                                                errors.phone_prefix
                                            }
                                            filterOption={(input, option) => {
                                                return (
                                                    (option?.label ?? "")
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        ) ||
                                                    (option?.name ?? "")
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        ) ||
                                                    (option?.nameEn ?? "")
                                                        .toLowerCase()
                                                        .includes(
                                                            input.toLowerCase()
                                                        )
                                                );
                                            }}
                                            defaultValue={"RU"}
                                        />
                                        {touched.phone_prefix &&
                                            errors.phone_prefix && (
                                                <span
                                                    className={
                                                        styles.error_text
                                                    }
                                                >
                                                    {errors.phone_prefix}
                                                </span>
                                            )}
                                    </div>
                                    <CustomInput
                                        blocked={!verified}
                                        placeholder={"Телефон"}
                                        container={styles.settings__input}
                                        value={values.phone}
                                        onChange={(newValue) =>
                                            setFieldValue(`phone`, newValue)
                                        }
                                        error={touched.phone && errors.phone}
                                    />
                                </div>
                            </div>
                            <div className={styles.settings__divider} />
                            <div className={styles.settings__item}>
                                <div className={styles.settings__item_header}>
                                    <ReactSVG src={document} />
                                    <h3 className={styles.settings__item_title}>
                                        Данные для бронирования
                                    </h3>
                                </div>
                                <div className={styles.settings__item_inputs2}>
                                    <div>
                                        <DropdownCountry
                                            disabled={!verified}
                                            options={citizenship}
                                            value={values.country}
                                            onChange={(value) => {
                                                if (value === "RU") {
                                                    setFieldValue(
                                                        `document_type`,
                                                        "FOREIGN"
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        `document_type`,
                                                        null
                                                    );
                                                }
                                                setFieldValue(`country`, value);
                                            }}
                                            error={
                                                touched.country &&
                                                errors.country
                                            }
                                            showSearch
                                            filterOption={
                                                filterSelectWithCountryName
                                            }
                                            label={"Гражданство"}
                                        />
                                    </div>
                                </div>
                                <div className={styles.settings__item_inputs2}>
                                    <CustomInput
                                        blocked={!verified}
                                        placeholder={"Имя"}
                                        value={values.firstname}
                                        onChange={(newValue) => {
                                            if (/^[A-Za-z]*$/.test(newValue)) {
                                                setFieldValue(
                                                    `firstname`,
                                                    newValue.toUpperCase()
                                                );
                                            }
                                        }}
                                        error={
                                            touched.firstname &&
                                            errors.firstname
                                        }
                                        container={styles.settings__input}
                                    />
                                    <CustomInput
                                        blocked={!verified}
                                        placeholder={"Фамилия"}
                                        value={values.lastname}
                                        onChange={(newValue) => {
                                            if (/^[A-Za-z]*$/.test(newValue)) {
                                                setFieldValue(
                                                    `lastname`,
                                                    newValue.toUpperCase()
                                                );
                                            }
                                        }}
                                        error={
                                            touched.lastname && errors.lastname
                                        }
                                        container={styles.settings__input}
                                    />
                                </div>
                                <p className={styles.settings__item_info}>
                                    Только на латинице, например IVANOV IVAN
                                </p>
                                <div className={styles.settings__item_inputs2}>
                                    <CustomDatePicker
                                        disabled={!verified}
                                        placeholder={"Дата рождения"}
                                        value={values.birthday}
                                        onChange={(newValue) => {
                                            setFieldValue(`birthday`, newValue);
                                        }}
                                        error={
                                            touched.birthday && errors.birthday
                                        }
                                        hasValue={!!birthday}
                                    />
                                    <RadioGender
                                        disabled={!verified}
                                        errors={errors.gender}
                                        touched={touched.gender}
                                        setFieldValue={setFieldValue}
                                        checkedFemale={
                                            values.gender === "female"
                                        }
                                        checkedMale={values.gender === "male"}
                                    />
                                </div>
                                <div className={styles.settings__item_inputs2}>
                                    <div>
                                        <DropdownInput
                                            disabled={
                                                !verified ||
                                                values.country === "RU"
                                            }
                                            options={
                                                values.country === "RU"
                                                    ? documentTypesRussia
                                                    : documentTypes
                                            }
                                            defaultValue={
                                                values.country === "RU"
                                                    ? "FOREIGN"
                                                    : values.document_type
                                            }
                                            value={
                                                values.country === "RU"
                                                    ? "FOREIGN"
                                                    : values.document_type
                                            }
                                            onChange={(value) =>
                                                setFieldValue(
                                                    `document_type`,
                                                    value
                                                )
                                            }
                                            error={
                                                touched.document_type &&
                                                errors.document_type
                                            }
                                            label={"Тип документа"}
                                        />
                                    </div>
                                    <CustomInput
                                        blocked={!verified}
                                        placeholder={"Номер документа"}
                                        value={values.document_number}
                                        container={styles.settings__input}
                                        error={
                                            touched.document_number &&
                                            errors.document_number
                                        }
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                `document_number`,
                                                newValue
                                            )
                                        }
                                    />
                                </div>
                                <div className={styles.settings__item_inputs2}>
                                    <CustomDatePicker
                                        placeholder={"Срок действия"}
                                        label={"Срок действия"}
                                        disabled={
                                            values.termNotSpecified || !verified
                                        }
                                        value={values.document_expires}
                                        onChange={(newValue) => {
                                            setFieldValue(
                                                `document_expires`,
                                                newValue
                                            );
                                        }}
                                        hasValue={!!document_expires}
                                        error={
                                            touched.document_expires &&
                                            values.termNotSpecified !== true &&
                                            errors.document_expires
                                        }
                                    />
                                </div>
                                {/*<div className={styles.settings__item_inputs2}>*/}
                                {/*    <div />*/}
                                {/*    <label*/}
                                {/*        className={styles.form__field_checkbox}*/}
                                {/*    >*/}
                                {/*        <CustomCheckbox*/}
                                {/*            disabled={!verified}*/}
                                {/*            type="checkbox"*/}
                                {/*            name={`checkbox`}*/}
                                {/*            checked={values.termNotSpecified}*/}
                                {/*            onChange={(event) => {*/}
                                {/*                setFieldValue(*/}
                                {/*                    `termNotSpecified`,*/}
                                {/*                    event.target.checked*/}
                                {/*                );*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*        Срок не указан*/}
                                {/*    </label>*/}
                                {/*</div>*/}
                                <CustomButton
                                    primary
                                    classes={styles.settings__btn}
                                    type="submit"
                                >
                                    {isLoading && (
                                        <Spinner
                                            className={styles.settings__spinner}
                                        />
                                    )}
                                    Сохранить
                                </CustomButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <Formik
                initialValues={{
                    current_password: "",
                    password: "",
                    password_confirmation: "",
                }}
                validationSchema={validation2}
                onSubmit={(values, { setErrors }) => {
                    setIsChangePasswordLoading(true);
                    updateUserPassword({ credentials: values, token })
                        .unwrap()
                        .then((data) => {
                            setIsChangePasswordLoading(false);
                            setIsChangePasswordSuccessModalVisible(true);
                            //console.info(data)
                        })
                        .catch((resp) => {
                            const errors = resp.data.errors;
                            if (errors) {
                                const errorsKeys = Object.keys(
                                    resp.data.errors
                                );
                                for (let error of errorsKeys) {
                                    setErrors({ [error]: errors[error][0] });
                                }
                                setIsChangePasswordLoading(false);
                            }

                            //console.info(resp)
                        });
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className={styles.settings__block}>
                            <div className={styles.settings__item}>
                                <div className={styles.settings__item_header}>
                                    <ReactSVG src={lock} />
                                    <h3 className={styles.settings__item_title}>
                                        Изменение пароля
                                    </h3>
                                </div>
                                <div
                                    className={
                                        styles.settings__item_inputs2_password
                                    }
                                >
                                    <CustomInput
                                        password={true}
                                        placeholder={"Старый пароль"}
                                        container={styles.settings__input}
                                        value={values.current_password}
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                `current_password`,
                                                newValue
                                            )
                                        }
                                        error={
                                            touched.current_password &&
                                            errors.current_password
                                        }
                                    />
                                    <CustomInput
                                        password={true}
                                        placeholder={"Новый пароль"}
                                        container={styles.settings__input}
                                        value={values.password}
                                        onChange={(newValue) =>
                                            setFieldValue(`password`, newValue)
                                        }
                                        error={
                                            touched.password && errors.password
                                        }
                                    />
                                    <CustomInput
                                        password={true}
                                        placeholder={"Пароль еще раз"}
                                        container={styles.settings__input}
                                        value={values.password_confirmation}
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                `password_confirmation`,
                                                newValue
                                            )
                                        }
                                        error={
                                            touched.password_confirmation &&
                                            errors.password_confirmation
                                        }
                                    />
                                </div>
                                <CustomButton
                                    primary
                                    classes={styles.settings__btn}
                                    type="submit"
                                >
                                    {isChangePasswordLoading && (
                                        <Spinner
                                            className={styles.settings__spinner}
                                        />
                                    )}
                                    Сохранить
                                </CustomButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <Popup
                open={isChangeSettingsSuccessModalVisible}
                onCancel={() => setIsChangeSettingsSuccessModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Успешно</p>
                        <span>Данные пользователя изменены</span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() =>
                            setIsChangeSettingsSuccessModalVisible(false)
                        }
                    >
                        Принять
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isChangePasswordSuccessModalVisible}
                onCancel={() => setIsChangePasswordSuccessModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Успешно</p>
                        <span>Пароль изменен</span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() =>
                            setIsChangePasswordSuccessModalVisible(false)
                        }
                    >
                        Принять
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isChangeEmailSuccessModalVisible}
                onCancel={() => setIsChangeEmailSuccessModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Успешно</p>
                        <span>E-mail изменен</span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() =>
                            setIsChangeEmailSuccessModalVisible(false)
                        }
                    >
                        Принять
                    </CustomButton>
                }
            ></Popup>

            <Popup
                open={isResendSuccessModalVisible}
                onCancel={() => setIsResendSuccessModalVisible(false)}
                title={
                    <>
                        <img alt="icon" src={ImageDone} />
                        <p>Успешно</p>
                        <span>
                            На вашу электронную почту отправлено повторное
                            письмо
                        </span>
                    </>
                }
                footer={
                    <CustomButton
                        primary
                        fullWidth
                        onClick={() => setIsResendSuccessModalVisible(false)}
                    >
                        Принять
                    </CustomButton>
                }
            ></Popup>
        </div>
    );
};

export default Settings;
