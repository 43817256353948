import style from "./Links.module.scss";
import LinkIcon from "../../../assets/icons/link-icon.png";
import { useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../../components/UI/CustomTable/CustomTable";
import Order from "./Order";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";
import classNames from "classnames";
import Sorting from "../Sorting/Sorting";
import { useDispatch } from "react-redux";
import { useHidePage } from "../../../hooks/useHidePage";
import { switchIsOpened } from "../../../store/slices/sorting";
import SingleBreadCrumb from "../../../components/UI/SingleBreadCrumb/SingleBreadCrumb";
import { orderBy } from "lodash";
import { useGetPromoCodeQuery } from "../../../services/links";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { orderStatuses } from "configs/order";

const columns = [
    {
        title: "№ Заказа",
        dataIndex: "order_number",
        key: "order_number",
        sorter: (a, b) => {
            if (a.order_number > b.order_number) {
                return -1;
            }
            if (b.order_number > a.order_number) {
                return 1;
            }
            return 0;
        },
        render: (value) => "№" + value,
    },
    {
        title: "Сумма заказа",
        dataIndex: "price",
        key: "price",
        sorter: (a, b) => {
            if (a.price > b.price) {
                return -1;
            }
            if (b.price > a.price) {
                return 1;
            }
            return 0;
        },
        render: (value) => value + "₽",
    },
    {
        title: "Дата и время",
        dataIndex: "updated_at",
        key: "updated_at",
        sorter: (a, b) => {
            if (a.updated_at > b.updated_at) {
                return -1;
            }
            if (b.updated_at > a.updated_at) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Перелет",
        dataIndex: "flights",
        render: (_, values) => {
            if (values?.withReturn)
                return `(${values.cityFrom} - ${values.cityTo}),(${values.cityTo} - ${values.cityFrom})`;
            else return `${values.cityFrom} - ${values.cityTo}`;
        },
    },
    {
        title: "Комиссия партнера",
        dataIndex: "commission",
        key: "commission",
        sorter: (a, b) => {
            if (a.commission > b.commission) {
                return -1;
            }
            if (b.commission > a.commission) {
                return 1;
            }
            return 0;
        },
        render: (value, values) => {
            if (values.status == orderStatuses.EXPIRED) return value + "₽";
            else return `0₽ (${value}₽)`;
        },
    },
    {
        title: "Статус заказа",
        dataIndex: "status_name",
        key: "status_name",
        render: (value) => value,
    },
];

const sortingNames = {
    order_number: "№ Заказа",
    price: "Сумма заказа",
    updated_at: "Дата и время",
    commission: "Комиссия партнера",
};

const LinkPage = () => {
    const [sorting, setSorting] = useState({
        order_number: null,
        price: null,
        updated_at: null,
        commission: null,
    });
    const [isSortingVisible, setIsSortingVisible] = useState(false);
    const [ordersData, setOrdersData] = useState([]);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: link, error, isFetching } = useGetPromoCodeQuery(id, page);

    const dispatch = useDispatch();
    const hideLinksPage = useHidePage();

    useEffect(() => {
        if (link) {
            setOrdersData([...ordersData, ...link?.data?.orders?.data]);
        }
    }, [link]);

    if (hideLinksPage && isSortingVisible) {
        return (
            <Sorting
                sorting={sorting}
                sortingNames={sortingNames}
                onCancel={() => {
                    setIsSortingVisible(false);
                    dispatch(switchIsOpened(false));
                }}
                setSorting={setSorting}
            />
        );
    }

    return (
        <div className={style.link_page}>
            <SingleBreadCrumb
                classes={style.link_page_breadcrumb}
                title={"Назад"}
                path={"/profile/links"}
            />
            <div className={style.header}>
                <div className={style.header_link}>
                    <p className={style.header_title}>{link?.data.title}</p>
                    <div className={style.header_link_info}>
                        {link?.data.url && (
                            <div>
                                <p className={style.header_name}>Ссылка</p>
                                <p className={style.header_description}>
                                    {link?.data.url}
                                </p>
                                <CustomButton
                                    small
                                    primary
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            link?.data.url
                                        );
                                    }}
                                    classes={style.header_button}
                                >
                                    Скопировать ссылку
                                </CustomButton>
                            </div>
                        )}
                        <div>
                            <p className={style.header_name}>Промокод</p>
                            <p className={style.header_description}>
                                {link?.data.promo_code}
                            </p>
                            <CustomButton
                                small
                                primary
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        link?.data.promo_code
                                    );
                                }}
                                classes={style.header_button}
                            >
                                Скопировать промокод
                            </CustomButton>
                        </div>
                    </div>
                    <div className={style.header_discounts}>
                        <div>
                            <p className={style.header_name}>
                                Скидка пользователю
                            </p>
                            <p className={style.header_description}>
                                {link?.data.discount}%
                            </p>
                        </div>
                        <div>
                            <p className={style.header_name}>Комис. партнера</p>
                            <p className={style.header_description}>
                                {link?.data.commission}%
                            </p>
                        </div>
                    </div>
                    <CustomButton
                        small
                        lined
                        onClick={() => navigate(`/profile/links/edit/${id}`)}
                        classes={style.header_button}
                    >
                        Редактировать
                    </CustomButton>
                </div>

                <img alt="icon" src={LinkIcon} className={style.header_icon} />
            </div>
            {isFetching ? (
                <Spinner className={style.spinner} />
            ) : !link?.data?.orders?.data.length ? (
                <p className={style.link_page_empty}>
                    По вашей ссылке еще нет заказов
                </p>
            ) : (
                <>
                    <CustomButton
                        lined
                        classes={style.links_page_sort_button}
                        onClick={() => {
                            setIsSortingVisible(true);
                            dispatch(switchIsOpened(true));
                        }}
                        small
                    >
                        Сортировка
                    </CustomButton>
                    <div className={style.links_page_table}>
                        <CustomTable
                            columns={columns}
                            dataSource={link?.data?.orders?.data}
                        />
                    </div>
                    <div className={style.links_page_list}>
                        {ordersData &&
                            orderBy(
                                ordersData,
                                [
                                    sorting.order_number ? "order_number" : "",
                                    sorting.price ? "price" : "",
                                    sorting.commission ? "commission" : "",
                                    sorting.updated_at
                                        ? (item) => new Date(item.updated_at)
                                        : "",
                                ].filter(Boolean),
                                [Object.values(sorting).filter(Boolean)]
                            ).map((order) => <Order orderItem={order} />)}
                    </div>
                    <CustomButton
                        linedBlack
                        small
                        classes={style.links_page_show_more}
                        onClick={() => setPage((page) => page + 1)}
                        disabled={
                            isFetching ||
                            error ||
                            link?.data?.orders?.meta?.current_page >=
                                link?.data?.orders?.meta?.last_page
                        }
                    >
                        Показать больше
                    </CustomButton>
                    <div
                        className={classNames(style.links_page_sorting, {
                            [style["links_page_sorting_visible"]]:
                                isSortingVisible,
                        })}
                    >
                        <Sorting
                            sorting={sorting}
                            sortingNames={sortingNames}
                            onCancel={() => setIsSortingVisible(false)}
                            setSorting={setSorting}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default LinkPage;
