import styles from "./AlreadyRegistered.module.scss"
import registeredImg from "../../../assets/icons/registeredImg.png"
import CustomButton from "../../../components/UI/CustomButton/CustomButton"

const AlreadyRegistered = ({isLoginModalVisible, setLoginModalVisible}) => {
    return (
        <div className={styles.registered}>
            <h2 className={styles.registered__title}>Уже зарегистрированы?</h2>
            <h2 className={styles.registered__subtitle}>
                Войдите и выбирайте пассажиров из записной книжки
            </h2>
            <CustomButton 
                classes={styles.registered__btn} 
                primary={true}
                onClick={() =>
                        setLoginModalVisible(true)
                }>Войти</CustomButton>
            <img
                className={styles.registered__img}
                src={registeredImg}
                alt="icon"
            />
        </div>
    )
}
export default AlreadyRegistered
