import { ReactSVG } from "react-svg";
import styles from "./TicketWayInfo.module.scss";
import plane from "../../../assets/icons/plane-ticket.svg";
import warning from "../../../assets/icons/warning-gray.svg";
import TicketTime from "./TicketTime/TicketTime";
import TicketTransfer from "./TicketTransfer/TicketTransfer";
import Button from "../../../components/UI/Button/Button";
import clsx from "clsx";
import { Tooltip as Tooltip2 } from "antd";
import TransferText from "./TransferText/TransferText";
import TransferInfoHeader from "./TransferInfoHeader/TransferInfoHeader";
import TicketDetails from "../../Tickets/TicketDetails";
import PlaneIcon from "../../../assets/icons/airCompany.svg";
import { useSelector } from "react-redux";
import Tooltip from "../../../components/UI/Tooltip/Tooltip";

const TicketWayInfo = (props) => {
    const {
        direction,
        single,
        withButton,
        fullWidth,
        backTicket,
        isOpened,
        openHandler,
        ticket,
        isBooking = false,
    } = props;

    const {
        flight,
        arrival_time,
        arrival_date,
        departure_time,
        departure_date,
    } = ticket;
    const ticketAirlines = ticket?.airlines;
    const companies = [];
    if (ticketAirlines) {
        for (let item of Object.keys(ticket?.airlines)) {
            companies.push({
                name: ticket?.airlines[item],
                logo: "https://mpics.avs.io/al_square/24/24/" + item + ".png",
            });
        }
    }
    const company_name = ticket
        ? ticket?.airlines[Object.keys(ticket?.airlines)[0]]
        : "";
    const company_logo = ticket
        ? "https://mpics.avs.io/al_square/24/24/" +
          Object.keys(ticket?.airlines)[0] +
          ".png"
        : "";

    const departureCity = ticket
        ? ticket?.flight[0].departure_city[
              Object.keys(ticket?.flight[0].departure_city)[0]
          ]
        : "";

    const departureAirport = ticket
        ? ticket?.flight[0]?.departure[
              Object.keys(ticket?.flight[0].departure)[0]
          ]
        : "";
    const departureAirportCode = ticket
        ? Object.keys(ticket?.flight[0].departure)[0]
        : "";
    const departureAirportStr =
        departureCity +
        ", " +
        departureAirport +
        "(" +
        departureAirportCode +
        ")";

    const arrivalCity = ticket
        ? ticket?.flight.slice(-1)[0].arrival_city[
              Object.keys(ticket?.flight.slice(-1)[0].arrival_city)[0]
          ]
        : "";
    const arrivalAirport = ticket
        ? ticket?.flight.slice(-1)[0].arrival[
              Object.keys(ticket?.flight.slice(-1)[0].arrival)[0]
          ]
        : "";
    const arrivalAirportCode = ticket
        ? Object.keys(ticket?.flight.slice(-1)[0].arrival)[0]
        : "";
    const arrivalAirportStr =
        arrivalCity + ", " + arrivalAirport + "(" + arrivalAirportCode + ")";

    const totalDuration = ticket.total_duration;
    const transfer = ticket?.flight?.length - 1;

    const timeLimits = useSelector((state) => state.tickets.timeLimits);
    let bookingDateTimeFrom;
    let bookingDateTimeTo;
    if (
        ticket &&
        ticket.airlines &&
        timeLimits &&
        Object.keys(timeLimits).length
    ) {
        const arrFrom = [];
        const arrTo = [];
        for (let airline in Object.keys(ticket?.airlines)) {
            arrFrom.push(
                +timeLimits.to[Object.keys(ticket?.airlines)[airline]]
            );
            arrTo.push(
                +timeLimits.back[Object.keys(ticket?.airlines)[airline]]
            );
        }
        const timeFrom = arrFrom.sort()[0];
        const timeTo = arrTo.sort()[0];
        const today = new Date().getTime();
        const departureTime = ticket.departure_timestamp * 1000;
        const firstTime = timeFrom * 1000 * 3600 * 24;
        const secondTime = timeTo * 1000 * 3600 * 24;
        if (
            today + firstTime > departureTime &&
            today + secondTime > departureTime
        ) {
            bookingDateTimeFrom = 0;
            bookingDateTimeTo = Math.ceil(
                (departureTime - today) / (1000 * 3600 * 24)
            );
        } else if (
            today + firstTime < departureTime &&
            today + secondTime > departureTime
        ) {
            bookingDateTimeFrom = timeFrom;
            bookingDateTimeTo = Math.ceil(
                (departureTime - today) / (1000 * 3600 * 24)
            );
        } else {
            bookingDateTimeFrom = timeFrom;
            bookingDateTimeTo = timeTo;
        }
        // if (bookingTime === 0){
        //     bookingDateTime = 0
        // } else {
        //     const bookingDate = new Date(new Date().getTime() + bookingTime * 60 * 1000);
        //     bookingDateTime = `${bookingDate.getDate()}.${bookingDate.getMonth() + 1}.${bookingDate.getFullYear()} ${bookingDate.getHours()}:${bookingDate.getMinutes()}`;
        // }
    }
    return (
        <div
            className={clsx(styles.ticket, {
                [styles.ticket__from]: direction === "back",
                [styles.ticket__to]: direction === "to",
                [styles.ticket__from__single]: single,
                [styles.ticket__from__single__with__button]:
                    single && withButton,
                [styles.ticket__mainFull]: fullWidth,
                [styles.ticket__backTicket]: backTicket,
                [styles.ticket__backTicket__with__button]:
                    backTicket && withButton,
                [styles.ticket__with__button]: !single && withButton,
                [styles.ticket__from__with__button]:
                    direction === "back" && withButton,
            })}
        >
            <div className={styles.ticket__header}>
                <div
                    className={clsx(styles.ticket__header_icon, {
                        [styles.ticket__header_iconReverse]:
                            direction === "back",
                    })}
                >
                    <ReactSVG src={plane} />
                </div>
                <p className={styles.ticket__header_direction}>
                    {direction === "to" ? "Туда" : "Обратно"}
                </p>

                {companies.map((item) => (
                    <div className={styles.ticket__header_company}>
                        <img
                            src={item.logo}
                            alt={item.name}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = PlaneIcon;
                            }}
                        />
                        {item.name}
                    </div>
                ))}
                {companies.length > 1 && (
                    <Tooltip
                        content={"Совместный (код-шеринговый) рейс"}
                        children={<ReactSVG src={warning} />}
                    />
                )}
            </div>
            {(!!bookingDateTimeFrom || !!bookingDateTimeTo) && (
                <div className={styles.ticket__header_booking_date}>
                    Бронь от <b>{bookingDateTimeFrom}</b> до{" "}
                    <b>{bookingDateTimeTo}</b> дней
                </div>
            )}
            {isOpened ? (
                <TransferInfoHeader
                    time={totalDuration}
                    isBack={direction === "back"}
                    departureCity={departureCity}
                    arrivalCity={arrivalCity}
                />
            ) : (
                <></>
            )}
            {fullWidth ? (
                <>
                    <div
                        className={clsx(styles.ticket__main, {
                            [styles.ticket__main_fullTime]: fullWidth,
                        })}
                    >
                        <div className={styles.ticket__main_time}>
                            <TicketTime
                                date={ticket?.departure_date}
                                time={ticket?.departure_time}
                                airport={departureAirportStr}
                            />
                        </div>
                        <div className={styles.ticket__main_tooltip}>
                            <div className={styles.ticket__tooltip_item}></div>
                            <Tooltip2 title="Пересадка в Мюнхене">
                                <div
                                    className={
                                        styles.ticket__tooltip_item_container
                                    }
                                >
                                    <div
                                        className={
                                            styles.ticket__tooltip_item_transfer
                                        }
                                    ></div>
                                </div>
                            </Tooltip2>
                            <div className={styles.ticket__tooltip_item}></div>
                        </div>
                        <div className={styles.ticket__main_time}>
                            <TicketTime
                                date={ticket?.arrival_date}
                                time={ticket?.arrival_time}
                                airport={arrivalAirportStr}
                            />
                        </div>
                        <div className={styles.ticket__main_tooltip__mobile}>
                            <div className={styles.ticket__tooltip_item}></div>
                            <Tooltip2
                                className={
                                    styles.ticket__tooltip_item_transfer_mobile
                                }
                                title="Пересадка в Мюнхене"
                            >
                                <div
                                    className={
                                        styles.ticket__tooltip_item_transfer
                                    }
                                ></div>
                            </Tooltip2>
                            <div className={styles.ticket__tooltip_item}></div>
                        </div>
                        <div className={styles.ticket__main_total}>
                            <TicketTransfer
                                time={totalDuration}
                                transfer={transfer}
                            />
                        </div>
                    </div>
                    {isOpened ? (
                        <>
                            <TransferText />
                            <div
                                className={clsx(styles.ticket__main, {
                                    [styles.ticket__main_fullTime]: fullWidth,
                                })}
                            >
                                <div className={styles.ticket__main_time}>
                                    <TicketTime
                                        date={ticket?.arrival_date}
                                        time={ticket?.arrival_time}
                                        airport={departureAirportStr}
                                    />
                                </div>
                                <div className={styles.ticket__main_tooltip}>
                                    <div
                                        className={styles.ticket__tooltip_item}
                                    ></div>
                                    <Tooltip2 title="Пересадка в Мюнхене">
                                        <div
                                            className={
                                                styles.ticket__tooltip_item_container
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.ticket__tooltip_item_transfer
                                                }
                                            ></div>
                                        </div>
                                    </Tooltip2>
                                    <div
                                        className={styles.ticket__tooltip_item}
                                    ></div>
                                </div>
                                <div className={styles.ticket__main_time}>
                                    <TicketTime
                                        date={ticket?.arrival_date}
                                        time={ticket?.arrival_time}
                                        airport={arrivalAirportStr}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.ticket__main_tooltip__mobile
                                    }
                                >
                                    <div
                                        className={styles.ticket__tooltip_item}
                                    ></div>
                                    <Tooltip2
                                        className={
                                            styles.ticket__tooltip_item_transfer_mobile
                                        }
                                        title="Пересадка в Мюнхене"
                                    >
                                        <div
                                            className={
                                                styles.ticket__tooltip_item_transfer
                                            }
                                        ></div>
                                    </Tooltip2>
                                    <div
                                        className={styles.ticket__tooltip_item}
                                    ></div>
                                </div>
                                <div className={styles.ticket__main_total}>
                                    <TicketTransfer
                                        time={totalDuration}
                                        transfer={transfer}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <>
                    {isOpened ? (
                        flight.map((item, index) => (
                            <>
                                <TicketDetails
                                    isOpened
                                    flights={flight}
                                    departureTime={item.departure_time}
                                    arrivalTime={item.arrival_time}
                                    departureDate={item.departure_date}
                                    arrivalDate={item.arrival_date}
                                    duration={+item.duration}
                                    transfer={transfer}
                                    flight={item}
                                />
                                {index < ticket.flight.length - 1 && (
                                    <TransferText
                                        time={
                                            flight[index + 1]
                                                .departure_timestamp -
                                            item.arrival_timestamp
                                        }
                                    />
                                )}
                            </>
                        ))
                    ) : (
                        <TicketDetails
                            departureTime={departure_time}
                            arrivalTime={arrival_time}
                            departureDate={departure_date}
                            arrivalDate={arrival_date}
                            flights={flight}
                            duration={totalDuration}
                            transfer={transfer}
                            departureAirport={departureAirportStr}
                            arrivalAirport={arrivalAirportStr}
                        />
                    )}
                </>
            )}

            {isBooking && (
                <Button
                    onClick={openHandler}
                    text={isOpened ? "Свернуть детали" : "Детали перелета"}
                    container={styles.ticket__main_btn}
                    imgRightContainer={
                        isOpened
                            ? styles.ticket__main_rightBtnClosed
                            : styles.ticket__main_rightBtn
                    }
                />
            )}
        </div>
    );
};

export default TicketWayInfo;
