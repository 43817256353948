import React from "react";

import { Link } from "react-router-dom";

const Button = React.forwardRef(
    (
        {
            onClick,
            type = "Button",
            url,
            imgLeftContainer,
            imgRightContainer,
            text,
            container,
        },
        ref
    ) => {
        if (type === "Button") {
            return (
                <button ref={ref} onClick={onClick} className={container}>
                    {imgLeftContainer ? (
                        <div className={imgLeftContainer}></div>
                    ) : (
                        ""
                    )}
                    {text}
                    {imgRightContainer ? (
                        <div className={imgRightContainer}>Скоро</div>
                    ) : (
                        ""
                    )}
                </button>
            );
        }
        if (type === "Link") {
            return (
                <Link onClick={onClick} to={url} className={container}>
                    {imgLeftContainer ? (
                        <div className={imgLeftContainer}></div>
                    ) : (
                        ""
                    )}
                    {text}
                    {imgRightContainer ? (
                        <div className={imgRightContainer}></div>
                    ) : (
                        ""
                    )}
                </Link>
            );
        }
        if (type === "A") {
            return (
                <a
                    onClick={onClick}
                    href={url}
                    className={container}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {imgLeftContainer ? (
                        <div className={imgLeftContainer}></div>
                    ) : (
                        ""
                    )}
                    {text}
                    {imgRightContainer ? (
                        <div className={imgRightContainer}></div>
                    ) : (
                        ""
                    )}
                </a>
            );
        }
    }
);

export default Button;
