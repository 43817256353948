import React from "react"
import styles from "./Burger.module.scss"

export const Burger = ({ open, setOpen }) => {
    
    return (
        <button
            className={`${styles.burger} ${open ? styles.burgerOpen : ""}`}
            onClick={() => setOpen(open)}
        >
            <div
                style={{
                    transform: `${open ? "rotate(46deg)" : "rotate(0)"}`,
                    background: `${open ? "#000000" : "#ed7e2e"}`,
                }}
            />
            <div
                style={{
                    opacity: `${open ? "0" : "1"}`,
                    transform: `${open ? "translateX(20px)" : "translateX(0)"}`,
                    background: `${open ? "#000000" : "#ed7e2e"}`,
                }}
            />
            <div
                style={{
                    transform: `${open ? "rotate(-46deg)" : "rotate(0)"}`,
                    background: `${open ? "#000000" : "#ed7e2e"}`,
                }}
            />
        </button>
    )
}
