import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
    const user = useSelector((state) => state.user);

    const isPartner =
        user.user && user.user.is_partner ? user.user.is_partner : false;

    if (user.token && !user.user) {
        return null;
    }

    if (!isPartner) {
        return <Navigate to="/profile/" replace />;
    }
    return children;
};
export default ProtectedRoute;
