import React, { useEffect, useState } from "react";

import "./Translate.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectLang } from "store/selectors/header";
import { setCurrency, setLang } from "store/slices/headerSlice";
import {
    availableLangs,
    getBrowserlang,
    initYTrasnlate,
    yaTranslateGetCode,
} from "services/lang";
import { selectCurrencies } from "store/selectors/catalog";

export const TranslateWidget = ({ onOpenModal }) => {
    const dispatch = useDispatch();

    const currentLang = useSelector(selectLang);

    const currencies = useSelector(selectCurrencies);

    useEffect(() => {
        let lang = yaTranslateGetCode();
        if (!lang) {
            lang = getBrowserlang();
        }
        const isValidLang = Object.keys(availableLangs).includes(lang);
        let currentCurrency = null;
        if (currencies)
            currentCurrency = currencies.find((item) => item.code == "USD");
        if (isValidLang) {
            if (lang == "EN" && currentCurrency)
                dispatch(setCurrency(currentCurrency));
            yaTranslateSetLang(lang);
        }
        initYTrasnlate();
    }, [currencies]);

    function yaTranslateSetLang(lang) {
        // Записываем выбранный язык в localStorage объект yt-widget
        // Writing the selected language to localStorage
        dispatch(setLang({ lang }));
    }

    const CurrentFlags = availableLangs[currentLang];
    if (!!currencies.length)
        return (
            <div className="lang " onClick={onOpenModal}>
                <div
                    id="ytWidget"
                    style={{
                        display: "none",
                    }}
                ></div>
                <div
                    className="lang__link lang__link_select"
                    data-lang-active=""
                >
                    {CurrentFlags && (
                        <CurrentFlags className="lang__img lang__img_select" />
                    )}
                </div>
            </div>
        );
};
