import {Button} from "antd"
import style from "./CustomButton.module.scss"
import classNames from "classnames"

const CustomButton = ({
    children,
    icon,
    small,
    primary,
    lined,
    secondary,
    linedBlack,
    fullWidth,
    onClick,
    classes,
    disabled,
    type,
    id
}) => {
    return (
        <Button
            className={classNames(style.custom_button, classes, {
                [style["custom_button_small"]]: small,
                [style["custom_button_primary"]]: primary,
                [style["custom_button_lined"]]: lined,
                [style["custom_button_secondary"]]: secondary,
                [style["custom_button_lined_black"]]: linedBlack,
                [style["custom_button_full_width"]]: fullWidth,
                [style["custom_button_disabled"]]: disabled,
            })}
            icon={icon}
            onClick={onClick}
            disabled={disabled}
            htmlType={type}
            id={id}
        >
            {children}
        </Button>
    )
}

export default CustomButton
