import Popup from "../../../../components/UI/Popup/Popup";
import MoneyWithdrawForm from "../../../../components/UI/Forms/MoneyWithdrawForm";
import React from "react";

const MoneyWithdrawPopup = ({
    visible,
    setVisibility,
    setResultVisibility,
}) => {
    const onSubmit = () => {
        setResultVisibility(true);
        setVisibility(false);
    };

    return (
        <Popup
            destroyOnClose
            open={visible}
            onCancel={() => setVisibility(false)}
            title={<p style={{ textAlign: "left" }}>Вывод средств</p>}
            footer={null}
        >
            <MoneyWithdrawForm
                onSubmit={onSubmit}
                onCancel={() => setVisibility(false)}
            />
        </Popup>
    );
};

export default MoneyWithdrawPopup;
