import style from "./RequisitesForm.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import CustomInput from "../../../../components/UI/Input/Input";
import CustomButton from "../../../../components/UI/CustomButton/CustomButton";
import { ReactSVG } from "react-svg";
import WarningIcon from "../../../../assets/icons/warning-icon.svg";
import UserIcon from "../../../../assets/icons/user.svg";
import DocumentIcon from "../../../../assets/icons/document.svg";
import Popup from "../../../../components/UI/Popup/Popup";
import {
    useGetPartnerBankQuery,
    usePostPartnerBankMutation,
    usePutPartnerBankMutation,
    useDeletePartnerBankMutation,
} from "services/partners";
import SingleBreadCrumb from "components/UI/SingleBreadCrumb/SingleBreadCrumb";

const RequisitesForm = () => {
    const { id } = useParams();
    const [requisites, setRequisites] = useState({ id });
    const [errors, setErrors] = useState({});
    const [isDeleteRequisitesModalVisible, setIsDeleteRequisitesModalVisible] =
        useState(false);
    const [formHasErrors, setFormHasErrors] = useState(false);

    const [postPartnerBank] = usePostPartnerBankMutation();
    const [putPartnerBank] = usePutPartnerBankMutation();
    const [deletePartnerBank] = useDeletePartnerBankMutation();

    const { data: partnersBankResponse } = useGetPartnerBankQuery(
        { id },
        { skip: !id }
    );

    useEffect(() => {
        if (partnersBankResponse) {
            setRequisites(partnersBankResponse);
        }
    }, [partnersBankResponse]);

    useEffect(() => {
        if (!Object.values(errors).filter(Boolean).length) {
            setFormHasErrors(false);
        }
    }, [errors]);

    const addForm =
        window.location.pathname === "/profile/partnerCabinet/requisites/add";

    const navigate = useNavigate();

    const handleChange = (name, value) => {
        setRequisites({ ...requisites, [name]: value });
        setErrors({ ...errors, [name]: null });
    };

    const handleSubmit = () => {
        setFormHasErrors(false);

        function partnerBankHandler(data) {
            if (data?.error) {
                if (data.error.status === 422) {
                    setFormHasErrors(true);
                }
                setErrors(data.error.data.errors);
            } else {
                setRequisites({});
                navigate("/profile/partnerCabinet");
            }
        }

        if (!id) postPartnerBank(requisites).then(partnerBankHandler);
        else putPartnerBank(requisites).then(partnerBankHandler);
    };

    return (
        <div className={style.requisites_form}>
            <SingleBreadCrumb
                title={"Назад"}
                path={"/profile/partnerCabinet"}
            />
            <div className={style.requisites_form__wrapper}>
                <h1>
                    {addForm ? "Добавить реквизиты" : "Редактировать реквизиты"}
                </h1>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.org_inn}
                        onChange={(value) => handleChange("org_inn", value)}
                        error={errors.org_inn}
                        placeholder={"ИНН"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                    <CustomInput
                        value={requisites.org_kpp}
                        onChange={(value) => handleChange("org_kpp", value)}
                        error={errors.org_kpp}
                        placeholder={"КПП"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.org_ogrn}
                        onChange={(value) => handleChange("org_ogrn", value)}
                        error={errors.org_ogrn}
                        placeholder={"ОГРН"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.org_name}
                        onChange={(value) => handleChange("org_name", value)}
                        error={errors.org_name}
                        placeholder={"Юридическое название организации"}
                        container={
                            style.requisites_form__row__full_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.org_address}
                        onChange={(value) => handleChange("org_address", value)}
                        error={errors.org_address}
                        placeholder={"Юридический адрес организации"}
                        container={
                            style.requisites_form__row__full_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.org_location}
                        onChange={(value) =>
                            handleChange("org_location", value)
                        }
                        error={errors.org_location}
                        placeholder={"Фактический адрес организации"}
                        container={
                            style.requisites_form__row__full_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form_title}>
                    <ReactSVG src={UserIcon} />
                    Контактная информация
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.contact_fio}
                        onChange={(value) => handleChange("contact_fio", value)}
                        error={errors.contact_fio}
                        placeholder={"Фио контактного лица"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                    <CustomInput
                        value={requisites.contact_job}
                        onChange={(value) => handleChange("contact_job", value)}
                        error={errors.contact_job}
                        placeholder={"Должность"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.contact_email}
                        onChange={(value) =>
                            handleChange("contact_email", value)
                        }
                        error={errors.contact_email}
                        placeholder={"E-mail"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                    <CustomInput
                        value={requisites.contact_tel}
                        onChange={(value) => handleChange("contact_tel", value)}
                        error={errors.contact_tel}
                        placeholder={"Телефон"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form_title}>
                    <ReactSVG src={DocumentIcon} />
                    Банк
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.bank_bik}
                        onChange={(value) => handleChange("bank_bik", value)}
                        error={errors.bank_bik}
                        placeholder={"БИК"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                    <CustomInput
                        value={requisites.bank_user_account}
                        onChange={(value) =>
                            handleChange("bank_user_account", value)
                        }
                        error={errors.bank_user_account}
                        placeholder={"Расчетный счет"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        value={requisites.bank_name}
                        onChange={(value) => handleChange("bank_name", value)}
                        error={errors.bank_name}
                        placeholder={"Наименование банка"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                    <CustomInput
                        value={requisites.bank_account}
                        onChange={(value) =>
                            handleChange("bank_account", value)
                        }
                        error={errors.bank_account}
                        placeholder={"Корреспондентский счет"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>
                <div className={style.requisites_form__buttons}>
                    {addForm ? (
                        <>
                            <CustomButton
                                classes={[
                                    style.requisites_form__buttons__left,
                                    style.requisites_form__buttons__button,
                                ]}
                                primary
                                onClick={handleSubmit}
                            >
                                Добавить
                            </CustomButton>
                            <CustomButton
                                classes={style.requisites_form__buttons__button}
                                linedBlack
                                onClick={() =>
                                    navigate("/profile/partnerCabinet")
                                }
                            >
                                Отмена
                            </CustomButton>
                        </>
                    ) : (
                        <>
                            <CustomButton
                                classes={[
                                    style.requisites_form__buttons__left,
                                    style.requisites_form__buttons__button,
                                ]}
                                primary
                                onClick={handleSubmit}
                            >
                                Сохранить
                            </CustomButton>
                            <CustomButton
                                classes={style.requisites_form__buttons__button}
                                linedBlack
                                onClick={() =>
                                    setIsDeleteRequisitesModalVisible(true)
                                }
                            >
                                Удалить
                            </CustomButton>
                        </>
                    )}
                </div>

                {formHasErrors && (
                    <div className={style.requisites_form__warning}>
                        <ReactSVG src={WarningIcon} />
                        <p>Проверьте правильность заполнения всех полей</p>
                    </div>
                )}

                <Popup
                    open={isDeleteRequisitesModalVisible}
                    onCancel={() => setIsDeleteRequisitesModalVisible(false)}
                    title={
                        <>
                            <p>Удалить реквизиты?</p>
                            <span>Все данные безвозвратно удалятся</span>
                        </>
                    }
                    footer={
                        <div>
                            <CustomButton
                                linedBlack
                                onClick={() =>
                                    setIsDeleteRequisitesModalVisible(false)
                                }
                            >
                                Отмена
                            </CustomButton>
                            <CustomButton
                                primary
                                onClick={() => {
                                    deletePartnerBank(id).finally(() => {
                                        setIsDeleteRequisitesModalVisible(
                                            false
                                        );
                                        navigate("/profile/partnerCabinet");
                                    });
                                }}
                            >
                                Удалить
                            </CustomButton>
                        </div>
                    }
                ></Popup>
            </div>
        </div>
    );
};

export default RequisitesForm;
