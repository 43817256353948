import CustomButton from "../../../../components/UI/CustomButton/CustomButton"
import styles from "./Empty.module.scss"
import checkmark from "../../../../assets/icons/checkmarkIcon.png"
import {Link} from "react-router-dom"

const Empty = () => {
    return (
        <div className={styles.empty}>
            <h2 className={styles.empty__title}>Бронирований пока нет</h2>
            <p className={styles.empty__text}>
                Сделайте первое бронирование, выбрав город, даты и пассажиров
            </p>
            <Link to={'/'}>
                <CustomButton primary classes={styles.empty__btn}>
                    На главную
                </CustomButton>
            </Link>
            <img src={checkmark} alt="checkmarkIcon" />
        </div>
    )
}

export default Empty
