import { ReactSVG } from "react-svg";
import styles from "./ReviewsMain.module.scss";
import OrangeStar from "../../../assets/icons/orangeStar.svg";
import GreenStar from "../../../assets/icons/greenStar.svg";
import Rating from "../../../components/UI/Rating/Rating";
import CustomButton from "../../../components/UI/CustomButton/CustomButton";

const ReviewItem = ({ fullName, date, rating, text }) => {
    return (
        <div className={styles.reviews__item}>
            <h3 className={styles.reviews__item_title}>{fullName}</h3>
            <p className={styles.reviews__item_date}>{date}</p>
            <div className={styles.reviews__item_stars}>
                <Rating disabled defaultValue={rating} />
            </div>
            <p className={styles.reviews__item_text}>{text}</p>
        </div>
    );
};

const ReviewsMain = () => {
    const reviewsContent = [
        {
            key: 0,
            fullName: "Анна",
            date: "13.08.23",
            rating: "5",
            text: "Как я раньше жила без сайта AirSurfer! Сделать визу для путешествий и командировок всегда было головной болью. Собирать документы, ждать подтверждения брони, " +
                "оплачивать билеты… Узнала о сервисе AirSurfer, настоящем помощнике для путешественников. Тут можно получить маршрутную квитанцию и подтвержденную бронь отеля. " +
                "Сайт очень удобный и простой в использовании, доступный и понятный. Есть разные тарифы, а также служба поддержки, которая оперативно отвечает на вопросы. " +
                "Очень довольна, что появились такие услуги. Обязательно буду пользоваться.",
        },
        {
            key: 1,
            fullName: "Юлия",
            date: "11.08.23",
            rating: "5",
            text: "В этом году запланировали с мужем поездку в Париж. Для оформления визы во Францию потребовали маршрутную квитанцию, обратились к сервису AirSurfer.\n" +
                "Супер удобный тариф сервиса - AirSurf Pro - для тех, кому нужны подтвержденные брони отеля и авиабилетов. Невероятно удобно! Огромное спасибо. Сервис очень советую.",
        },
        {
            key: 2,
            fullName: "Julia",
            date: "09.08.23",
            rating: "5",
            text: "Оформляла визу в Словакию для деловой поездки в Братиславу. Для подачи заявления на визу были необходимы брони гостиницы и авиабилетов. \n" +
                "Воспользовалась сайтом AirSurfer для получения броней – хочу отметить, что сайт очень удобный и простой в использовании! Помогли получить визу быстро и без проблем.\n" +
                "Очень рекомендую AirSurfer, буду советовать друзьям и знакомым. Спасибо!",
        },
        {
            key: 3,
            fullName: "Павел",
            date: "19.07.23",
            rating: "5",
            text: "Тур виза в Малазию\n" +
                "Мы записались на ближайшую дату через сайт консульства Тайланда в Куала-Лумпуре. На каждого из взрослых следующие доки:\n" +
                "Заполненная электронная анкета\n" +
                "Чек-лист с официального сайта консульства\n" +
                "Фотография\n" +
                "Копия первой страницы паспорта + контракта на аренду жилья\n" +
                "Бронь билетов в Тай + бронь из Тая. Делали через Серфер. Брали максимальную бронь на 14 дней. Держалась по итогу 9-10 дней\n" +
                "Выписка со счета, движение д/c за последние 3 месяца\n" +
                "Получили визу быстро. Номер выдали на КПП, по нему и выдавали. Никаких записей отдельно не нужно было делать. По прилету в Тай ничего не спрашивали дополнительно",
        },
    ];

    return (
        <section className={styles.reviews}>
            <div className="container">
                <div
                    className={`${styles.reviews__wrapper} ${styles.reviews__container}`}
                >
                    <div className={styles.reviews__header}>
                        <div className={styles.reviews__header_text}>
                            <h2 className={styles.reviews__header_title}>
                                И все это от надежного сервиса
                            </h2>
                            <p className={styles.reviews__header_note}>
                                Пользуйтесь нашим сервисом на основе отзывов путешественников
                            </p>
                        </div>
                        <div className={styles.reviews__header_info}>
                            <div className={styles.reviews__header_leftSide}>
                                <div
                                    className={styles.reviews__header_whiteStar}
                                >
                                    <ReactSVG src={OrangeStar} />
                                </div>
                                <p className={styles.reviews__header_rate}>
                                    4.8
                                </p>
                            </div>
                            <div className={styles.reviews__header_rightSide}>
                                <div
                                    className={styles.reviews__header_greenStar}
                                >
                                    <ReactSVG src={GreenStar} />
                                </div>
                                <a href="https://www.trustpilot.com/review/airsurfer.co" target="_blank" rel="noopener noreferrer">
                                    <p className={styles.reviews__header_text}>
                                        Trustpilot
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.reviews__items}>
                        {reviewsContent.map((item) => {
                            return <ReviewItem key={item.key} {...item} />;
                        })}
                    </div>
                    <CustomButton
                        lined={true}
                        classes={styles.reviews__btn}
                        onClick={()=> window.open("https://www.trustpilot.com/review/airsurfer.co", "_blank")}>
                        Смотреть все
                    </CustomButton>
                </div>
            </div>
        </section>
    );
};

export default ReviewsMain;
