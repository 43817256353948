import { Form, Radio } from "antd";
import Input from "../Input/Input";
import CustomButton from "../CustomButton/CustomButton";
import RadioGroup from "../RadioGroup/RadioGroup";
import style from "./Form.module.scss";
import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import CustomInput from "../Input/Input";
import {usePostPartnerMessageMutation} from "../../../services/partners";
import Spinner from "../Spinner/Spinner";
import {makeMask} from "../../../utils/makeMask";

const requiredFields = ["link", "type"];
const requiredFieldsRequisites = [
    "org_inn",
    "org_kpp",
    "org_ogrn",
    "org_name",
    "org_address",
    "org_location",
    "contact_fio",
    "contact_job",
    "contact_email",
    "contact_tel",
    "bank_bik",
    "bank_user_account",
    "bank_account",
    "bank_name",
];
const requiredFieldsCard = ["card_bank_name", "card_number"];

const BecomeAPartnerForm = ({ onSubmit, onSuccess }) => {

    const phoneMask = "(000) 000-00-00";
    // const creditCardMask = "0000 0000 0000 0000";

    const [postPartnersMessage] = usePostPartnerMessageMutation();

    const [payment, setPayment] = useState({});
    const [errors, setErrors] = useState({});
    const [formHasErrors, setFormHasErrors] = useState(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState(null);
    //
    const handlerResponseErrors = ( errors ) => {
        const newErrors = {...errors}
        Object.keys(errors).forEach( (error) => {
            newErrors[error] = errors[error][0];
        } )
        setErrors(newErrors);
    }

    const onFinish = () => {

        let isValid = true;
        const newErrors = { ...errors };

        const relevantFields =
            payment.type === "credit-card"
                ? requiredFieldsCard
                : requiredFieldsRequisites;

        if (!payment.type) {
            newErrors["type"] = "Выберите способ пополнения";
            isValid = false;
        } else {
            for (let field of relevantFields) {
                if (!payment[field]) {
                    newErrors[field] = "Поле обязательно";
                    isValid = false;
                }
            }
        }

        for (let field of requiredFields) {
            if (!payment[field]) {
                newErrors[field] = "Поле обязательно";
                isValid = false;
            }
        }

        setErrors(newErrors);

        if (isValid) {

            const relevantPayment = relevantFields.reduce((obj, field) => {
                obj[field] = payment[field];
                return obj;
            }, {});

            const dataToSend = {
                ...relevantPayment,
                partner_url_location: payment.link,
                take_out_type: payment.type,
                card_number: payment.card_number ? +payment.card_number.replaceAll(' ', '') : undefined
            };

            // console.log( dataToSend );

            setIsLoadingRequest( true );

            postPartnersMessage(dataToSend).unwrap()
                .then( (resp) => {

                    console.log( resp );

                    setPayment({});
                    setErrors({});
                    onSuccess();
                    setFormHasErrors(false);
                    setIsLoadingRequest( false );

                } ).catch( (err) => {

                    setIsLoadingRequest( false );

                    console.log( err );

                    if ( err.data && err.data.errors ) {
                        handlerResponseErrors(err.data.errors);
                    }

                });

        } else {
            setFormHasErrors(true);
        }
    };

    const onChange = (value, field) => {
        setPayment({ ...payment, [field]: value });
        setErrors({ ...errors, [field]: null });
        setFormHasErrors(false);
    };

    return (
        <Form name="login_form" className={style.form}>
            <Input
                placeholder="Место размещения ссылки"
                value={payment.link}
                error={errors.link}
                name={"partner_input_link"}
                onChange={(value) => onChange(value, "link")}
            />
            <p className={style.form__text}>
                Например: Яндекс Дзен, личный сайт, YouTube канал
            </p>
            <p className={style.partner__subtitle}>Способ пополнения</p>
            <RadioGroup
                value={payment.type}
                name={"partner_input_type"}
                onChange={(event) => onChange(event.target.value, "type")}
            >
                <Radio value={"credit-card"}>На карту</Radio>
                <Radio value={"bank"}>По реквизитам</Radio>
            </RadioGroup>
            {!!errors.type && (
                <p className={style.form__errorText}>
                    Выберите способ пополнения
                </p>
            )}
            {payment.type === "credit-card" && (
                <div className={style.form__req}>
                    <h2 className={style.form__card_title}>Данные карты</h2>
                    <CustomInput
                        placeholder={"Номер карты"}
                        value={payment.card_number}
                        name={ `partner_input_card_number` }
                        onChange={(value) => onChange(value, "card_number")}
                        error={errors.card_number}
                        mask={"0000 0000 0000 0000"}
                    />
                    <CustomInput
                        placeholder={"Название банка"}
                        value={payment.card_bank_name}
                        name={"partner_input_card_bank_name"}
                        onChange={(value) => onChange(value, "card_bank_name")}
                        error={errors.card_bank_name}
                    />
                </div>
            )}
            {payment.type === "bank" && (
                <div className={style.form__req}>
                    <h2 className={style.form__card_title}>Реквизиты</h2>
                    <div className={style.form__req_tree}>
                        <CustomInput
                            placeholder={"ИНН"}
                            value={payment.org_inn}
                            onChange={(value) => onChange(value, "org_inn")}
                            label={"ИНН"}
                            error={errors.org_inn}
                            name={"partner_input_org_inn"}
                        />
                        <CustomInput
                            placeholder={"КПП"}
                            value={payment.org_kpp}
                            onChange={(value) => onChange(value, "org_kpp")}
                            label={"КПП"}
                            error={errors.org_kpp}
                            name={"partner_input_org_kpp"}
                        />
                        <CustomInput
                            placeholder={"ОГРН"}
                            value={payment.org_ogrn}
                            onChange={(value) => onChange(value, "org_ogrn")}
                            label={"ОГРН"}
                            error={errors.org_ogrn}
                            name={"partner_input_org_ogrn"}
                        />
                    </div>
                    <div className={style.form__req_one}>
                        <CustomInput
                            placeholder={"Юридический название организации"}
                            value={payment.org_name}
                            onChange={(value) => onChange(value, "org_name")}
                            label={"Юридический название организации"}
                            error={errors.org_name}
                            name={"partner_input_org_name"}
                        />
                        <CustomInput
                            placeholder={"Юридический адрес организации"}
                            value={payment.org_address}
                            onChange={(value) => onChange(value, "org_address")}
                            label={"Юридический адрес организации"}
                            error={errors.org_address}
                            name={"partner_input_org_address"}
                        />
                        <CustomInput
                            placeholder={"Фактический адрес организации"}
                            value={payment.org_location}
                            onChange={(value) => onChange(value, "org_location")}
                            label={"Фактический адрес организации"}
                            error={errors.org_location}
                            name={"partner_input_org_location"}
                        />
                    </div>
                    <h2 className={style.form__card_title}>
                        Контактная информация
                    </h2>
                    <div className={style.form__req_tree}>
                        <CustomInput
                            placeholder={"ФИО контактного лица"}
                            value={payment.contact_fio}
                            onChange={(value) =>
                                onChange(value, "contact_fio")
                            }
                            label={"ФИО контактного лица"}
                            error={errors.contact_fio}
                            name={"partner_input_contact_fio"}
                        />
                        <CustomInput
                            placeholder={"Должность"}
                            value={payment.contact_job}
                            onChange={(value) =>
                                onChange(value, "contact_job")
                            }
                            label={"Должность"}
                            error={errors.contact_job}
                            name={"partner_input_contact_job"}
                        />
                        <CustomInput
                            placeholder={"E-mail"}
                            value={payment.contact3}
                            onChange={(value) =>
                                onChange(value, "contact_email")
                            }
                            error={errors.contact_email}
                            label={"E-mail"}
                            name={"partner_input_contact_email"}
                        />
                        <CustomInput
                            placeholder={"Телефон"}
                            value={payment.contact_tel}
                            onChange={(value) =>
                                onChange(value, "contact_tel")
                            }
                            error={errors.contact_tel}
                            label={"Телефон"}
                            name={"partner_input_contact_tel"}
                        />
                    </div>
                    <h2 className={style.form__card_title}>Банк</h2>
                    <div className={style.form__req_tree}>
                        <CustomInput
                            placeholder={"БИК"}
                            value={payment.bank_bik}
                            onChange={(value) => onChange(value, "bank_bik")}
                            label={"БИК"}
                            error={errors.bank_bik}
                            name={"partner_input_bank_bik"}
                        />
                        <CustomInput
                            placeholder={"Расчетный счет"}
                            value={payment.bank_user_account}
                            onChange={(value) => onChange(value, "bank_user_account")}
                            label={"Расчетный счет"}
                            error={errors.bank_user_account}
                            name={"partner_input_bank_user_account"}
                        />
                        <CustomInput
                            placeholder={"Наименование банка"}
                            value={payment.bank_name}
                            onChange={(value) => onChange(value, "bank_name")}
                            label={"Наименование банка"}
                            error={errors.bank_name}
                            name={"partner_input_bank_name"}
                        />
                        <CustomInput
                            placeholder={"Корреспондентский счет"}
                            name={ 'partner_input_bank_account' }
                            value={payment.bank_account}
                            onChange={(value) => onChange(value, "bank_account")}
                            label={"Корреспондентский счет"}
                            error={errors.bank_account}
                        />
                    </div>
                </div>
            )}

            {
                isLoadingRequest && (
                    <div className={style.partner__spinner}>
                        <Spinner />
                    </div>

                )
            }

            <div className={style.form__btns}>
                <CustomButton
                    primary
                    onClick={() => onFinish()}
                    className="login-form-button"
                >
                    Стать партнером
                </CustomButton>
            </div>
            {!!formHasErrors && (
                <div className={style.warning}>
                    <ReactSVG src={WarningIcon} />
                    <p>Проверьте правильность заполнения всех полей</p>
                </div>
            )}
        </Form>
    );
};
export default BecomeAPartnerForm;
