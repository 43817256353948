import styles from "./TicketSpinner.module.scss"

const TicketSpinner = ({cityFrom, cityTo}) => {
    return (
        <div className={styles.spinner}>
            <div className={styles.progress_bar}>
                <div className={styles.progress_bar__active}></div>
            </div>
            <p className={styles.spinner__title}>
                Ищем для вас лучшие предложения!{" "}
                <br/>
                <span>
                    {cityFrom} → {cityTo}
                </span>
            </p>
        </div>
    )
}

export default TicketSpinner
