import { createSlice } from "@reduxjs/toolkit";
import { langList } from "services/lang";
import {
    cookieExpires,
    cookieKeys,
    localStorageKeys,
    setCookie,
} from "utils/cookie";

const initialState = {
    isOpened: false,
    isModalOpened: false,
    currency: undefined,
    lang: langList.ru,
};

const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.isOpened = action.payload;
        },
        setModalOpen: (state, action) => {
            state.isModalOpened = action.payload;
        },
        setCurrency: (state, action) => {
            setCookie(cookieKeys.currency, action.payload?.code, {
                expires: cookieExpires.never,
            });
            state.currency = action.payload;
        },
        setLang: (state, action) => {
            const { lang } = action.payload;
            setCookie(cookieKeys.langKey, lang.toUpperCase());
            localStorage.setItem(
                localStorageKeys.langKey,
                JSON.stringify({
                    lang: lang.toLowerCase(),
                    active: true,
                })
            );
            state.lang = lang;
        },
    },
});

export const { setOpen, setModalOpen, setCurrency, setLang } =
    headerSlice.actions;
export default headerSlice.reducer;
