import styles from "./Spinner.module.scss";
import classNames from "classnames";

const Spinner = ({className}) => {
  return (
    <div className={classNames(styles.spinner, className)}>
      <div className={styles.lds_ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Spinner