import { Link } from "react-router-dom";
import Button from "../UI/Button/Button";
import styles from "./Footer.module.scss";
import preview from "../../assets/img/payment_icons.png";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles.footer__wrapper}>
                    <div className={styles.footer__top}>
                        <ul className={styles.footer__top_list}>
                            <li className={styles.footer__logo}>
                                <Button
                                    type={"Link"}
                                    url={"/"}
                                    imgLeftContainer={styles.footer__logo_img}
                                    imgRightContainer={styles.footer__logo_text}
                                    container={styles.footer__logo}
                                />
                            </li>
                            <li className={styles.footer__logo_title}>
                                <p>
                                    Бронирование авиабилетов для визы без оплаты
                                </p>

                                <p>
                                    <br />
                                    <br />
                                    ИП Гнатюк Анатолий Дмитриевич
                                    <br /> (ОГРНИП: 323265100162401)
                                </p>
                            </li>
                        </ul>
                        <ul className={styles.footer__top_list}>
                            <li className={styles.footer__about_title}>
                                <Link to="/">Авиабилеты и отели для визы</Link>
                            </li>
                            <li className={styles.footer__about_text}>
                                <Link to="/">Авиабилеты и отели для визы</Link>
                            </li>
                            <li className={styles.footer__about_text}>
                                <Link to="/faq">Вопросы и ответы</Link>
                            </li>
                            <li className={styles.footer__about_text}>
                                <a
                                    href="https://www.trustpilot.com/review/airsurfer.co"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Отзывы
                                </a>
                            </li>
                            <li className={styles.footer__about_text}>
                                <Link to="/products">Тарифы</Link>
                            </li>
                            <li className={styles.footer__about_text}>
                                <Link to="/partner">Партнерская программа</Link>
                            </li>
                        </ul>
                        <ul className={styles.footer__top_list}>
                            <li className={styles.footer__top_soon}>
                                <Button
                                    container={styles.button__disabled}
                                    text={"Документы для визы"}
                                    imgRightContainer={
                                        styles.button__disabled_info
                                    }
                                />
                            </li>
                            <li className={styles.footer__top_soon}>
                                <Button
                                    container={styles.button__disabled}
                                    text={"Страхование за границей"}
                                    imgRightContainer={
                                        styles.button__disabled_info
                                    }
                                />
                            </li>
                        </ul>
                        <ul className={styles.footer__top_list}>
                            <li className={styles.footer__support_title}>
                                <Link to={"contacts"}>Контакты</Link>
                            </li>
                            <li className={styles.footer__support_email}>
                                <a href="mailto:support@airsurfer.co">
                                    support@airsurfer.co
                                </a>
                            </li>
                            <li className={styles.footer__support_email}>
                                <a
                                    href="https://t.me/airsurfer_bot"
                                    target="black"
                                >
                                    Telegram-бот
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.footer__divider} />
                    <div className={styles.footer__bottom}>
                        <ul className={styles.footer__bottom_list}>
                            <li className={styles.footer__bottom_item}>
                                <img
                                    src={preview}
                                    className={styles.footer__payment_icons}
                                />
                            </li>
                            <li className={styles.footer__bottom_item}>
                                <a href="/contractOffer">Договор оферты</a>
                            </li>
                            <li className={styles.footer__bottom_item}>
                                <a href="/returnPolicy">
                                    Политика безопасности и возврата
                                </a>
                            </li>
                            <li className={styles.footer__bottom_item}>
                                <a href="/privacyPolicy">
                                    Обработка персональных данных
                                </a>
                            </li>
                            <li className={styles.footer__bottom_item}>
                                <p>© 2022-2024. AirSurfer</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
