import { Tabs } from 'antd';
import style from "./Tabs.module.scss";

const onChange = (key) => {
  console.log(key);
};

const TabsItems = ({items}) => {
  return(
    <Tabs className={style.tabs_items} type="card" items={items} onChange={onChange}/>
  )
}

export default TabsItems