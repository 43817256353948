import {Table} from "antd";
import style from "./CustomTable.module.scss";

const CustomTable = ({columns, dataSource, onChange}) => {

  return (
    <Table
      pagination={false}
      className={style.custom_table}
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
    />
  )
}

export default CustomTable;