/**
 * Цена тарифа по коду валюты
 */
export const getTariffPrice = (currencyCode, tariff) => {
    if (!currencyCode) return 0;
    const priceObj = tariff.prices.find((item) => item.code == currencyCode);
    if (!priceObj) return 0;
    return priceObj.price;
};

/**
 * Тарифы по полетам
 */
export const getTariffsFlight = (tariffs) => {
    return tariffs.filter((item) => !item.is_hotel);
};

/**
 * Тарифы по отелям
 */
export const getTariffsHotel = (tariffs) => {
    return tariffs.filter((item) => item.is_hotel);
};

export const getPassengerAmountString = function (amount) {
    if (amount === undefined || amount === 1) {
        return "пассажир";
    } else if (amount >= 2 && amount <= 4) {
        return "пассажира";
    } else {
        return "пассажиров";
    }
};
export const pluralizeDay = (number) => {
    var cases = [2, 0, 1, 1, 1, 2];
    var titles = ["день", "дня", "дней"];
    return titles[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[Math.min(number % 10, 5)]
    ];
};
