import { orderStatuses } from "configs/order";
import style from "./Links.module.scss";


const Order = ({ orderItem }) => {
    const {
        id,
        order_number,
        updated_at,
        price,
        commission,
        status_name,
        status,
    } = orderItem;
    return (
        <div className={style.order} key={id}>
            <div className={style.order_info}>
                <div className={style.order_info_item}>
                    <div className={style.order_info_item_title}>№ Заказа</div>
                    <div className={style.order_info_item_value}>
                        №{order_number}
                    </div>
                </div>
                <div className={style.order_info_item}>
                    <div className={style.order_info_item_title}>
                        Сумма заказов
                    </div>
                    <div className={style.order_info_item_value}>{price} ₽</div>
                </div>
                <div className={style.order_info_item}>
                    <div className={style.order_info_item_title}>
                        Дата и время
                    </div>
                    <div className={style.order_info_item_value}>
                        {updated_at}
                    </div>
                </div>
                <div className={style.order_info_item}>
                    <div className={style.order_info_item_title}>
                        Комиссия партнера
                    </div>
                    <div className={style.order_info_item_value}>
                        {status === orderStatuses.EXPIRED && <>{commission}₽</>}
                        {status !== orderStatuses.EXPIRED && (
                            <>0₽ ({commission}₽)</>
                        )}
                    </div>
                </div>
                <div className={style.order_info_item}>
                    <div className={style.order_info_item_title}>
                        Статус заказа
                    </div>
                    <div className={style.order_info_item_value}>
                        {status_name}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order;
