import { Outlet } from "react-router-dom";
import style from "./Profile.module.scss";
import ProfileMenu from "./ProfileMenu/ProfileMenu";

const Profile = () => {
    return (
        <div className={style.profile_page}>
            <div className="container">
                <div className={style.profile_page__wrapper}>
                    <aside className={style.profile_page_menu}>
                        <ProfileMenu />
                    </aside>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Profile;
