import React, { useState, useEffect } from "react";
import style from "./ProfileMenu.module.scss";
import ProfileIcon from "../../../assets/icons/profile-icon.png";
import SettingsIcon from "../../../assets/icons/profile-settings-icon.svg";
import BookingIcon from "../../../assets/icons/profile-booking-icon.svg";
import PassengersIcon from "../../../assets/icons/profile-passengers-icon.svg";
import LinkIcon from "../../../assets/icons/profile-link-icon.svg";
import BalanceIcon from "../../../assets/icons/profile-balance-icon.svg";
import ExitIcon from "../../../assets/icons/profile-exit-icon.svg";
import { ReactSVG } from "react-svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../store/slices/user";
import { useLogoutMutation } from "../../../services/user";
import { resetCache } from "api";
import classNames from "classnames";

const menu = [
    {
        id: 1,
        label: (
            <div className={style.profile_menu_items_item}>
                <ReactSVG src={SettingsIcon} /> Настройки профиля
            </div>
        ),
        link: "/profile/settings",
        private: false,
    },
    {
        id: 2,
        label: (
            <div className={style.profile_menu_items_item}>
                <ReactSVG src={BookingIcon} /> Бронирования
            </div>
        ),
        link: "/profile/booking",
        private: false,
    },
    {
        id: 3,
        label: (
            <div className={style.profile_menu_items_item}>
                <ReactSVG src={PassengersIcon} /> Пассажиры
            </div>
        ),
        link: "/profile/passengers",
        private: false,
    },
    {
        id: 4,
        label: (
            <div className={style.profile_menu_items_item}>
                <ReactSVG src={LinkIcon} /> Партнерские ссылки/промокоды
            </div>
        ),
        link: "/profile/links",
        firstPartnerLink: true,
        private: true,
    },
    {
        id: 5,
        label: (
            <div className={style.profile_menu_items_item}>
                <ReactSVG src={BalanceIcon} /> Баланс
            </div>
        ),
        link: "/profile/balance",
        private: true,
    },
    {
        id: 6,
        label: (
            <div className={style.profile_menu_items_item}>
                <ReactSVG src={SettingsIcon} /> Настройки кабинета партнера
            </div>
        ),
        link: "/profile/partnerCabinet",
        private: true,
    },
];

const ProfileMenu = React.forwardRef(({ onClick, dropdown }, ref) => {
    const location = useLocation();

    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const [logout] = useLogoutMutation();

    const user = useSelector((state) => state.user.user);
    const profile = useSelector((state) => state.user.profile);

    const [isPartner, setPartner] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logoutUser());
        logout({ token });
        resetCache(dispatch);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/");
    };

    useEffect(() => {
        if (user) {
            setPartner(user.is_partner);
        }
    }, [user]);

    return (
        <div
            ref={ref}
            className={classNames(
                style.profile_menu,
                dropdown ? style.profile_menu_dropdown : ""
            )}
        >
            <div className={style.profile_menu_header} onClick={onClick}>
                <button className={style.burger}>
                    <div
                        style={{
                            transform: "rotate(46deg)",
                            background: "#000000",
                        }}
                    />
                    <div
                        style={{
                            opacity: "0",
                            transform: "translateX(20px)",
                            background: "#000000",
                        }}
                    />
                    <div
                        style={{
                            transform: "rotate(-46deg)",
                            background: "#000000",
                        }}
                    />
                </button>
                Личный кабинет
            </div>
            {!dropdown && (
                <img
                    className={style.profile_menu__userImg}
                    alt="profileIcon"
                    src={ProfileIcon}
                />
            )}
            {profile && (
                <p className={style.profile_menu_username}>
                    {profile.firstname} {profile.lastname}
                </p>
            )}
            {user && <p className={style.profile_menu_email}>{user.email}</p>}
            <div className={style.profile_menu_items}>
                {menu.map((item) => (
                    <div key={item.id}>
                        {item.firstPartnerLink && isPartner && (
                            <p className={style.profile_menu_items_partner}>
                                КАБИНЕТ ПАРТНЕРА
                            </p>
                        )}
                        {(!item.private || isPartner) && (
                            <div
                                key={item.id}
                                className={
                                    location.pathname.indexOf(item.link) !==
                                        -1 && item.link !== "/"
                                        ? style.profile_menu_items_item_active
                                        : null
                                }
                            >
                                <Link
                                    onClick={onClick ? () => onClick() : null}
                                    to={item.link}
                                >
                                    {item.label}
                                </Link>
                            </div>
                        )}
                    </div>
                ))}
                <div>
                    <a
                        onClick={() => {
                            handleLogout();
                            onClick && onClick();
                        }}
                    >
                        <div className={style.profile_menu_items_item}>
                            <ReactSVG src={ExitIcon} /> Выход
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
});

export default ProfileMenu;
