import style from "./RequisitesForm.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import CustomInput from "../../../../components/UI/Input/Input";
import CustomButton from "../../../../components/UI/CustomButton/CustomButton";
import { ReactSVG } from "react-svg";
import WarningIcon from "../../../../assets/icons/warning-icon.svg";
import {
    usePostPartnerCreditCardMutation,
    usePutPartnerCreditCardMutation,
    useGetPartnerCreditCardQuery,
    useDeletePartnerCreditCardMutation,
} from "services/partners";
import Popup from "../../../../components/UI/Popup/Popup";
import SingleBreadCrumb from "components/UI/SingleBreadCrumb/SingleBreadCrumb";
import { getDirtyFields } from "utils/getDirtyFields";

const unmask = (value) => value?.replace(/[^\d]/g, "");

const CardForm = () => {
    const { id } = useParams();
    const [card, setCard] = useState({ id });
    const [oldValue, setOldValue] = useState({});
    const [dirtyValue, setDirtyValue] = useState({});
    const [errors, setErrors] = useState({});
    const [formHasErrors, setFormHasErrors] = useState(false);
    const [isDeleteCardModalVisible, setIsDeleteCardModalVisible] =
        useState(false);
    const [postPartnerCreditCard] = usePostPartnerCreditCardMutation();
    const [putPartnerCreditCard] = usePutPartnerCreditCardMutation();
    const [deletePartnerCreditCard] = useDeletePartnerCreditCardMutation();
    const { data: partnersCreditCardResponse } = useGetPartnerCreditCardQuery(
        { id },
        { skip: !id }
    );

    const addForm =
        window.location.pathname === "/profile/partnerCabinet/cards/add";

    const navigate = useNavigate();

    const handleChange = useCallback((name, value) => {
        setCard((card) => ({ ...card, [name]: value }));
        setErrors((errors) => ({ ...errors, [name]: null }));
    }, []);

    useEffect(() => {
        if (!Object.values(errors).filter(Boolean).length) {
            setFormHasErrors(false);
        }
    }, [errors]);

    useEffect(() => {
        const dirtyFields = getDirtyFields(oldValue, card);
        setDirtyValue(dirtyFields);
    }, [card, oldValue]);

    useEffect(() => {
        if (partnersCreditCardResponse) {
            handleChange("card_number", partnersCreditCardResponse.card_number);
            handleChange(
                "card_bank_name",
                partnersCreditCardResponse.card_bank_name
            );
            setOldValue((card) => ({
                ...card,
                card_number: partnersCreditCardResponse.card_number,
            }));
            setOldValue((card) => ({
                ...card,
                card_bank_name: partnersCreditCardResponse.card_bank_name,
            }));
        }
    }, [handleChange, partnersCreditCardResponse]);

    const handleSubmit = () => {
        setFormHasErrors(false);
        if (
            dirtyValue["card_number"] &&
            unmask(card.card_number)?.length < 16
        ) {
            setErrors((errors) => ({
                ...errors,
                card_number: "Номер карты не может быть меньше 16 символов",
            }));
            return;
        }

        function creaditCardHandler(data) {
            if (data?.error) {
                if (data.error.status === 422) {
                    setFormHasErrors(true);
                }
                setErrors(data.error.data.errors);
            } else {
                setCard({});
                navigate("/profile/partnerCabinet");
            }
        }
        const data = { ...card, card_number: unmask(card.card_number) };
        if (!id) postPartnerCreditCard(data).then(creaditCardHandler);
        else if (
            Object.keys(dirtyValue).filter((name) => name !== "id").length
        ) {
            const data = {
                ...dirtyValue,
            };
            if (data["card_number"]) {
                data["card_number"] = unmask(data["card_number"]);
            }
            putPartnerCreditCard(data).then(creaditCardHandler);
        }
    };

    return (
        <div className={style.requisites_form}>
            <SingleBreadCrumb
                title={"Назад"}
                path={"/profile/partnerCabinet"}
            />
            <div className={style.requisites_form__wrapper}>
                <h1>{addForm ? "Добавить карту" : "Редактировать карту"}</h1>
                <div className={style.requisites_form__row}>
                    <CustomInput
                        isCard
                        value={card.card_number}
                        onChange={(value) => handleChange("card_number", value)}
                        error={errors.card_number}
                        placeholder={"Номер карты"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                    <CustomInput
                        value={card.card_bank_name}
                        onChange={(value) =>
                            handleChange("card_bank_name", value)
                        }
                        error={errors.card_bank_name}
                        placeholder={"Название банка"}
                        container={
                            style.requisites_form__row__half_width_element
                        }
                    />
                </div>

                <div className={style.requisites_form__buttons}>
                    {addForm ? (
                        <>
                            <CustomButton
                                classes={[
                                    style.requisites_form__buttons__left,
                                    style.requisites_form__buttons__button,
                                ]}
                                primary
                                onClick={handleSubmit}
                            >
                                Добавить
                            </CustomButton>
                            <CustomButton
                                classes={style.requisites_form__buttons__button}
                                linedBlack
                                onClick={() =>
                                    navigate("/profile/partnerCabinet")
                                }
                            >
                                Отмена
                            </CustomButton>
                        </>
                    ) : (
                        <>
                            <CustomButton
                                classes={[
                                    style.requisites_form__buttons__left,
                                    style.requisites_form__buttons__button,
                                ]}
                                primary
                                disabled={
                                    Object.keys(dirtyValue).filter(
                                        (name) => name !== "id"
                                    ).length === 0
                                }
                                onClick={handleSubmit}
                            >
                                Сохранить
                            </CustomButton>
                            <CustomButton
                                classes={style.requisites_form__buttons__button}
                                linedBlack
                                onClick={() =>
                                    setIsDeleteCardModalVisible(true)
                                }
                            >
                                Удалить
                            </CustomButton>
                        </>
                    )}
                </div>

                {formHasErrors && (
                    <div className={style.requisites_form__warning}>
                        <ReactSVG src={WarningIcon} />
                        <p>Проверьте правильность заполнения всех полей</p>
                    </div>
                )}

                <Popup
                    open={isDeleteCardModalVisible}
                    onCancel={() => setIsDeleteCardModalVisible(false)}
                    title={
                        <>
                            <p>Удалить карту?</p>
                            <span>Все данные безвозвратно удалятся</span>
                        </>
                    }
                    footer={
                        <div>
                            <CustomButton
                                linedBlack
                                onClick={() =>
                                    setIsDeleteCardModalVisible(false)
                                }
                            >
                                Отмена
                            </CustomButton>
                            <CustomButton
                                primary
                                onClick={() => {
                                    deletePartnerCreditCard(id).finally(() => {
                                        setIsDeleteCardModalVisible(false);
                                        navigate("/profile/partnerCabinet");
                                    });
                                }}
                            >
                                Удалить
                            </CustomButton>
                        </div>
                    }
                ></Popup>
            </div>
        </div>
    );
};

export default CardForm;
